import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../context";
import { chevron_left } from "../../images/NewDesign";
import { theme } from "../../utils/theme";
import { NewSelectField } from "../Field";
import { AppErrorText, AppText, SkeletonBlock } from "../UI";
import { PhoenixAppButton, PhoenixCheckbox, PhoenixIcon, PhoenixRadio } from "../UI/Phoenix";

import { IIntegrations } from "../../types";
import { appToast } from "../../utils/toast";

interface FetchSingleRecordExpectedResponse {
  fetchOneIntegrationROObject?: {
    id?: string;
    active?: boolean;
    integration_type?: string;
    lead_fk_field?: string;
    integration_fk_field?: string;
    num_fields?: number;
    object_name?: string;
    organization_id?: string;
    updated_at?: string;
    created_at?: string;
  };
}
const FETCH_RECORD = gql`
  query FetchOneIntegrationROObject($fetchOneIntegrationRoObjectId: String!) {
    fetchOneIntegrationROObject(id: $fetchOneIntegrationRoObjectId) {
      id
      active
      integration_type
      lead_fk_field
      integration_fk_field
      num_fields
      object_name
      # ^ for step 0 (display the name of the record)
      organization_id
      updated_at
      created_at
    }
  }
`;

// display object options (step 1)

interface FetchDisplayObjectOptionsExpectedResponse {
  fetchROIntegrationObjectOptions?: {
    label: string;
    value: string;
  }[];
}

const FETCH_DISPLAY_OBJECT_OPTIONS = gql`
  query fetchROIntegrationObjectOptions($integration: INTEGRATION_TYPE!, $id: String) {
    fetchROIntegrationObjectOptions(integration: $integration, id: $id) {
      label
      value
    }
  }
`;

interface ROFieldDetailInput {
  field_name: string;
  display: boolean;
  data_type: string;
  field_label: string;
}

// display field options (step 2)
interface FetchRecordDisplayOptionsExpectedResponse {
  fetchIntegrationROFieldOptions?: ROFieldDetailInput[];
}

const FETCH_RECORD_DISPLAY_FIELDS = gql`
  query FetchIntegrationROFieldOptions($externalObject: String!, $integration: INTEGRATION_TYPE!) {
    fetchIntegrationROFieldOptions(external_object: $externalObject, integration: $integration) {
      data_type
      display
      field_label
      field_name
    }
  }
`;

// foreign field key options

interface FetchRecordFieldOptionsExpectedResponse {
  fetchIntegrationForeignKeys?: {
    label?: string;
    value?: string;
    integration_fk_field?: string;
  }[];
}

const FETCH_RECORD_FIELD_OPTIONS = gql`
  query fetchIntegrationForeignKeys($externalObject: String!, $integration: INTEGRATION_TYPE!) {
    # external object is the value selected in the first step
    # pre select value is from fetch one objects.lead_fk_field
    fetchIntegrationForeignKeys(external_object: $externalObject, integration: $integration) {
      label
      value
      integration_fk_field
    }
  }
`;

// update record data

const UPDATE_RECORD_DATA = gql`
  mutation UpsertROObject(
    $selectedFields: [ROFieldDetailInput!]!
    $externalObject: String!
    $leadFkField: String!
    $integration: INTEGRATION_TYPE!
    $active: Boolean
    $integrationFkField: String!
  ) {
    upsertROObject(
      selected_fields: $selectedFields
      external_object: $externalObject
      lead_fk_field: $leadFkField
      integration: $integration
      active: $active
      integration_fk_field: $integrationFkField
    ) {
      id
      integration_type
      lead_fk_field
      num_fields
      object_name
      organization_id
      created_at
      updated_at
      __typename
    }
  }
`;

// delete record data

const DELETE_RECORD_DATA = gql`
  mutation DeleteROObject($deleteRoObjectId: String!) {
    deleteROObject(id: $deleteRoObjectId) {
      message
      status
      status_boolean
    }
  }
`;

const BackButton = ({ goBack }: { goBack: () => void }) => {
  return (
    <BackButtonDiv
      onClick={() => {
        goBack();
      }}
    >
      <PhoenixIcon svg={chevron_left} size={16} color={theme.PRIMARY600} />
      <AppText fontSize={10} fontWeight={600} color={theme.PRIMARY600}>
        Back
      </AppText>
    </BackButtonDiv>
  );
};

// STEP 2
interface IField {
  name: string;
  id: string;
  integration_fk_field: string;
}

interface IFieldsSelector {
  selectedFields: ROFieldDetailInput[];
  onChange: (value: ROFieldDetailInput) => void;
  allFields: ROFieldDetailInput[];
  setFieldValue: (field: string, value: any) => void;
}

const FieldsSelector = ({ selectedFields, onChange, allFields, setFieldValue }: IFieldsSelector) => {
  const handleSelectAll = () => {
    setFieldValue("selectedFields", allFields);
  };

  const handleDeselectAll = () => {
    setFieldValue("selectedFields", []);
  };

  return (
    <>
      <FieldsSelectorHeader>
        <FieldsSelectorHeaderButton
          onClick={() => {
            handleSelectAll();
          }}
          disabled={selectedFields?.length === allFields.length}
        >
          Select All
        </FieldsSelectorHeaderButton>
        <FieldsSelectorHeaderButton
          onClick={() => {
            handleDeselectAll();
          }}
          disabled={selectedFields?.length === 0}
        >
          Deselect All
        </FieldsSelectorHeaderButton>
      </FieldsSelectorHeader>
      <div
        style={{
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        {allFields?.map((field: ROFieldDetailInput) => {
          return (
            <FlexDiv key={field.field_name}>
              <PhoenixCheckbox
                checked={selectedFields
                  ?.map((field: ROFieldDetailInput) => field.field_name)
                  .includes(field.field_name)}
                onChange={() => {
                  onChange(field);
                }}
              />
              <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
                {field.field_label}
              </AppText>
            </FlexDiv>
          );
        })}
      </div>
    </>
  );
};

// STEP 3
interface IFieldsKeySelector {
  selected: string;
  onChange: (value: IField) => void;
  allFields: IField[];
  setFieldValue: (field: string, value: any) => void;
}

const FieldTiedToLeadsSelector = ({ selected, onChange, allFields }: IFieldsKeySelector) => {
  return (
    <div
      style={{
        maxHeight: "500px",
        overflow: "auto",
      }}
    >
      {allFields?.map((field: IField) => {
        return (
          <FlexDiv key={field.id}>
            <PhoenixRadio
              selected={selected === field.id}
              onClick={() => {
                onChange(field);
              }}
            />
            <AppText fontSize={12} fontWeight={500} color={theme.BLACK_COLOR}>
              {field.name}
            </AppText>
          </FlexDiv>
        );
      })}
    </div>
  );
};

const SetupRelatedRecordModal = () => {
  // STEP 1 : select the record

  // STEP 2 : select the fields to display

  // STEP 3 : select the key to tie to leads

  // + Delete Record Step

  const { setShowSetupRelatedRecordModal, relatedRecordModalData, setRelatedRecordModalData } = useContext(
    ModalContext,
  );

  const [errorMessage, setErrorMessage] = useState("");

  // fetch the data for that specific record
  const {
    data: recordData,
    loading: recordDataLoading,
    error: recordDataError,
  } = useQuery<FetchSingleRecordExpectedResponse>(FETCH_RECORD, {
    skip: !relatedRecordModalData?.id,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      fetchOneIntegrationRoObjectId: relatedRecordModalData?.id,
    },
    onError: (error) => {
      console.log("error", error);
      appToast("Error fetching Related Record: " + error.message);

      setErrorMessage(error.message);
    },
  });

  // fetch the options for the display object select (STEP 1)
  const {
    data: displayObjectOptionsData,
    loading: displayObjectOptionsLoading,
    error: displayObjectOptionsError,
  } = useQuery<FetchDisplayObjectOptionsExpectedResponse>(FETCH_DISPLAY_OBJECT_OPTIONS, {
    fetchPolicy: "network-only",
    variables: {
      integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
      id: relatedRecordModalData?.id ?? undefined,
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  // fetch the options for the display fields select (STEP 2)

  const [
    fetchDisplayOptions,
    { data: displayOptionsData, loading: displayOptionsLoading, error: displayOptionsError },
  ] = useLazyQuery<FetchRecordDisplayOptionsExpectedResponse>(FETCH_RECORD_DISPLAY_FIELDS, {
    fetchPolicy: "network-only",
    variables: {
      externalObject: recordData?.fetchOneIntegrationROObject?.object_name,
      integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
    },
    onError: (error) => {
      console.log("error", error);

      setErrorMessage(error.message);
    },
  });

  useEffect(() => {
    if (recordData?.fetchOneIntegrationROObject?.object_name) {
      fetchDisplayOptions({
        variables: {
          externalObject: recordData?.fetchOneIntegrationROObject?.object_name,
          integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
        },
      });
    }
  }, [recordData]);

  // fetch the options for the keys field select (STEP 3)

  const [
    fetchKeysOptions,
    { data: keysOptionsData, loading: keysOptionsLoading, error: keysOptionsError },
  ] = useLazyQuery<FetchRecordFieldOptionsExpectedResponse>(FETCH_RECORD_FIELD_OPTIONS, {
    variables: {
      externalObject: recordData?.fetchOneIntegrationROObject?.object_name,
      integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
    },
    onError: (error) => {
      console.log("error", error);

      setErrorMessage(error.message);
    },
  });

  useEffect(() => {
    if (recordData?.fetchOneIntegrationROObject?.object_name) {
      fetchKeysOptions();
    }
  }, [recordData]);

  // update record data mutation

  const [updateRecordData, { data: updateRecordDataResponse }] = useMutation(UPDATE_RECORD_DATA, {
    onCompleted: (data) => {
      appToast("Updated Related Record Successfully!");
      //close modal
      setShowSetupRelatedRecordModal(false);
    },
    onError: (error) => {
      console.log("error", error);
      appToast("Error updating Related Record: " + error.message);

      setErrorMessage(error.message);
    },
    refetchQueries: ["FetchAllIntegrationROObject", "fetchAllIntegrationROObject"],
  });

  const [deleteRecordData, { data: deleteRecordDataResponse }] = useMutation(DELETE_RECORD_DATA, {
    onCompleted: (data) => {
      appToast("Deleted Related Record Successfully!");
      //close modal
      setShowSetupRelatedRecordModal(false);
    },
    onError: (error) => {
      console.log("error", error);
      appToast("Error deleting Related Record: " + error.message);

      setErrorMessage(error.message);
    },
    refetchQueries: ["FetchAllIntegrationROObject", "fetchAllIntegrationROObject"],
  });

  const [step, setStep] = useState(1);

  const [showDeleteRecordStep, setShowDeleteRecordStep] = useState(false);

  const finalStep = 3;

  const loading = recordDataLoading || displayOptionsLoading || keysOptionsLoading || displayObjectOptionsLoading;

  const error = recordDataError || displayOptionsError || keysOptionsError || displayObjectOptionsError;

  interface MyFormikProps {
    relatedRecord: string;
    selectedFields: ROFieldDetailInput[] | [];
    selectedKey: string;
    integration_fk_field: string;
  }

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      validateOnMount
      initialValues={{
        relatedRecord: recordData?.fetchOneIntegrationROObject?.object_name || "",
        selectedFields:
          displayOptionsData?.fetchIntegrationROFieldOptions?.filter((field) => field.display) ||
          ([] as ROFieldDetailInput[]),
        selectedKey: recordData?.fetchOneIntegrationROObject?.lead_fk_field || "",
        integration_fk_field: recordData?.fetchOneIntegrationROObject?.integration_fk_field || "",
      }}
      validationSchema={Yup.object().shape({
        relatedRecord: Yup.string().required("Required"),
        selectedFields: Yup.array()
          .of(
            Yup.object().shape({
              field_name: Yup.string().required("Required"),
              display: Yup.boolean().required("Required"),
              data_type: Yup.string().required("Required"),
              field_label: Yup.string().required("Required"),
            }),
          )
          .required("Required"),
        integration_fk_field: Yup.string().required("Required"),
        selectedKey: Yup.string().required("Required"),
      })}
      onSubmit={async (values) => {
        console.log("values", values);

        updateRecordData({
          variables: {
            selectedFields: values.selectedFields?.map((field) => ({
              field_name: field.field_name,
              data_type: field.data_type,
              field_label: field.field_label,
              // make so that it is always true to display
              display: true,
            })),
            externalObject: values.relatedRecord,
            leadFkField: values.selectedKey,
            integrationFkField: values.integration_fk_field,
            integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
            active: true,
          },
        });
      }}
    >
      {({ submitForm, values, setFieldValue, isValid, dirty, errors }: FormikProps<MyFormikProps>) => {
        console.log("values", values);
        console.log("errors", errors);

        return (
          <Main>
            <TitleDiv>
              <PopupTitle>Salesforce Related Records</PopupTitle>
            </TitleDiv>
            <ScrollingDiv>
              {showDeleteRecordStep ? (
                <>
                  <Spacer height={40} />
                  <AppText
                    fontSize={14}
                    fontWeight={500}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Are you sure you want to delete this related record?
                  </AppText>
                </>
              ) : step === 1 ? (
                <>
                  <Spacer height={40} />
                  <AppText
                    fontSize={12}
                    fontWeight={500}
                    style={
                      // editing a record we can't currently support updating the object name
                      relatedRecordModalData?.id !== undefined
                        ? {
                            color: theme.NEUTRAL300,
                          }
                        : {}
                    }
                  >
                    Select a related record to display
                  </AppText>
                  <Spacer height={16} />
                  {displayObjectOptionsLoading || recordDataLoading ? (
                    <SkeletonBlock height={42} width={"100%"} borderRadius={6} />
                  ) : (
                    <NewSelectField
                      name="relatedRecord"
                      options={displayObjectOptionsData?.fetchROIntegrationObjectOptions ?? []}
                      placeholder="Select a related record"
                      isDisabled={
                        // editing a record we can't currently support updating the object name
                        relatedRecordModalData?.id !== undefined
                      }
                      disabledText={relatedRecordModalData?.id !== undefined}
                      onChangeSideEffect={(value) => {
                        // fetch the display options for that object for step 2 (display fields)
                        fetchDisplayOptions({
                          variables: {
                            externalObject: value?.value,
                            integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
                          },
                        });

                        // fetch the keys options for that object for step 3 (tied to leads)
                        fetchKeysOptions({
                          variables: {
                            externalObject: value?.value,
                            integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
                          },
                        });

                        // reset the selected fields in formik to prevent edge case where user selects a record with different fields and then goes back
                        setFieldValue("selectedFields", []);
                        setFieldValue("selectedKey", "");
                        setFieldValue("integration_fk_field", "");
                      }}
                    />
                  )}
                  {error && (
                    <>
                      <Spacer height={16} />
                      <AppErrorText>{errorMessage}</AppErrorText>
                    </>
                  )}
                </>
              ) : step === 2 ? (
                <>
                  <Spacer height={24} />
                  <BackButton goBack={() => setStep(step - 1)} />
                  <Spacer height={40} />
                  <AppText fontSize={12} fontWeight={500}>
                    Select the fields you want to display
                  </AppText>
                  <Spacer height={16} />
                  {loading ? (
                    <SkeletonBlock height={200} width={"100%"} borderRadius={6} />
                  ) : (
                    <FieldsSelector
                      selectedFields={values.selectedFields}
                      allFields={
                        displayOptionsData?.fetchIntegrationROFieldOptions
                          ?.slice()
                          ?.sort((a: ROFieldDetailInput, b: ROFieldDetailInput) => {
                            // return alphabetical order
                            return a?.field_label?.localeCompare(b?.field_label) ?? 0;
                          }) ?? ([] as ROFieldDetailInput[])
                      }
                      onChange={(value: ROFieldDetailInput) => {
                        // if it is not in the selected fields, add it
                        const selectedFieldsIds = values.selectedFields?.map((field) => field.field_name);

                        if (!selectedFieldsIds.includes(value?.field_name)) {
                          setFieldValue("selectedFields", [...values.selectedFields, value]);
                        }

                        // if it is in the selected fields, remove it
                        if (selectedFieldsIds.includes(value.field_name)) {
                          setFieldValue(
                            "selectedFields",
                            values.selectedFields.filter((field) => {
                              return field.field_name !== value.field_name;
                            }),
                          );
                        }
                      }}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {error && (
                    <>
                      <Spacer height={16} />
                      <AppErrorText>{errorMessage}</AppErrorText>
                    </>
                  )}
                </>
              ) : step === 3 ? (
                <>
                  <Spacer height={24} />
                  <BackButton goBack={() => setStep(step - 1)} />
                  <Spacer height={40} />
                  <AppText fontSize={12} fontWeight={500}>
                    Select which field should be tied to OPSIQ leads
                  </AppText>
                  <Spacer height={16} />
                  <Spacer height={16} />
                  {loading ? (
                    <SkeletonBlock height={200} width={"100%"} borderRadius={6} />
                  ) : (
                    <FieldTiedToLeadsSelector
                      selected={values.selectedKey}
                      allFields={
                        keysOptionsData?.fetchIntegrationForeignKeys?.map((field) => ({
                          name: field.label ?? "",
                          id: field.value ?? "",
                          integration_fk_field: field.integration_fk_field ?? "",
                        })) ?? []
                      }
                      onChange={(value: IField) => {
                        // set the key
                        setFieldValue("selectedKey", value.id);

                        // (v2 update) set the integration_fk_field tied to the key
                        setFieldValue("integration_fk_field", value.integration_fk_field);
                      }}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {error && (
                    <>
                      <Spacer height={16} />
                      <AppErrorText>{errorMessage}</AppErrorText>
                    </>
                  )}
                </>
              ) : (
                <AppErrorText>Something went wrong</AppErrorText>
              )}
            </ScrollingDiv>
            {showDeleteRecordStep ? (
              <SubmitDiv>
                <PhoenixAppButton
                  disabled={loading}
                  buttonType="secondary"
                  variant="brand"
                  onClick={() => {
                    setShowDeleteRecordStep(false);
                  }}
                >
                  No, Cancel
                </PhoenixAppButton>
                <Spacer height={16} />
                <PhoenixAppButton
                  disabled={loading}
                  buttonType="secondary"
                  variant="danger-outline"
                  onClick={async () => {
                    await deleteRecordData({
                      variables: {
                        deleteRoObjectId: relatedRecordModalData?.id,
                      },
                    });
                  }}
                >
                  Yes,Delete
                </PhoenixAppButton>
              </SubmitDiv>
            ) : (
              <SubmitDiv>
                {relatedRecordModalData?.id ? (
                  <PhoenixAppButton
                    buttonType="secondary"
                    variant="danger-outline"
                    onClick={() => {
                      setShowDeleteRecordStep(true);
                    }}
                    disabled={loading}
                  >
                    Delete
                  </PhoenixAppButton>
                ) : (
                  <div />
                )}
                <PhoenixAppButton
                  buttonType="secondary"
                  variant="brand"
                  disabled={
                    loading ||
                    !!error ||
                    (step === 1
                      ? // haven't picked a record
                        !values.relatedRecord || values.relatedRecord === ""
                      : // havent picked a field
                      step === 2
                      ? !values.selectedFields || values.selectedFields.length === 0
                      : // step 3 (final step)

                        // ideally we would use the isValid prop, but we had validation timing issues

                        // todo: update this to use isValid prop to check all values at the last step
                        !values.selectedKey ||
                        values.selectedKey === "" ||
                        !values.integration_fk_field ||
                        values.integration_fk_field === "")
                  }
                  onClick={() => {
                    if (step === finalStep) {
                      submitForm();
                    } else {
                      setStep(step + 1);
                    }
                  }}
                >
                  {step === finalStep ? "Finish" : "Next"}
                </PhoenixAppButton>
              </SubmitDiv>
            )}
          </Main>
        );
      }}
    </Formik>
  );
};

interface CustomHeightProps {
  customHeight?: boolean;
}

const Main = styled.div`
  width: 425px;
  height: 100%;
`;

const SubmitDiv = styled.div<CustomHeightProps>`
  position: absolute;

  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin-top: auto;
  margin-bottom: ${(props) => (props.customHeight ? "0px" : "5px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleDiv = styled.div`
  height: 62px;
  border-bottom: solid 1px ${theme.NEUTRAL200};

  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
  justify-content: center;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div<CustomHeightProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  width: 100%;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
  max-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
`;

const FieldsSelectorHeader = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;
const FieldsSelectorHeaderButton = styled.div<{ disabled: boolean }>`
  padding: 4px;
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => (props.disabled ? theme.PRIMARY200 : theme.PRIMARY600)};
  text-transform: uppercase;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
`;
const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  width: "100%";
`;

const BackButtonDiv = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;
export { SetupRelatedRecordModal };
