import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppButton, AppText, AppInput, Loading, AppErrorText } from "../../UI";
import { NewAppButton } from "../../UI/NewAppButton";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField, FormSelectField } from "../../Field";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { sortByMonthAndYear } from "../../../utils/sorts";
import { useHistory } from "react-router-dom";
import { appToast, successToast } from "../../../utils/toast";
import { iconNewCaretDown } from "../../../images";
import { loggedInUser } from "../../../apollo/cache";
import { values } from "lodash";
import ReactTooltip from "react-tooltip";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

interface MyFormikProps {
  skipped_cold_call_rest: number;
  short_resting: number;
  resting: number;
  self_sourcing_rule: number;
  dial_rule: number;
  made_contact_rule: number;
  days_before_release: number;
  claim_rule: number;
  rep_claim_limit: number;
}

const stepRestingRules = Yup.object().shape({
  skipped_cold_call_rest: Yup.number().required(),
  short_resting: Yup.number().required(),
  resting: Yup.number().required(),
  self_sourcing_rule: Yup.number().required(),
  dial_rule: Yup.number().required(),
  made_contact_rule: Yup.number().required(),
  days_before_release: Yup.number().required(),
  claim_rule: Yup.number().required(),
  rep_claim_limit: Yup.number().required(),
});

const ORGANIZATION_DATA = gql`
  {
    fetchOrganization {
      id
      release_lead_resting
      short_resting
      resting_period
      self_sourcing_rule
      dial_rule
      made_contact_rule
      lead_release_same_rep_delay_rule
      claim_rule
      rep_claim_limit
    }
  }
`;

const UPDATE_ORGANIZATION_REST_RULE = gql`
  mutation updateOrganizationSettings(
    $long_rest_interval: Int!
    $short_rest_interval: Int!
    $release_rest_interval: Int!
    $self_sourcing_rule: Int!
    $dial_rule: Int!
    $made_contact_rule: Int!
    $lead_release_same_rep_delay_rule: Int!
    $claim_rule: Int!
    $rep_claim_limit: Int!
  ) {
    updateOrganizationSettings(
      long_rest_interval: $long_rest_interval
      short_rest_interval: $short_rest_interval
      release_rest_interval: $release_rest_interval
      self_sourcing_rule: $self_sourcing_rule
      dial_rule: $dial_rule
      made_contact_rule: $made_contact_rule
      lead_release_same_rep_delay_rule: $lead_release_same_rep_delay_rule
      claim_rule: $claim_rule
      rep_claim_limit: $rep_claim_limit
    ) {
      id
      release_lead_resting
      short_resting
      resting_period
      self_sourcing_rule
      dial_rule
      made_contact_rule
      claim_rule
      rep_claim_limit
    }
  }
`;

const StepRestingRules: React.FC<DisappearingDivProps> = ({ step, blinds, setBlinds, isOnboarding }) => {
  const history = useHistory();

  const short_resting_min = 0;
  const short_resting_max = 2 ** 32 - 1;
  const resting_min = 0;
  const resting_max = 2 ** 32 - 1;
  const skipped_cold_call_rest_min = 0;
  const skipped_cold_call_rest_max = 2 ** 32 - 1;

  const { data: dataOrg, loading: loadingOrg, error: errorOrg, refetch: refetchOrg } = useQuery(ORGANIZATION_DATA, {
    fetchPolicy: "network-only",
    variables: {
      org_id: loggedInUser().organization_id,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [updateOrganizationSettings, { loading: loadingRules, error: errorRules }] = useMutation(
    UPDATE_ORGANIZATION_REST_RULE,
    {
      async onCompleted({ updateOrganizationSettings }) {
        if (!updateOrganizationSettings) {
          appToast("Error updating Rules of Engagement. Something went wrong.");
          return;
        }
        successToast("Updated Rules of Engagement");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `updateOrganizationSettings GraphQL Error: ${message}`,
        });
        console.log("Error in updateOrganizationSettings: ", message);
      },
    },
  );

  if (loadingOrg) return <Loading />;
  if (errorOrg) return <AppErrorText>Error loading data</AppErrorText>;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        skipped_cold_call_rest: dataOrg.fetchOrganization.release_lead_resting,
        short_resting: dataOrg.fetchOrganization.short_resting,
        resting: dataOrg.fetchOrganization.resting_period,
        self_sourcing_rule: dataOrg.fetchOrganization.self_sourcing_rule,
        dial_rule: dataOrg.fetchOrganization.dial_rule,
        made_contact_rule: dataOrg.fetchOrganization.made_contact_rule,
        days_before_release: dataOrg.fetchOrganization.lead_release_same_rep_delay_rule,
        claim_rule: dataOrg.fetchOrganization.claim_rule,
        rep_claim_limit: dataOrg.fetchOrganization.rep_claim_limit,
      }}
      validationSchema={stepRestingRules}
      onSubmit={async (values) => {
        await updateOrganizationSettings({
          variables: {
            release_rest_interval: Number(values.skipped_cold_call_rest),
            short_rest_interval: Number(values.short_resting),
            long_rest_interval: Number(values.resting),
            self_sourcing_rule: Number(values.self_sourcing_rule),
            dial_rule: Number(values.dial_rule),
            made_contact_rule: Number(values.made_contact_rule),
            lead_release_same_rep_delay_rule: Number(values.days_before_release),
            claim_rule: Number(values.claim_rule),
            rep_claim_limit: Number(values.rep_claim_limit),
          },
        });
        if (isOnboarding === true) {
          history.push(`/onboarding?step=${step + 1}`);
        }
      }}
    >
      {({ dirty, isValid, submitForm, values, setFieldValue }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);
        return (
          <DisappearingDiv>
            <LocalWrapper isOnboarding={isOnboarding}>
              <ReactTooltip
                multiline
                place="top"
                effect="solid"
                css={{
                  maxWidth: 600,
                  lineHeight: 1.4,
                  textAlign: "center",
                  fontFamily: theme.PRIMARY_FONT,
                }}
                id="resting"
                backgroundColor={theme.PRIMARY800}
                getContent={(dataTip) => (
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "10px",
                      lineHeight: "14px",
                    }}
                  >
                    {dataTip}
                  </span>
                )}
              />
              {/* <TopAlignDiv> */}
              <TopContentDiv>
                <SegmentTitle>Resting Rules</SegmentTitle>

                <LabelText>
                  Released Lead Resting<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="skipped_cold_call_rest"
                    type="number"
                    max={skipped_cold_call_rest_max}
                    min={skipped_cold_call_rest_min}
                    value={values.skipped_cold_call_rest}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= skipped_cold_call_rest_max && value >= skipped_cold_call_rest_min) {
                        setFieldValue("skipped_cold_call_rest", value);
                      }
                    }}
                  />

                  <AbsDivTop
                    disabled={values.skipped_cold_call_rest >= skipped_cold_call_rest_max}
                    onClick={() =>
                      values.skipped_cold_call_rest < skipped_cold_call_rest_max &&
                      setFieldValue("skipped_cold_call_rest", Number(values.skipped_cold_call_rest) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.skipped_cold_call_rest <= skipped_cold_call_rest_min}
                    onClick={() =>
                      values.skipped_cold_call_rest > skipped_cold_call_rest_min &&
                      setFieldValue("skipped_cold_call_rest", Number(values.skipped_cold_call_rest) - 1)
                    }
                  />
                </RelDiv>

                <LabelText>
                  Minimum Until Lead Reassigned to Rep Who Released<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "}
                  <TooltipText
                    data-tip="If a rep releases a lead, this is the minimum amount of time that they will not be reassigned the same lead."
                    data-for="resting"
                  >
                    ?
                  </TooltipText>
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="days_before_release"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.days_before_release}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("days_before_release", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.days_before_release >= resting_max}
                    onClick={() =>
                      values.days_before_release < resting_max &&
                      setFieldValue("days_before_release", Number(values.days_before_release) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.days_before_release <= resting_min}
                    onClick={() =>
                      values.days_before_release > resting_min &&
                      setFieldValue("days_before_release", Number(values.days_before_release) - 1)
                    }
                  />
                </RelDiv>

                <LabelText>
                  Regular Resting<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "}
                  <TooltipText
                    data-tip="The amount of time leads are removed from circulation if they enter the resting phase prior to a demo."
                    data-for="resting"
                  >
                    ?
                  </TooltipText>
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="short_resting"
                    type="number"
                    max={short_resting_max}
                    min={short_resting_min}
                    value={values.short_resting}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= short_resting_max && value >= short_resting_min) {
                        setFieldValue("short_resting", value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.short_resting >= short_resting_max}
                    onClick={() =>
                      values.short_resting < short_resting_max &&
                      setFieldValue("short_resting", Number(values.short_resting) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.short_resting <= short_resting_min}
                    onClick={() =>
                      values.short_resting > short_resting_min &&
                      setFieldValue("short_resting", Number(values.short_resting) - 1)
                    }
                  />
                </RelDiv>
                <LabelText>
                  Long Resting<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "}
                  <TooltipText
                    data-tip="The amount of time leads are removed from circulation if a prospect saw a demo and said “no.”"
                    data-for="resting"
                  >
                    ?
                  </TooltipText>
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="resting"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.resting}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("resting", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.resting >= resting_max}
                    onClick={() =>
                      values.short_resting < resting_max && setFieldValue("resting", Number(values.resting) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.resting <= resting_min}
                    onClick={() => values.resting > resting_min && setFieldValue("resting", Number(values.resting) - 1)}
                  />
                </RelDiv>

                <SegmentTitle style={{ marginTop: "12px" }}>Ownership Rules</SegmentTitle>

                <LabelText>
                  Self-Sourcing<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "}
                  {/* <TooltipText
                    data-tip="Resting days after self sourcing"
                    data-for="resting"
                  >
                    ?
                  </TooltipText> */}
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="self_sourcing_rule"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.self_sourcing_rule}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("self_sourcing_rule", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.self_sourcing_rule >= resting_max}
                    onClick={() =>
                      values.self_sourcing_rule < resting_max &&
                      setFieldValue("self_sourcing_rule", Number(values.self_sourcing_rule) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.self_sourcing_rule <= resting_min}
                    onClick={() =>
                      values.self_sourcing_rule > resting_min &&
                      setFieldValue("self_sourcing_rule", Number(values.self_sourcing_rule) - 1)
                    }
                  />
                </RelDiv>

                <LabelText>
                  Dialed<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "}
                  {/* <TooltipText data-tip="Resting days after dialed" data-for="resting">
                    ?
                  </TooltipText> */}
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="dial_rule"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.dial_rule}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("dial_rule", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.dial_rule >= resting_max}
                    onClick={() =>
                      values.dial_rule < resting_max && setFieldValue("dial_rule", Number(values.dial_rule) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.dial_rule <= resting_min}
                    onClick={() =>
                      values.dial_rule > resting_min && setFieldValue("dial_rule", Number(values.dial_rule) - 1)
                    }
                  />
                </RelDiv>

                <LabelText>
                  Made Contact<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "}
                  {/* <TooltipText data-tip="Resting days after made contact" data-for="resting">
                    ?
                  </TooltipText> */}
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="made_contact_rule"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.made_contact_rule}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("made_contact_rule", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.made_contact_rule >= resting_max}
                    onClick={() =>
                      values.made_contact_rule < resting_max &&
                      setFieldValue("made_contact_rule", Number(values.made_contact_rule) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.made_contact_rule <= resting_min}
                    onClick={() =>
                      values.made_contact_rule > resting_min &&
                      setFieldValue("made_contact_rule", Number(values.made_contact_rule) - 1)
                    }
                  />
                </RelDiv>

                <LabelText>
                  Claim from the Lead Pool<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(in days)</span>{" "}
                  <TooltipText
                    data-tip="The number of days leads are owned by reps after they claim them from the lead pool"
                    data-for="resting"
                  >
                    ?
                  </TooltipText>
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="claim_rule"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.claim_rule}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("claim_rule", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.claim_rule >= resting_max}
                    onClick={() =>
                      values.claim_rule < resting_max && setFieldValue("claim_rule", Number(values.claim_rule) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.claim_rule <= resting_min}
                    onClick={() =>
                      values.claim_rule > resting_min && setFieldValue("claim_rule", Number(values.claim_rule) - 1)
                    }
                  />
                </RelDiv>
                <LabelText>
                  Daily Rep Claim Limit<span style={{ color: theme.ATTENTION700 }}>*</span>{" "}
                  <span style={{ color: theme.NEUTRAL400 }}>(# of leads)</span>{" "}
                  <TooltipText
                    data-tip="The maximum number of leads that a rep can claim from the lead pool in a day"
                    data-for="resting"
                  >
                    ?
                  </TooltipText>
                </LabelText>
                <RelDiv>
                  <NumberInput
                    name="rep_claim_limit"
                    type="number"
                    max={resting_max}
                    min={resting_min}
                    value={values.rep_claim_limit}
                    onChange={(e: any) => {
                      const value = e.target?.value;
                      if (value <= resting_max && value >= resting_min) {
                        setFieldValue("rep_claim_limit", e.target.value);
                      }
                    }}
                  />
                  <AbsDivTop
                    disabled={values.rep_claim_limit >= resting_max}
                    onClick={() =>
                      values.rep_claim_limit < resting_max &&
                      setFieldValue("rep_claim_limit", Number(values.rep_claim_limit) + 1)
                    }
                  />
                  <AbsDivTop
                    bottom
                    disabled={values.rep_claim_limit <= resting_min}
                    onClick={() =>
                      values.rep_claim_limit > resting_min &&
                      setFieldValue("rep_claim_limit", Number(values.rep_claim_limit) - 1)
                    }
                  />
                </RelDiv>
              </TopContentDiv>
              {/* {JSON.stringify(values)} */}
              {isOnboarding === true ? (
                <CenterContentDiv>
                  <OldSaveAndContinueButton disabled={!(dirty && isValid)} type="submit" onClick={submitForm}>
                    Save & Continue
                  </OldSaveAndContinueButton>
                </CenterContentDiv>
              ) : (
                <CenterDiv>
                  <SaveAndContinueButton
                    disabled={!(dirty && isValid)}
                    type="submit"
                    onClick={submitForm}
                    variant={"primary"}
                  >
                    Save Changes
                  </SaveAndContinueButton>
                </CenterDiv>
              )}
            </LocalWrapper>
          </DisappearingDiv>
        );
      }}
    </Formik>
  );
};

const SegmentTitle = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
`;

const OldSaveAndContinueButton = styled(AppButton)`
  margin: 20px auto;
  border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500;
  width: 221px;
  height: 47px;
`;

const CenterContentDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RelDiv = styled.div`
  position: relative;
  width: 100%;
`;

interface CaretProps {
  disabled: boolean;
  bottom?: boolean;
}

const AbsDivTop = styled.div<CaretProps>`
  display: flex;
  background: url(${iconNewCaretDown}) no-repeat;
  background-position: 5px 13px;
  position: absolute;
  right: 10px;
  top: ${(props) => (props.bottom ? "20px" : "0px")};
  transform: ${(props) => (props.bottom ? "rotate(180deg)" : "rotate(0deg)")};
  width: 20px;
  height: 20px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const LabelText = styled(AppText)`
  margin-bottom: 8px;
`;

const TooltipText = styled.span`
  /* margin-bottom: 8px; */
  background-color: ${theme.NEUTRAL200};
  padding: 0px 5px;
  border-radius: 50%;
  min-width: 15px;
  height: 15px;
  margin-left: 8px;
  opacity: 0.7;
  font-size: 12px;
  cursor: help;
`;

const NumberInput = styled.input`
  height: 40px;
  padding-left: 12px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 2px;
  border: 1px solid ${theme.NEUTRAL200};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  :focus {
    /* border-bottom-color: ${theme.PRIMARY500}; */
    outline: none;
    border: 1px solid ${theme.NEUTRAL400};
  }
  ::placeholder {
    opacity: 0.4;
  }
  :hover {
    border: 1px solid ${(props) => (props.disabled ? theme.NEUTRAL200 : theme.NEUTRAL400)};
  }
`;
interface IsOnboardingProp {
  isOnboarding?: boolean;
}

const RevenueText = styled(AppText)`
  width: 150px;
  font-size: 14px;
`;

const LocalWrapper = styled.div<IsOnboardingProp>`
  width: 420px;
  margin: ${(props) => (props.isOnboarding ? "20px auto" : "0px")};
`;

const RevenueTotalText = styled(AppText)`
  display: inline;
  font-size: 14px;
  font-weight: 600;
  line-height: 35px;
  margin-top: 12px;
  margin-bottom: 20px;
`;

const SelectLabelText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
`;

const SwtichLabelText = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  margin-left: 0px;
`;

const TableTitle = styled(AppText)<IsOnboardingProp>`
  width: ${(props) => (props.isOnboarding ? "300px" : "450px")};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 21px;
`;

const TableHeading = styled(AppText)`
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 21px;
  margin-top: 10px;
`;

const RevenueContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AmountInput = styled(InputField)`
  height: 35px;
  border-radius: 2.9px;
  border: solid 1px ${theme.NEUTRAL200};
  font-size: 13px;
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
  ::placeholder {
    font-size: 13px;
  }
`;

const RevenueRow = styled.div<IsOnboardingProp>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isOnboarding ? "300px" : "450px")};
  margin-top: -10px;
  margin-bottom: -10px;
`;

const RevenueTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const RevenueSelect = styled(FormSelectField)`
  height: 40px;
  width: 179px;
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  margin-top: 20px;
`;

const TopContentDiv = styled.div`
  width: 364px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-around; */
  /* margin: 22px 0px; */
  margin-bottom: 16px;
`;

const DisappearingDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SaveAndContinueButton = styled(NewAppButton)`
  /* margin: 20px auto; */
  /* border-radius: 2.9px;
  font-size: 15px;
  font-weight: 500; */
  width: 155px;
  height: 48px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { StepRestingRules };
