import * as React from "react";
import { toast, ToastOptions } from "react-toastify";
import { StyledToast } from "../Components/Dumb/StyledToast";
import { TOAST_CLOSE_DURATION } from "./variables";

export const TOAST_DEFAULT_OPTIONS: ToastOptions = {
  hideProgressBar: true,
  autoClose: TOAST_CLOSE_DURATION,
};
// export const appToast = (message: string, options?: ToastOptions) => {
//   toast(message, { ...TOAST_DEFAULT_OPTIONS, ...options });
// };

export const appToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"primary"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });
};

export const successToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"success"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });
};

export const errorToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"error"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });
};

export const warningToast = (message: string, options?: ToastOptions, subtext?: string) => {
  toast(<StyledToast message={message} subtext={subtext} variant={"warning"} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
  });
};

export const notificationToast = ({
  message,
  subtext,
  variant,
  options,
  id,
  lead_id,
  redirect,
}: {
  message: string;
  subtext?: string;
  variant: "primary" | "success" | "error" | "warning";
  options?: ToastOptions;
  id?: string;
  route?: string;
  lead_id?: string;
  redirect?: string;
}) => {
  toast(<StyledToast message={message} subtext={subtext} variant={variant} lead_id={lead_id} redirect={redirect} />, {
    ...TOAST_DEFAULT_OPTIONS,
    ...options,
    toastId: id,
  });
};

export const customToast = (component: React.ReactNode, options?: ToastOptions) => {
  toast(component, { ...TOAST_DEFAULT_OPTIONS, ...options });
};
