import styled, { css } from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { theme } from "../../utils/theme";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AppErrorText, AppText, Loading } from "../UI";
import { useState, useContext, useEffect, useMemo, useRef } from "react";
import { CallReportItem } from "../Smart/CallReportItem";
import { FaCaretDown, FaCaretUp, FaPlayCircle } from "react-icons/fa";
import { GridFilterContext, ModalContext } from "../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  camelToProperSplit,
  formatBusinessName,
  formatCallDuration,
  formatCellData,
  formatImportDate,
} from "../../utils/format";
import GreenCheck from "../../images/icons-ic-notifications-accept.svg";
import { iconDownload, iconSave } from "../../images";
import { downloadResourceCors } from "../../utils/misc";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { appToast } from "../../utils/toast";
import { AppTable, TableRow, TableTD, TableTH } from "../UI/AppTable";
import { ACTIVITY_COLUMN_DATA } from "./DashboardSegments/GridInfoTableV2";
import { isUserRep, loggedInUser } from "./../../apollo/cache";
import { useFlags } from "launchdarkly-react-client-sdk";
const FETCH_CALL_REPORTS_V2 = gql`
  query fetchCallReportListV2($dashboard_detail_args: DashboardDetailArgument!) {
    fetchCallReportListV2(dashboard_detail_args: $dashboard_detail_args) {
      id
      created_at
      communication_type
      call_end_reason
      stat_item_aggregation {
        id
        revenue
        revenue_value
        recurring_revenue
        days_set_out
        #    skip
        #   release
      }
      conference {
        id
        duration
        start_time
        recording_url_computed
        __typename
      }
      related_disposition {
        id
        phase
        person_spoke_to
        type
        label
        associated_action
        __typename
      }
      schedule_item {
        id
        start_time
        action_label
        resulting_activity {
          id
          related_disposition {
            id
            type
            label
          }
        }
        __typename
      }
      user {
        id
        first_name
        last_name
        __typename
      }
      sale_cycle {
        cycle_num
        lead_id
        set
        hold
        close
        set_user {
          full_name
        }
        close_user {
          full_name
        }
        num_dials
        __typename
      }
      lead {
        id
        first_name
        last_name
        business_name
        industry
        sub_industry
        lead_source
        # dials
        __typename
      }
      __typename
    }
  }
`;

const FETCH_CALL_REPORT_TOTALS = gql`
  query fetchCallReportSetHoldSale($dashboard_detail_args: DashboardDetailArgument!) {
    fetchCallReportSetHoldSale(dashboard_detail_args: $dashboard_detail_args) {
      total_set
      total_held
      total_closed
    }
  }
`;

const FETCH_USER_CALL_REPORT_COLUMNS = gql`
  query fetchUserCallReportColumns {
    fetchUser {
      id
      visible_call_report_columns_computed
    }
  }
`;

interface CallReportProps {
  userID?: string;
  teamID?: string;
  siteID?: string;
  leadID?: string;
  orgID?: string;
  metric?: string;
  gridFilter: boolean;
  callReportPage?: boolean;
  computedID?: string;
  position?: string;
  v2bool?: boolean;
}

const CallReportListV2: React.FC<CallReportProps> = (props) => {
  const [sortAscending, setSortAscending] = useState(false);
  const {
    dateLabel,
    dateStart,
    dateEnd,
    products,
    channel,
    gridFilter,
    repFilter,
    measureBy,
    leadFilterArgs,
    repFilterArgs,
    dateFilterArgs,
  } = useContext(GridFilterContext);

  const [reportsList, setReportsList] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skip, setSkip] = useState(0);
  const [hasToBeRefetched, setHasToBeRefetched] = useState(false);

  const { disableSortDrilldownOptions } = useFlags();

  // V2 params
  const [orderBy, setOrderBy] = useState(!!props.metric && props.metric === "Pipeline" ? "EventTime" : "CallTime");

  const takeNumber = 7;

  const { saveCallToLibraryModal, setSaveCallToLibraryModal, currentConferenceID, setCurrentConferenceID } = useContext(
    ModalContext,
  );

  const { data: listData, loading: listLoading, error: listError, refetch, called } = useQuery(FETCH_CALL_REPORTS_V2, {
    variables: {
      dashboard_detail_args: {
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: {
          date_range: dateLabel,
          lowerbound_date: dateStart,
          upperbound_date: dateEnd,
        },
        measure_by: measureBy,
        metric: !!props.metric ? props.metric : undefined,
        position: !!props.position ? props.position : undefined,
        computed_id: !!props.computedID ? props.computedID : undefined,
        take: takeNumber,
        skip: skip,
        desc: !sortAscending,
        order_by: orderBy,
        team_id: props.teamID ?? undefined,
        site_id: props.siteID ?? undefined,
      },
    },
    fetchPolicy: "network-only",
    onCompleted() {
      const newList = [...reportsList, ...listData.fetchCallReportListV2].reduce(
        (acc, cv) => (acc?.map((a: any) => a.id)?.includes(cv.id) ? acc : [...acc, cv]),
        [],
      );
      setReportsList(newList);
      // setReportsList(reportsList.concat(listData.fetchCallReportList));
      if (listData?.fetchCallReportListV2?.length < takeNumber) setHasMore(false);
      else setHasMore(true);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const {
    data: dataTotals,
    loading: loadingTotals,
    error: errorTotals,
    refetch: refetchTotals,
    called: calledTotals,
  } = useQuery(FETCH_CALL_REPORT_TOTALS, {
    variables: {
      dashboard_detail_args: {
        lead_filter: leadFilterArgs,
        rep_filter: repFilterArgs,
        date_filter: dateFilterArgs,
        metric: !!props.metric ? props.metric : undefined,
        position: !!props.position ? props.position : undefined,
        computed_id: !!props.computedID ? props.computedID : undefined,
        desc: !sortAscending,
        order_by: orderBy,
        measure_by: measureBy,
      },
    },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in fetchCallReportSetHoldSale: `, message);
    },
  });
  const { data: dataColumns, loading: loadingColumns, error: errorColumns } = useQuery(FETCH_USER_CALL_REPORT_COLUMNS, {
    fetchPolicy: "network-only",
    onError({ message }) {
      // Sentry.captureEvent({
      //   message: `fetchUser for Call Report Columns GraphQL Error: ${message}`,
      // });
      console.log(`Error in fetchUser for Call Report Columns: `, message);
      appToast(message);
    },
  });

  const handleShowMore = () => {
    setSkip(skip + takeNumber);
  };

  useEffect(() => {
    if (!called) {
      return;
    }
    setReportsList([]);
    setSkip(0);
    setHasMore(true);
  }, [
    props.gridFilter,
    props.leadID,
    props.metric,
    props.orgID,
    props.teamID,
    props.userID,
    props.computedID,
    props.position,
    channel,
    products,
    dateStart,
    dateEnd,
    sortAscending,
    orderBy,
  ]);

  const handleColHeaderClick = (metric: string) => {
    if (!!disableSortDrilldownOptions) {
      const disableList = ["Set", "Held", "Close", "ClosedByUser", "SetByUser"];
      // dont update sort state if metric is one of ^^^
      if (disableList.includes(metric)) return;
    }
    if (orderBy === metric) {
      setSortAscending(!sortAscending);
    } else {
      setOrderBy(metric);
    }
  };

  const visibleColumns = useMemo(() => {
    return dataColumns?.fetchUser?.visible_call_report_columns_computed ?? [];
  }, [dataColumns]);

  if (listError || errorColumns)
    return (
      <ListContainer callReportPage={props.callReportPage}>
        <AppErrorText>Error Loading Items</AppErrorText>
      </ListContainer>
    );

  if (loadingColumns)
    return (
      <ListContainer callReportPage={props.callReportPage}>
        <Loading />
      </ListContainer>
    );

  // If no call reports available.
  if (!listLoading && reportsList.length === 0 && listData?.fetchCallReportListV2?.length === 0)
    return (
      <ListContainer
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        callReportPage={props.callReportPage}
      >
        <AppText>No reports to display.</AppText>
      </ListContainer>
    );

  const numberOfAdjustableColumns = visibleColumns
    .slice()
    .filter((item: string) => !["Set", "Held", "Close"].includes(item)).length;

  const RenderTableHeaderFor = (metric: string) => {
    const METRIC_LABELS = {
      CallTime: "Time of Call",
      LeadName: "Contact Name",
      BusinessName: "Business Name",
      SetByUser: "Sales Rep Who Set Demo",
      ClosedByUser: "Sales Rep Who made Sale",
      RepName: "Sales Rep",
      CallResult: "Call Result",
      AssociatedAction: "Associated Action",
      EventTime: "Time of Event",
      Industry: "Industry",
      SubIndustry: "Sub-Industry",
      LeadSource: "Lead Source",
      Revenue: "Revenue",
      RevenueValue: "Revenue Value",
      RecurringRevenue: "Recurring Revenue",
      Set: "Set",
      Held: "Held",
      Close: "Close",
      DaysSetOut: "Days Set Out",
      DialsInCurrentSalesCycle: "Dials: Current Sales Cycle",
      CallEndReason: "Call End Reason",
    };

    // @ts-ignore
    let label = METRIC_LABELS[metric];
    let style: any = { whiteSpace: "nowrap" };

    return {
      label: label,
      onClick: () => handleColHeaderClick(metric),
      isAscending: sortAscending,
      showArrow: orderBy === metric,
      style: style,
    };
  };

  const RenderTableDataFor = (metric: string, item: any) => {
    switch (metric) {
      case "CallTime":
        const isMetricRelevant =
          !!props.metric &&
          (["AllSales", "TotalRevenueValue", "TotalRevenue"].includes(props.metric) ||
            ACTIVITY_COLUMN_DATA.filter((item: any) => item.metric === props?.metric)?.length > 0);
        const isPipelineMetric = props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric);
        const isNonCall = item?.communication_type === "NonCall";

        const dateToDisplay = isMetricRelevant
          ? item.created_at
          : isPipelineMetric
          ? item.conference?.start_time
          : isNonCall
          ? item?.created_at
          : item?.conference?.start_time ?? item?.created_at;

        return (
          <TableTD fixedWidth="180px">
            <ListItemTextWrap>{formatImportDate(dateToDisplay)}</ListItemTextWrap>
          </TableTD>
        );
      case "LeadName":
        return (
          <TableTD fixedWidth="150px">
            <ListItemTextWrap>{`${item.lead?.first_name ?? ""} ${item.lead?.last_name ?? ""}`}</ListItemTextWrap>
          </TableTD>
        );
      case "BusinessName":
        return (
          <TableTD fixedWidth="150px" style={{ cursor: "pointer" }}>
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.lead?.id ?? ""}`}>
              <ListItemTextWrap>{formatBusinessName(item.lead?.business_name)}</ListItemTextWrap>
            </WrapAnchor>
          </TableTD>
        );
      case "SetByUser":
        return (
          <TableTD fixedWidth="120px" style={{ cursor: "pointer" }}>
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.lead?.id ?? ""}`}>
              <ListItemTextWrap>{item.sale_cycle?.set_user?.full_name ?? ""}</ListItemTextWrap>
            </WrapAnchor>
          </TableTD>
        );
      case "ClosedByUser":
        return (
          <TableTD fixedWidth="120px" style={{ cursor: "pointer" }}>
            <WrapAnchor target="_blank" rel="noopener noreferrer" href={`/lead-detail/${item?.lead?.id ?? ""}`}>
              <ListItemTextWrap>{item.sale_cycle?.close_user?.full_name ?? ""}</ListItemTextWrap>
            </WrapAnchor>
          </TableTD>
        );
      case "RepName":
        return (
          <TableTD fixedWidth="120px">
            <ListItemTextWrap>{`${item.user?.first_name ?? ""} ${item.user?.last_name ?? ""}`}</ListItemTextWrap>
          </TableTD>
        );
      case "CallResult":
        // CALL RESULT SHOULD BE THE FETCHED FROM DISPOSITION IF IT IS NOT FOR PIPELINE TAB

        // const call_result_is_for_pipeline =
        //   !!props.metric && ["Pipeline", "ClosingOpportunitiesPipeline"].includes(props.metric);
        const activity_result = item?.related_disposition?.label ?? "No Action";
        const innerText = activity_result;
        return (
          <TableTD fixedWidth="180px">
            <ListItemTextWrap>{innerText}</ListItemTextWrap>
          </TableTD>
        );
      case "AssociatedAction":
        return (
          <TableTD fixedWidth="180px">
            <ListItemTextWrap>{item.related_disposition?.associated_action ?? "No Result"}</ListItemTextWrap>
          </TableTD>
        );
      case "EventTime":
        return (
          <TableTD fixedWidth="180px">
            <ListItemTextWrap>
              {!!item.schedule_item?.start_time ? formatImportDate(item.schedule_item?.start_time) : `-`}
            </ListItemTextWrap>
          </TableTD>
        );
      case "Industry":
        return (
          <TableTD fixedWidth="150px">
            <ListItemTextWrap>{`${item.lead?.industry ?? ""} `}</ListItemTextWrap>
          </TableTD>
        );
      case "SubIndustry":
        return (
          <TableTD fixedWidth="150px">
            <ListItemTextWrap>{`${item.lead?.sub_industry ?? ""} `}</ListItemTextWrap>
          </TableTD>
        );
      case "LeadSource":
        return (
          <TableTD fixedWidth="120px">
            <ListItemTextWrap>{`${item.lead?.lead_source ?? ""}`}</ListItemTextWrap>
          </TableTD>
        );
      case "Revenue":
        return (
          <TableTD fixedWidth="120px">
            <ListItemTextWrap>{`${formatCellData(item.stat_item_aggregation?.revenue, "Dollar")}`}</ListItemTextWrap>
          </TableTD>
        );
      case "RevenueValue":
        return (
          <TableTD fixedWidth="120px">
            <ListItemTextWrap>{`${formatCellData(
              item.stat_item_aggregation?.revenue_value,
              "Dollar",
            )}`}</ListItemTextWrap>
          </TableTD>
        );
      case "RecurringRevenue":
        return (
          <TableTD fixedWidth="120px">
            <ListItemTextWrap>{`${formatCellData(
              item.stat_item_aggregation?.recurring_revenue,
              "Dollar",
            )}`}</ListItemTextWrap>
          </TableTD>
        );
      case "Set":
        return (
          <TableTD fixedWidth="60px">
            <ListItemIcon>{item?.sale_cycle?.set ? <img src={GreenCheck} height="10px" /> : "-"}</ListItemIcon>
          </TableTD>
        );
      case "Held":
        return (
          <TableTD fixedWidth="60px">
            <ListItemIcon>{item?.sale_cycle?.hold ? <img src={GreenCheck} height="10px" /> : "-"}</ListItemIcon>
          </TableTD>
        );
      case "Close":
        return (
          <TableTD fixedWidth="60px">
            <ListItemIcon>{item?.sale_cycle?.close ? <img src={GreenCheck} height="10px" /> : "-"}</ListItemIcon>
          </TableTD>
        );
      case "DaysSetOut":
        return (
          <TableTD fixedWidth="120px">
            {typeof item?.stat_item_aggregation?.days_set_out === "number" &&
              `${item?.stat_item_aggregation?.days_set_out} days`}
          </TableTD>
        );
      case "DialsInCurrentSalesCycle":
        return (
          <TableTD fixedWidth="120px">
            {typeof item?.sale_cycle?.num_dials === "number" && `${item?.sale_cycle?.num_dials}`}
          </TableTD>
        );
      case "CallEndReason":
        return (
          <TableTD fixedWidth="120px">
            {typeof item?.call_end_reason === "string" && `${camelToProperSplit(item?.call_end_reason)}`}
          </TableTD>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 600,
          lineHeight: 1.4,
          textAlign: "center",
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "10px",
              lineHeight: "14px",
            }}
          >
            {dataTip}
          </span>
        )}
        id="overflowTooltip"
      />
      {props.callReportPage && <TitleText>Call History</TitleText>}
      <ListContainer callReportPage={props.callReportPage} id="call-report-list">
        <InfiniteScroll
          dataLength={reportsList.length}
          next={handleShowMore}
          hasMore={hasMore}
          loader={<Loading />}
          scrollableTarget="call-report-list"
          style={{ overflow: "inherit" }}
        >
          <AppTable
            sticky
            columns={[
              ...visibleColumns?.map((metric: string) => RenderTableHeaderFor(metric)).filter((ele: any) => ele.label),
              "",
            ]}
            headerStyle={{ fontSize: 10 }}
            // height="299px"
          >
            {!!reportsList.length &&
              reportsList.slice()?.map((item: any, index: number) => {
                return (
                  <TableRow key={`index${index}-conference${item.conference?.id}`}>
                    {visibleColumns?.map((metric: string) => RenderTableDataFor(metric, item))}
                    <TableTD fontSize="10px">
                      {!!item?.conference?.recording_url_computed && (
                        <FlexDiv>
                          <ListItemDuration display={!!item.conference?.duration}>
                            <ReactTooltip
                              multiline
                              place="bottom"
                              effect="solid"
                              css={{
                                maxWidth: 600,
                                lineHeight: 1.4,
                                textAlign: "center",
                                fontFamily: theme.PRIMARY_FONT,
                              }}
                              backgroundColor={theme.PRIMARY800}
                              getContent={(dataTip) => (
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "10px",
                                    lineHeight: "14px",
                                  }}
                                >
                                  {dataTip}
                                </span>
                              )}
                            />
                            <StyledLink to={`/call-report/${item.conference?.id}`}>
                              <div style={{ display: "flex", height: "100%", paddingRight: "4px" }}>
                                <CenterDiv>
                                  <FaPlayCircle
                                    size={10}
                                    color={theme.PRIMARY500}
                                    style={{ marginRight: "6px", marginLeft: "4px" }}
                                  />
                                </CenterDiv>
                                <CenterDiv>{formatCallDuration(item.conference?.duration ?? 0)}</CenterDiv>
                              </div>
                            </StyledLink>
                          </ListItemDuration>
                          {!!item.conference?.duration && (
                            <IconDiv>
                              <img
                                data-tip="Save"
                                onClick={() => {
                                  setCurrentConferenceID(item.conference?.id);
                                  setSaveCallToLibraryModal(true);
                                }}
                                src={iconSave}
                                alt="Save to Call Library"
                              />
                              {!!item?.conference?.recording_url_computed && (
                                <div style={{ marginLeft: "2px" }} data-tip="Download">
                                  {/* <a href={`${recording_url}`} download={`${recording_url.split("/").pop()}.wav`} target="_blank"> */}
                                  <img
                                    src={iconDownload}
                                    alt="Download"
                                    onClick={() => downloadResourceCors(item.conference.recording_url_computed)}
                                  />
                                  {/* </a> */}
                                </div>
                              )}
                            </IconDiv>
                          )}
                        </FlexDiv>
                      )}
                    </TableTD>
                  </TableRow>
                );
              })}
          </AppTable>
          {/* {listLoading === true && <Loading />} */}
        </InfiniteScroll>
      </ListContainer>
    </>
  );
};

const checkOverflow = (e: any) => e.offsetWidth < e.scrollWidth;

const ListItemTextWrap = (props: any) => {
  const ref = useRef<any>(null);
  const [dataFor, setDataFor] = useState("");
  const [dataTip, setDataTip] = useState("");

  useEffect(() => {
    if (checkOverflow(ref.current)) {
      setDataFor("overflowTooltip");
      setDataTip(ref.current.innerText);
    }
  }, []);

  return (
    <ListItemText ref={ref} data-for={dataFor} data-tip={dataTip}>
      {props.children}
    </ListItemText>
  );
};

const AbsoluteDiv = styled.div`
  display: sticky;
  top: 0;
`;

const TableRowHead = styled.div`
  position: sticky;
  top: 0px;
`;

const CallListTHead = styled.thead`
  position: sticky !important;
  inset-block-start: 0;
  top: 0px;
`;

const CallListTH = styled.th`
  position: sticky !important;
  inset-block-start: 0;
`;

const IconDiv = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-end;
  align-items: center;
  font-size: 8px;
  line-height: 13px;
  color: ${theme.TERTIARY500};
  margin-left: 8px;
  /* cursor: pointer; */
`;

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

interface ListItemDurationProps {
  display: boolean;
}

const ListItemDuration = styled.div<ListItemDurationProps>`
  border: solid 0.7px ${theme.PRIMARY500};
  border-radius: 2.1px;
  width: 74px;
  height: 17px;
  font-size: 10px;
  place-self: center;
  display: ${(props) => (props.display ? "flex" : "none")};
  margin-left: 12px;
`;

const WrapAnchor = styled.a`
  max-width: 100px;
  min-width: 50px;
  min-height: 40px;
  text-overflow: ellipsis;
`;

interface TDProps {
  colNum: number;
  width?: number;
}

const TableData = styled.td<TDProps>`
  display: table-cell;
  /* overflow: hidden; */
  height: 40px;
  border-bottom: solid 1px ${theme.NEUTRAL100};
  table-layout: auto;
  width: ${(props) => (!!props.width ? `${props.width}px` : `calc((100%) / ${props.colNum})`)};
  max-width: 250px;
`;

const ListItemIcon = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListItemText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  padding-right: 15px;
`;

const MainTable = styled.table`
  max-width: 100%;
  position: relative;
  table-layout: fixed;
  overflow-x: hidden;
`;

const TitleText = styled(AppText)`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.NEUTRAL500};
  margin-top: 14px;
  margin-bottom: 5px;
`;

const HeaderDiv = styled.div`
  display: grid;
  position: sticky;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 0.3fr 0.3fr 0.3fr 1.5fr;
  align-items: center;
  justify-items: start;
  margin-top: 14px;
  height: 30px;
  top: 0px;
  background-color: ${theme.WHITE_COLOR};
`;

interface ClickableProp {
  clickable?: boolean;
}

const HeaderText = styled(AppText)<ClickableProp>`
  font-size: 8px;
  font-weight: 500;
  border-bottom: none;
  cursor: ${(props) => (props.clickable ? "pointer" : "text")};
`;

const HeaderTextCenter = styled(AppText)<ClickableProp>`
  margin: auto;
  font-size: 8px;
  font-weight: 500;
  cursor: ${(props) => (props.clickable ? "pointer" : "text")};
  border-bottom: none;
`;

interface CallReportPageProps {
  callReportPage?: boolean;
}

const ListContainer = styled.div<CallReportPageProps>`
  width: ${(props) => (props.callReportPage ? "947px" : "100%")};
  height: 299px;
  /* padding: 16px; */
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: ${(props) => (props.callReportPage ? "3px" : "16px")};
  /* box-shadow: 0 4px 8px 0 rgba(224, 224, 224, 0.5); */
  background-color: ${theme.WHITE_COLOR};
  overflow: auto;
  /* padding: 0px 25px 0px 25px; */
`;

export { CallReportListV2 };
