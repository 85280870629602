import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { Loading, AppText } from ".";
import Select, { StylesConfig, Props as SelectProps } from "react-select";

interface SearchSelectInputProps {
  options: CallMonitorItem[];
  onCheckboxClick: (object: any) => void;
  name?: string;
  maxHeight?: number;
  selectedValues?: any;
  loading?: boolean;
  allowMultiple?: boolean;
}

interface CallMonitorItem {
  label: string;
  value: string;
}

type MyOptionType = {
  label: string;
  value: string;
  component?: string;
};

const SearchSelectInput = ({
  options,
  onCheckboxClick,
  name,
  maxHeight,
  selectedValues,
  loading,
  allowMultiple,
  ...props
}: SearchSelectInputProps & SelectProps) => {
  const customControlStyles: React.CSSProperties = {
    maxHeight: !!maxHeight ? `${maxHeight}px` : "300px",
    overflow: "auto",
    borderRadius: "2px",
    fontFamily: theme.PRIMARY_FONT,
    fontSize: "12px",
    letterSpacing: "0.2px",
    backgroundColor: theme.NEUTRAL100,
    minHeight: "42px",
  };

  const selectStyle: StylesConfig<MyOptionType, boolean> = {
    control: (provided, state) => {
      // provided has CSSObject type
      // state has ControlProps type

      // return type is CSSObject which means this line will throw error if uncommented
      // return false;

      return {
        ...provided,
        ...customControlStyles,
      };
    },
    input: (base, state) => ({
      ...base,
      fontFamily: theme.PRIMARY_FONT,
      fontSize: "12px",
      letterSpacing: "0.2px",
    }),
    option: (base, state) => ({
      ...base,
      fontFamily: theme.PRIMARY_FONT,
      fontSize: "12px",
      letterSpacing: "0.2px",
    }),
    container: (base, state) => ({
      ...base,
      // This might have been used to fix something, but it was also causing the select to render above the dropdown options of other multi selects
      // zIndex: 5,
    }),
  };

  const handleClick = (e: MyOptionType) => {
    return !!allowMultiple ? onCheckboxClick({ component: name, value: e }) : onCheckboxClick(e);
  };

  return (
    <div>
      {name && <AppText>{name}</AppText>}
      <StyledSelect
        isMulti={allowMultiple}
        placeholder={loading ? <Loading /> : name}
        options={options}
        components={options}
        onChange={(e: any) => handleClick(e)}
        styles={selectStyle}
        value={selectedValues}
        {...props}
      />
    </div>
  );
};

const StyledSelect = styled(Select)`
  min-height: 32px;
  min-width: 185px;
  max-height: 32px;
  /* max-width: 185px; */
`;

export { SearchSelectInput };
