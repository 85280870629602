import styled from "styled-components";
import * as React from "react";

import { useContext } from "react";
import { AppText } from "../UI";
import { theme } from "../../utils/theme";
import gql from "graphql-tag";
import { ModalContext } from "../../context";
import { PhoenixAppButton } from "../UI/Phoenix";
import { Modal } from "./Modal";
import { useMutation } from "@apollo/client";
import { appToast } from "../../utils/toast";

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($saleItemId: String!) {
    deleteProduct(sale_item_id: $saleItemId) {
      message
      status
      status_boolean
    }
  }
`;

const DeleteProductModal: React.FC = () => {
  const { showDeleteProductModal, setShowDeleteProductModal, selectedSale } = useContext(ModalContext);

  const [deleteProduct, { loading, error }] = useMutation(DELETE_PRODUCT, {
    onCompleted: (data) => {
      if (data.deleteProduct.status_boolean) {
        setShowDeleteProductModal(false);
      }
    },
    onError: (error) => {
      console.log(error);
      appToast("Something went wrong");
    },

    refetchQueries: ["fetchAllSales", "fetchSaleDetail", "FetchSaleDetail"],
  });

  const handleCancel = () => setShowDeleteProductModal(false);

  const handleDelete = async () => {
    deleteProduct({
      variables: {
        saleItemId: selectedSale?.sale_item_id,
      },
    });
  };

  return (
    <Modal open={showDeleteProductModal} onClose={() => setShowDeleteProductModal(false)} bigCloseButton>
      <EditCardDiv>
        <TitleDiv>
          <TitleText>Delete Product </TitleText>
        </TitleDiv>

        <FlexDiv>
          <FlexInnerDiv>
            <AppText fontSize={14} style={{ textAlign: "center", marginBottom: "16px" }} fontWeight={400}>
              Are you sure you want to delete this product? This action cannot be undone.
            </AppText>
          </FlexInnerDiv>
        </FlexDiv>
        <SubmitDiv>
          <div
            style={{
              width: "118px",
            }}
          >
            <PhoenixAppButton
              variant="danger-outline"
              buttonType="secondary"
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
              onClick={handleCancel}
            >
              no, cancel
            </PhoenixAppButton>
          </div>
          <div
            style={{
              width: "138px",
            }}
          >
            <PhoenixAppButton
              variant="brand"
              buttonType="secondary"
              onClick={handleDelete}
              style={{ textTransform: "capitalize", fontWeight: 600, fontSize: 10 }}
            >
              yes, continue
            </PhoenixAppButton>
          </div>
        </SubmitDiv>
      </EditCardDiv>
    </Modal>
  );
};

const SubmitDiv = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 16px 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
`;

const FlexInnerDiv = styled.div`
  height: 130px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
  position: relative;
  min-height: 56px;
  max-height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditCardDiv = styled.div`
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 476px;
`;

export { DeleteProductModal };
