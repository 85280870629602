import styled from "styled-components";
import * as React from "react";
import { useState } from "react";
import { NewAppButton, AppText, Loading, AppErrorText } from "../UI";
import { theme } from "../../utils/theme";
import { useMutation, gql } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import { AiOutlineLeft } from "react-icons/ai";
import * as Sentry from "@sentry/react";
import { set } from "lodash";

interface MyFormikProps {
  email: string;
}
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required("Please enter an email address!").email("Please enter a valid email address!"),
});

interface ForgotPasswordResponse {
  resetPassword: boolean;
}

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

const ForgotPassword: React.FC = () => {
  const history = useHistory();

  const [PasswordResetState, setPasswordResetState] = useState<"completed" | "error" | undefined>(undefined);

  const [resetPassword, { loading: mutationLoading, error: mutationError }] = useMutation<ForgotPasswordResponse>(
    FORGOT_PASSWORD,
    {
      onCompleted({ resetPassword }) {
        console.log("Password reset: ", resetPassword);

        if (!resetPassword) {
          setPasswordResetState("error");
          return;
        }
        setPasswordResetState("completed");
      },
      onError({ message }) {
        console.log("Error sending email for password reset in: ", message);
        Sentry.captureEvent({
          message: `resetPassword GraphQL Error: ${message}`,
        });
      },
    },
  );

  const BackToLoginDiv = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          display: "flex",
          alignItems: "center",
          gap: "4px",
          color: theme.WHITE_COLOR,
          cursor: "pointer",
        }}
        onClick={() => history.push("/login")}
      >
        <AiOutlineLeft style={{ fontSize: "20px", marginRight: "10px" }} />
        <AppText fontSize={22} fontWeight={600} color={theme.WHITE_COLOR}>
          Back To Login
        </AppText>
      </div>
    );
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred on the Forgot Password page"}>
      <BackToLoginDiv />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={forgotPasswordSchema}
        onSubmit={({ email }) => {
          resetPassword({ variables: { email: email.toLowerCase() } });
        }}
      >
        {({ submitForm, values }: FormikProps<MyFormikProps>) => {
          const submitted = PasswordResetState === "completed";

          if (mutationLoading) {
            return (
              <Main>
                <LoginContainer>
                  <Loading />
                </LoginContainer>
              </Main>
            );
          }

          if (mutationError) {
            return (
              <Main>
                <LoginContainer>
                  <AppErrorText>Sorry! Something went wrong. Please try again.</AppErrorText>
                </LoginContainer>
              </Main>
            );
          }

          if (PasswordResetState === "error") {
            return (
              <Main>
                <LoginContainer>
                  <AppErrorText style={{ textAlign: "center" }}>
                    Sorry! Something went wrong. Please try again later.
                  </AppErrorText>
                </LoginContainer>
              </Main>
            );
          }

          if (!submitted) {
            return (
              <Main>
                <LoginContainer>
                  <AppText fontSize={24} fontWeight={600} style={{ textAlign: "center" }} color={theme.PRIMARY300}>
                    Enter Your Account's Email to Send Password Reset Instructions
                  </AppText>
                  <PhoenixInputField name="email" textAlign="center" primary />
                  <NewAppButton size={"lg"} onClick={submitForm} disabled={!values.email} variant={"confirm"}>
                    Attempt Password Reset
                  </NewAppButton>
                </LoginContainer>
              </Main>
            );
          }

          if (submitted) {
            return (
              <Main>
                <LoginContainer>
                  <AppText style={{ textAlign: "center", color: theme.SUCCESS200 }} fontSize={24} fontWeight={600}>
                    Email received!
                  </AppText>
                  <AppText style={{ textAlign: "center", color: theme.SUCCESS200 }} fontSize={24} fontWeight={600}>
                    If the entered email is associated with an account, we will send instructions to reset your
                    password.
                  </AppText>
                </LoginContainer>
              </Main>
            );
          }

          // Fallback
          return (
            <Main>
              <LoginContainer>
                <AppErrorText>There was an application error. Please contact support.</AppErrorText>
              </LoginContainer>
            </Main>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 426px;
  max-width: 90%;
  padding: 25px;
  gap: 22px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background: linear-gradient(to bottom, ${theme.PRIMARY500}, #08056e);
`;

export { ForgotPassword };
