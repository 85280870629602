import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import { FieldArray, Formik, FormikProps } from "formik";
import React, { useContext, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
import * as Yup from "yup";
import { formatTypeName } from "../../../src/utils/format";
import { ModalContext } from "../../context";
import { refresh, reorder, trash } from "../../images/NewDesign";
import { ConditionTypes, handleRateAndPercentage } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { FormSelectField, NewSelectField } from "../Field";
import { PhoenixInputField } from "../Field/Phoenix/PhoenixInputField";
import "../Segments/Onboarding/transfer-tab/TransferRulesTable.css";
import { AppErrorText, AppText, NewAppSelect } from "../UI";
import {
  PhoenixAppButton,
  PhoenixCheckbox,
  PhoenixIcon,
  PhoenixInput,
  PhoenixMultiSelect,
  PhoenixRadio,
} from "../UI/Phoenix";
import { SkeletonBlock } from "../UI/SkeletonBlock";

import { info } from "../../images/NewDesign";
import {
  testForArrayFormatedAsAString,
  testForArrayWithMultipleElements,
  testIfFieldsAreUnique,
} from "../../utils/misc";
import { AgGridTooltip } from "../UI";

import { DATE_RANGES_WITHOUT_SPACE, METRIC_TYPES_WITHOUT_SPACE } from "../../../src/utils/format";

import DateTimePicker from "react-datetime-picker";
import { useDebounce } from "../../utils/hooks";
import { OptionItem } from "../../types";
import { parse } from "path";

interface CreateOrUpdateCustomTransferRuleExpectedResponse {
  createOrUpdateCustomTransferRule?: {
    id?: string;
    priority?: number;
    active?: boolean;
    rep_selection_method?: string;
    distribution_method?: string;
    updated_at?: string;
    conditions?: {
      id?: string;
    }[];
    routings?: {
      rep_id?: string;
      rule_id?: string;
    }[];
  };
}
const CREATE_OR_UPDATE_CUSTOM_RULE = gql`
  mutation createOrUpdateCustomTransferRule($rule_id: String, $data: TransferRuleInput!) {
    createOrUpdateCustomTransferRule(rule_id: $rule_id, data: $data) {
      id
      priority
      active
      rep_selection_method
      distribution_method
      updated_at
      conditions {
        id
      }
      routings {
        rep_id
        rule_id
      }
    }
  }
`;

const FETCH_CLOSE_RATE = gql`
  query Query($data: RepRatingRuleInput!) {
    computeRepCloseRate(data: $data)
  }
`;

interface FetchIndustryOptionsExpectedResponse {
  fetchIndustryOptions?: {
    id: string;
    label: string;
    sub_industries: string[];
  }[];
}
const FETCH_INDUSTRY_OPTIONS = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;
const FETCH_ALL_AVAILABLE_CONDITION = gql`
  query fetchAllAvailableCondition {
    fetchAllAvailableCondition
  }
`;

const GET_ORG_INFO = gql`
  query {
    fetchOrganization {
      Teams {
        id
        name
      }
      Reps {
        id
        full_name
      }
      Sites {
        id
        name
      }
      can_edit_sdr_transfer_rules
    }
  }
`;

const FETCH_ONE_CUSTOM_RULE = gql`
  query fetchOneCustomTransferRule($rule_id: String!) {
    fetchOneCustomTransferRule(rule_id: $rule_id) {
      id
      name
      priority
      active
      updated_at
      rep_selection_method
      distribution_method
      entity_type
      rank_sort_type
      date_range
      metric
      conditions {
        field
        operator
        value
        type
        cadence
      }
      routings {
        rep_id
        close_rate
      }
    }
  }
`;

interface FetchAllCustomTransferRuleExpectedResponse {
  fetchAllCustomTransferRule: {
    id: string;
    name: string;
    priority: number;
    active: boolean;
    rep_selection_method: string;
    distribution_method: string;
    updated_at: string;
    conditions: {
      field: string;
      operator: string;
      value: string;
      type: string;
      cadence: string;
    }[];
    routings: {
      rep_id: string;
      close_rate: string;
    }[];
  }[];
}
const FETCH_CUSTOM_TRANSFER_RULE = gql`
  query fetchAllCustomTransferRule {
    fetchAllCustomTransferRule {
      id
      name
      priority
      active
      updated_at
      rep_selection_method
      distribution_method
      updated_at
      conditions {
        field
        operator
        rule {
          id
        }
        value
        type
        cadence
      }
      rule_type
    }
  }
`;

interface ICondition {
  field: string;
  operator: string;
  value: string;
  type: string;
  cadence?: string;
}
interface IRep {
  rep_id: string;
  close_rate: string;
  priority: number;
}
interface MyFormikProps {
  id: string;
  name: string;
  priority: number;
  active: boolean;
  distribution_method: string;
  entity_type: string;
  conditions: any;
  reps: IRep[];
  transfer_type: string;
  backup_rule: string;
  rank_sort_type: string;
  metric: string;
  date_range: string;
  search_term: string;
  all_reps: any[];
}

const handleRepRowDragEnd = async ({
  event,
  values,
  setFieldValue,
}: {
  event: any;
  values: MyFormikProps;
  setFieldValue: any;
}) => {
  // catch edge cases
  const invalidRowsPresent =
    event?.overIndex === undefined ||
    event?.node?.data === undefined ||
    event.nodes.some((item: any) => item.data === undefined);

  const invalidState = !values.reps || !event || !event.node || !event.node.data || invalidRowsPresent;
  if (invalidState) return;

  // if it is dynamic switch to manual
  if (values.rank_sort_type !== "Manually") {
    setFieldValue("rank_sort_type", "Manually");
  }

  // Get the current order of reps
  const currentReps = [...values.reps];
  const currentRepIDs = currentReps?.map((item: IRep) => item.rep_id);

  // Get the dragged row data
  const draggedRowData = event.node.data;

  // Get the index of the dragged row in the current order
  const draggedRowIndex = currentRepIDs?.indexOf(draggedRowData.rep_id);

  // Get the new order of reps after dragging
  const newRepOrder = currentReps?.slice(); // Create a copy of the current reps

  // Remove the dragged rep_id from the current position
  newRepOrder.splice(draggedRowIndex, 1);

  // Insert the dragged rep_id at the new position
  newRepOrder.splice(event.overIndex, 0, draggedRowData);

  //go through and update the priority of each rep
  const updatedPriorityRepArray = newRepOrder?.map((rep: IRep, index: number) => {
    return {
      ...rep,
      priority: index + 1,
    };
  });

  // Update the reps in settingsData
  setFieldValue("reps", updatedPriorityRepArray);
};

const yupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Field is required"),
  conditions: Yup.array()
    .required("Field is required")
    .test("has at least one condition", "Must have at least one condition", (value) => {
      return !!value && value?.length > 0;
    })
    .test("unique fields", "Each field can only be used once", (value) => testIfFieldsAreUnique(value ?? []))
    .of(
      Yup.object().shape({
        field: Yup.string()
          .required("Field is required")
          .test("Field is required", "Field is required", (value) => value !== "" && value !== undefined),
        operator: Yup.string()
          .required("Operator is required")
          .test("Operator is required", "Operator is required", (value) => value !== "" && value !== undefined),
        value: Yup.string()
          .when("operator", {
            is: (value) => value === "In" || value === "NotIn",
            then: Yup.string().test("value", "Requires multiple values", (value) =>
              testForArrayWithMultipleElements(value ?? ""),
            ),
          })
          .when("operator", {
            is: (value) => value === "IsNull" || value === "NotNull",
            then: Yup.string().notRequired(),
          })
          // default is required
          .when("operator", {
            is: (value) => value !== "In" && value !== "NotIn" && value !== "IsNull" && value !== "NotNull",
            then: Yup.string().required("Value is required"),
          }),
        cadence: Yup.string().when("operator", {
          is: (value) => value === "InTheNext" || value === "SinceTheLast", // Corrected
          then: Yup.string().required("Cadence is required"),
        }),
      }),
    ),
  reps: Yup.array()
    .required("Field is required")
    .of(
      Yup.object().shape({
        rep_id: Yup.string(),
        priority: Yup.string(),
        close_rate: Yup.number(),
      }),
    ),
});

const TransferRuleModal = () => {
  const gridRef: any = useRef(); // Optional - for accessing Grid's API
  const { transferRuleModalData, setShowTransferRuleModal } = useContext(ModalContext);
  const formikRef = useRef() as any;
  const [dataCustomRulesState, setDataCustomRules] = useState<any[]>([]);
  const {
    data: dataCustomRules,
    loading: loadingCustomRules,
    error: errorCustomRules,
  } = useQuery<FetchAllCustomTransferRuleExpectedResponse>(FETCH_CUSTOM_TRANSFER_RULE, {
    fetchPolicy: "cache-and-network",
    skip: !!transferRuleModalData?.rule_id,
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error loading rule.");
    },
    onCompleted({ fetchAllCustomTransferRule }) {
      if (fetchAllCustomTransferRule.length) {
        setDataCustomRules(fetchAllCustomTransferRule);
      }
    },
  });

  const DEFAULT_RULE = {
    id: "",
    name: "",
    priority: !!dataCustomRules ? dataCustomRulesState.length : 0,
    active: true,
    distribution_method: "RoundRobin",
    entity_type: "User",
    conditions: [],
    reps: [],
    transfer_type: "Auto",
    backup_rule: "ScheduleMeeting",
    rank_sort_type: "Manually",
    metric: "CloseRate",
    date_range: "Today",
  } as const;

  const { data: dataOrgInfo, error: errorOrgInfo, loading: loadingOrgInfo } = useQuery(GET_ORG_INFO, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error loading org info.");
    },
  });
  const { data: dataOptions, loading: loadingOptions, error: errorOptions } = useQuery(FETCH_ALL_AVAILABLE_CONDITION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching available conditions");
    },
  });

  // lazy query to get close_rate should be run on a debounced change of reps or instantly on change of time frame
  const [fetchRepCloseRates, { data: dataRepCloseRates, loading: loadingRepCloseRates }] = useLazyQuery(
    FETCH_CLOSE_RATE,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
        appToast("Error fetching close rate");
      },
      onCompleted({ computeRepCloseRate }) {
        const currentReps = [...formikRef?.current?.values.reps];

        const updatedReps = currentReps?.map((rep: IRep) => {
          return {
            ...rep,
            close_rate: computeRepCloseRate.find((item: any) => item.user_id === rep.rep_id)?.close_rate ?? "N/A",
            priority:
              formikRef?.current?.values.rank_sort_type === "Manually"
                ? rep.priority
                : // metric based order returned from BE
                  computeRepCloseRate.findIndex((item: any) => item.user_id === rep.rep_id) + 1,
          };
        });

        formikRef?.current?.setFieldValue("reps", updatedReps);
      },
    },
  );

  // we can't use the formikref to debounce the fetch because it is a ref not a state.

  // we will change this state on change of reps or timeframe to trigger the fetch to update the close rate in the AG grid
  const [debounceCloseRateFetch, setTriggerDebounceFetch] = useState<undefined | boolean>(undefined);

  useDebounce(
    () => {
      if (debounceCloseRateFetch !== undefined) {
        fetchRepCloseRates({
          variables: {
            data: {
              rep_ids: formikRef?.current?.values.reps?.map((item: any) => item.rep_id),
              date_range: formikRef?.current?.values.date_range,
              rank_sort_type: formikRef?.current?.values.rank_sort_type,
            },
          },
        });
      }
    },
    [debounceCloseRateFetch],
    1000,
  );

  const triggerDebounceFetch = () => {
    if (debounceCloseRateFetch === undefined) {
      setTriggerDebounceFetch(true);
    } else {
      setTriggerDebounceFetch(!debounceCloseRateFetch);
    }
  };

  const {
    data: industryOptions,
    loading: loadingIndustryOptions,
    error: errorIndustryOptions,
  } = useQuery<FetchIndustryOptionsExpectedResponse>(FETCH_INDUSTRY_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching industry options");
    },
  });

  const formatMultipleTypeNames = (item: string) => {
    switch (item) {
      case "In":
        return "Equals Multiple";
      case "NotIn":
        return "Does Not Equal Multiple";
      default:
        return formatTypeName(item);
    }
  };

  const {
    data: dataSelectedRule,
    loading: loadingSelectedRule,
    error: errorSelectedRule,
    refetch: refetchSelectedRule,
  } = useQuery(FETCH_ONE_CUSTOM_RULE, {
    fetchPolicy: "network-only",
    skip: !transferRuleModalData?.rule_id,
    variables: {
      rule_id: transferRuleModalData?.rule_id,
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const [
    createOrUpdateCustomTransferRule,
    { data: updateRule, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation<CreateOrUpdateCustomTransferRuleExpectedResponse>(CREATE_OR_UPDATE_CUSTOM_RULE, {
    onCompleted({ createOrUpdateCustomTransferRule }) {
      if (!createOrUpdateCustomTransferRule) {
        return;
      }

      appToast("Rules Saved");
      setShowTransferRuleModal(false);
    },
    onError({ message }) {
      console.log("Error in createOrUpdateCustomTransferRule: ", message);
      appToast(message);
    },
    refetchQueries: ["fetchAllCustomTransferRule", "FetchAllCustomTransferRule"],
  });

  interface ConditionField {
    key: string;
    label: string;
    options: string[];
    type: ConditionTypes;
  }

  const findConditionTypeFromField = (value: string, PersonType: "Lead" | "User"): ConditionTypes => {
    // this changes based on the person type for the condition
    const availableFields: ConditionField[] = [...dataOptions?.fetchAllAvailableCondition?.fields?.[PersonType]];

    console.log("availableFields", availableFields);

    const type = availableFields?.slice().filter((item: any) => item?.key === value)[0]?.type;

    if (!type) {
      return ConditionTypes.Text;
    }

    return handleRateAndPercentage(type) as ConditionTypes;
  };

  const findOperatorsFromFieldType = (conditionType: ConditionTypes) => {
    const val = conditionType === "Rate" || conditionType === "Percentage" ? "Number" : conditionType;

    return !!conditionType &&
      !!dataOptions?.fetchAllAvailableCondition?.operations &&
      !!dataOptions.fetchAllAvailableCondition.operations[val]
      ? conditionType === "Text"
        ? [
            ...dataOptions.fetchAllAvailableCondition.operations[val]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
            ...dataOptions.fetchAllAvailableCondition.operations["MultiText"]?.map((item: string) => ({
              label: formatMultipleTypeNames(item),
              value: item,
            })),
          ]
        : dataOptions.fetchAllAvailableCondition.operations[val]?.map((item: string) => ({
            label: formatMultipleTypeNames(item),
            value: item,
          }))
      : [{ label: "Select field first", value: "" }];
  };

  interface FetchedIndustry {
    id: string;
    label: string;
    sub_industries: string[];
  }

  const generateSubIndustryOptions = ({ conditions }: { conditions: ICondition[] }) => {
    const industryConditions = conditions.filter((item: any) => item.field === "industry");
    console.log("industryConditions", industryConditions);

    if (!industryConditions.length) {
      return [{ label: "Select industry first", value: "", disabled: true }];
    }

    if (industryConditions.some((item: any) => item.operator === "IsNull")) {
      return [{ label: "Subindustry condition requires industry to be not null", value: "", disabled: true }];
    }

    const sub_industries: OptionItem[] = [];

    const allowedIndustries: string[] = [];
    const notAllowedIndustries: string[] = [];

    industryConditions.forEach((item: ICondition) => {
      if (item.operator === "Equal") {
        allowedIndustries.push(item.value ?? "N/A");
      }

      if (item.operator === "In") {
        const industries = testForArrayFormatedAsAString(item.value)
          ? JSON.parse(item.value) ?? item.value
          : item.value;
        industries.length && allowedIndustries.push(...industries);
      }

      if (item.operator === "NotEqual") {
        notAllowedIndustries.push(item.value ?? "");
      }

      if (item.operator === "NotIn") {
        const industries = testForArrayFormatedAsAString(item.value)
          ? JSON.parse(item.value) ?? item.value
          : item.value;
        industries.length && notAllowedIndustries.push(...industries);
      }
    });

    console.log("allowedIndustries", allowedIndustries);
    console.log("notAllowedIndustries", notAllowedIndustries);

    const specifiesNotNull = industryConditions.every((item: ICondition) => item.operator === "NotNull");
    const noEqualsOrEqualsMultiple = industryConditions.every(
      (item: ICondition) => item.operator !== "Equal" && item.operator !== "In",
    );

    const shouldStartWithAllIndustries = specifiesNotNull || noEqualsOrEqualsMultiple;

    if (shouldStartWithAllIndustries) {
      // push all sub industries
      industryOptions?.fetchIndustryOptions?.forEach((industry: FetchedIndustry) => {
        industry.sub_industries.forEach((sub_industry: string) => {
          sub_industries.push({ label: sub_industry ?? "", value: sub_industry ?? "" });
        });
      });
    }

    industryOptions?.fetchIndustryOptions?.forEach((industry: FetchedIndustry) => {
      if (allowedIndustries.includes(industry.label)) {
        industry.sub_industries.forEach((sub_industry: string) => {
          // if not already in the array
          if (!sub_industries.some((item: any) => item.label === sub_industry)) {
            sub_industries.push({ label: sub_industry ?? "", value: sub_industry ?? "" });
          }
        });
      }
      // if not allowed check and remove any
      if (notAllowedIndustries.includes(industry.label)) {
        industry.sub_industries.forEach((sub_industry: string) => {
          // if already in the array
          if (sub_industries.some((item: any) => item.label === sub_industry)) {
            sub_industries.splice(
              sub_industries.findIndex((item: any) => item.label === sub_industry),
              1,
            );
          }
        });
      }
    });

    return sub_industries.sort((a: any, b: any) => a.label.localeCompare(b.label));
  };

  const findDropdownOptionsFromField = (value: string, personType: "User" | "Lead", values: any) => {
    // sub industry is a special case with lots of extra logic
    if (value === "sub_industry") {
      return generateSubIndustryOptions({
        conditions: values.conditions,
      });
    }

    // BE fetched Data is split betwen User and Lead
    const fields = [...dataOptions?.fetchAllAvailableCondition?.fields?.[personType]];

    if (!fields) {
      return "";
    }
    return !!value && fields?.slice().filter((item: any) => item.key === value).length
      ? fields
          ?.slice()
          .filter((item: any) => item.key === value)[0]
          .options?.map((item: string) => ({
            label: item ?? "N/A",
            value: item ?? "",
          }))
      : [{ label: "Select field first", value: "" }];
  };

  const loading = loadingSelectedRule || loadingCustomRules || loadingOptions || loadingOrgInfo || loadingUpdate;

  const error = errorSelectedRule || errorCustomRules || errorOptions || errorOrgInfo || errorUpdate;

  const LoadingState = () => {
    return (
      <>
        <LoadingContainer>
          <SkeletonBlock height={200} width={"90%"} borderRadius={4} />
          <SkeletonBlock height={400} width={"90%"} borderRadius={4} />
          <SkeletonBlock height={200} width={"90%"} borderRadius={4} />
          <SkeletonBlock height={200} width={"90%"} borderRadius={4} />
        </LoadingContainer>
      </>
    );
  };

  if (loading) {
    return <LoadingState />;
  }

  if (error) return <AppErrorText>An error has occured</AppErrorText>;

  return (
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        id: dataSelectedRule?.fetchOneCustomTransferRule?.id || DEFAULT_RULE.id,
        name: dataSelectedRule?.fetchOneCustomTransferRule?.name || DEFAULT_RULE.name,
        priority: dataSelectedRule?.fetchOneCustomTransferRule?.priority || DEFAULT_RULE.priority + 1,
        active: dataSelectedRule?.fetchOneCustomTransferRule?.active ?? DEFAULT_RULE.active,
        distribution_method:
          dataSelectedRule?.fetchOneCustomTransferRule?.distribution_method || DEFAULT_RULE.distribution_method,
        entity_type: dataSelectedRule?.fetchOneCustomTransferRule?.entity_type || DEFAULT_RULE.entity_type,
        conditions: dataSelectedRule?.fetchOneCustomTransferRule?.conditions
          ? dataSelectedRule?.fetchOneCustomTransferRule?.conditions?.map((condition: ICondition) => ({
              field: condition.field,
              operator: condition.operator,
              value: parseInt(condition.value) ? parseInt(condition.value) : condition.value,
              type: condition.type,
              cadence: condition.cadence,
            }))
          : DEFAULT_RULE.conditions,
        reps: dataSelectedRule?.fetchOneCustomTransferRule?.routings
          ? dataSelectedRule?.fetchOneCustomTransferRule?.routings?.map((rep: IRep, index: number) => ({
              rep_id: rep.rep_id,
              close_rate: rep.close_rate,
              priority: rep.priority ?? index + 1,
            }))
          : DEFAULT_RULE.reps,
        rank_sort_type: dataSelectedRule?.fetchOneCustomTransferRule?.rank_sort_type || DEFAULT_RULE.rank_sort_type,
        transfer_type: dataSelectedRule?.fetchOneCustomTransferRule?.transfer_type || DEFAULT_RULE.transfer_type,
        backup_rule: dataSelectedRule?.fetchOneCustomTransferRule?.backup_rule || DEFAULT_RULE.backup_rule,
        metric: dataSelectedRule?.fetchOneCustomTransferRule?.metric || DEFAULT_RULE.metric,
        date_range: dataSelectedRule?.fetchOneCustomTransferRule?.date_range || DEFAULT_RULE.date_range,
        search_term: "",
        all_reps: dataOrgInfo?.fetchOrganization?.Reps,
      }}
      validationSchema={yupValidationSchema}
      onSubmit={async (values) => {
        await createOrUpdateCustomTransferRule({
          variables: {
            data: {
              name: values.name,
              conditions: values.conditions?.map((item: ICondition) => ({
                field: item.field,
                operator: item.operator,
                value: !!item.value ? item.value.toString() : "",
                type: item.type,
                cadence: item.cadence,
              })),
              date_range: values.date_range,
              metric: values.metric,
              rank_sort_type: values.rank_sort_type,
              distribution_method: values.distribution_method,
              rep_ids: values.reps?.map((item: any) => item.rep_id),
              entity_type: values.entity_type,
              rule_type: transferRuleModalData?.rule_type,
            },
            rule_id: transferRuleModalData?.rule_id ?? undefined,
          },
        });
      }}
    >
      {({ submitForm, values, setFieldValue, errors, isValid }: FormikProps<MyFormikProps>) => {
        console.log("values", values);

        // AG grid column items
        const PriorityCellRenderer = (props: any) => {
          return (
            <div style={{ display: "flex", height: "100%", width: "100%", alignItems: "center" }}>
              <PhoenixIcon svg={reorder} size={16} />
              <div style={{ marginLeft: "8px" }}>{props.value}</div>
            </div>
          );
        };

        const DeleteRepButton = (props: any) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                // remove the rep from the selected reps
                const newSelectedReps = [...values.reps];

                newSelectedReps.splice(props.rowIndex, 1);
                setFieldValue("reps", newSelectedReps);
              }}
            >
              <PhoenixIcon svg={trash} size={14} color={theme.DANGER600} />
            </div>
          );
        };

        const renderCadenceSelect = ({ currentCondition, index }: { currentCondition: ICondition; index: number }) => {
          const dateTypeCondition =
            findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") === "Date";

          const operatorRequiresCadence = ["InTheNext", "SinceTheLast"].includes(currentCondition?.operator);

          if (dateTypeCondition && operatorRequiresCadence) {
            return (
              <div>
                <PhoenixMultiSelect
                  name={`conditions[${index}].cadence`}
                  isMulti={false}
                  isClearable={false}
                  placeholder="Select one"
                  options={dataOptions?.fetchAllAvailableCondition?.date_cadence}
                  value={{
                    label: dataOptions?.fetchAllAvailableCondition?.date_cadence.find(
                      (item: { label: string; value: string }) => item.value === currentCondition?.cadence,
                    )?.label,
                    value: currentCondition?.cadence,
                  }}
                  onChange={(value: any) => {
                    setFieldValue(`conditions[${index}].cadence`, value.value);
                  }}
                />
              </div>
            );
          } else {
            return null;
          }
        };

        const renderValueSelect = ({ currentCondition, index }: { currentCondition: ICondition; index: number }) => {
          // for Booleons there can be only 1 type of input regardless of the field
          if (!currentCondition?.field) {
            return null;
          }

          // date checks

          const dateCondition =
            findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") === "Date";

          const operatorRequiresCadence = ["InTheNext", "SinceTheLast"].includes(currentCondition?.operator);

          const handleDateValueArrowClick = (direction: 1 | -1) => {
            // check if value would be negative
            if (direction === -1 && parseInt(currentCondition.value || "1") <= 1) {
              appToast("Value must be a positive number");
              return;
            }

            setFieldValue("conditions", [
              ...values.conditions?.map((item: ICondition, i: number) =>
                i !== index
                  ? item
                  : {
                      ...currentCondition,
                      value: ((parseInt(currentCondition.value) || 0) + direction).toString(),
                    },
              ),
            ]);
          };

          const handleDateValueChange = (e: any) => {
            const parsedValue = parseInt(e?.target?.value);

            if (parsedValue <= 0 || isNaN(parsedValue)) {
              setFieldValue("conditions", [
                ...values.conditions?.map((item: ICondition, i: number) =>
                  i !== index
                    ? item
                    : {
                        ...currentCondition,
                        value: "",
                      },
                ),
              ]);
              return;
            }

            setFieldValue("conditions", [
              ...values.conditions?.map((item: ICondition, i: number) =>
                i !== index
                  ? item
                  : {
                      ...currentCondition,
                      value: parsedValue.toString(),
                    },
              ),
            ]);
          };

          if (dateCondition && !operatorRequiresCadence) {
            return (
              <DateTimePickerWrapper>
                <DateTimePicker
                  format="MM/dd/yyyy"
                  // no clock
                  disableClock={true}
                  onChange={(date) => {
                    setFieldValue(`conditions[${index}].value`, date);
                  }}
                  value={currentCondition.value ? new Date(currentCondition.value) : undefined}
                />
              </DateTimePickerWrapper>
            );
          }

          if (dateCondition && operatorRequiresCadence) {
            return (
              <PhoenixInput
                inputValueType="number"
                value={
                  typeof currentCondition.value === "string" ? parseInt(currentCondition.value) : currentCondition.value
                }
                showNumberArrows={true}
                handleNumberArrowDownClick={() => handleDateValueArrowClick(-1)}
                handleNumberArrowUpClick={() => handleDateValueArrowClick(1)}
                onChange={handleDateValueChange}
              />
            );
          }

          if (
            !!currentCondition.field &&
            findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") === "Boolean"
          ) {
            return (
              <RulesSelect
                textAlign="left"
                placeholder="Select one"
                allowSelectPlaceholder
                name={`conditions[${index}].value`}
                options={[
                  { label: "True", value: "true" },
                  { label: "False", value: "false" },
                ]}
              />
            );
          }

          // for Null and Not Null there can never be any input

          if (
            currentCondition.operator === "IsNull" ||
            currentCondition.operator === "NotNull" ||
            !currentCondition.operator
          ) {
            return (
              <div
                style={{
                  width: "100%",
                }}
              />
            );
          }

          // start of the logic based on the field type

          // for dropdows or multidropdowns there can be 2 types of inputs depending on the operator

          if (
            (!!currentCondition.field &&
              findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") ===
                "Dropdown") || // if the field is a multi
            findConditionTypeFromField(currentCondition.field, values.entity_type as "User" | "Lead") ===
              "MultiDropdown"
          ) {
            switch (currentCondition.operator) {
              // this is different from the text field which is a normal input not a select
              case "Equal":
              case "NotEqual":
                return (
                  <RulesSelect
                    textAlign="left"
                    placeholder="Select one"
                    allowSelectPlaceholder
                    name={`conditions[${index}].value`}
                    options={findDropdownOptionsFromField(
                      currentCondition.field,
                      values.entity_type as "User" | "Lead",
                      values,
                    )}
                  />
                );

              default:
                return (
                  <CreatableSelect
                    isMulti={true}
                    textAlign="left"
                    placeholder="Select two or more"
                    allowSelectPlaceholder
                    options={findDropdownOptionsFromField(
                      currentCondition.field,
                      values.entity_type as "User" | "Lead",
                      values,
                    )}
                    onChange={(newValue: any) => {
                      const fullList = newValue?.map((item: any) => item.value);
                      setFieldValue("conditions", [
                        ...values.conditions.slice()?.map((item: ICondition, i: number) =>
                          i !== index
                            ? item
                            : {
                                field: currentCondition.field,
                                operator: currentCondition.operator,
                                value: JSON.stringify(fullList),
                                type: currentCondition.type,
                              },
                        ),
                      ]);
                    }}
                    value={
                      testForArrayFormatedAsAString(currentCondition.value)
                        ? JSON.parse(currentCondition.value)?.map((item: string) => ({
                            label: item,
                            value: item,
                          }))
                        : []
                    }
                  />
                );
            }
          }

          // the default for the rest of the field types is the the same currently but this should be changed in the future by adding more cases above
          // this is applied currently applied to Text, List, Number, and Date field types

          switch (currentCondition.operator) {
            case "In":
            case "NotIn":
              return (
                <>
                  <CreatableSelect
                    isMulti={true}
                    onChange={(newValue: any) => {
                      const emailsList = newValue?.map((item: any) => item.value);
                      setFieldValue("conditions", [
                        ...values.conditions.slice()?.map((item: ICondition, i: number) =>
                          i !== index
                            ? item
                            : {
                                field: currentCondition.field,
                                operator: currentCondition.operator,
                                value: JSON.stringify(emailsList),
                                type: currentCondition.type,
                              },
                        ),
                      ]);
                    }}
                    placeholder="Enter multiple options..."
                    defaultValue={
                      testForArrayFormatedAsAString(currentCondition.value)
                        ? JSON.parse(currentCondition.value)?.map((item: string) => ({
                            label: item,
                            value: item,
                          }))
                        : []
                    }
                  />
                </>
              );
            default:
              // unlike the dropdown and multi dropdown the Equal and NotEqual operators for text fields are standard inputs not selects
              return (
                <PhoenixInput
                  value={currentCondition.value}
                  onChange={(e: any) => {
                    setFieldValue("conditions", [
                      ...values.conditions.slice()?.map((item: ICondition, i: number) =>
                        i !== index
                          ? item
                          : {
                              field: currentCondition.field,
                              operator: currentCondition.operator,
                              value: e.target.value,
                              type: currentCondition.type,
                            },
                      ),
                    ]);
                  }}
                />
              );
          }
        };

        const handleRepChange = async ({
          repID,
          setFieldValue,
          values,
        }: {
          repID: string;
          setFieldValue: any;
          values: any;
        }) => {
          let currentReps = [...values.reps];

          const currentRepIDs = currentReps?.map((item: any) => item.rep_id);

          if (currentRepIDs.includes(repID)) {
            currentReps = currentReps.filter((rep: IRep) => rep.rep_id !== repID);
          } else {
            currentReps = [
              ...currentReps,
              {
                rep_id: repID,
                close_rate: "loading...",
                priority: currentReps.length + 1,
              },
            ];
          }

          setFieldValue("reps", currentReps);

          triggerDebounceFetch();
        };

        const handleDateRangeChange = ({ dateRange, setFieldValue }: { dateRange: string; setFieldValue: any }) => {
          setFieldValue("date_range", dateRange);
          triggerDebounceFetch();
        };

        return (
          <>
            <TitleDiv>
              <PopupTitle>
                {transferRuleModalData?.type === "Edit" ? "Edit Transfer Rule" : "Add Transfer Rule"}
              </PopupTitle>
            </TitleDiv>

            <ScrollingDiv>
              <ContainerTitle>Configure New Rule</ContainerTitle>
              <ContainerBoldText>Rule Name</ContainerBoldText>
              <RadioDiv>
                <PhoenixInputField
                  requiredStar
                  name="name"
                  placeholder="Give Your Rule A Name"
                  type="text"
                  onChange={(e) => {
                    setFieldValue("name", e.target.value);
                  }}
                />
              </RadioDiv>
              <SpacerDiv />
              <ContainerBoldText>How would you like to manage event transfers for this rule?</ContainerBoldText>
              <RadioSection>
                <RadioDiv>
                  <PhoenixRadio
                    selected={values.entity_type === "User"}
                    onClick={() => {
                      setFieldValue("entity_type", "User");
                      setFieldValue("conditions", []);
                    }}
                  />
                  <AppText>Rep Based</AppText>
                </RadioDiv>
                <RadioDiv>
                  <PhoenixRadio
                    selected={values.entity_type === "Lead"}
                    onClick={() => {
                      setFieldValue("entity_type", "Lead");
                      setFieldValue("conditions", []);
                    }}
                  />
                  <AppText>Lead Based</AppText>
                </RadioDiv>
              </RadioSection>
              <SpacerDiv />
              <SpacerDiv />
              <ContainerBoldText>How would you like to prioritize lead distribution for this rule?</ContainerBoldText>
              <RadioSection>
                <RadioDiv>
                  <PhoenixRadio
                    selected={values.distribution_method === "RoundRobin"}
                    onClick={() => {
                      setFieldValue("distribution_method", "RoundRobin");
                    }}
                  />
                  <AppText>Round Robin (even distribution) </AppText>
                </RadioDiv>
                <RadioDiv>
                  <PhoenixRadio
                    selected={values.distribution_method === "Ranking"}
                    onClick={async () => {
                      setFieldValue("distribution_method", "Ranking");
                    }}
                  />
                  <AppText>By Rank (always prioritize leads to your top ranked Reps)</AppText>
                </RadioDiv>
              </RadioSection>
              <SpacerDiv
                style={{
                  borderBottom: `solid 1px ${theme.NEUTRAL200}`,
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              />
              <ContainerTitle>Rule Criteria</ContainerTitle>
              <AppText style={{ marginBottom: "16px" }} fontWeight={500} fontSize={12} color={theme.NEUTRAL300}>
                Use the button below to create conditions that a {values.entity_type === "User" ? "Rep" : "Lead"} must
                meet in order to be prioritized in this rule
              </AppText>
              <AppText style={{ marginBottom: "16px" }} color={theme.PRIMARY600}>
                If {values.entity_type === "User" ? "Rep" : "Lead"} Meets These Criteria
              </AppText>

              <CriteriaDiv>
                <FieldArray name="conditions">
                  {({ remove, push }) => (
                    <>
                      {values.conditions?.map((item: any, index: number) => (
                        <div key={index}>
                          {index > 0 && (
                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                              <OperationBox>AND</OperationBox>
                            </div>
                          )}
                          <Criteria>
                            <CriteriaHeader>
                              <AppText fontWeight={600} fontSize={12}>
                                {`Condition ${index + 1}`}
                              </AppText>
                              <PhoenixIcon
                                svg={trash}
                                size={16}
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            </CriteriaHeader>
                            <NewSelectField
                              textAlign="left"
                              placeholder="Select one"
                              allowSelectPlaceholder
                              name={`conditions.${index}.field`}
                              options={
                                dataOptions?.fetchAllAvailableCondition?.fields?.[values.entity_type]?.map(
                                  (item: any) => ({
                                    label: item.label,
                                    value: item.key,
                                    type: item.type,
                                  }),
                                ) || []
                              }
                              // resets other fields when the condition field is changed
                              onChangeSideEffect={(value: any) => {
                                setFieldValue(`conditions[${index}].type`, value.type);
                                setFieldValue(`conditions[${index}].operator`, "");
                                setFieldValue(`conditions[${index}].value`, undefined);
                                setFieldValue(`conditions[${index}].cadence`, undefined);
                              }}
                            />
                            <NewSelectField
                              key={values.conditions[index]?.field ?? `condition ${index}`}
                              textAlign="left"
                              placeholder="Select one"
                              name={`conditions.${index}.operator`}
                              allowSelectPlaceholder
                              options={findOperatorsFromFieldType(
                                findConditionTypeFromField(
                                  values.conditions[index].field,
                                  values.entity_type as "User" | "Lead",
                                ),
                              )}
                              onChangeSideEffect={(value: any) => {
                                // reset value on operator change
                                setFieldValue(`conditions[${index}].value`, "");
                                setFieldValue(`conditions[${index}].cadence`, undefined);
                              }}
                            />
                            {!!values.conditions[index]?.operator && (
                              <ValueDiv>
                                <div>
                                  {renderValueSelect({
                                    currentCondition: values.conditions[index],
                                    index,
                                  })}
                                </div>
                                <div>
                                  {renderCadenceSelect({
                                    currentCondition: values.conditions[index],
                                    index,
                                  })}
                                </div>
                              </ValueDiv>
                            )}
                          </Criteria>
                        </div>
                      ))}
                      <PhoenixAppButton
                        buttonType="primary"
                        variant="brand-outline"
                        width={200}
                        onClick={() => push({ field: "", operator: "", value: "", type: "" })}
                      >
                        ADD CRITERIA
                      </PhoenixAppButton>
                    </>
                  )}
                </FieldArray>
              </CriteriaDiv>
              <div></div>
              <SpacerDiv />
              <AppText color={theme.PRIMARY600}>
                ...then distribute to the following Reps according to the rules below
              </AppText>
              <SpacerDiv />
              <ContainerTitle>Rep Selection</ContainerTitle>
              <PhoenixInputField placeholder="Search for Reps" searchInput name="search_term" />
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
                onClick={() => {
                  setFieldValue("search_term", "");
                }}
              >
                <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                <AppText
                  fontSize={10}
                  fontWeight={500}
                  color={theme.PRIMARY500}
                  style={{ lineHeight: "14px", userSelect: "none" }}
                >
                  Reset Filters
                </AppText>
              </div>
              <SpacerDiv />
              <RepContainer>
                <RepsDiv>
                  {dataOrgInfo?.fetchOrganization?.Reps?.map((rep: any) => {
                    const noSearch =
                      values.search_term === "" || values.search_term === undefined || values.search_term === null;
                    if (noSearch || rep.full_name.toLowerCase().includes(values.search_term.toLowerCase())) {
                      return (
                        <div
                          key={rep.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <PhoenixCheckbox
                              checked={values.reps?.map((rep: IRep) => rep.rep_id).includes(rep.id)}
                              onChange={() => {
                                handleRepChange({
                                  repID: rep.id,
                                  setFieldValue: setFieldValue,
                                  values: values,
                                });
                              }}
                            />
                            <AppText fontSize={12} fontWeight={500}>
                              {rep.full_name}
                            </AppText>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </RepsDiv>
                <div
                  className={"ag-theme-transfer-rules-list"}
                  style={{
                    width: "100%",
                  }}
                >
                  <AgGridReact
                    ref={gridRef}
                    rowDragEntireRow={!loading}
                    rowDragManaged={!loading}
                    onRowDragEnter={async (event: any) => {
                      if (loading) return;
                    }}
                    onRowDragEnd={async (event: any) => {
                      if (loading) return;

                      try {
                        await handleRepRowDragEnd({
                          event: event,
                          values: values,
                          setFieldValue: setFieldValue,
                        });
                      } catch (e) {
                        console.log("error in ag grid", e);
                      }
                    }}
                    domLayout="autoHeight"
                    rowData={values.reps
                      ?.map((rep: IRep) => {
                        // find the rep from the org info
                        const repFromOrg = dataOrgInfo?.fetchOrganization?.Reps.find(
                          (item: any) => item.id === rep.rep_id,
                        );

                        return {
                          ...rep,
                          full_name: repFromOrg?.full_name,
                          delete: rep.rep_id,
                        };
                      })
                      .sort((a: any, b: any) => a.priority - b.priority)}
                    columnDefs={[
                      {
                        headerName: "Priority",
                        field: "priority",
                        width: 100,
                        headerClass: "ag-transfer-rules-header",
                        rowDrag: true,
                        cellRendererFramework: PriorityCellRenderer,
                      },
                      {
                        headerName: "Rep Name",
                        field: "full_name",
                        flex: 1,
                        minWidth: 100,
                        headerClass: "ag-transfer-rules-header",
                      },
                      {
                        headerName: `Close Rate (${
                          DATE_RANGES_WITHOUT_SPACE.find((item) => item.value === values.date_range)?.label
                        })`,
                        field: "close_rate",
                        width: 175,
                        headerClass: "ag-transfer-rules-header",
                        headerTooltip: "Timeframe is configured in the below section under 'Dynamically'.",
                        tooltipComponent: AgGridTooltip,
                        tooltipComponentParams: {
                          showList: false,
                        },
                        headerComponentFramework: (props: any) => {
                          return (
                            <div>
                              <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                {props.displayName}
                                <PhoenixIcon svg={info} size={12} />
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        headerName: "Delete",
                        field: "delete",
                        cellRendererFramework: DeleteRepButton,
                        width: 60,
                        headerClass: "ag-transfer-rules-header",
                      },
                    ]}
                  />
                </div>
              </RepContainer>
              <SpacerDiv />
              <SpacerDiv />
              <ContainerTitle>Priority Order Of Reps</ContainerTitle>
              <ContainerBoldText>
                How would you like to determine the order in which the above reps are prioritized?
              </ContainerBoldText>
              <SpacerDiv />
              <RadioSection>
                <RadioDiv>
                  <PhoenixRadio
                    selected={values.rank_sort_type === "Manually"}
                    onClick={async () => {
                      setFieldValue("rank_sort_type", "Manually");
                    }}
                  />
                  <AppText>Manually (by priority number)</AppText>
                </RadioDiv>
                <RadioDiv>
                  <PhoenixRadio
                    selected={values.rank_sort_type === "Dynamically"}
                    onClick={async () => {
                      setFieldValue("rank_sort_type", "Dynamically");

                      triggerDebounceFetch();
                    }}
                  />
                  <AppText>Dynamically </AppText>
                </RadioDiv>
              </RadioSection>
              {values.rank_sort_type === "Dynamically" && (
                <>
                  <SpacerDiv />
                  <div
                    style={{
                      width: "336px",
                    }}
                  >
                    <NewAppSelect
                      placeholder="Select Metric"
                      options={METRIC_TYPES_WITHOUT_SPACE}
                      value={values.metric}
                      onChange={async (e: any) => {
                        setFieldValue(`metric`, e?.value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "336px",
                    }}
                  >
                    <NewAppSelect
                      placeholder="Select Date Range"
                      options={DATE_RANGES_WITHOUT_SPACE}
                      value={values.date_range}
                      title="Date Range"
                      onChange={(e: any) => {
                        // updates and triggers the debounce fetch
                        handleDateRangeChange({
                          dateRange: e?.value,
                          setFieldValue: setFieldValue,
                        });
                      }}
                    />
                  </div>
                </>
              )}
            </ScrollingDiv>
            <SubmitDiv>
              <PhoenixAppButton
                buttonType="secondary"
                variant="danger-outline"
                onClick={() => {
                  setShowTransferRuleModal(false);
                }}
              >
                Cancel
              </PhoenixAppButton>

              <PhoenixAppButton
                disabled={!isValid}
                buttonType="secondary"
                variant="brand"
                onClick={() => {
                  submitForm();
                }}
              >
                Save Changes
              </PhoenixAppButton>
            </SubmitDiv>
          </>
        );
      }}
    </Formik>
  );
};

interface CustomHeightProps {
  customHeight?: boolean;
}

const ValueDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const RepContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 60px;
  border-bottom: 1px solid ${theme.NEUTRAL300};
  padding-bottom: 16px;
`;
const RepsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 400px;
  overflow: auto;
  padding: 8px;
  width: 336px;
`;

const Criteria = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: solid 1px ${theme.NEUTRAL200};
  border-radius: 4px;
  padding: 16px;
`;
const CriteriaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CriteriaDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const OperationBox = styled(AppText)`
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  color: ${theme.NEUTRAL400};
  padding: 12px 18px;
  border: 1px solid ${theme.NEUTRAL400};
  border-radius: 99px;
  margin-bottom: 16px;
  margin-left: 391px;
  background-color: ${theme.WHITE_COLOR};
`;

const RulesSelect = styled(FormSelectField)`
  background-color: ${theme.WHITE_COLOR};
`;

const ContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const InputLabel = styled(AppText)`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight: 500;
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SpacerDiv = styled.div`
  height: 16px;
  min-height: 16px;
`;

const RadioSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const SubmitDiv = styled.div<CustomHeightProps>`
  position: absolute;
  bottom: 0px;
  height: 80px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin-top: auto;
  margin-bottom: ${(props) => (props.customHeight ? "0px" : "5px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;
const ContainerBoldText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const TitleDiv = styled.div`
  height: 72px;
  width: 100%;
  border-bottom: solid 1px ${theme.NEUTRAL200};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20;
  align-items: center;
  padding-top: 24px;
`;

const ScrollingDiv = styled.div<CustomHeightProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 90px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
  max-height: ${(props) => (props.customHeight ? "calc(100vh - 250px)" : "calc(100vh - 80px - 56px - 26px)")};
`;

const DateTimePickerWrapper = styled.div`
  .react-datetime-picker {
    height: 40px;
    * {
      /* color: ${theme.PRIMARY600} !important; */
    }
    border: none;
  }
`;

export { TransferRuleModal };
