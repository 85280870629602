import { gql, useQuery } from "@apollo/client";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { DateRangePicker } from "react-dates";
import styled, { keyframes } from "styled-components";
import { loggedInUser } from "../../../apollo/cache";
import {
  LeadFilterContext,
  LeadFilterObject,
  LeadFilterOperator,
  ModalContext,
  additionalLeadSystemOperatorsInterface,
} from "../../../context";
import { chevron_left, chevron_right, plus, refresh, trash, xIcon } from "../../../images/NewDesign";
import { OptionItem } from "../../../types";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { OutsideEvent } from "../../Dumb";
import { AppText, DarkDiv, ReactDatesWrapper } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import {
  PhoenixAppButton,
  PhoenixCheckbox,
  PhoenixIcon,
  PhoenixInput,
  PhoenixMultiSelect,
  PhoenixRadio,
} from "../../UI/Phoenix";

const GET_POSSIBLE_INDUSTRIES_DATA = gql`
  query fetchIndustryOptions {
    fetchIndustryOptions {
      id
      label
      sub_industries
    }
  }
`;

const FETCH_REPS_FROM_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      Reps {
        id
        first_name
        last_name
      }
    }
  }
`;

const FETCH_REPS_FROM_ACTIVITY = gql`
  query fetchRepsAsOptions {
    fetchRepsAsOptions
  }
`;

const GET_TIMEZONE_OPTIONS = gql`
  query getTZOptions {
    getTZOptions
  }
`;

const GET_CALL_RESULT_OPTIONS = gql`
  query getCallResultOptions {
    getCallResultOptions
  }
`;

const CHECK_INTEGRATION_STATUS = gql`
  query checkIntegrationStatus {
    checkIntegrationStatus
  }
`;

const GET_DISPOSITION_TYPE_OPTIONS = gql`
  query getDispositionTypeOptions {
    getDispositionTypeOptions
  }
`;

const FETCH_CUSTOM_FIELDS = gql`
  query fetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;

const GET_STATE_OPTIONS = gql`
  query getStateOptions {
    getStateOptions
  }
`;

const GET_LEAD_SOURCE_OPTIONS = gql`
  query getUniqueLeadSources {
    getUniqueLeadSources
  }
`;

const NEXT_SCHEDULED_EVENT_OPTIONS = [
  { label: "Decision Call", value: "DecisionCall" },
  { label: "Reschedule Demo", value: "RescheduleDemo" },
  { label: "Scheduled Event", value: "ScheduledEvent" },
  { label: "Scheduled Callback", value: "ScheduleCallback" },
  { label: "Demo", value: "Demo" },
  { label: "Follow-Up Demo", value: "FollowUpDemo" },
];

const LEAD_CREATION_SOURCE_OPTIONS = [
  { label: "Self-Sourced", value: "self_sourced" },
  { label: "Bulk Import", value: "CSV" },
  { label: "Added by Admin", value: "as_admin" },
  { label: "Hubspot", value: "hubspot" },
  { label: "Salesforce", value: "salesforce" },
];

const LEAD_CREATION_SOURCE_OPTIONS_HUBSPOT = [
  { label: "Self-Sourced", value: "self_sourced" },
  { label: "Bulk Import", value: "CSV" },
  { label: "Added by Admin", value: "as_admin" },
  { label: "Hubspot", value: "hubspot" },
  { label: "Salesforce", value: "salesforce" },
];

const CALL_RESULT_OPTIONS = [
  { label: "Cold Calls", value: "cold_call" },
  { label: "Call Followups", value: "call_followup" },
  { label: "General Callback Held", value: "general_callback_held" },
  { label: "General Callback Set", value: "general_callback_set_not_held" },
  { label: "Schedule Callback Held", value: "schedule_callback_held" },
  { label: "Schedule Callback Set", value: "schedule_callback_set_not_held" },
  { label: "Demo Held", value: "demo_held" },
  { label: "Demo Set", value: "demo_set_not_held" },
  { label: "Decision Call Held", value: "decision_call_held" },
  { label: "Decision Call Set", value: "decision_call_set_not_held" },
  { label: "Emails Sent", value: "email_sent" },
  { label: "Emails Received", value: "email_received" },
  { label: "Dials", value: "dial" },
];

const LEAD_HISTORY_OPERATORS = [
  { label: "Greater than", value: "GreaterThan" },
  { label: "Less than", value: "LessThan" },
  { label: "Greater than or equal to", value: "GreaterThanOrEqualTo" },
  { label: "Less than or equal to", value: "LessThanOrEqualTo" },
  { label: "Equal to", value: "EqualTo" },
  { label: "Not equal to", value: "NotEqualTo" },
  { label: "Between", value: "Between" },
  { label: "Not Between", value: "NotBetween" },
];

const FILTER_OPERATIONS_ARRAY: OptionItem[] = [
  {
    label: `Includes`,
    value: "DoesInclude",
  },
  {
    label: `Excludes`,
    value: "DoesNotInclude",
  },
  {
    label: `Empty field`,
    value: "EmptyField",
  },
];

const DESIRED_FILTER_TAB_ORDER = [
  "reps",
  "status",
  "industries",
  "sub_industry",
  "lead_sources",
  "lead_creation_source",
  "states",
  "timezones",
  "history_filter",
  "rep_activity_filter",
  "call_result_filter",
  "next_scheduled_event",
  "custom_fields",
];

// helper functions
const filterIsActive = (
  key: string,
  leadSystemFilter: LeadFilterObject,
  tempLeadSystemOperators: additionalLeadSystemOperatorsInterface,
) => {
  const value = leadSystemFilter[key];

  // Check if the value is not an empty array or object
  if (Array.isArray(value) && value.length > 0) {
    return true; // Array is not empty, filter is active
  } else if (typeof value === "object" && !isEmptyObject(value)) {
    return true; // Object is not empty, filter is active
  }

  // check if the operator is set to NotEmptyField because that is an active filter as well
  const operator = tempLeadSystemOperators[returnOperatorFromKey(key) as keyof additionalLeadSystemOperatorsInterface];

  if (operator === LeadFilterOperator.EmptyField) {
    return true;
  }

  return false; // No active filter found
};

// Helper function to check if an object is empty
function isEmptyObject(obj: any) {
  // go through object and check if any key has a value or if the key is an array with a length
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] && obj[key].length > 0) {
        return false;
      }
    }
  }
  return true;
}

const mapLabelToKey = (key: string) => {
  switch (key) {
    case "lead_sources":
      return "Lead Sources";
    case "industries":
      return "Industry";
    case "sub_industry":
      return "Sub-Industry";
    case "lead_creation_source":
      return "Lead Creation Source";
    case "history_filter":
      return "Lead History";
    case "call_result_filter":
      return "Call Result";
    case "rep_activity_filter":
      return "Rep Activity";
    case "next_scheduled_event":
      return "Next Scheduled Event";
    case "sequences":
      return "Sequences";
    case "custom_fields":
      return "Custom Fields";
    case "reps":
      return "Reps";
    case "states":
      return "States";
    case "timezones":
      return "Timezones";
    case "PDV":
      return "PDV";
    case "status":
      return "Status";
    case "channels":
      return "Channel";
    default:
      return key;
  }
};

const returnOperatorFromKey = (key: string) => {
  console.log("key:", key);
  switch (key) {
    case "reps":
      return "reps_operator";
    case "lead_sources":
      return "lead_sources_operator";
    case "lead_creation_source":
      return "lead_creation_source_operator";
    case "status":
      return "status_operator";
    case "industries":
      return "industries_operator";
    case "sub_industry":
      return "sub_industry_operator";
    case "states":
      return "states_operator";
    case "timezones":
      return "timezones_operator";
    case "channels":
      return "channels_operator";
    default:
      break;
  }
};

// to do - refactor this to not have different name objects passed in
const formatFilterItemLabel = (key: string, name: any) => {
  if (key === "lead_creation_source") {
    switch (name) {
      case "self_sourced":
        return "Self Sourced";
      case "CSV":
        return "CSV Upload";
      case "as_admin":
        return "Admin Upload";
      case "hubspot":
        return "Hubspot";
      case "salesforce":
        return "Salesforce";
      default:
        return name;
    }
  }

  if (key === "sequences") {
    return name?.label;
  }

  if (key === "custom_fields") {
    return name?.key;
  }

  if (key !== "types") return name;

  const array = (name as string).match(/[A-Z][a-z]+/g);
  return array?.join(" ") ?? name;
};

const LeadSearchFiltersModal: React.FC = () => {
  const { showFiltersModal, setShowFiltersModal } = useContext(ModalContext);
  const {
    leadSystemFilter,
    setLeadSystemFilter,
    tempLeadFilter,
    setTempLeadFilter,
    currentFilterCount,
    leadSystemOperators,
    setLeadSystemOperators,
    tempLeadSystemOperators,
    setTempLeadSystemOperators,
    resetLeadSystemOperators,
    resetTempLeadFilter,
    resetTempLeadSystemOperators,
  } = useContext(LeadFilterContext);
  const [expanded, setExpanded] = useState([] as string[]);

  // fetches

  const {
    data: dataDispositionTypeOptions,
    loading: loadingDispositionTypeOptions,
    error: errorDispositionTypeOptions,
  } = useQuery(GET_DISPOSITION_TYPE_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: industriesData, loading: industriesLoading } = useQuery(GET_POSSIBLE_INDUSTRIES_DATA, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataStatus } = useQuery(CHECK_INTEGRATION_STATUS, {
    fetchPolicy: "cache-and-network",
  });

  const { data: allCustomFieldOptions } = useQuery(FETCH_CUSTOM_FIELDS, {
    fetchPolicy: "cache-and-network",
  });

  const { data: dataReps, loading: loadingReps, error: errorReps } = useQuery(FETCH_REPS_FROM_ORG, {
    variables: {
      org_id: loggedInUser().organization_id,
    },
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataCallResultOptions, loading: loadingCallResultOptions, error: errorCallResultOptions } = useQuery(
    GET_CALL_RESULT_OPTIONS,
    {
      fetchPolicy: "cache-and-network",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const {
    data: leadSourceOptions,
    error: leadSourceError,
    loading: leadSourceLoading,
  } = useQuery(GET_LEAD_SOURCE_OPTIONS, { fetchPolicy: "network-only" });

  const { data: dataRepsActivities } = useQuery(FETCH_REPS_FROM_ACTIVITY, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: stateOptions, error: stateError, loading: stateLoading } = useQuery(GET_STATE_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: tzOptions, error: tzError, loading: tzLoading } = useQuery(GET_TIMEZONE_OPTIONS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  // computed vars

  const filterOptionsArray = Object.keys(tempLeadFilter)
    .filter((k) => true)
    .sort(
      // following the order of the DesiredFilterTabOrder
      (a: string, b: string) => DESIRED_FILTER_TAB_ORDER.indexOf(a) - DESIRED_FILTER_TAB_ORDER.indexOf(b),
    );

  const industryOptionsArray = industriesData?.fetchIndustryOptions?.map((item: any) => item.label);

  const leadSourceOptionsArray = leadSourceOptions?.getUniqueLeadSources
    ?.map((item: any) => item.label)
    ?.sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));

  const leadCreationSrcOptionsArray = !!dataStatus?.checkIntegrationStatus?.HubSpot
    ? LEAD_CREATION_SOURCE_OPTIONS_HUBSPOT?.map((item: any) => item.label)?.sort((a: string, b: string) =>
        `${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1,
      )
    : LEAD_CREATION_SOURCE_OPTIONS?.map((item: any) => item.label)?.sort((a: string, b: string) =>
        `${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1,
      );

  const leadCreationSrcOptionsArrayVals = !!dataStatus?.checkIntegrationStatus?.HubSpot
    ? LEAD_CREATION_SOURCE_OPTIONS_HUBSPOT?.map((item: any) => item.value)?.sort((a: string, b: string) =>
        `${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1,
      )
    : LEAD_CREATION_SOURCE_OPTIONS?.map((item: any) => item.value)?.sort((a: string, b: string) =>
        `${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1,
      );

  const repsOptionsArray: OptionItem[] = dataReps?.fetchOrganization?.Reps?.map((item: any) => ({
    label: `${item.first_name} ${item.last_name}`,
    value: item?.id,
  }))?.sort((a: any, b: any) => (`${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1));

  const isDisabled = useMemo(() => {
    // check if the temp filter is the same as the current filter
    const newGridFilter = cloneDeep(tempLeadFilter);
    newGridFilter.custom_fields = newGridFilter.custom_fields.filter((ele: SelectedCustomFieldType) => !!ele?.id);

    const filtersHaventChanged =
      JSON.stringify(newGridFilter) === JSON.stringify(leadSystemFilter) ||
      newGridFilter.custom_fields.some((ele: SelectedCustomFieldType) => !ele?.value?.length);

    const operatorsHaventChanged = isEqual(tempLeadSystemOperators, leadSystemOperators);

    return filtersHaventChanged && operatorsHaventChanged;
  }, [tempLeadFilter, tempLeadSystemOperators]);

  // state vars

  const [selectedFilter, setSelectedFilter] = useState<null | string>(null);

  const [selectedCustomFields, setSelectedCustomFields] = useState<SelectedCustomFieldType[]>(
    leadSystemFilter?.custom_fields?.length
      ? leadSystemFilter?.custom_fields
      : [{ id: undefined, key: undefined, value: undefined, type: undefined }],
  );

  // For react-dates
  const [focusedInput, setFocusedInput] = useState(null as any);
  const [focusedInput2, setFocusedInput2] = useState(null as any);

  const customFieldOptions = useMemo(
    () =>
      allCustomFieldOptions?.fetchCustomFields?.length > 0
        ? allCustomFieldOptions?.fetchCustomFields
            // filter unsupported types
            .filter((item: any) => {
              return ["MultiDropdown", "Dropdown", "Boolean"].includes(item.type);
            })
            ?.map((item: any) => ({
              label: item.key,
              value: item.id,
              type: item.type,
            }))
            .filter((item: any) => !selectedCustomFields.some((selectedOption) => selectedOption.id === item.value))
        : [],
    [allCustomFieldOptions, selectedCustomFields],
  );

  // useEffects

  useEffect(() => {
    setTempLeadFilter(JSON.parse(JSON.stringify(leadSystemFilter)));
  }, [leadSystemFilter]);

  // component level state dependent helper functions

  const mapOptionsToKey = (key: string) => {
    switch (key) {
      case "lead_sources":
        return leadSourceOptionsArray;
      case "industries":
        return industryOptionsArray;
      case "sub_industry":
        return returnSubIndustryOptions(tempLeadFilter?.industries);
      case "status":
        return ["Unassigned", "Assigned", "Owned", "Resting", "Retired", "Customer"].sort();
      case "lead_creation_source":
        return leadCreationSrcOptionsArrayVals;
      case "next_scheduled_event":
        return NEXT_SCHEDULED_EVENT_OPTIONS;

      case "reps":
        return repsOptionsArray;
      case "states":
        return stateOptions.getStateOptions
          ?.map((item: any) => item)
          ?.sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));
      case "timezones":
        return tzOptions.getTZOptions
          ?.map((item: any) => item)
          ?.sort((a: string, b: string) => (`${a}`.toLowerCase() > `${b}`.toLowerCase() ? 1 : -1));
      case "PDV":
        return ["$0.00-$0.99", "$1.00-$1.99", "$2.00-$2.99", "$3.00+"];
      case "next_scheduled_event":
        return NEXT_SCHEDULED_EVENT_OPTIONS;
      case "channels":
        return ["Inbound", "Outbound"];
      default:
        return [];
    }
  };

  const returnSubIndustryOptions = (industry: string[]) => {
    if (industriesLoading) {
      return [
        {
          label: "Loading...",
          value: "",
        },
      ];
    }
    if (!!industry.length) {
      return (
        industriesData?.fetchIndustryOptions
          ?.slice()
          ?.filter((item: any) => industry.includes(item.label))
          ?.map((item: any) => item.sub_industries)
          ?.flat()
          ?.sort() ?? []
      );
    } else {
      return [];
    }
  };

  const handleMultiSelectChange = (key: string, items: string[]) => {
    {
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: items,
      });
    }
  };

  const handleCheckboxClick = (key: string, item: string | number) => {
    if (tempLeadFilter[key]?.includes(item)) {
      const filterKeyArray = tempLeadFilter[key]?.filter((selected: string) => {
        return selected !== item;
      });
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: filterKeyArray,
      });
    } else {
      setTempLeadFilter({
        ...tempLeadFilter,
        [key]: [...(tempLeadFilter[key] ?? []), item],
      });
    }
  };

  const resetIndustries = () => {
    setTempLeadFilter({ ...tempLeadFilter, industries: [], sub_industry: [] });
  };
  const resetSubindustries = () => {
    setTempLeadFilter({ ...tempLeadFilter, sub_industry: [] });
  };

  // to-do: refactor this to be cleaner (Not in scope for quick follow up ticket)
  const handleFilterUpdate = () => {
    /* local var used since we have to delete amount 2 to prevent an error in the BE
     when not using Between and not between. if fixed we could simply reference the temp state lead filter */
    let onSubmitTempLeadFilter = tempLeadFilter;

    if (
      onSubmitTempLeadFilter.call_result_filter.call_result_option ||
      onSubmitTempLeadFilter.call_result_filter.call_result_type
    ) {
      if (!onSubmitTempLeadFilter.call_result_filter.call_result_option) {
        appToast("Invalid Call Result Filter! Must specify an option");
        return;
      }
      if (!onSubmitTempLeadFilter.call_result_filter.call_result_type) {
        appToast("Invalid Call Result Filter! Must specify a type");
        return;
      }
    }
    if (
      onSubmitTempLeadFilter?.history_filter?.amount ||
      onSubmitTempLeadFilter?.history_filter?.operator ||
      onSubmitTempLeadFilter?.history_filter?.metric
    ) {
      if (!onSubmitTempLeadFilter?.history_filter?.amount && onSubmitTempLeadFilter?.history_filter?.amount !== 0) {
        appToast("Invalid Lead History Filter! Must specify an amount");
        return;
      }
      if (!onSubmitTempLeadFilter?.history_filter?.operator) {
        appToast("Invalid Lead History Filter! Must specify an operator");
        return;
      }
      if (!onSubmitTempLeadFilter?.history_filter?.metric) {
        appToast("Invalid Lead History Filter! Must specify a metric");
        return;
      }
    }

    // Between and Not_Between checks for history filter
    if (
      onSubmitTempLeadFilter.history_filter?.operator === "Between" ||
      onSubmitTempLeadFilter.history_filter?.operator === "NotBetween"
    ) {
      // if amount_2 is not present, it will throw an error
      if (!onSubmitTempLeadFilter.history_filter?.amount_2) {
        appToast("Invalid Lead History Filter! Must specify a second amount");
        return;
      }

      // amount > amount2 will still run the query but switch the values

      const temp = onSubmitTempLeadFilter.history_filter?.amount;
      const temp2 = onSubmitTempLeadFilter.history_filter?.amount_2;

      if (temp && temp2 && temp > temp2) {
        // Swap values of amount and amount_2
        if (onSubmitTempLeadFilter.history_filter) {
          const { amount, amount_2 } = onSubmitTempLeadFilter.history_filter;
          onSubmitTempLeadFilter.history_filter.amount = amount_2;
          onSubmitTempLeadFilter.history_filter.amount_2 = amount;
        }
      }
    }
    // any other operator MUST delete amount_2 or any error will occur
    else {
      if (onSubmitTempLeadFilter.history_filter && onSubmitTempLeadFilter.history_filter.amount_2 !== undefined) {
        // Delete amount_2 property if it exists
        // @ts-ignore
        delete onSubmitTempLeadFilter.history_filter.amount_2;
      }
    }

    // handle custom fields that have no field name
    if (!!onSubmitTempLeadFilter.custom_fields) {
      const invalidFields = onSubmitTempLeadFilter.custom_fields.filter((item: any) => item.id === "");
      if (invalidFields.length > 0) {
        appToast("Invalid Custom Field Filter! Must specify a field name");
        return;
      }
    }

    if (!!onSubmitTempLeadFilter.custom_fields) {
      const invalidFields = onSubmitTempLeadFilter.custom_fields.filter((item: any) => item.value?.length === 0);
      if (invalidFields.length > 0) {
        appToast("Invalid Custom Field Filter! Must specify a value");
        return;
      }
    }

    setLeadSystemFilter(onSubmitTempLeadFilter);
    setShowFiltersModal(!showFiltersModal);
    setLeadSystemOperators(tempLeadSystemOperators);
  };

  return (
    <>
      <ModalContainer>
        <ModalContent>
          <HeaderDiv>
            <FlexDiv gap={16}>
              <CloseModalIcon
                svg={xIcon}
                size={24}
                pointer
                onClick={(e) => {
                  e?.stopPropagation();

                  // reset the unsaved temp filters to the current filters
                  setTempLeadFilter(leadSystemFilter);

                  // hide the modal
                  setShowFiltersModal(!showFiltersModal);
                }}
              />
              <AppText fontSize={16} fontWeight={600} lineHeight={22}>
                Add Filters
              </AppText>
              <AppText fontWeight={600} fontSize={16} color={theme.text.neutral.secondary}>
                {currentFilterCount}
              </AppText>
            </FlexDiv>
            {!selectedFilter && (
              <FlexDiv
                gap={8}
                align="center"
                onClick={() => {
                  resetTempLeadFilter();
                  resetTempLeadSystemOperators();
                }}
                style={{ cursor: "pointer", width: "fit-content" }}
              >
                <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                <AppText fontSize={10} fontWeight={500} color={theme.PRIMARY500}>
                  Reset Filters
                </AppText>
              </FlexDiv>
            )}
          </HeaderDiv>

          {!!selectedFilter && <BackToAllFiltersButton setSelectedFilter={setSelectedFilter} />}

          {!selectedFilter && (
            <AllFiltersStep>
              {filterOptionsArray?.map((key: string) => {
                const displayRedDot = filterIsActive(key, tempLeadFilter, tempLeadSystemOperators);

                return (
                  <SectionContainerDiv key={key} displayDot={displayRedDot}>
                    {displayRedDot && <Dot />}
                    <SectionRow onClick={() => setSelectedFilter(key)}>
                      <SectionTitle>
                        <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                          {mapLabelToKey(key)} &nbsp;
                        </AppText>
                      </SectionTitle>
                      <PhoenixIcon
                        svg={chevron_right}
                        size={24}
                        variant="brand"
                        pointer
                        onClick={() =>
                          expanded?.includes(key)
                            ? setExpanded(expanded.filter((item) => item !== key))
                            : setExpanded([...expanded, key])
                        }
                      />
                    </SectionRow>
                  </SectionContainerDiv>
                );
              })}
            </AllFiltersStep>
          )}

          {selectedFilter === "lead_sources" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                selectedFilter={selectedFilter}
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, lead_sources: [] });

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    lead_sources_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                theme={theme}
              />

              {returnOperatorFromKey(selectedFilter || "") && (
                <FilterOperatorSelectionComponent
                  selectedFilter={selectedFilter}
                  tempLeadSystemOperators={tempLeadSystemOperators}
                  setTempLeadSystemOperators={setTempLeadSystemOperators}
                />
              )}
              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                    return (
                      <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`lead_sources-${i}`}>
                        <PhoenixCheckbox
                          checked={tempLeadFilter[selectedFilter]?.includes(item)}
                          onChange={() => {
                            handleCheckboxClick(selectedFilter, item);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {formatFilterItemLabel(selectedFilter, item)}
                        </AppText>
                      </FlexDiv>
                    );
                  })}
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "lead_creation_source" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                selectedFilter={selectedFilter}
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, lead_creation_source: [] });

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    lead_creation_source_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                theme={theme}
              />

              {returnOperatorFromKey(selectedFilter || "") && (
                <FilterOperatorSelectionComponent
                  selectedFilter={selectedFilter}
                  tempLeadSystemOperators={tempLeadSystemOperators}
                  setTempLeadSystemOperators={setTempLeadSystemOperators}
                />
              )}

              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                    return (
                      <FlexDiv
                        gap={8}
                        align="center"
                        style={{ paddingBottom: "16px" }}
                        key={`lead_creation_source-${i}`}
                      >
                        <PhoenixCheckbox
                          checked={tempLeadFilter[selectedFilter]?.includes(item)}
                          onChange={() => {
                            handleCheckboxClick(selectedFilter, item);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {formatFilterItemLabel(selectedFilter, item)}
                        </AppText>
                      </FlexDiv>
                    );
                  })}
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "states" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                selectedFilter={selectedFilter}
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, states: [] });

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    states_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                theme={theme}
              />

              <FilterOperatorSelectionComponent
                selectedFilter={selectedFilter}
                tempLeadSystemOperators={tempLeadSystemOperators}
                setTempLeadSystemOperators={setTempLeadSystemOperators}
              />

              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                    return (
                      <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`states-${i}`}>
                        <PhoenixCheckbox
                          checked={tempLeadFilter[selectedFilter]?.includes(item)}
                          onChange={() => {
                            handleCheckboxClick(selectedFilter, item);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {formatFilterItemLabel(selectedFilter, item)}
                        </AppText>
                      </FlexDiv>
                    );
                  })}
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "timezones" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                selectedFilter={selectedFilter}
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, timezones: [] });

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    timezones_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                theme={theme}
              />
              <FilterOperatorSelectionComponent
                selectedFilter={selectedFilter}
                tempLeadSystemOperators={tempLeadSystemOperators}
                setTempLeadSystemOperators={setTempLeadSystemOperators}
              />

              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                    return (
                      <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`timezones-${i}`}>
                        <PhoenixCheckbox
                          checked={tempLeadFilter[selectedFilter]?.includes(item)}
                          onChange={() => {
                            handleCheckboxClick(selectedFilter, item);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {formatFilterItemLabel(selectedFilter, item)}
                        </AppText>
                      </FlexDiv>
                    );
                  })}
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "history_filter" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({
                    ...tempLeadFilter,
                    history_filter: {
                      ...tempLeadFilter.history_filter,
                      amount: null,
                      amount_2: null,
                      lowerbound_date: "",
                      metric: "",
                      operator: "",
                      upperbound_date: "",
                    },
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />

              <FlexDiv gap={24} direction="column">
                <FlexDiv gap={8} direction="column">
                  <AppText>
                    Lead Received
                    {tempLeadFilter?.history_filter?.operator === "Between" ||
                    tempLeadFilter?.history_filter?.operator === "NotBetween"
                      ? ""
                      : " a"}
                  </AppText>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <PhoenixMultiSelect
                      isMulti={false}
                      name="lead_history_received"
                      options={CALL_RESULT_OPTIONS
                        // sort alphabetically
                        ?.sort((a: OptionItem, b: OptionItem) =>
                          a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                        )}
                      value={
                        tempLeadFilter?.history_filter?.metric
                          ? CALL_RESULT_OPTIONS?.find(
                              (option: OptionItem) => option.value === tempLeadFilter?.history_filter?.metric,
                            )
                          : undefined
                      }
                      menuPortal
                      marginBottom={false}
                      placeholder="Metric"
                      onChange={(e: any) => {
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          history_filter: {
                            ...tempLeadFilter.history_filter,
                            metric: e?.value ? e.value : "",
                          },
                        });
                      }}
                    />
                  </div>
                </FlexDiv>

                <FlexDiv gap={16} align="center">
                  <PhoenixMultiSelect
                    name="lead_history_operator"
                    isMulti={false}
                    width={205}
                    options={LEAD_HISTORY_OPERATORS
                      // sort alphabetically
                      ?.sort((a: OptionItem, b: OptionItem) =>
                        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                      )}
                    value={
                      tempLeadFilter?.history_filter?.operator
                        ? LEAD_HISTORY_OPERATORS?.find(
                            (option: OptionItem) => option.value === tempLeadFilter?.history_filter?.operator,
                          )
                        : undefined
                    }
                    marginBottom={false}
                    menuPortal
                    placeholder="Operator"
                    onChange={(e: any) => {
                      console.log("e:", e);
                      setTempLeadFilter({
                        ...tempLeadFilter,
                        history_filter: {
                          ...tempLeadFilter.history_filter,
                          // @ts-ignore
                          operator: e?.value ? e.value : "",
                        },
                      });
                    }}
                  />
                  {tempLeadFilter?.history_filter?.operator !== "Between" &&
                  tempLeadFilter?.history_filter?.operator !== "NotBetween" ? (
                    <>
                      <PhoenixInput
                        displayNoContextText
                        name="lead_history_number"
                        type="number"
                        value={tempLeadFilter?.history_filter?.amount || ""}
                        placeholder="0"
                        onChange={(e) => {
                          setTempLeadFilter({
                            ...tempLeadFilter,
                            history_filter: {
                              ...tempLeadFilter.history_filter,
                              // @ts-ignore
                              amount: e?.target?.value ? Math.abs(parseInt(e.target.value)) : undefined,
                            },
                          });
                        }}
                        showNumberArrows
                        handleNumberArrowUpClick={() => {
                          setTempLeadFilter({
                            ...tempLeadFilter,
                            history_filter: {
                              ...tempLeadFilter.history_filter,
                              amount: tempLeadFilter?.history_filter?.amount
                                ? tempLeadFilter?.history_filter?.amount + 1
                                : 1,
                            },
                          });
                        }}
                        handleNumberArrowDownClick={() => {
                          setTempLeadFilter({
                            ...tempLeadFilter,
                            history_filter: {
                              ...tempLeadFilter.history_filter,
                              amount: tempLeadFilter?.history_filter?.amount
                                ? tempLeadFilter?.history_filter?.amount - 1
                                : 0,
                            },
                          });
                        }}
                      />

                      <AppText>
                        {tempLeadFilter?.history_filter?.operator === "Between" ||
                        tempLeadFilter?.history_filter?.operator === "NotBetween"
                          ? "and"
                          : "times"}
                      </AppText>
                    </>
                  ) : null}
                </FlexDiv>

                {/* if operator is between or not between show the inputs on a new line */}
                {tempLeadFilter?.history_filter?.operator === "Between" ||
                tempLeadFilter?.history_filter?.operator === "NotBetween" ? (
                  <FlexDiv gap={16} align="center">
                    <PhoenixInput
                      displayNoContextText
                      name="lead_history_number"
                      type="number"
                      value={tempLeadFilter?.history_filter?.amount || ""}
                      placeholder="0"
                      onChange={(e) => {
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          history_filter: {
                            ...tempLeadFilter.history_filter,
                            // @ts-ignore
                            amount: e?.target?.value ? Math.abs(parseInt(e.target.value)) : undefined,
                          },
                        });
                      }}
                      showNumberArrows
                      handleNumberArrowUpClick={() => {
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          history_filter: {
                            ...tempLeadFilter.history_filter,
                            amount: tempLeadFilter?.history_filter?.amount
                              ? tempLeadFilter?.history_filter?.amount + 1
                              : 1,
                          },
                        });
                      }}
                      handleNumberArrowDownClick={() => {
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          history_filter: {
                            ...tempLeadFilter.history_filter,
                            amount: tempLeadFilter?.history_filter?.amount
                              ? tempLeadFilter?.history_filter?.amount - 1
                              : 0,
                          },
                        });
                      }}
                    />
                    <AppText fontSize={12} fontWeight={500}>
                      and
                    </AppText>
                    <PhoenixInput
                      displayNoContextText
                      name="lead_history_number"
                      type="number"
                      value={tempLeadFilter?.history_filter?.amount_2 || ""}
                      placeholder="0"
                      onChange={(e) => {
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          history_filter: {
                            ...tempLeadFilter.history_filter,
                            // @ts-ignore
                            amount_2: e?.target?.value ? Math.abs(parseInt(e.target.value)) : undefined,
                          },
                        });
                      }}
                      showNumberArrows
                      handleNumberArrowUpClick={() => {
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          history_filter: {
                            ...tempLeadFilter.history_filter,
                            amount_2: tempLeadFilter?.history_filter?.amount_2
                              ? tempLeadFilter?.history_filter?.amount_2 + 1
                              : 1,
                          },
                        });
                      }}
                      handleNumberArrowDownClick={() => {
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          history_filter: {
                            ...tempLeadFilter.history_filter,
                            amount_2: tempLeadFilter?.history_filter?.amount_2
                              ? tempLeadFilter?.history_filter?.amount_2 - 1
                              : 0,
                          },
                        });
                      }}
                    />
                    <AppText fontSize={12} fontWeight={500}>
                      times
                    </AppText>
                  </FlexDiv>
                ) : null}
                <Horizontal>
                  <AppText style={{ marginRight: "18px" }}>During</AppText>
                  <TimeColumnDiv>
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLabelText style={{ marginLeft: "15px" }}>Start Date</TimeLabelText>
                      <TimeLabelText style={{ marginLeft: "95px" }}>End Date</TimeLabelText>
                    </div>
                    <OutsideEvent onOutsideClick={() => setFocusedInput(focusedInput)}>
                      <ReactDatesWrapper>
                        <DateRangePicker
                          minimumNights={0}
                          showClearDates
                          isOutsideRange={() => false}
                          startDate={
                            tempLeadFilter?.history_filter?.lowerbound_date
                              ? moment(tempLeadFilter?.history_filter?.lowerbound_date)
                              : null
                          }
                          startDateId="system_lead_filter_start_date"
                          endDate={
                            tempLeadFilter?.history_filter?.upperbound_date
                              ? moment(tempLeadFilter?.history_filter?.upperbound_date)
                              : null
                          }
                          endDateId="system_lead_filter_end_date"
                          onDatesChange={({ startDate, endDate }) => {
                            setTempLeadFilter((tempLeadFilter: any) => ({
                              ...tempLeadFilter,
                              history_filter: {
                                ...tempLeadFilter.history_filter,
                                lowerbound_date: startDate,
                                upperbound_date: endDate,
                              },
                            }));
                          }}
                          focusedInput={focusedInput}
                          onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                        />
                      </ReactDatesWrapper>
                    </OutsideEvent>
                  </TimeColumnDiv>
                </Horizontal>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "rep_activity_filter" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({
                    ...tempLeadFilter,
                    rep_activity_filter: {
                      user_ids: [],
                      rep_lowerbound_date: "",
                      rep_upperbound_date: "",
                    },
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />
              <FlexDiv direction="column" gap={32} style={{ width: "100%" }}>
                <FlexDiv
                  align="center"
                  gap={8}
                  style={{
                    width: "100%",
                    height: "max-content",
                  }}
                >
                  <AppText>Reps</AppText>
                  <div style={{ width: "100%" }}>
                    <PhoenixMultiSelect
                      name="rep_activity_users"
                      options={
                        dataRepsActivities?.fetchRepsAsOptions
                          ?.slice()
                          // sort alphabetically
                          ?.sort((a: OptionItem, b: OptionItem) => (a?.label < b?.label ? -1 : 1)) || []
                      }
                      value={tempLeadFilter?.rep_activity_filter?.user_ids}
                      isMulti
                      menuPortal
                      marginBottom={false}
                      onChange={(e: any) => {
                        console.log("multi output: ", e);
                        setTempLeadFilter({
                          ...tempLeadFilter,
                          rep_activity_filter: {
                            ...tempLeadFilter.rep_activity_filter,
                            user_ids: e,
                          },
                        });
                      }}
                    />
                  </div>
                </FlexDiv>
                <FlexDiv
                  align="center"
                  gap={8}
                  style={{
                    width: "100%",
                    height: "max-content",
                  }}
                >
                  <AppText
                    style={{
                      paddingTop: "8px",
                    }}
                  >
                    During
                  </AppText>
                  <TimeColumnDiv>
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLabelText style={{ marginLeft: "15px" }}>Start Date</TimeLabelText>
                      <TimeLabelText style={{ marginLeft: "95px" }}>End Date</TimeLabelText>
                    </div>
                    <ReactDatesWrapper>
                      <DateRangePicker
                        minimumNights={0}
                        showClearDates
                        isOutsideRange={() => false}
                        startDate={
                          tempLeadFilter?.rep_activity_filter?.rep_lowerbound_date
                            ? moment(tempLeadFilter?.rep_activity_filter?.rep_lowerbound_date)
                            : null
                        }
                        startDateId="system_lead_filter_rep_start_date"
                        endDate={
                          tempLeadFilter?.rep_activity_filter?.rep_upperbound_date
                            ? moment(tempLeadFilter?.rep_activity_filter?.rep_upperbound_date)
                            : null
                        }
                        endDateId="system_lead_filter_rep_end_date"
                        onDatesChange={({ startDate, endDate }) => {
                          // if (startDate && moment(startDate).isValid()) {
                          setTempLeadFilter((tempLeadFilter: any) => ({
                            ...tempLeadFilter,
                            rep_activity_filter: {
                              ...tempLeadFilter.rep_activity_filter,
                              rep_lowerbound_date: startDate,
                            },
                          }));
                          // }
                          // if (endDate && moment(endDate).isValid()) {
                          setTempLeadFilter((tempLeadFilter: any) => ({
                            ...tempLeadFilter,
                            rep_activity_filter: {
                              ...tempLeadFilter.rep_activity_filter,
                              rep_upperbound_date: endDate,
                            },
                          }));
                          // }
                        }}
                        focusedInput={focusedInput2}
                        onFocusChange={(focusedInput) => setFocusedInput2(focusedInput)}
                      />
                    </ReactDatesWrapper>
                  </TimeColumnDiv>
                </FlexDiv>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "call_result_filter" && (
            <PaddingAndScrollDiv>
              <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
                <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
                <FlexDiv
                  gap={8}
                  align="center"
                  style={{ cursor: "pointer", width: "fit-content" }}
                  onClick={() => {
                    setTempLeadFilter({
                      ...tempLeadFilter,
                      call_result_filter: {
                        call_result_option: "",
                        call_result_type: "",
                      },
                    });
                  }}
                >
                  <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
                  <AppText
                    fontSize={10}
                    fontWeight={500}
                    color={theme.PRIMARY500}
                    style={{ lineHeight: "14px", userSelect: "none" }}
                  >
                    Reset Filter
                  </AppText>
                </FlexDiv>
              </FlexDiv>
              <FlexDiv gap={32} direction="column" style={{ width: "100%" }}>
                <FlexDiv gap={8} align="center">
                  <AppText>When</AppText>
                  <div style={{ width: "100%" }}>
                    <PhoenixMultiSelect
                      isMulti={false}
                      name="call_result_type"
                      menuPortal
                      marginBottom={false}
                      options={
                        dataDispositionTypeOptions?.getDispositionTypeOptions
                          ?.slice()
                          ?.sort((a: any, b: any) => (a.label < b.label ? -1 : 1)) || []
                      }
                      value={{
                        label: tempLeadFilter?.call_result_filter?.call_result_type,
                        value: tempLeadFilter?.call_result_filter?.call_result_type,
                      }}
                      placeholder="None"
                      onChange={(e: any) => {
                        if (e?.value) {
                          setTempLeadFilter({
                            ...tempLeadFilter,
                            call_result_filter: {
                              ...tempLeadFilter.call_result_filter,
                              call_result_type: !!e?.value && e.value !== "0" ? e.value : undefined,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </FlexDiv>
                <FlexDiv gap={8} align="center">
                  <AppText>Call Result Type</AppText>
                  <div style={{ width: "100%" }}>
                    <PhoenixMultiSelect
                      name="call_result_option"
                      isMulti={false}
                      menuPortal
                      options={
                        dataCallResultOptions?.getCallResultOptions
                          .slice()
                          .sort((a: any, b: any) => (a.label < b.label ? -1 : 1)) || []
                      }
                      value={{
                        label: tempLeadFilter?.call_result_filter?.call_result_option,
                        value: tempLeadFilter?.call_result_filter?.call_result_option,
                      }}
                      marginBottom={false}
                      placeholder="None"
                      onChange={(e: any) => {
                        if (e?.value) {
                          setTempLeadFilter({
                            ...tempLeadFilter,
                            call_result_filter: {
                              ...tempLeadFilter.call_result_filter,
                              call_result_option: !!e?.value && e.value !== "0" ? e.value : undefined,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </FlexDiv>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "reps" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, reps: [] });

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    reps_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />
              {returnOperatorFromKey(selectedFilter || "") && (
                <FilterOperatorSelectionComponent
                  selectedFilter={selectedFilter}
                  tempLeadSystemOperators={tempLeadSystemOperators}
                  setTempLeadSystemOperators={setTempLeadSystemOperators}
                />
              )}
              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  {mapOptionsToKey(selectedFilter)?.map(
                    (
                      item: {
                        label: string;
                        value: string;
                      },
                      i: number,
                    ) => {
                      return (
                        <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`reps-${i}`}>
                          <PhoenixCheckbox
                            checked={tempLeadFilter[selectedFilter]?.includes(item.value)}
                            onChange={() => {
                              handleCheckboxClick(selectedFilter, item.value);
                            }}
                          />
                          <AppText fontSize={12} fontWeight={500}>
                            {item.label}
                          </AppText>
                        </FlexDiv>
                      );
                    },
                  )}
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "status" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, status: [] });

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    status_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />

              {returnOperatorFromKey(selectedFilter || "") && (
                <FilterOperatorSelectionComponent
                  selectedFilter={selectedFilter}
                  tempLeadSystemOperators={tempLeadSystemOperators}
                  setTempLeadSystemOperators={setTempLeadSystemOperators}
                />
              )}
              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                    return (
                      <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`status-${i}`}>
                        <PhoenixCheckbox
                          checked={tempLeadFilter[selectedFilter]?.includes(item)}
                          onChange={() => {
                            handleCheckboxClick(selectedFilter, item);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {formatFilterItemLabel(selectedFilter, item)}
                        </AppText>
                      </FlexDiv>
                    );
                  })}
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "industries" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  // reset clears out sub_industry too  but not the reverse
                  resetIndustries();

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    industries_operator: LeadFilterOperator.DoesInclude,
                    sub_industry_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />

              {returnOperatorFromKey(selectedFilter || "") && (
                <FilterOperatorSelectionComponent
                  selectedFilter={selectedFilter}
                  tempLeadSystemOperators={tempLeadSystemOperators}
                  setTempLeadSystemOperators={setTempLeadSystemOperators}
                />
              )}
              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  <FlexDiv gap={8} direction="column" style={{ paddingBottom: "16px" }}>
                    <AppText fontSize={12} fontWeight={500}>
                      Industry
                    </AppText>
                    <PhoenixMultiSelect
                      maxHeight={200}
                      name={selectedFilter}
                      options={mapOptionsToKey(selectedFilter)?.map((item: any) => ({ label: item, value: item }))}
                      value={tempLeadFilter?.industries?.map((item: any) => ({ label: item, value: item })) ?? []}
                      onChange={(e: any) => {
                        !!e.length
                          ? handleMultiSelectChange(
                              "industries",
                              e?.map((item: any) => item.value),
                            )
                          : resetIndustries();
                      }}
                      isClearable={false}
                      showCheckbox
                      menuPortal
                      width={376}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      marginBottom={false}
                    />
                  </FlexDiv>
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "sub_industry" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, sub_industry: [] });

                  // reset operator
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    sub_industry_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />
              {returnOperatorFromKey(selectedFilter || "") && (
                <FilterOperatorSelectionComponent
                  selectedFilter={selectedFilter}
                  tempLeadSystemOperators={tempLeadSystemOperators}
                  setTempLeadSystemOperators={setTempLeadSystemOperators}
                />
              )}
              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  <FlexDiv gap={8} direction="column" style={{ paddingBottom: "16px" }}>
                    <AppText fontSize={12} fontWeight={500}>
                      Sub-Industry
                    </AppText>
                    <PhoenixMultiSelect
                      maxHeight={200}
                      name={selectedFilter}
                      options={returnSubIndustryOptions(tempLeadFilter?.industries)?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      value={tempLeadFilter?.sub_industry?.map((item: any) => ({ label: item, value: item })) ?? []}
                      onChange={(e: any) => {
                        !!e.length
                          ? handleMultiSelectChange(
                              "sub_industry",
                              e?.map((item: any) => item.value),
                            )
                          : resetSubindustries();
                      }}
                      isClearable={false}
                      showCheckbox
                      menuPortal
                      // width={376}

                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      marginBottom={false}
                    />
                  </FlexDiv>
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "next_scheduled_event" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({
                    ...tempLeadFilter,
                    next_scheduled_event: {
                      NextScheduledEventTypes: [],
                      NextScheduledEventDays: undefined,
                    },
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />

              <PhoenixMultiSelect
                name="next_scheduled_event_types"
                placeholder={"Select Event Type"}
                options={mapOptionsToKey(selectedFilter)}
                value={tempLeadFilter?.next_scheduled_event?.NextScheduledEventTypes?.map((item: string) =>
                  NEXT_SCHEDULED_EVENT_OPTIONS?.find((option: OptionItem) => option.value === item),
                )}
                onChange={(e: any) => {
                  setTempLeadFilter({
                    ...tempLeadFilter,
                    next_scheduled_event: {
                      NextScheduledEventTypes: !!e.length ? e?.map((item: any) => item.value) : [],
                      NextScheduledEventDays: tempLeadFilter?.next_scheduled_event?.NextScheduledEventDays,
                    },
                  });
                }}
                marginBottom={false}
                menuPortal
                style={{ marginBottom: "16px" }}
              />

              <FlexDiv align="center" gap={8} style={{ paddingBottom: "2px" }}>
                <AppText fontSize={12} fontWeight={500}>
                  Occurs Within
                </AppText>
                <PhoenixInput
                  showNumberArrows
                  type="number"
                  inputValueType="number"
                  handleNumberArrowUpClick={() =>
                    setTempLeadFilter({
                      ...tempLeadFilter,
                      next_scheduled_event: {
                        ...tempLeadFilter?.next_scheduled_event,
                        NextScheduledEventDays: (tempLeadFilter?.next_scheduled_event?.NextScheduledEventDays || 0) + 1,
                      },
                    })
                  }
                  handleNumberArrowDownClick={() =>
                    setTempLeadFilter({
                      ...tempLeadFilter,
                      next_scheduled_event: {
                        ...tempLeadFilter?.next_scheduled_event,
                        NextScheduledEventDays: (tempLeadFilter?.next_scheduled_event?.NextScheduledEventDays || 1) - 1,
                      },
                    })
                  }
                  value={tempLeadFilter?.next_scheduled_event?.NextScheduledEventDays ?? ""}
                  onChange={(e: any) => {
                    setTempLeadFilter({
                      ...tempLeadFilter,
                      next_scheduled_event: {
                        ...tempLeadFilter?.next_scheduled_event,
                        NextScheduledEventDays: !!e?.target?.value ? e.target.value : undefined,
                      },
                    });
                  }}
                  width={72}
                  displayNoContextText
                />
                <AppText fontSize={12} fontWeight={500} style={{ paddingLeft: "8px" }}>
                  Days
                </AppText>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "custom_fields" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({
                    ...tempLeadFilter,
                    custom_fields: [],
                  });
                  setSelectedCustomFields([{ id: undefined, key: undefined, value: [], type: undefined }]);
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />

              <FlexDiv gap={24} direction="column">
                {selectedCustomFields?.map((_: SelectedCustomFieldType, i: number) => (
                  <CustomFieldFilter
                    index={i}
                    allCustomFieldOptions={allCustomFieldOptions}
                    customFieldOptions={customFieldOptions}
                    selectedCustomFields={selectedCustomFields}
                    setSelectedCustomFields={setSelectedCustomFields}
                    tempLeadFilter={tempLeadFilter}
                    setTempLeadFilter={setTempLeadFilter}
                    key={`custom_field-${i}`}
                  />
                ))}

                <FlexDiv
                  gap={8}
                  style={{ cursor: "pointer", paddingBottom: "16px" }}
                  onClick={() =>
                    setSelectedCustomFields([
                      ...selectedCustomFields,
                      {
                        id: undefined,
                        key: undefined,
                        value: undefined,
                        type: undefined,
                      },
                    ])
                  }
                >
                  <PhoenixIcon svg={plus} size={16} variant="brand" hoverColor={theme.PRIMARY500} pointer />
                  <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.PRIMARY500}>
                    Add Custom Field
                  </AppText>
                </FlexDiv>
              </FlexDiv>
            </PaddingAndScrollDiv>
          )}

          {selectedFilter === "channels" && (
            <PaddingAndScrollDiv>
              <ResetFilterHeader
                onClick={() => {
                  setTempLeadFilter({ ...tempLeadFilter, channels: [] });
                  setTempLeadSystemOperators({
                    ...tempLeadSystemOperators,
                    channels_operator: LeadFilterOperator.DoesInclude,
                  });
                }}
                selectedFilter={selectedFilter}
                theme={theme}
              />

              {returnOperatorFromKey(selectedFilter || "") && (
                <FilterOperatorSelectionComponent
                  selectedFilter={selectedFilter}
                  tempLeadSystemOperators={tempLeadSystemOperators}
                  setTempLeadSystemOperators={setTempLeadSystemOperators}
                />
              )}
              {(!returnOperatorFromKey(selectedFilter || "") ||
                tempLeadSystemOperators[
                  returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators
                ] !== LeadFilterOperator.EmptyField) && (
                <CheckboxList>
                  {mapOptionsToKey(selectedFilter)?.map((item: string, i: number) => {
                    return (
                      <FlexDiv gap={8} align="center" style={{ paddingBottom: "16px" }} key={`lead_sources-${i}`}>
                        <PhoenixCheckbox
                          checked={tempLeadFilter[selectedFilter]?.includes(item)}
                          onChange={() => {
                            handleCheckboxClick(selectedFilter, item);
                          }}
                        />
                        <AppText fontSize={12} fontWeight={500}>
                          {formatFilterItemLabel(selectedFilter, item)}
                        </AppText>
                      </FlexDiv>
                    );
                  })}
                </CheckboxList>
              )}
            </PaddingAndScrollDiv>
          )}
        </ModalContent>

        <ModalBottom>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            width={95}
            onClick={() => setShowFiltersModal(!showFiltersModal)}
          >
            <AppText fontSize={10} fontWeight={600} lineHeight={16} letterSpacing={1} color={theme.DANGER600} uppercase>
              Cancel
            </AppText>
          </PhoenixAppButton>

          <PhoenixAppButton
            buttonType="secondary"
            variant="brand"
            width={138}
            onClick={handleFilterUpdate}
            disabled={isDisabled}
          >
            <AppText
              fontSize={10}
              fontWeight={600}
              lineHeight={16}
              letterSpacing={1}
              color={theme.WHITE_COLOR}
              uppercase
            >
              Apply Filters
            </AppText>
          </PhoenixAppButton>
        </ModalBottom>
      </ModalContainer>

      <DarkDiv />
    </>
  );
};

/* This component is used to display the filter operator selection for the filter
 * selected by the user. It is used in the FilterModal component.
 * options are displayed as radio buttons
 * options = ["Does Include", "Does Not Include", "Empty Field"]
 */
const FilterOperatorSelectionComponent = ({
  selectedFilter,
  tempLeadSystemOperators,
  setTempLeadSystemOperators,
}: {
  selectedFilter: string;
  tempLeadSystemOperators: additionalLeadSystemOperatorsInterface;
  setTempLeadSystemOperators: React.Dispatch<React.SetStateAction<additionalLeadSystemOperatorsInterface>>;
}) => {
  return (
    <RadioSection>
      {FILTER_OPERATIONS_ARRAY?.map((item: any) => {
        const operator = returnOperatorFromKey(selectedFilter || "") as keyof typeof tempLeadSystemOperators;
        return (
          <RadioDiv>
            <PhoenixRadio
              selected={tempLeadSystemOperators[operator] === item.value}
              onClick={async () => {
                setTempLeadSystemOperators({ ...tempLeadSystemOperators, [operator]: item.value });
              }}
            />
            <AppText fontSize={12} fontWeight={500}>
              {item.label}
            </AppText>
          </RadioDiv>
        );
      })}
    </RadioSection>
  );
};

// helper components
type SelectedCustomFieldType = {
  id?: string;
  key?: string;
  value?: any[];
  type?: string;
};

interface CustomFieldFilterProps {
  index: number;
  allCustomFieldOptions: any;
  customFieldOptions: { label: string; value: any; type: string }[];
  selectedCustomFields: SelectedCustomFieldType[];
  setSelectedCustomFields: React.Dispatch<React.SetStateAction<SelectedCustomFieldType[]>>;
  tempLeadFilter: any;
  setTempLeadFilter: React.Dispatch<React.SetStateAction<any>>;
}

const CustomFieldFilter = ({
  index,
  allCustomFieldOptions,
  customFieldOptions,
  selectedCustomFields,
  setSelectedCustomFields,
  tempLeadFilter,
  setTempLeadFilter,
}: CustomFieldFilterProps) => {
  return (
    <CustomFieldContainer gap={16} direction="column">
      <FlexDiv justify="space-between" align="center">
        <AppText fontSize={10} fontWeight={600} lineHeight={16} color={theme.NEUTRAL350} letterSpacing={1} uppercase>
          Custom Field {index + 1}
        </AppText>
        {index !== 0 && (
          <PhoenixIcon
            svg={trash}
            size={16}
            variant="danger"
            pointer
            onClick={() => {
              const arr = [...selectedCustomFields];
              arr.splice(index, 1);
              setSelectedCustomFields(arr);

              const newTempGridFilters = cloneDeep(tempLeadFilter);
              newTempGridFilters.custom_fields = arr;
              setTempLeadFilter(newTempGridFilters);
            }}
          />
        )}
      </FlexDiv>

      <FlexDiv gap={8} direction="column">
        <AppText fontSize={12} lineHeight={18}>
          Choose Custom Field <span style={{ color: theme.DANGER600 }}>*</span>
        </AppText>

        <PhoenixMultiSelect
          maxHeight={200}
          name={`custom_fields_option${index}`}
          options={customFieldOptions}
          value={
            selectedCustomFields?.[index]?.id
              ? {
                  label: selectedCustomFields?.[index]?.key,
                  value: selectedCustomFields?.[index]?.id,
                }
              : null
          }
          onChange={(e: { label: string; value: any; type: string }) => {
            const newVal = cloneDeep(selectedCustomFields);
            newVal[index] = { id: e.value, key: e.label, value: [], type: e.type };
            setSelectedCustomFields(newVal);
          }}
          isMulti={false}
          isClearable={false}
          hideSelectedOptions={false}
          marginBottom={false}
          maxMenuHeight={140}
        />
      </FlexDiv>

      <PhoenixMultiSelect
        maxHeight={200}
        name={`custom_fields_value${index}`}
        options={
          selectedCustomFields?.[index]
            ? selectedCustomFields?.[index]?.type === "Boolean"
              ? [
                  {
                    label: "true",
                    value: "true",
                  },
                  {
                    label: "false",
                    value: "false",
                  },
                ]
              : allCustomFieldOptions?.fetchCustomFields
                  ?.find((ele: any) => ele.id === selectedCustomFields?.[index]?.id)
                  ?.options?.map((value: string) => ({
                    label: value,
                    value: value,
                  }))
            : []
        }
        value={selectedCustomFields?.[index]?.value?.map((value: any) => ({ label: value, value: value }))}
        onChange={(e: any) => {
          const newVal = cloneDeep(selectedCustomFields);
          newVal[index].value = e?.map((ele: { label: string; value: any }) => ele?.value);
          setSelectedCustomFields(newVal);

          const newTempGridFilter = cloneDeep(tempLeadFilter);
          newTempGridFilter.custom_fields = newVal;
          setTempLeadFilter(newTempGridFilter);
        }}
        isMulti={true}
        showCheckbox={true}
        isClearable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        marginBottom={false}
      />
    </CustomFieldContainer>
  );
};

// helper components

const BackToAllFiltersButton = ({
  setSelectedFilter,
}: {
  setSelectedFilter: React.Dispatch<React.SetStateAction<null | string>>;
}) => {
  return (
    <FlexDiv
      align="center"
      gap={4}
      style={{ padding: "24px 40px", cursor: "pointer", width: "fit-content" }}
      onClick={() => {
        setSelectedFilter(null);
      }}
    >
      <PhoenixIcon svg={chevron_left} size={16} color={theme.PRIMARY500} hoverColor={theme.PRIMARY500} pointer />

      <AppText fontSize={10} fontWeight={600} letterSpacing={1} uppercase color={theme.PRIMARY500}>
        Back
      </AppText>
    </FlexDiv>
  );
};

interface ResetFilterProps {
  selectedFilter: string;
  onClick: () => void;
  theme: any;
}

const ResetFilterHeader: React.FC<ResetFilterProps> = ({ selectedFilter, onClick, theme }) => {
  return (
    <FlexDiv justify="space-between" align="center" style={{ marginBottom: "24px" }}>
      <FilterTitle>{mapLabelToKey(selectedFilter)}</FilterTitle>
      <FlexDiv gap={8} align="center" onClick={onClick} style={{ cursor: "pointer", width: "fit-content" }}>
        <PhoenixIcon svg={refresh} variant="brand" size={12} pointer />
        <AppText
          fontSize={10}
          fontWeight={500}
          color={theme.PRIMARY500}
          style={{ lineHeight: "14px", userSelect: "none" }}
        >
          Reset Filter
        </AppText>
      </FlexDiv>
    </FlexDiv>
  );
};

const slideInAnimation = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 899;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 456px;
  height: 100vh;
  top: 0;
  right: 0;

  background-color: ${theme.WHITE_COLOR};
  animation: ${slideInAnimation} 0.4s ease forwards;
`;

const CustomFieldContainer = styled(FlexDiv)`
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 8px;
  padding: 16px;
`;

const HeaderDiv = styled.div`
  padding: 32px 32px 8px 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 20;

  max-height: 92vh;
  min-height: 92vh;

  padding-bottom: 100px;
`;

const ModalBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  padding: 16px 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: ${theme.WHITE_COLOR};
`;

const SectionTitle = styled.div`
  display: flex;
  margin: 0px;
  height: 56px;
  align-items: center;
`;

interface SectionContainerDivProps {
  displayDot: boolean;
}

const SectionContainerDiv = styled.div<SectionContainerDivProps>`
  display: flex;
  align-items: center;
  padding-left: ${(props) => (props.displayDot ? "0px" : "16px")};
  padding-right: 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  cursor: pointer;
  /* max-height: 400px; */
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${theme.DANGER600};
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const slideUpFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const PaddingAndScrollDiv = styled.div`
  /* overflow-x: visible;
  overflow-y: auto; */

  max-height: 78vh;
  min-height: 78vh;
  padding: 0px 40px;

  & > * {
    animation: ${slideUpFadeIn} 0.3s ease forwards;
  }
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const FilterTitle = styled(AppText)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  height: 68vh;
  overflow-y: auto;
  overflow-x: visible;
`;

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
`;

const RadioSection = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 3px;
  margin-bottom: 24px;
`;

const TimeColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;
  margin-bottom: 12px;
`;

const TimeLabelText = styled(AppText)`
  margin: 0px;
  margin-bottom: 5px;
  line-height: 1.3;
`;

const CloseModalIcon = styled(PhoenixIcon)`
  position: absolute;
  left: 8px;
  top: 8px;
`;

const AllFiltersStep = styled.div`
  padding: 0px 32px;
  height: 100%;
  overflow-y: auto;
`;
export { LeadSearchFiltersModal };
