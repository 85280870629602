import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText } from "../UI";

const LeadIsValuable: React.FC = () => {
  return (
    <Container>
      <ValueText>You're about to make what OPSIQ predicts to be the most valuable dial!</ValueText>
    </Container>
  );
};

const ValueText = styled(AppText)`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${theme.PRIMARY500};
  z-index: 9;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 432px;
  padding: 20px 28px;
  background: ${theme.WHITE_COLOR};
  z-index: 2;
  height: 66px;
  margin-bottom: 24px;
  margin-top: 8px;
  :before {
    position: absolute;
    content: "";
    top: 0px;
    bottom: 0px;
    left: 4px;
    right: 4px;
    z-index: 1;
    background: ${theme.WHITE_COLOR};
    border-radius: 6px;
  }
  :after {
    position: absolute;
    content: "";
    top: -4px;
    bottom: -4px;
    left: 0px;
    right: 0px;
    z-index: -1;
    background: linear-gradient(#3e65ff, #738fff);
    border-radius: 8px;
  }
`;

export { LeadIsValuable };
