import styled from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState, useEffect, useRef, useMemo, useContext } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { AppText, AppIconButton } from "../UI";
import { theme } from "../../utils/theme";
import moment, { invalid } from "moment";
import opsiqLogo from "../../images/OPSIQ_LOGO.svg";
import { HeaderNotificationsComponent } from "./";
import { isLoggedInVar, loggedInUser } from "../../apollo/cache";
import { MessagesInfoHeader } from "./InfoHeader/Messages";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { APP_ROLES, FETCH_SMS_ITEMS_INFO_HEADER_POLL_INTERVAL } from "../../utils/variables";
import { IS_LOGGED_IN, LOGGED_IN_USER } from "../../apollo/query";
import { useChannel } from "@ably-labs/react-hooks";
import { CallContext, LeadFilterContext, NotificationsContext } from "../../context";
import { appToast, errorToast, notificationToast, successToast } from "../../utils/toast";
import {
  InboundConciergeModal,
  SMSAndEmailDetailsModal,
  DeleteTemplateModal,
  TemplatePreviewModal,
  UpdateFormModal,
  DeleteFormModal,
  FormPreviewModal,
} from "../modal";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TOKEN_PERSIST_KEY } from "../../utils/variables";
import ReactTooltip from "react-tooltip";
import { LeadCardContext } from "../../context";
import { cloudinary } from "../../services/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import { MixpanelActions } from "../../services/mixpanel";
import { SMSDialogModalV2 } from "../modal/SMSDialogModalV2";
import { ModalContext } from "../../context/ModalContext";
import { PhoenixMenuButton } from "../UI/Phoenix/PhoenixNavigation/PhoenixMenuButton";
import { PhoenixIcon } from "../UI/Phoenix/PhoenixIcon";
import { RepStatusMenuIcon } from "../UI";
import { APP_REP_ROLES } from "../../utils/variables";
import {
  bar_chart,
  book,
  calendar,
  dashboard,
  dialer,
  log_out,
  settings,
  system_view,
  message_square,
  sequences,
} from "../../images/NewDesign";
import { logout } from "../../utils/auth";
import { UserStatusContext } from "../../context";
import { getLocalStorage } from "../../utils/misc";

interface SMSItems {
  id: string;
  sent_at: string;
  text: string;
  isFromUser: string;
  sender_label: string;
  sender_id: string;
  read_at: string;
  read: boolean;
}

interface SMSItemsData {
  fetchSMSItems: {
    sms_messages: SMSItems[];
  };
}

interface NotificationType {
  id: string;
  lead_id: string;
  text?: string;
  details?: string;
  read?: boolean;
  payload?: any;
  send_action_back_to_server?: boolean;
  type: string;
  updated_at?: any;
  created_at?: any;
  relevant_id?: string;
  call_to_action_read?: boolean;
  text_action?: string;
  date_string?: string;
  type_label?: string;
  salesperson?: string;
  voicemail_url?: string;
  voicemail_url_computed?: string;
}

const FETCH_SMS_ITEMS = gql`
  query fetchSMSItems($take: Int!) {
    fetchSMSItems(take: $take) {
      sms_messages {
        id
        sent_at
        text
        isFromUser
        lead_number_used #this is a fallback in case sender_id is false
        sender_label
        sender_id
        read_at
        read
        twilio_failed_at
      }
    }
  }
`;

const ORGANIZATION_DATA_REP_PERMISSION = gql`
  {
    fetchOrganization {
      id
      show_system_view
    }
  }
`;

const RESPOND_TO_CONCIERGE = gql`
  mutation respondToConcierge($callMeNowId: String!, $called: Boolean!) {
    respondToConcierge(callMeNowId: $callMeNowId, called: $called)
  }
`;

const INVALIDATE_SESSION = gql`
  mutation Logout($sessionToken: String!) {
    Logout(session_token: $sessionToken)
  }
`;

const InfoHeader: React.FC = () => {
  const { toggleSequences } = useFlags();

  const [dropdownOpen, setDropdownOpen] = useState("");

  const [currentDateTime, setCurrentDateTime] = useState(moment().format("h:mm A"));

  const node: React.MutableRefObject<any> = useRef();

  const { updateUserToIdle } = useContext(UserStatusContext);

  const {
    showSMSChat,
    SMSAndEmailDetailsModalData,
    showDeleteTemplateModal,
    showTemplatePreviewModal,
    templatePreviewModalData,
    showUpdateFormModal,
    showDeleteFormModal,
    showFormPreviewModal,
  } = useContext(ModalContext);
  const { descendingOnlySortedNotifications } = useContext(NotificationsContext);

  const isInternational = loggedInUser().organization?.international;
  const isRep = !!loggedInUser().role && APP_REP_ROLES.includes(loggedInUser().role as APP_ROLES);
  const notificationsNumber = descendingOnlySortedNotifications?.filter((item: NotificationType) => !item?.read)
    ?.length;

  const [invalidateSession] = useMutation(INVALIDATE_SESSION, {
    onCompleted: () => {
      console.log("Session token invalidated");
    },
    onError: (error) => {
      console.log("Error invalidating session token", error);
    },
  });

  const invalidateSessionToken = () => {
    const token = localStorage.getItem(TOKEN_PERSIST_KEY);
    if (token) {
      invalidateSession({
        variables: {
          sessionToken: token,
        },
      });
    }
  };

  const { data, loading, error, startPolling, refetch } = useQuery<SMSItemsData>(FETCH_SMS_ITEMS, {
    fetchPolicy: "network-only",
    variables: { take: 50 },
    // Skips query if organization is international. This also stops polling.
    skip: isInternational,
  });

  const { data: dataOrg } = useQuery(ORGANIZATION_DATA_REP_PERMISSION, {
    fetchPolicy: "network-only",
    variables: {
      org_id: loggedInUser().organization_id,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [respondToConcierge, { loading: loadingrespondToConcierge, error: errorRespondToConcierge }] = useMutation(
    RESPOND_TO_CONCIERGE,
    {
      onError({ message, name }: { message: string; name: string }) {
        Sentry.captureEvent({
          message: `respondToConcierge GraphQL Error: ${message}`,
        });
        console.log(`Error in ${name}: `, message);
      },

      onCompleted(data: any) {
        console.log("Completed", data);
      },
    },
  );

  useEffect(() => {
    startPolling(FETCH_SMS_ITEMS_INFO_HEADER_POLL_INTERVAL);
  }, []);

  const handleClickOutside = (e: MouseEvent): void => {
    if (node.current.contains(e.target)) {
      return;
    }
    setDropdownOpen("");
  };

  useEffect(() => {
    if (!currentDateTime) return;
    const intervalId = setInterval(() => {
      setCurrentDateTime(moment().format("h:mm A"));
    }, 10000);
    return () => clearInterval(intervalId);
  }, [currentDateTime]);

  useEffect(() => {
    if (!!dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const currentLocation = useLocation().pathname;

  const messageNotificationNumber = !!data
    ? data?.fetchSMSItems?.sms_messages
        .filter((item: SMSItems) => {
          return item.sender_id !== loggedInUser()?.id ? true : false;
        })
        .reduce((accum, value) => {
          return accum + (!!value.read ? 0 : 1);
        }, 0)
    : 0;

  const allowRepToSeeSystemView = useMemo(() => {
    return dataOrg?.fetchOrganization?.show_system_view ?? false;
  }, [dataOrg]);

  const imageURL = localStorage.getItem("profile_image");
  const profileImage = cloudinary.image(imageURL ? imageURL : "hfvs3jniatz9yadvcb7r");

  const { data: ablyLoggedInUser } = useQuery(LOGGED_IN_USER);
  const { data: ablyIsLoggedIn } = useQuery(IS_LOGGED_IN);

  const { fetchNotifications } = useContext(NotificationsContext);

  const { resetNextDial } = useContext(CallContext);

  const { refreshActiveAndCustomQueue, fetchSuggestedActionLeadV2 } = useContext(LeadCardContext);

  const { leadFilter } = useContext(LeadFilterContext);

  const {
    setInboundConciergeModalData,
    showInboundConciergeModal,
    setShowInboundConciergeModal,
    showSMSAndEmailDetailsModal,
  } = useContext(ModalContext);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useChannel(`${ablyLoggedInUser?.loggedInUser?.id}:notification`, (payload) => {
    console.log("inbound from Ably: ", payload);

    if (!ablyLoggedInUser?.loggedInUser?.id) {
      return;
    }
    if (!ablyIsLoggedIn?.isLoggedIn) {
      return;
    }
    const notification = payload.data;

    if (notification.type === "UpdatedActiveQueue") {
      // this ably channel is used to update the active queue and next action when a sms or email step was completed

      // reset next dial if parent was from active queue (added logic change from next action feature)
      const nextActionOverride = JSON.parse(localStorage.getItem("next_dial_override") || "{}");

      if (nextActionOverride?.parent === "activeQueueDials") {
        resetNextDial();
      }

      const tabIsOnDash = window.location.pathname.includes("/dash/");
      console.log("tabIsOnDash: ", tabIsOnDash);

      // then do a refetch of the active queue if it is selected.
      if (tabIsOnDash && localStorage.getItem("selected_tab_dial_queue") === "active") {
        // refresh active queue
        refreshActiveAndCustomQueue();
        // refresh suggested action lead
        fetchSuggestedActionLeadV2({
          variables: {
            lead_filter: leadFilter,
            skip: 0,
            take: 1,
            // the active queue let's the user change this sort option
            // the suggested action is always sorted by the organization's order defind by admin
            sort_option: "OrganizationOrder",
          },
        });
      }

      return;
    }

    if (notification.type === "FailedSMS") {
      errorToast(notification.text);
      return;
    }

    if (notification.type === "SuccessfulSMS") {
      successToast(notification.text);
      return;
    }

    if (notification.type === "RequestMoreLeads") {
      appToast(`${notification?.text}`);
      return;
    }

    if (!!notification && !!notification.text && !!notification.details) {
      // what gets passed in determines whether the toast is clickable and where the link goes
      switch (notification.type) {
        case "HotLead": {
          notificationToast({
            id: notification.id || "",
            message: notification.text,
            subtext: notification.details,
            variant: "primary",
            lead_id: notification.relevant_id,
            redirect: "/system-view/my-leads",
          });
          break;
        }
        case "LeadAlreadyOwned": {
          notificationToast({
            id: notification.id || "",
            message: notification.text,
            subtext: notification.details,
            variant: "primary",
            lead_id: notification.relevant_id,
          });
          break;
        }
        case "NewSMS": {
          notificationToast({
            id: notification.id || "",
            message: notification.text,
            variant: "primary",
          });
          refetch({ take: 50 });
        }
        default: {
          notificationToast({
            id: notification.id || "",
            message: notification.text,
            subtext: notification.details,
            variant: "primary",
          });
        }
      }

      /* Voicemail update. (the FE makes a temporary missed call notification automaticly on missed calls 
       if there is a voicemail the BE prompts the FE to refresh the notifications overwritting the temporary missed call notification) */
      if (notification.type && notification.type === "MissedCall") {
        // called missedcall but it is only for voicemails
        fetchNotifications();
      }
    }
  });

  useChannel(`${ablyLoggedInUser?.loggedInUser?.id}:inboundConcierge`, (payload) => {
    console.log("inbound from Ably: ", payload);
    if (!ablyLoggedInUser?.loggedInUser?.id) {
      return;
    }
    if (!ablyIsLoggedIn?.isLoggedIn) {
      return;
    }

    // the var must not be from context because it will never be updated in time after the listener is created
    const isOnCall = localStorage.getItem("userIsOnCallLocal");

    if (payload.data) {
      const data = payload.data;
      setInboundConciergeModalData({
        business_name: data.business_name,
        lead_name: data.lead_name,
        phone_number: data.phone_number,
        lead_id: data.lead_id,
        timer_start_time: data.timer_start_time,
        existingLead: data.existingLead,
        address: data.address,
        callMeNowId: data.callMeNowId,
        response_time: data.response_time,
      });

      // Removed this because we now allow secondary incoming calls
      if (isOnCall === "true") {
        console.log("rejecting inbound concierge request because user is on a call");
        respondToConcierge({
          variables: {
            callMeNowId: data.callMeNowId,
            called: false,
          },
        });
        return;
      }

      setShowInboundConciergeModal(true);
      return;
    }

    console.log("something went wrong with the inbound concierge ably channel");
  });

  const { setUpcomingDials } = useContext(LeadCardContext);

  return (
    <BlackHeader ref={node}>
      <ReactTooltip
        multiline
        place="right"
        effect="solid"
        id="navbar"
        offset={{ right: 20 }}
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      {/** moved here so they can show up on any page */}
      {showInboundConciergeModal && <InboundConciergeModal />}
      {showUpdateFormModal && <UpdateFormModal />}
      {showDeleteFormModal && <DeleteFormModal />}
      {showFormPreviewModal && <FormPreviewModal />}
      {showSMSAndEmailDetailsModal && <SMSAndEmailDetailsModal />}
      {showDeleteTemplateModal && (
        <DarkDiv2>
          <DeleteTemplateModal />
        </DarkDiv2>
      )}
      {showTemplatePreviewModal &&
        (!templatePreviewModalData?.noDarkDiv ? (
          <DarkDiv2>
            <TemplatePreviewModal />
          </DarkDiv2>
        ) : (
          <TemplatePreviewModal noDarkDiv={templatePreviewModalData?.noDarkDiv} />
        ))}

      <FlexMiddleDiv>
        <IconNavLink style={{ textDecoration: "none" }} to="/settings/">
          <AccountSettingsBorder>
            <AdvancedImageStyle style={{}} cldImg={profileImage} />
          </AccountSettingsBorder>
        </IconNavLink>
        {isRep && <RepStatusMenuIcon />}
        {(loggedInUser().role === "SM" || loggedInUser().role === "ADMIN") && (
          <IconNavLink to="/dash/" activeClassName="active" data-tip="Dashboard" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                MixpanelActions.track("SideNav", { type: "clicked", tab: "Dashboard" });
              }}
              selected={currentLocation.includes("dash")}
            >
              <PhoenixIcon svg={dashboard} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}

        {(loggedInUser().role === "AE" || loggedInUser().role === "SDR") && (
          <IconNavLink to="/dash/" activeClassName="active" data-tip="Dialer" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                updateUserToIdle({ do_not_update_if_on_call: true });
                MixpanelActions.track("SideNav", { type: "clicked", tab: "Dialer" });
              }}
              selected={currentLocation.includes("dash")}
            >
              <PhoenixIcon svg={dialer} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}

        {loggedInUser().role === "ADMIN" && (
          <IconNavLink to="/system-config/" activeClassName="active" data-tip="System Configuration" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                updateUserToIdle({ do_not_update_if_on_call: true });
                MixpanelActions.track("SideNav", { type: "clicked", tab: "System Configuration" });
              }}
              selected={currentLocation.includes("system-config")}
            >
              <PhoenixIcon svg={settings} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}

        {loggedInUser().role === "ADMIN" && (
          <IconNavLink to="/system-view/" activeClassName="active" data-tip="System View" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                MixpanelActions.track("SideNav", { type: "clicked", tab: "System View" });
              }}
              selected={currentLocation.includes("system-view")}
            >
              <PhoenixIcon svg={system_view} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}
        {(loggedInUser().role === "SM" || loggedInUser().role === "ADMIN") && (
          <IconNavLink to="/event-manager" activeClassName="active" data-tip="Event Manager" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                MixpanelActions.track("SideNav", { type: "clicked", tab: "Event Manager" });
              }}
              selected={currentLocation.includes("event-manager")}
            >
              <PhoenixIcon svg={calendar} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}
        {(loggedInUser().role === "SDR" || loggedInUser().role === "AE") && (
          <IconNavLink to="/manage-schedule" activeClassName="active" data-tip="Manage Schedule" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                updateUserToIdle({ do_not_update_if_on_call: true });
                MixpanelActions.track("SideNav", { type: "clicked", tab: "Manage Schedule" });
              }}
              selected={currentLocation.includes("manage-schedule")}
            >
              <PhoenixIcon svg={calendar} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}

        <IconNavLink to="/call-library/" activeClassName="active" data-tip="Call Library" data-for="navbar">
          <PhoenixMenuButton
            onClick={() => {
              updateUserToIdle({ do_not_update_if_on_call: true });
              MixpanelActions.track("SideNav", { type: "clicked", tab: "Call Library" });
            }}
            selected={currentLocation.includes("call-library")}
          >
            <PhoenixIcon svg={book} variant="white" pointer={true} />
          </PhoenixMenuButton>
        </IconNavLink>

        {(loggedInUser().role === "AE" || loggedInUser().role === "SDR") && (
          <IconNavLink to="/grid/" activeClassName="active" data-tip="My Performance" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                updateUserToIdle({ do_not_update_if_on_call: true });
                MixpanelActions.track("SideNav", { type: "clicked", tab: "My Performance" });
              }}
              selected={currentLocation.includes("grid")}
            >
              <PhoenixIcon svg={bar_chart} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}

        {(loggedInUser().role === "AE" || loggedInUser().role === "SDR" || loggedInUser().visible_all_leads_sm) &&
          allowRepToSeeSystemView && (
            <IconNavLink to="/system-view/" activeClassName="active" data-tip="System View" data-for="navbar">
              <PhoenixMenuButton
                onClick={() => {
                  updateUserToIdle({ do_not_update_if_on_call: true });
                  MixpanelActions.track("SideNav", { type: "clicked", tab: "System View" });
                }}
                selected={currentLocation.includes("system-view")}
              >
                <PhoenixIcon svg={system_view} variant="white" pointer={true} />
              </PhoenixMenuButton>
            </IconNavLink>
          )}

        {!!toggleSequences && loggedInUser().role === "ADMIN" && (
          <IconNavLink to="/sequences/" activeClassName="active" data-tip="Sequences" data-for="navbar">
            <PhoenixMenuButton
              onClick={() => {
                MixpanelActions.track("SideNav", { type: "clicked", tab: "Sequences" });
              }}
              selected={currentLocation.includes("sequences")}
            >
              <PhoenixIcon svg={sequences} variant="white" pointer={true} />
            </PhoenixMenuButton>
          </IconNavLink>
        )}

        <SeparatingLine />

        {/* Hide SMS UI from International organizations. */}
        {!isInternational && (loggedInUser().role === "SDR" || loggedInUser().role === "AE") && (
          <div style={{ marginBottom: "24px" }}>
            <PhoenixMenuButton
              onClick={() => {
                updateUserToIdle({ do_not_update_if_on_call: true });
                MixpanelActions.track("SideNav", { type: "clicked", tab: "Messages" });
                setDropdownOpen(dropdownOpen === "messages" ? "" : "messages");
              }}
              notification={messageNotificationNumber > 0}
            >
              <PhoenixIcon svg={message_square} variant="white" pointer={true} />
              {dropdownOpen === "messages" && (
                <MessagesInfoHeader data={data} loading={loading} error={error} setDropdownOpen={setDropdownOpen} />
              )}
            </PhoenixMenuButton>
          </div>
        )}

        <PhoenixMenuButton
          onClick={() => {
            updateUserToIdle({ do_not_update_if_on_call: true });
            MixpanelActions.track("SideNav", { type: "clicked", tab: "Notifications" });
            setDropdownOpen(dropdownOpen === "notifications" ? "" : "notifications");
          }}
          notification={!!notificationsNumber}
        >
          <MessagesDiv>
            <HeaderNotificationsComponent dropdownOpen={dropdownOpen} setDropdownOpen={setDropdownOpen} />
          </MessagesDiv>
        </PhoenixMenuButton>
      </FlexMiddleDiv>
      {showSMSChat && <SMSDialogModalV2 />}
      <FlexMiddleDiv style={{ gap: "40px" }}>
        <div data-tip="Log Out" data-for="navbar">
          <PhoenixMenuButton
            id="logout-text"
            onClick={() => {
              MixpanelActions.track("Logout");
              MixpanelActions.reset();
              invalidateSessionToken();
              logout();
              // edge case for people using multiple rep logins
              setUpcomingDials([]);
            }}
          >
            <PhoenixIcon svg={log_out} variant="white" pointer={true} />
          </PhoenixMenuButton>
        </div>
        <TimeText>{currentDateTime}</TimeText>
      </FlexMiddleDiv>
    </BlackHeader>
  );
};

const SeparatingLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.PRIMARY500};
  margin: 40px 0px 40px 0px;
`;

const FlexMiddleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 8px 0px 8px;
`;

const TimeText = styled(AppText)`
  margin-top: 12px;
  margin-bottom: 32px;
  color: ${theme.WHITE_COLOR};
  font-weight: 600;
  font-size: 10px;
`;

const AccountSettingsBorder = styled.div`
  height: 36px;
  width: 36px;
  max-height: 36px;
  max-width: 36px;
  padding: 2px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid ${theme.PRIMARY500};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.WHITE_COLOR};
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 30px;
  width: 30px;
  height: 30px;
`;

const IconNavLink = styled(NavLink)`
  margin-top: 24px;
`;

const MessagesDiv = styled.div`
  position: relative;

  width: fit-content;
  cursor: pointer;
  div {
    right: -12px;
  }
`;

const BlackHeader = styled.div`
  overflow-y: auto;
  width: 72px;
  min-width: 72px;
  max-width: 72px;
  min-height: fit-content;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${theme.PRIMARY900};
  z-index: 100;
`;

const DarkDiv2 = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -72px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export { InfoHeader };
