import moment from "moment";
import * as Sentry from "@sentry/react";
import React from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppText, AppSelect, AppErrorText, Loading, FlexDiv } from "../UI";
import { FiMenu } from "react-icons/fi";
import { loggedInUser } from "../../apollo/cache";
import { gql, useQuery } from "@apollo/client";
import { APP_REP_ROLES, APP_ROLES } from "../../utils/variables";
import { OptionItem } from "../../types/genericTypes";
import { PhoenixIcon, PhoenixAppButton, PhoenixMultiSelect } from "../UI/Phoenix";
import { chevron_left, chevron_right } from "../../images/NewDesign";
import { eventManagerFilterOptions } from "../../utils/misc";

const FETCH_USERS_UNDER = gql`
  query fetchUsersUnder {
    fetchUsersUnder {
      id
      role
      first_name
      last_name
    }
  }
`;

interface FetchUsersUnderExpected {
  id?: string;
  role?: string;
  first_name?: string;
  last_name?: string;
}

const CalendarToolbar: React.FC<any> = (props: any) => {
  const goBackMonth = () => {
    props.onNavigate("PREV");
  };

  const goForwardMonth = () => {
    props.onNavigate("NEXT");
  };

  const goToday = () => {
    props.onNavigate("TODAY");
  };

  const changeView = (view: string) => {
    props.onView(view);
  };

  const changeTitleFormat = (view: string, date: Date) => {
    if (view === "day") return moment(date).format("MMMM Do[,] YYYY");
    else if (view === "week") {
      return `${moment(date).weekday(0).format("MM/DD")} - ${moment(date).weekday(6).format("MM/DD")}`;
    } else if (view === "month") return moment(date).format("MMMM YYYY");
    else return moment(date).format("MMMM YYYY");
  };

  const { data: optionsRepsData, loading: loadingReps, error: errorReps } = useQuery(FETCH_USERS_UNDER, {
    fetchPolicy: "no-cache",
    skip: APP_REP_ROLES.includes(loggedInUser().role as APP_ROLES),
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <Container>
      <div style={{ justifySelf: "start" }}></div>

      <EventFilterContainer>
        <AppText>Event Filter:</AppText>

        <PhoenixMultiSelect
          name="event-manager-filter"
          options={eventManagerFilterOptions}
          value={props.eventFilter ? props.eventFilter : []}
          onChange={(e: OptionItem[]) => props?.handleEventFilterChange && props.handleEventFilterChange(e)}
          marginBottom={false}
          width={240}
          maxHeight={40}
          isClearable={false}
          menuPortal
        />
      </EventFilterContainer>

      <FiltersDiv>
        {!APP_REP_ROLES.includes(loggedInUser().role as APP_ROLES) && (
          <ListViewButton onClick={() => props.setActiveView("List")}>
            <FiMenu style={{ marginRight: "10px" }} />
            List View
          </ListViewButton>
        )}
        {!APP_REP_ROLES.includes(loggedInUser().role as APP_ROLES) &&
          (loadingReps ? (
            <div style={{ minWidth: "40px" }}>
              <Loading />
            </div>
          ) : errorReps ? (
            <AppErrorText>Could not fetch reps</AppErrorText>
          ) : (
            <AppSelect
              style={{ minWidth: "150px" }}
              value={props.value}
              options={
                !!optionsRepsData?.fetchUsersUnder?.length
                  ? [
                      { label: "All Reps", value: "" },
                      ...optionsRepsData.fetchUsersUnder
                        ?.map((item: FetchUsersUnderExpected) => ({
                          label: `${item?.first_name} ${item?.last_name}`,
                          value: item?.id,
                        }))
                        ?.sort((a: OptionItem, b: OptionItem) => {
                          const nameA = a.label.toLowerCase();
                          const nameB = b.label.toLowerCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        }),
                    ]
                  : []
              }
              onChange={async (e: any) => {
                await props.setUserFilter(e.target.value);
              }}
              allowSelectPlaceholder={false}
              placeholder={"Filter Rep"}
            />
          ))}
      </FiltersDiv>

      <MonthContainer>
        <PhoenixIcon svg={chevron_left} size={32} pointer onClick={goBackMonth} />
        <DateText>{changeTitleFormat(props.view, props.date)}</DateText>
        <PhoenixIcon svg={chevron_right} size={32} pointer onClick={goForwardMonth} />
      </MonthContainer>
      <PhoenixAppButton
        buttonType="secondary"
        variant="brand-outline"
        uppercase
        buttonTextFontSize={12}
        borderRadius={6}
        style={{ letterSpacing: "1px" }}
        onClick={goToday}
      >
        Today
      </PhoenixAppButton>
      <ViewContainer>
        <ViewSelect onClick={() => changeView("day")} view={props.view === "day"}>
          Day
        </ViewSelect>
        <ViewSelect onClick={() => changeView("week")} view={props.view === "week"}>
          Week
        </ViewSelect>
        <ViewSelect onClick={() => changeView("month")} view={props.view === "month"}>
          Month
        </ViewSelect>
      </ViewContainer>
    </Container>
  );
};

const FiltersDiv = styled.div`
  display: grid;
  grid-column-start: 2;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr;
  align-self: center;
  margin-top: 20px;
`;

const CaretIcon = styled.div`
  background-color: ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
`;

const Container = styled.div`
  background-color: ${theme.NEUTRAL100};
  height: 95px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  align-items: center;
  justify-items: center;
  padding-bottom: 12px;
`;

const DateText = styled(AppText)`
  font-size: 27px;
  margin: auto;
  line-height: 27px;
`;

const ListViewButton = styled.div`
  border-radius: 4px;
  font-size: 13px;
  padding: 10px 10px;
  border: solid 1.5px #16151a;
  background-color: #ffffff;
  height: 36px;
  min-width: 103px;
  max-width: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const MonthContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  width: 380px;
  grid-column-start: 3;
`;

const TodayButton = styled.div`
  border-radius: 4px;
  border: solid 1.5px #16151a;
  background-color: #ffffff;
  height: 36px;
  width: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ViewContainer = styled.div`
  width: 312px;
  height: 41px;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 6px;
  overflow: hidden;
`;

interface ViewSelectProps {
  view: boolean;
}

const ViewSelect = styled.div<ViewSelectProps>`
  display: flex;
  width: 100%;
  height: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 39px;
  color: ${(props) => (props.view ? theme.WHITE_COLOR : theme.PRIMARY500)};
  background-color: ${(props) => (props.view ? theme.PRIMARY500 : theme.WHITE_COLOR)};

  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;

  :hover {
    background-color: ${(props) => (props.view ? theme.PRIMARY500 : theme.PRIMARY200)};
  }

  transition: background-color 0.15s ease-in-out;
`;

const EventFilterContainer = styled(FlexDiv)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 320px;

  grid-column-start: 1;
`;

export { CalendarToolbar };
