import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { xIcon } from "../../images/NewDesign";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { InputField } from "../Field";
import { PhoenixInputField } from "../Field/Phoenix";
import { AppText, Loading } from "../UI";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { SYSTEM_FIELD_CHAR_LIMIT } from "./../../utils/format";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  id?: string;
  keyName?: string;
  options?: string[];
  label?: string;
  allow_reps_to_edit?: boolean;
  visible?: boolean;
  setDeleteModalData?: Dispatch<SetStateAction<{ id: string; fieldType: string; fieldName: string }>>;
  sub_fields?: string[];
}

const ADD_INDUSTRY_FIELD = gql`
  mutation addSystemField($systemFieldType: SystemFieldType!, $label: String!, $subFields: [String!]) {
    addSystemField(systemFieldType: $systemFieldType, label: $label, subFields: $subFields)
  }
`;

const EDIT_INDUSTRY_FIELD = gql`
  mutation updateSubIndustryList($label: String!, $sub_industries: [String!]!) {
    updateSubIndustryList(label: $label, sub_industries: $sub_industries) {
      id
      label
      sub_industries
    }
  }
`;

const UpdateIndustryV2: React.FC<DisappearingDivProps> = ({
  blinds,
  close,
  id,
  keyName,
  setDeleteModalData,
  label,
  ...propsField
}) => {
  interface MyFormikProps {
    id: string;
    key: string;
    options: string[];
    allow_reps_to_edit: boolean;
    visible: boolean;
    sub_industries: string[];
    sub_industry: string;
  }

  const [step, setStep] = useState(!!id ? 3 : 1);

  const customFieldsSchema = Yup.object().shape({
    id: Yup.string(),
    key: Yup.string()
      .required("Field required!")
      .max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    allow_reps_to_edit: Yup.boolean(),
    visible: Yup.boolean(),
    options: Yup.array(Yup.string()),
    sub_industries: Yup.array(
      Yup.string().max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
    ),

    sub_industry: Yup.string().max(SYSTEM_FIELD_CHAR_LIMIT, `Must be ${SYSTEM_FIELD_CHAR_LIMIT} characters or less`),
  });

  const [addIndustry, { loading: loadingIndustry, error: errorIndustry }] = useMutation(ADD_INDUSTRY_FIELD, {
    onCompleted({ addIndustry }) {
      appToast("Added industry");
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addIndustry GraphQL Error: ${message}`,
      });
      console.log("Error in addIndustry: ", message);
    },
    refetchQueries: ["fetchIndustryOptions"],
  });

  const [editIndustry, { loading: loadingIndustryEdit, error: errorEditIndustry }] = useMutation(EDIT_INDUSTRY_FIELD, {
    onCompleted({ editIndustry }) {
      appToast("Edited sub-industries");
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `editIndustry GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchIndustryOptions"],
  });
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: id || "",
        key: label || "",
        visible: propsField.visible ?? false,
        allow_reps_to_edit: propsField.allow_reps_to_edit ?? false,
        options: propsField.options || ["new", "option"],
        sub_industries: propsField.sub_fields || [],
        sub_industry: "",
      }}
      validationSchema={customFieldsSchema}
      onSubmit={async ({ id, visible, key, allow_reps_to_edit, options, sub_industries }) => {}}
    >
      {({
        submitForm,
        isSubmitting,
        values,
        isValid,
        dirty,
        setFieldValue,
        touched,
        errors,
      }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={blinds} onClose={close}>
            <ModalContent>
              <TitleDiv>
                <PopupTitle>{id ? `Edit ` : `Add `}Industry</PopupTitle>
              </TitleDiv>

              {!!id ? (
                <>
                  {step === 3 && (
                    <TopAlignDiv>
                      <FlexDivRow>
                        <AppText style={{ marginBottom: "12px" }}>Primary Industry</AppText>
                        <DeleteText
                          onClick={() => {
                            !!setDeleteModalData &&
                              setDeleteModalData({ id: id, fieldType: "Custom Field", fieldName: keyName ?? "" });
                          }}
                        >
                          Delete
                        </DeleteText>
                      </FlexDivRow>
                      <FilterItemLabel>{values.key}</FilterItemLabel>
                      <AppText style={{ marginBottom: "8px", marginTop: "16px" }}>Add Sub-Industry</AppText>
                      <FlexDivSubIndustry>
                        <SubIndustryField name="sub_industry" />
                        <PhoenixAppButton
                          disabled={values.sub_industry.toString().trim() === "" || !isValid}
                          onClick={() => {
                            setFieldValue("sub_industries", [...values.sub_industries, values.sub_industry]);
                            setFieldValue("sub_industry", "");
                          }}
                          buttonType="secondary"
                          variant="brand-outline"
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          Add
                        </PhoenixAppButton>
                      </FlexDivSubIndustry>
                      <GridDivFilters>
                        {values.sub_industries?.map((industry: string, index: number) => (
                          <FilterSelectBox key={`${index}-${industry}`}>
                            <AppText fontSize={8} color="#3E65FF" uppercase={true}>
                              {industry}
                            </AppText>
                            <PhoenixIcon
                              svg={xIcon}
                              size={8}
                              variant="brand"
                              pointer={true}
                              onClick={() => {
                                const newVals = values.sub_industries.filter(
                                  (item: string, i: number) => !(item == industry && index === i),
                                );
                                setFieldValue("sub_industries", newVals);
                              }}
                            />
                          </FilterSelectBox>
                        ))}
                      </GridDivFilters>
                    </TopAlignDiv>
                  )}
                </>
              ) : (
                <>
                  {step === 1 && (
                    <ScrollingDiv>
                      <PopupInputLabel>
                        Industry Name<span style={{ color: "red" }}>*</span>
                      </PopupInputLabel>
                      <PhoenixInputField name="key" inputValueType="string" />
                    </ScrollingDiv>
                  )}
                  {step === 2 && (
                    <TopAlignDiv>
                      <AppText style={{ marginBottom: "12px" }}>Primary Industry</AppText>
                      <FieldInput>{values.key}</FieldInput>

                      <AppText style={{ marginBottom: "8px", marginTop: "16px" }}>Add Sub-Industry</AppText>
                      <FlexDivSubIndustry>
                        <PhoenixInputField name="sub_industry" />
                        <PhoenixAppButton
                          disabled={values.sub_industry.toString().trim() === "" || !isValid}
                          onClick={() => {
                            setFieldValue("sub_industries", [...values.sub_industries, values.sub_industry]);
                            setFieldValue("sub_industry", "");
                          }}
                          buttonType="secondary"
                          variant="brand-outline"
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          Add
                        </PhoenixAppButton>
                      </FlexDivSubIndustry>
                      <GridDivFilters>
                        {values.sub_industries?.map((industry: string, index: number) => (
                          <FilterSelectBox key={`${index}-${industry}`}>
                            <AppText fontSize={8} color="#3E65FF" uppercase={true}>
                              {industry}
                            </AppText>
                            <PhoenixIcon
                              svg={xIcon}
                              size={8}
                              variant="brand"
                              pointer={true}
                              onClick={() => {
                                const newVals = values.sub_industries.filter(
                                  (item: string, i: number) => !(item == industry && index === i),
                                );
                                setFieldValue("sub_industries", newVals);
                              }}
                            />
                          </FilterSelectBox>
                        ))}
                      </GridDivFilters>
                    </TopAlignDiv>
                  )}
                </>
              )}

              <SubmitDiv>
                <PhoenixAppButton buttonType="secondary" variant="danger-outline" onClick={close} uppercase={true}>
                  Cancel
                </PhoenixAppButton>
                <div>
                  {!!id ? (
                    <>
                      {isSubmitting ? (
                        <Loading />
                      ) : (
                        <PhoenixAppButton
                          buttonType="secondary"
                          variant="brand"
                          onClick={async () =>
                            await editIndustry({
                              variables: {
                                label: values.key,
                                sub_industries: values.sub_industries,
                              },
                            })
                          }
                          uppercase={true}
                          disabled={loadingIndustryEdit || !isValid}
                        >
                          Update
                        </PhoenixAppButton>
                      )}
                    </>
                  ) : (
                    <>
                      {step === 2 ? (
                        <PhoenixAppButton
                          buttonType="secondary"
                          variant="brand"
                          onClick={async () =>
                            await addIndustry({
                              variables: {
                                label: values.key,
                                systemFieldType: "Industry",
                                subFields: values.sub_industries,
                              },
                            })
                          }
                          uppercase={true}
                          disabled={loadingIndustryEdit || !isValid}
                        >
                          Create
                        </PhoenixAppButton>
                      ) : (
                        <PhoenixAppButton
                          buttonType="secondary"
                          variant="brand"
                          disabled={(step === 1 && values.key.toString().trim() === "") || !isValid}
                          onClick={() => setStep(step + 1)}
                          uppercase={true}
                        >
                          Continue
                        </PhoenixAppButton>
                      )}
                    </>
                  )}
                </div>
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

const ModalContent = styled.div`
  min-width: 456px;
`;

const FlexDivRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const FlexDivSubIndustry = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`;

const SubIndustryField = styled(InputField)`
  width: 330px;
  max-width: 330px;
  margin: 0px;
`;

const FilterSelectBox = styled.div`
  height: 24px;
  padding: 4px 8px;
  width: fit-content;
  min-width: 61px;
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: ${theme.PRIMARY100};
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: ${theme.PRIMARY500};
  text-transform: uppercase;
`;

const TopAlignDiv = styled.div`
  padding: 24px 32px;
  max-width: 536px;
  min-height: 300px;
  overflow: auto;
  max-height: 70vh;
`;

const GridDivFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterItemLabel = styled(AppText)`
  height: 24px;
  line-height: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${theme.PRIMARY500};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  text-transform: uppercase;
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.PRIMARY500};
`;

const SubmitDiv = styled.div`
  bottom: 0px;
  height: 72px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const FieldInput = styled.div`
  font-size: 12px;
  font-weight: 600;
  height: 16px;
  width: fit-content;
  border-radius: 4px;
  padding: 0px 8px 0px 8px;
  background-color: #3e65ff;
  color: #ffffff;
  border: 1px solid #3e65ff;
  cursor: default;
`;
const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div`
  padding: 40px;
  overflow: auto;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DeleteText = styled.p`
  padding: 4px;
  width: 43px;
  height: 16px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${theme.DANGER600};
  text-align: center;
  cursor: pointer;
`;

export { UpdateIndustryV2 };
