import { gql, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { IChannel, extractNumberWithCountry, getChannelLabel, onlyDigits } from "../../utils/format";
import { PHONE_REGEX } from "../../utils/regex";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppErrorText, AppText, DarkDiv, SkeletonBlock } from "../UI";
import { PhoenixAppButton, PhoenixInput, PhoenixMultiSelect } from "../UI/Phoenix";

import { AiOutlineLeft } from "react-icons/ai";

import { ModalContext } from "../../context";

import { AppSidebarCard } from "../UI";

import * as Sentry from "@sentry/react";

import { OptionItem } from "../../types";
import { PhoenixInputField, PhoenixMultiSelectField } from "../Field/Phoenix";

interface MyFormikProps {
  lead_id: string;
  category: string;
  value: string;
  title: string;
  type: string;
  channel: string;
  card_type: string;
  country_code: string;
  country: string;
  alternate_mapping_option?: number;
  alternate_mapping_option_label?: string;
}

interface ContactProps {
  setShowAddContact: (value: boolean) => void;
  width?: number;
}

const FETCH_ALL_LEADS = gql`
  query FetchLeadAllAssociatedGroup($fetchLeadAllAssociatedGroupId: String!) {
    fetchLeadAllAssociatedGroup(id: $fetchLeadAllAssociatedGroupId) {
      id
      first_name
      last_name
      full_name
      conference_number
      associate_parent_id
    }
  }
`;

const CHECK_LEAD_PHONE_NUMBER = gql`
  query checkLeadPhoneNumber($phone_number: String!, $country_code: String!) {
    checkLeadPhoneNumber(phone_number: $phone_number, country_code: $country_code)
  }
`;

const UPDATE_CONFERENCE_NUMBER = gql`
  mutation UpdateLeadConferenceNumber($lead_id: String!, $phone_number: String!, $country: String) {
    updateLeadConferenceNumber(lead_id: $lead_id, phone_number: $phone_number, country: $country) {
      # return lead property
      id
      first_name
      last_name
      conference_number
    }
  }
`;

const ADD_CONTACT_REQUEST = gql`
  mutation addContactRequest(
    $lead_id: String!
    $title: String!
    $mappingOrder: Int
    $channel: ContactType
    $type: String
    $value: String
    $countryCode: String
  ) {
    addContactRequest(
      lead_id: $lead_id
      title: $title
      mapping_order: $mappingOrder
      channel: $channel
      type: $type
      value: $value
      country_code: $countryCode
    ) {
      id
      channel
      type
      value
      title
      mapping_order
    }
  }
`;

const EDIT_CONTACT_REQUEST = gql`
  mutation editContactRequest(
    $lead_id: String!
    $contact_id: String
    $channel: ContactType!
    $primary_phone_number: Boolean!
    $primary_email: Boolean!
    $type: String!
    $country_code: String
    $value: String!
    $title: String!
    $mappingOrder: Int
  ) {
    editContactRequest(
      lead_id: $lead_id
      contact_id: $contact_id
      channel: $channel
      primary_phone_number: $primary_phone_number
      primary_email: $primary_email
      country_code: $country_code
      mapping_order: $mappingOrder
      type: $type
      value: $value
      title: $title
    ) {
      id
      channel
      primary_email
      primary_email_type
      primary_email_title
      primary_phone_number
      primary_phone_number_type
      primary_phone_number_title
      title
      alternate_contacts {
        id
        channel
        type
        value
        title
        mapping_order
      }
    }
  }
`;

const DELETE_CONTACT_REQUEST = gql`
  mutation deleteContactRequest($contact_id: String!) {
    deleteContactRequest(contact_id: $contact_id) {
      id
      channel
      type
      value
      title
    }
  }
`;

const FETCH_ALTERNATE_CONTACT_MAPPING_OPTIONS = gql`
  query FetchAvailableMappingOrder($leadId: String!, $channel: ContactType!, $currentMappingOrder: Int) {
    fetchAvailableMappingOrder(lead_id: $leadId, channel: $channel, current_mapping_order: $currentMappingOrder) {
      label
      value
    }
  }
`;

const VALIDATE_PHONE_NUMBER = gql`
  mutation validatePhoneNumber($phone_number: String!, $lead_id: String!) {
    validatePhoneNumber(phone_number: $phone_number, lead_id: $lead_id)
  }
`;

const AddOrEditContactModal = ({ setShowAddContact, width }: ContactProps) => {
  const formikRef = React.useRef<FormikProps<MyFormikProps>>(null);
  // primary lead id
  const { selectedContactV2, setSelectedContactV2 } = useContext(ModalContext);

  const [selectedLeadToAddContactTo, setSelectedLeadToAddContactTo] = useState(selectedContactV2?.lead_id || "");
  const { setShowAddOrEditContactModal } = useContext(ModalContext);

  const { data: allLeadsData, loading: loadingAllLeads, error: errorAllLeads, refetch: refetchAllLeads } = useQuery(
    FETCH_ALL_LEADS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        fetchLeadAllAssociatedGroupId: selectedContactV2?.lead_id || "",
      },
      onCompleted: (data) => {
        checkIfLeadHasConferenceNumber(formikRef?.current?.values?.lead_id || selectedContactV2?.lead_id || "", () => {
          formikRef?.current?.setFieldValue("channel", "Phone");
        });
      },
      onError: (error) => { },
    },
  );

  const BaseAlternateContactOptions: OptionItem[] = [
    {
      label: getChannelLabel(IChannel.Phone),
      value: IChannel.Phone,
    },
    { label: getChannelLabel(IChannel.Email), value: IChannel.Email },
  ];

  const ScreenSharingContactOption = {
    label: getChannelLabel(IChannel.Conference),
    value: IChannel.Conference,
  };

  const [channelType, setChannelType] = useState<OptionItem[]>(BaseAlternateContactOptions);

  const checkIfLeadHasConferenceNumber = (leadId: string, setChannelToPhone: any) => {
    const selectedLeadToAddContactTo = leadId;

    const currentlySelectedlead = allLeadsData?.fetchLeadAllAssociatedGroup?.find(
      (lead: any) => lead?.id === selectedLeadToAddContactTo,
    );

    const leadHasConferenceNumber = !!currentlySelectedlead?.conference_number;

    if (!leadHasConferenceNumber) {
      // include option to add screen sharing number
      setChannelType([...BaseAlternateContactOptions, ScreenSharingContactOption]);
    } else {
      // don't include option to add screen sharing number
      setChannelType(BaseAlternateContactOptions);
      // update the channel if it is currently set to screen sharing
      if (formikRef?.current?.values?.channel === "Conference") setChannelToPhone();
    }
  };

  const optionsType = [
    {
      label: "Mobile",
      value: "Mobile",
    },
    {
      label: "Work",
      value: "Work",
    },
    {
      label: "Home",
      value: "Home",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const optionsTypeEmail = [
    {
      label: "Work",
      value: "Work",
    },
    {
      label: "Home",
      value: "Home",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  const {
    data: alternateMappingOptions,
    loading: loadingAlternateMappingOptions,
    error: errorAlternateMappingOptions,
    refetch: refetchAlternateMappingOptions,
  } = useQuery(FETCH_ALTERNATE_CONTACT_MAPPING_OPTIONS, {
    variables: {
      leadId: formikRef?.current?.values?.lead_id || selectedContactV2?.lead_id || "",
      channel: selectedContactV2?.channel ? selectedContactV2?.channel : "Phone",
      currentMappingOrder: selectedContactV2?.alternate_phone_mapping_option || undefined,
    },
    // not supported by BE
    skip: selectedContactV2?.channel === "Conference",
    fetchPolicy: "network-only",

    onError({ message }) {
      console.log("Error in FETCH_ALTERNATE_CONTACT_MAPPING_OPTIONS: ", message);
    },
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const phone_number_without_chars = extractNumberWithCountry(phoneNumber);

  //set to 1 for US coutntry code (matches formik default)
  const [countryCode, setCountryCode] = useState(
    selectedContactV2?.country_code ? selectedContactV2?.country_code : "1",
  );

  const [oldPhoneValue, setOldPhoneValue] = useState("");
  const [phoneNumberVariant, setPhoneNumberVariant] = useState<"error" | "primary" | "success" | "warning" | undefined>(
    undefined,
  );

  const addContactSchema = Yup.object().shape({
    lead_id: Yup.string(),
    category: Yup.string(),
    channel: Yup.string().required("Field required!"),
    country: Yup.string().notRequired(),
    value: Yup.string()
      .required("Field required!")
      .when("channel", {
        is: "Phone",
        then: Yup.string()
          .required("Field is required")
          .test("max 15", "Phone number must be at most 15 digits", (value: any) => onlyDigits(value)?.length <= 15)
          .test("min 7", "Phone number must be at least 7 digits", (value: any) => onlyDigits(value).length >= 7)
          .test("PHONE_REGEX", "Phone number must be valid", (value: any) =>
            PHONE_REGEX.test(extractNumberWithCountry(value)),
          )
          .test("phone_number", "This number is already in use", async (value: any) => {
            const phone_number_without_chars = onlyDigits(value);
            if (
              !!phone_number_without_chars &&
              phone_number_without_chars.length === 10 &&
              phone_number_without_chars !== oldPhoneValue
            ) {
              if (phone_number_without_chars !== oldPhoneValue) {
                setOldPhoneValue(phone_number_without_chars);
              }
              const isPhoneValid = await validatePhoneNumber({
                variables: { phone_number: phone_number_without_chars || "", lead_id: "" },
              });
              return isPhoneValid.data.validatePhoneNumber;
            }
            return true;
          })
          .when("country_code", {
            is: (country_code) => country_code === "1",
            then: Yup.string()
              .required("Field is required")
              .test(
                "length 10",
                "US phone numbers must be 10 digits",
                (value: any) => onlyDigits(value)?.length === 10,
              ),
          }),
      })
      .when("channel", {
        is: "Email",
        then: Yup.string().required("Field is required").email(),
      })
      .when("channel", {
        is: "Conference",
        then: Yup.string()
          .required("Field is required")
          .test("max 15", "Phone number must be at most 15 digits", (value: any) => onlyDigits(value)?.length <= 15)
          .test("min 7", "Phone number must be at least 7 digits", (value: any) => onlyDigits(value)?.length >= 7)
          .test("PHONE_REGEX", "Phone number must be valid", (value: any) =>
            PHONE_REGEX.test(extractNumberWithCountry(value)),
          )
          .test("phone_number", "This number is already in use", async (value: any) => {
            const phone_number_without_chars = onlyDigits(value);
            if (
              !!phone_number_without_chars &&
              phone_number_without_chars.length === 10 &&
              phone_number_without_chars !== oldPhoneValue
            ) {
              if (phone_number_without_chars !== oldPhoneValue) {
                setOldPhoneValue(phone_number_without_chars);
              }
              const isPhoneValid = await validatePhoneNumber({
                variables: { phone_number: phone_number_without_chars || "", lead_id: "" },
              });
              return isPhoneValid.data.validatePhoneNumber;
            }
            return true;
          }),
      }),
    title: Yup.string().required("Field required!"),
    type: Yup.string().notRequired(),

    // only validate for alternate phone numbers
    alternate_mapping_option: Yup.number().when(["category", "channel"], {
      is: (category, channel) => category === "Alternate" && channel === "Phone",
      then: Yup.number().required("Field required!"),
    }),
  });

  const [editContactRequest, { loading: editContactRequestLoading, error: editContactRequestError }] = useMutation(
    EDIT_CONTACT_REQUEST,
    {
      async onCompleted({ editContactRequest }) {
        if (!editContactRequest) {
          appToast("Error requesting edit. Something went wrong.");
          return;
        }
        appToast("Successful edit!");

        setShowAddOrEditContactModal(false);
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `editContactRequest GraphQL Error: ${message}`,
        });
        console.log("Error in createNewLead: ", message);
      },
      refetchQueries: ["fetchLead", "FetchAssociateContact"],
    },
  );

  const [addContactRequest, { loading: addContactLoading, error: addContactError }] = useMutation(ADD_CONTACT_REQUEST, {
    async onCompleted({ addContactRequest }) {
      if (!addContactRequest) {
        appToast("Error requesting edit. Something went wrong.");
        return;
      }
      appToast("Contact added successfully!");

      setShowAddOrEditContactModal(false);
    },
    onError({ message }) {
      appToast(message);
      console.log("Error in createNewLead: ", message);
    },
    refetchQueries: ["fetchLead", "FetchAssociateContact"],
  });

  const [deleteContactRequest, { loading: deleteContactLoading, error: deleteContactError }] = useMutation(
    DELETE_CONTACT_REQUEST,
    {
      async onCompleted({ deleteContactRequest }) {
        if (!deleteContactRequest) {
          appToast("Error deleting. Something went wrong.");
          return;
        }
        appToast("Delete requested!");
        setShowAddOrEditContactModal(false);
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `deleteContactRequest GraphQL Error: ${message}`,
        });
        console.log("Error in createNewLead: ", message);
      },
      refetchQueries: ["fetchLead", "FetchAssociateContact"],
    },
  );

  const [
    updateConferenceNumber,
    { loading: updateConferenceNumberLoading, error: updateConferenceNumberError },
  ] = useMutation(UPDATE_CONFERENCE_NUMBER, {
    async onCompleted({ updateConferenceNumber }) {
      appToast("Updated Screen Sharing Phone Number");
      // if (!selfSource) setBlinds(false);
      setShowAddContact(false);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateConferenceNumber GraphQL Error: ${message}`,
      });
      console.log("Error in createNewLead: ", message);
    },
    refetchQueries: ["fetchLead", "FetchAssociateContact"],
  });

  // same query as the above but duplicated for toast and readability
  const [
    deleteConferenceNumber,
    { loading: deleteConferenceNumberLoading, error: deleteConferenceNumberError },
  ] = useMutation(UPDATE_CONFERENCE_NUMBER, {
    async onCompleted({ updateConferenceNumber }) {
      appToast("Deleted Screen Sharing Phone Number");
      setShowAddOrEditContactModal(false);
    },
    onError({ message }) {
      appToast("Error deleting Screen Sharing Phone Number");
      appToast(message);
      Sentry.captureEvent({
        message: `updateConferenceNumber GraphQL Error: ${message}`,
      });
      console.log("Error in createNewLead: ", message);
    },
    refetchQueries: ["fetchLead", "FetchAssociateContact"],
  });

  const [validatePhoneNumber, { loading: phoneLoading, data: phoneData, error: phoneError }] = useMutation(
    VALIDATE_PHONE_NUMBER,
  );

  const resolveCardTypeTitle = (category: string, type: string, channel: string) => {
    if (type === "Add") {
      if (channel === "Conference") return "Add Screen Sharing Phone Number";
      if (category === "Alternate") return "Add Alternate Contact";
      else return "Add Primary Contact";
    }

    if (type === "Edit") {
      if (channel === "Conference") return "Edit Screen Sharing Phone Number";
      if (channel === "Email" && category === "Primary") return "Edit Primary Email";
      if (channel === "Email" && category === "Alternate") return "Edit Alternate Email";
      if (channel === "Phone" && category === "Primary") return "Edit Primary Phone Number";
      if (channel === "Phone" && category === "Alternate") return "Edit Alternate Phone Number";
      if (category === "Primary") {
        return "Edit Primary Contact";
      } else {
        return "Edit Alternate Contact";
      }
    }
  };

  const resolveValueLabel = (channel: string, category: string): string => {
    if (channel === "Email" && category === "Primary") {
      return "Primary Email Address";
    }
    if (channel === "Email" && category !== "Primary") {
      return "Email Address";
    }
    if (channel === "Phone" && category === "Primary") {
      return "Primary Phone Number";
    }
    if (channel === "Phone" && category !== "Primary") {
      return "Phone Number";
    }
    if (channel.includes("Conference") && category === "Primary") {
      return "Screen Sharing Phone Number";
    }
    return "Value";
  };

  const {
    id = "",
    category = "",
    email,
    phone_number,
    local_phone_number,
    country_code,
    country,
    title,
    type,
    card_type,
    channel,
    lead_id,
  } = selectedContactV2;

  console.log("selectedContactV2", selectedContactV2);

  const loading = loadingAlternateMappingOptions;

  const error = errorAlternateMappingOptions;

  if (error) {
    return (
      <>
        <DarkDiv />
        <AppSidebarCard
          width={width}
          style={{
            height: "85vh",
            position: "relative",
            zIndex: 899,
          }}
        >
          <ModalContentContainer>
            <AddAssociatedContactContainer>
              <AppErrorText>
                Something went wrong. Please try again and contact support if the problem persists.
              </AppErrorText>
            </AddAssociatedContactContainer>
          </ModalContentContainer>
        </AppSidebarCard>
      </>
    );
  }

  return (
    <Formik
      enableReinitialize={true}
      validateOnBlur
      initialValues={{
        lead_id: selectedContactV2.lead_id || "",
        category: selectedContactV2.category || "Alternate",
        value:
          selectedContactV2.email || selectedContactV2.local_phone_number || selectedContactV2?.conference_number || "",
        title: selectedContactV2.title || "Contact",
        type: selectedContactV2.card_type === "Add" ? "Mobile" : type || "",
        channel: selectedContactV2.channel || "Phone",
        card_type: selectedContactV2.card_type || "Add",
        country_code: selectedContactV2.country_code || "1",
        country: selectedContactV2.country || "US",

        // only for alternate phone numbers
        alternate_mapping_option: selectedContactV2?.alternate_phone_mapping_option || undefined,
        alternate_mapping_option_label: selectedContactV2?.alternate_phone_mapping_option_label || undefined,
      }}
      validationSchema={addContactSchema}
      onSubmit={async (values) => {
        const alternateContactNumber = values.category === "Alternate" && values.channel === "Phone";

        if (values.channel === "Conference") {
          await updateConferenceNumber({
            variables: {
              lead_id: values.lead_id,
              phone_number: extractNumberWithCountry(values.value, values.country),
              country: values.country,
            },
          });
          return;
        }
        if (values.card_type === "Edit" && category === "Primary") {
          await editContactRequest({
            variables: {
              lead_id: values.lead_id,
              contact: id,
              channel: values.channel,
              primary_phone_number: category === "Primary" && channel === "Phone" ? true : false,
              primary_email: category === "Primary" && channel === "Email" ? true : false,
              type: values.type,
              value: values.channel === "Phone" ? extractNumberWithCountry(values.value, values.country) : values.value,
              country_code: countryCode,
              title: values.title,
            },
          });
          return;
          // for alternate contacts only primary has separate endpoint for editing above
        } else if (values.card_type === "Edit") {
          await editContactRequest({
            variables: {
              lead_id: values.lead_id,
              contact_id: id,
              channel: values.channel,
              primary_phone_number: category === "Primary" && channel === "Phone" ? true : false,
              primary_email: category === "Primary" && channel === "Email" ? true : false,
              type: values.type,
              value: values.channel === "Phone" ? extractNumberWithCountry(values.value, values.country) : values.value,
              country_code: countryCode,
              title: values.title,
              mappingOrder: values.alternate_mapping_option,
            },
          });
          return;
        }

        addContactRequest({
          variables: {
            lead_id: values.lead_id,
            channel: values.channel,
            type: values.type,
            value: values.channel === "Phone" ? extractNumberWithCountry(values.value, values.country) : values.value,
            country_code: country_code,
            title: values.title,
            mappingOrder: alternateContactNumber ? values.alternate_mapping_option : undefined,
          },
        });
      }}
    >
      {({
        submitForm,
        isSubmitting,
        values,
        errors,
        touched,

        setFieldValue,
        setTouched,
        handleBlur,
        isValid,
        dirty,
      }: FormikProps<MyFormikProps>) => {
        return (
          <>
            <DarkDiv />
            <AppSidebarCard
              title={resolveCardTypeTitle(values.category, values.card_type, values.channel)}
              width={width}
              style={{
                height: "85vh",
                position: "relative",
                zIndex: 899,
              }}
            >
              <ModalContentContainer>
                <AddAssociatedContactContainer>
                  {loading ? (
                    <SkeletonBlock height={"95%"} width={"95%"} borderRadius={6} />
                  ) : (
                    <>
                      <Top>
                        <div
                          style={{
                            width: "100%",
                            borderRadius: 0,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            paddingBottom: "16px",
                            borderBottom: `1px solid ${theme.NEUTRAL100}`,
                          }}
                          onClick={() => setShowAddContact(false)}
                        >
                          <AiOutlineLeft size={12} color={theme.PRIMARY500} />
                          <AppText fontSize={12} fontWeight={600} style={{ color: theme.PRIMARY500 }}>
                            Back
                          </AppText>
                        </div>
                        {category !== "Primary" && values.card_type !== "Edit" && (
                          <>
                            <TitleLabel>
                              Contact Type <span style={{ color: "red" }}>*</span>
                            </TitleLabel>
                            <PhoenixMultiSelect
                              isMulti={false}
                              isClearable={false}
                              key={values.channel}
                              name="channel"
                              options={channelType}
                              value={
                                [
                                  {
                                    label: getChannelLabel(values.channel as IChannel),
                                    value: values.channel,
                                  },
                                ] || []
                              }
                              onChange={(e: any) => {
                                setFieldValue("channel", e?.value);

                                if (e?.value === "Email" && values.type === "Mobile") {
                                  setFieldValue("type", "Work");
                                }
                              }}
                            />
                          </>
                        )}
                        {category !== "Primary" && (
                          <>
                            <TitleLabel disabled={values.card_type === "Edit"}>
                              Contact
                              {values.card_type !== "Edit" && <span style={{ color: "red" }}>*</span>}
                            </TitleLabel>

                            <PhoenixMultiSelect
                              isMulti={false}
                              isClearable={false}
                              key={selectedLeadToAddContactTo}
                              name="lead_id"
                              isDisabled={
                                loadingAllLeads ||
                                // if editing don't allow changing the lead
                                values.card_type === "Edit"
                              }
                              options={
                                allLeadsData?.fetchLeadAllAssociatedGroup?.map((lead: any) => ({
                                  label: `${lead?.full_name || "N/A"} (${lead?.associate_parent_id ? "Associated" : "Primary"
                                    })`,
                                  value: lead?.id,
                                })) || []
                              }
                              value={
                                [
                                  {
                                    label: `${allLeadsData?.fetchLeadAllAssociatedGroup?.find(
                                      (lead: any) => lead?.id === values.lead_id,
                                    )?.full_name || "N/A"
                                      } (${allLeadsData?.fetchLeadAllAssociatedGroup?.find(
                                        (lead: any) => lead?.id === values.lead_id,
                                      )?.associate_parent_id
                                        ? "Associated"
                                        : "Primary"
                                      })`,
                                    value: values.lead_id,
                                  },
                                ] || []
                              }
                              onChange={(e: any) => {
                                // set the new lead
                                setFieldValue("lead_id", e?.value);

                                // validate if current selections are still valid for the new selected lead

                                // 1. leads are only allowed to have one conference number (resets options if necessary)
                                checkIfLeadHasConferenceNumber(e?.value, () => {
                                  setFieldValue("channel", "Phone");
                                });

                                // 2. reset alternate phone mapping options to new selected lead
                                refetchAlternateMappingOptions({
                                  leadId: e?.value,
                                  channel: selectedContactV2?.channel ? selectedContactV2?.channel : "Phone",
                                  currentMappingOrder: selectedContactV2?.alternate_phone_mapping_option || undefined,
                                });

                                // 3. reset the selected option for the above alternate phone mapping options
                                setFieldValue("alternate_mapping_option", undefined);
                                setFieldValue("alternate_mapping_option_label", undefined);
                              }}
                            />
                          </>
                        )}

                        <TitleLabel>
                          {resolveValueLabel(values.channel, values.category)} <span style={{ color: "red" }}>*</span>
                        </TitleLabel>
                        {values.channel === "Phone" || values.channel.includes("Conference") ? (
                          <NumberExtensionDiv>
                            <ExtensionText>+{country_code}</ExtensionText>
                            <PhoenixInputField
                              name="value"
                              variant={errors.value ? "error" : "primary"}
                              contextText={touched.value && errors.value && errors.value}
                              onBlur={(e) => {
                                handleBlur(e);
                                setTouched({ ...touched, value: true });
                              }}
                            />
                          </NumberExtensionDiv>
                        ) : (
                          <div>
                            <PhoenixInputField
                              variant={errors.value ? "error" : "primary"}
                              contextText={touched.value && errors.value && errors.value}
                              name="value"
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            />
                          </div>
                        )}

                        {values.channel !== "Conference" && (
                          <div>
                            <TitleLabel>
                              Title <span style={{ color: "red" }}>*</span>
                            </TitleLabel>
                            <PhoenixInputField name="title" />
                          </div>
                        )}
                        {values.category === "Alternate" && values.channel === "Phone" && (
                          <>
                            <TitleLabel>
                              Phone Number Label <span style={{ color: "red" }}>*</span>
                            </TitleLabel>
                            <PhoenixMultiSelect
                              isMulti={false}
                              isClearable={false}
                              key={values.alternate_mapping_option}
                              name="alternate_mapping_option"
                              options={alternateMappingOptions?.fetchAvailableMappingOrder
                                ?.slice()
                                ?.map((option: OptionItem) => ({
                                  label: option?.label ?? "N/A",
                                  value: option?.value,
                                }))}
                              isDisabled={loadingAlternateMappingOptions}
                              value={[
                                {
                                  label: values.alternate_mapping_option_label,
                                  value: values.alternate_mapping_option,
                                },
                              ]}
                              onChange={(e: OptionItem) => {
                                setFieldValue("alternate_mapping_option", e?.value);
                                setFieldValue("alternate_mapping_option_label", e?.label);
                              }}
                            />
                          </>
                        )}

                        {values.channel !== "Conference" && (
                          <>
                            <TitleLabel>Type</TitleLabel>
                            <PhoenixMultiSelectField
                              isMulti={false}
                              isClearable={false}
                              name="type"
                              value={[{ label: values.type, value: values.type }] || []}
                              options={values.channel === "Email" ? optionsTypeEmail : optionsType}
                              onChange={(e: any) => {
                                setFieldValue("type", e?.value);
                              }}
                            />
                          </>
                        )}
                      </Top>
                      <SubmitDiv>
                        {/*the conference number is the only primary key that can be deleted and uses the same endpoint*/}
                        {values.channel === "Conference" && values.card_type === "Edit" ? (
                          <div style={{ width: "100px" }}>
                            <PhoenixAppButton
                              buttonType="secondary"
                              variant="danger-outline"
                              onClick={() =>
                                deleteConferenceNumber({
                                  variables: {
                                    lead_id: lead_id,
                                    phone_number: "",
                                    country: country,
                                  },
                                })
                              }
                              disabled={updateConferenceNumberLoading}
                            >
                              Delete
                            </PhoenixAppButton>
                          </div>
                        ) : (
                          <>
                            {values.card_type === "Edit" && values.category !== "Primary" ? (
                              <PhoenixAppButton
                                buttonType="secondary"
                                variant="danger-outline"
                                onClick={() =>
                                  deleteContactRequest({
                                    variables: {
                                      contact_id: id,
                                    },
                                  })
                                }
                                disabled={deleteContactLoading}
                              >
                                Delete
                              </PhoenixAppButton>
                            ) : (
                              <PhoenixAppButton
                                buttonType="secondary"
                                variant="danger-outline"
                                onClick={() => {
                                  setShowAddOrEditContactModal(false);
                                }}
                              >
                                Cancel
                              </PhoenixAppButton>
                            )}
                          </>
                        )}

                        <PhoenixAppButton
                          variant="brand"
                          buttonType="secondary"
                          onClick={submitForm}
                          disabled={editContactRequestLoading || addContactLoading || !isValid}
                        >
                          Save
                        </PhoenixAppButton>
                      </SubmitDiv>
                    </>
                  )}
                </AddAssociatedContactContainer>
              </ModalContentContainer>
            </AppSidebarCard>
          </>
        );
      }}
    </Formik>
  );
};

export { AddOrEditContactModal };

const ModalContentContainer = styled.div`
  padding: 16px 24px;
  height: 100%;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
`;

const Top = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 4px;
`;

const AddAssociatedContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  overflow: visible;
`;

const SubmitDiv = styled.div`
  height: 80px;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExtensionText = styled(AppText)`
  margin-top: 10px;
  margin-right: 10px;
  height: 20px;
  font-size: 13px;
`;

const NumberExtensionDiv = styled.div`
  display: flex;
`;

const TitleLabel = styled(AppText) <{ disabled?: boolean }>`
  margin-bottom: 8px;
  color: ${(props) => (props.disabled ? theme.NEUTRAL300 : theme.BLACK_COLOR)};
`;
