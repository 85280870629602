import { gql, useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { loggedInUser } from "../../../apollo/cache";
import { ModalContext } from "../../../context/ModalContext";
import { plus } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { AppErrorText, AppText, SkeletonBlock } from "../../UI";
import { FlexDiv } from "../../UI/FlexDiv";
import { FormRow, FormRowDiv } from "../../UI/OPSIQForms/FormRow";
import { PhoenixAppButton, PhoenixIcon } from "../../UI/Phoenix";
import { APP_ROLES } from "../../../utils/variables";

interface FormExpectedResponse {
  id: string;
  form_name?: string;
  session_token?: string;
  created_at?: string;
  updated_at?: string;
  last_updated_by?: {
    full_name?: string;
  };
  created_by?: {
    full_name?: string;
  };
}

export interface IFetchOPSIQForms {
  fetchOPSIQForms: FormExpectedResponse[];
  getInboundConciergeWidgetSettings?: {
    session_token: string;
  };
}

export const FETCH_ALL_FORMS = gql`
  query FetchOPSIQForms($opsiqFormId: String) {
    fetchOPSIQForms(opsiq_form_id: $opsiqFormId) {
      id
      form_name
      created_at
      session_token
      updated_at
      last_updated_by {
        full_name
      }
      created_by {
        full_name
      }
    }
  }
`;

const DELETE_FORM = gql`
  mutation DeleteOpsiqForm($opsiqFormId: String!) {
    deleteOpsiqForm(opsiq_form_id: $opsiqFormId)
  }
`;

const DUPLICATE_FORM = gql`
  mutation CloneOpsiqForm($opsiqFormId: String!) {
    cloneOpsiqForm(opsiq_form_id: $opsiqFormId) {
      id
    }
  }
`;

export const OPSIQFormsPage: React.FC = () => {
  const { setShowUpdateFormModal, setUpdateFormModalData, setShowDeleteFormModal } = useContext(ModalContext);

  const handleCreateNewForm = () => {
    setUpdateFormModalData({
      id: undefined,
      name: undefined,
    });
    setShowUpdateFormModal(true);
  };

  const { data: allFormsData, error: allFormsError, loading: allFormsLoading } = useQuery<IFetchOPSIQForms>(
    FETCH_ALL_FORMS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        opsiqFormId: undefined,
      },
      onCompleted: (data) => {},
      onError: (error) => {
        appToast("Error fetching forms");
        console.log("fetchOPSIQForms error", error);
      },
    },
  );

  // delete form
  const [deleteOpsiqForm, { loading: deleteOpsiqFormLoading, error: deleteOpsiqFormError }] = useMutation(DELETE_FORM, {
    onCompleted: () => {
      appToast("Deleted form.");
      setShowDeleteFormModal(false);
    },
    onError: (error) => {
      appToast("Error deleting form.");
      console.log("delteOPSIQForm", error);
    },
    refetchQueries: [{ query: FETCH_ALL_FORMS }],
  });

  // duplicate form
  const [cloneOpsiqForm, { loading: cloneOpsiqFormLoading, error: cloneOpsiqFormError }] = useMutation(DUPLICATE_FORM, {
    onCompleted: () => {
      appToast("Duplicated form.");
    },
    onError: () => {
      appToast("Something went wrong.");
    },
    refetchQueries: [{ query: FETCH_ALL_FORMS }],
  });

  const error = allFormsError || deleteOpsiqFormError || cloneOpsiqFormError;

  const loading = allFormsLoading || deleteOpsiqFormLoading || cloneOpsiqFormLoading;

  const hasNoForms = !allFormsData?.fetchOPSIQForms?.length;

  if (loggedInUser()?.role !== APP_ROLES.ADMIN) return null;

  return (
    <>
      <Main>
        <TopOptions>
          <AppText fontSize={22} fontWeight={500}>
            OPSIQ Forms
          </AppText>
          <RightOptions>
            <PhoenixAppButton buttonType="secondary" variant="brand" onClick={() => {}}>
              <FlexDiv justify="center" align="center" gap={16} onClick={handleCreateNewForm}>
                <PhoenixIcon svg={plus} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} pointer />
                <AppText fontSize={10} fontWeight={600} variant="white" uppercase>
                  Create New
                </AppText>
              </FlexDiv>
            </PhoenixAppButton>
          </RightOptions>
        </TopOptions>

        <Body>
          <FormsListContainer>
            {!!error ? (
              <AppErrorText>Something went wrong</AppErrorText>
            ) : !!loading ? (
              <LoadingComponent />
            ) : hasNoForms ? (
              <FlexDiv direction="column" align="center">
                <AppText
                  color={theme.text.neutral.secondary}
                  fontSize={12}
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  Let's get started.
                </AppText>
                <FlexDiv width={387}>
                  <AppText fontSize={16} fontWeight={400} style={{ marginBottom: "32px", textAlign: "center" }}>
                    Create a custom form to capture lead information on your website.
                  </AppText>
                </FlexDiv>
                <PhoenixAppButton buttonType="secondary" variant="brand" onClick={() => {}}>
                  <FlexDiv justify="center" align="center" gap={16} onClick={handleCreateNewForm}>
                    <PhoenixIcon
                      svg={plus}
                      size={16}
                      color={theme.WHITE_COLOR}
                      hoverColor={theme.WHITE_COLOR}
                      pointer
                    />
                    <AppText fontSize={10} fontWeight={600} variant="white" uppercase>
                      Create New
                    </AppText>
                  </FlexDiv>
                </PhoenixAppButton>
              </FlexDiv>
            ) : (
              <FormsList>
                <FormRowDiv
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  <AppText fontSize={12} fontWeight={600}>
                    Form Name
                  </AppText>
                  <AppText fontSize={12} fontWeight={600}>
                    Created By
                  </AppText>
                  <AppText fontSize={12} fontWeight={600}>
                    Last Updated
                  </AppText>
                  <FlexDiv />
                </FormRowDiv>
                {allFormsData?.fetchOPSIQForms?.map((i: FormExpectedResponse, index) => {
                  return (
                    <FormRow
                      key={i.id}
                      formId={i.id}
                      formName={i.form_name || "N/A "}
                      formSessionToken={i.session_token || "N/A"}
                      createdAt={i.created_at || "N/A"}
                      createdBy={i.created_by?.full_name || "N/A"}
                      lastUpdatedAt={i.updated_at || "N/A"}
                      lastUpdatedBy={i.last_updated_by?.full_name || "N/A"}
                      deleteOpsiqForm={() => {
                        deleteOpsiqForm({ variables: { opsiqFormId: i.id } });
                      }}
                      cloneOpsiqForm={() => {
                        cloneOpsiqForm({ variables: { opsiqFormId: i.id } });
                      }}
                    />
                  );
                })}
              </FormsList>
            )}
          </FormsListContainer>
        </Body>
      </Main>
    </>
  );
};

const LoadingComponent = () => {
  return (
    <FlexDiv align="center" direction="column" gap={8} width={"100%"}>
      {[...Array(6)]?.map((_, i) => (
        <SkeletonBlock
          key={i}
          opacity={1 - i * 0.16}
          delayNumber={i + 1}
          height={73}
          width="calc(100% - 32px)"
          borderRadius={8}
        />
      ))}
    </FlexDiv>
  );
};

const Main = styled.div`
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  margin-left: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: margin-left 0.22s ease-in-out;
  min-width: 1200px;
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 89px;
  padding: 0px 40px;
`;

const RightOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  height: fit-content;
  max-height: 80vh;
  min-height: 80vh;

  margin: 0px;
  padding: 48px 112px 0px 112px;

  background-color: ${theme.PRIMARY50};
  border-top: 1px solid ${theme.NEUTRAL200};
  overflow-y: auto;
`;

const FormsListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const FormsList = styled.div`
  height: 100%;
  max-height: 65vh;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 800px;
  width: 100%;
`;
