import * as Sentry from "@sentry/react";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { theme } from "../../utils/theme";
import { FETCH_SMS_ITEMS_POLL_INTERVAL, MAX_SMS_CHARACTERS } from "../../utils/variables";

import { CallContext, ModalContext } from "../../context";

import moment from "moment";
import { LeadCardContext } from "../../context";
import { alert_circle, info, point } from "../../images/NewDesign";
import { CommunicationStates } from "../../utils/format";
import { appToast } from "../../utils/toast";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { AppErrorText, AppText, FlexDiv, Loading, TemplateEditor } from "../UI";
import { PhoenixIcon, PhoenixMultiSelect, PhoenixTextArea } from "../UI/Phoenix";
import { PhoenixAppButton } from "../UI/Phoenix/PhoenixAppButton";
import { Modal } from "./Modal";

import { CUSTOM_TASK_ACK, LeadContactLabel } from "../Segments/DashboardSideBarSegments/LeadCardV2";
import ReactTooltip from "react-tooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SHOW_NEXT_ACTION } from "../../apollo/query";

interface SMSDialogModalV2Props {}

interface MyFormikProps {
  message: string;
}

const messageSchema = Yup.object().shape({
  message: Yup.string().required("A valid message is required"),
  messagePreview: Yup.string().notRequired(),
});
interface SMSItem {
  id: string;
  sent_at: string;
  text: string;
  isFromUser: boolean;
  lead_number_used: string;
  lead_activity: {
    lead: {
      first_name: string;
      last_name: string;
      full_name: string;
      id: string;
    };
    user: {
      first_name: string;
      last_name: string;
    };
  };
  twilio_failed_at: string;
}

interface FetchSMSItemsExpectedResponse {
  fetchSMSItems: {
    sms_messages: SMSItem[];
    applyCompliance: boolean;
  };
}
const FETCH_SMS_ITEMS = gql`
  query fetchSMSItems($lead_id: String!, $lead_phone_number: String) {
    fetchSMSItems(lead_id: $lead_id, lead_phone_number: $lead_phone_number) {
      sms_messages {
        id
        sent_at
        text
        isFromUser
        lead_number_used
        lead_activity {
          lead {
            first_name
            last_name
            full_name
            id
          }
          user {
            first_name
            last_name
          }
        }
        twilio_failed_at
      }
      applyCompliance
    }
  }
`;

const SEND_SMS = gql`
  mutation SendSMS(
    $lead_id: String!
    $text: String!
    $phone_number: String!
    $template_id: String
    $step_action_id: String
  ) {
    sendSMS(
      lead_id: $lead_id
      text: $text
      phone_number: $phone_number
      template_id: $template_id
      step_action_id: $step_action_id
    ) {
      channel
      created_at
      isFromLead
      id
      isFromRep
      isFromUser
    }
  }
`;

const FETCH_TEMPLATES = gql`
  query fetchTemplates($TemplateFilter: TemplateFilter!) {
    fetchTemplates(TemplateFilter: $TemplateFilter) {
      id
      name
      subject
      content
      type
    }
  }
`;

const FETCH_TEMPLATE_PREVIEW = gql`
  query FetchSMSTemplatePreview($template_id: String!, $lead_id: String!) {
    fetchSMSTemplatePreview(template_id: $template_id, lead_id: $lead_id) {
      text
      template_id
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      full_name
      business_name
      associate_parent_id
      local_primary_phone_number
      sms_sub_status
      sequence_step {
        actions {
          id
          task
        }
      }
    }
  }
`;

const FETCH_SMS_GLOBAL_TEMPLATE = gql`
  query fetchSMSComplianceTemplate {
    fetchSMSComplianceTemplate
  }
`;

const placeholderBodyForPreview = "<Your Message>";
const generateMessagePreview = (
  content: string,
  setMessagePreview: (content: string) => void,
  fetchSMSGlobalTemplateData: any,
) => {
  const { fetchSMSComplianceTemplate } = fetchSMSGlobalTemplateData || {};
  const { sms_text_order } = fetchSMSComplianceTemplate || {};

  const previewSections = sms_text_order?.map((section: string) => {
    switch (section) {
      case "OptInMessage":
        return fetchSMSComplianceTemplate?.sms_opt_in;
      case "MessageContent":
        return content || placeholderBodyForPreview;
      case "OptOutMessage":
        return fetchSMSComplianceTemplate?.sms_opt_out;
      default:
        return "";
    }
  });

  setMessagePreview(previewSections?.join("\n") || "");
};

const SMSDialogModalV2: React.FC<SMSDialogModalV2Props> = () => {
  const goToLeadDetail = (lead_id: string) => {
    window.open(`/lead-detail/${lead_id}`, "_blank");
  };
  const { data: orgData } = useQuery(SHOW_NEXT_ACTION);

  const showNextAction = orgData?.showNextAction;

  const formikRef = useRef() as any;
  const messageDialogContainerRef = useRef() as any;
  // SMSLead should be renamed to SMSLead once SMSDialogModal is deprecated
  const { SMSLead, showSMSChat, closeSMSChat } = useContext(ModalContext);
  const { suggestedActionData } = useContext(CallContext);
  const { nextActionOverride, resetNextDial } = useContext(CallContext);

  const { data: leadData, loading: leadLoading, error: leadError } = useQuery(FETCH_LEAD, {
    variables: {
      id: SMSLead.lead_id,
    },
    onCompleted({ fetchLead }) {
      console.log("fetchLead: ", fetchLead);
    },
    onError({ message }) {
      appToast(message);
      console.log("Error in fetching lead: ", message);
    },
    fetchPolicy: "network-only",
  });

  const [initialContent, setInitialContent] = useState<string | null | undefined>("");
  const [userHasAlteredTemplate, setUserHasAlteredTemplate] = useState<boolean>(false);

  const [showCompliancePreview, setShowCompliancePreview] = useState(false);

  const { ableToOverideNextDial, leadInNextDial, upcomingDials, setUpcomingDials } = useContext(LeadCardContext);

  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");

  const [messagePreview, setMessagePreview] = useState("");

  const [sendSMS, { error: sendSMSError }] = useMutation(SEND_SMS, {
    async onCompleted({ sentSMS }) {
      console.log("sentSMS: ", sentSMS);

      // if this was the compliance message hide the preview for the next message
      if (showCompliancePreview) {
        setShowCompliancePreview(false);
      }

      // update initial content to a falsey value to wipe content clean with useEffect in TemplateEditor
      initialContent === "" ? setInitialContent(null) : setInitialContent("");

      // reset the selected template id
      setSelectedTemplateId("");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `sendSMS GraphQL Error: ${message}`,
      });
      console.log("Error in sending SMS: ", message);
    },
    refetchQueries: ["fetchFilteredLeadsInQueueV2", "fetchLead", "fetchAssociateContact"],
  });

  const [
    fetchTemplatePreview,
    { data: templatePreviewData, loading: templatePreviewLoading, error: templatePreviewLoadingError },
  ] = useLazyQuery(FETCH_TEMPLATE_PREVIEW, {
    onCompleted({ fetchSMSTemplatePreview }) {
      console.log("fetchSMSTemplatePreview: ", fetchSMSTemplatePreview);
      if (!fetchSMSTemplatePreview?.text) {
        return;
      }
      // set the selected template id
      setSelectedTemplateId(fetchSMSTemplatePreview.template_id);

      // override the editor content
      setInitialContent(fetchSMSTemplatePreview.text);
    },
    onError({ message }) {
      appToast(message);
      console.log("Error in fetching template preview: ", message);
    },
    fetchPolicy: "network-only",
  });

  const {
    data: fetchSMSGlobalTemplateData,
    loading: fetchSMSGlobalTemplateLoading,
    error: fetchSMSGlobalTemplateError,
  } = useQuery(FETCH_SMS_GLOBAL_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      // update formik values with returned data
      console.log("Successfully fetched SMS global template: ", data);
      generateMessagePreview("", setMessagePreview, fetchSMSGlobalTemplateData);
    },
    onError: (error) => {
      console.error("Error fetching SMS global template: ", error);
    },
  });

  interface TemplateFilter {
    template_id?: string;
    template_name?: string;
    team_ids?: string[];
    user_ids?: string[];
    roles?: string[];
    type?: "SMS" | "EMAIL";
  }

  const { data: templates, loading: templatesLoading, error: templatesError } = useQuery(FETCH_TEMPLATES, {
    variables: {
      TemplateFilter: {
        type: "SMS",
      },
    } as TemplateFilter,
    onCompleted(fetchTemplates: any) {
      console.log("fetchTemplates: ", fetchTemplates);
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      appToast(message);
      console.log(`Error in ${name}: `, message);
    },
  });

  const {
    data: SMSData,
    loading: SMSItemsLoading,
    error: SMSItemsError,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery<FetchSMSItemsExpectedResponse>(FETCH_SMS_ITEMS, {
    fetchPolicy: "network-only",
    variables: {
      lead_id: SMSLead.lead_id,
      lead_phone_number: SMSLead.phone_number,
    },
    onCompleted(data) {
      ReactTooltip.rebuild();
      setShowCompliancePreview(data?.fetchSMSItems?.applyCompliance);
    },
    onError({ message, name }) {
      Sentry.captureEvent({
        message: `${name} GraphQL Error: ${message}`,
      });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    startPolling(FETCH_SMS_ITEMS_POLL_INTERVAL);
  }, []);

  useEffect(() => {
    if (messageDialogContainerRef.current) {
      messageDialogContainerRef.current.scrollTo({
        top: messageDialogContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [SMSData?.fetchSMSItems?.sms_messages?.length]);
  if (SMSItemsError) {
    return <div>Error loading sms messages</div>;
  }

  const fetchSMSItems = SMSData?.fetchSMSItems?.sms_messages || [];

  interface SortedSMSMessagesByDate {
    [key: string]: SMSItem[];
  }
  const sortedSMSMessagesByDate = fetchSMSItems
    ?.slice()
    // sort by oldest to newest
    ?.sort((a, b) => new Date(a.sent_at).getTime() - new Date(b.sent_at).getTime())
    ?.reduce((acc: any, item: any) => {
      const date = moment(item?.sent_at).format("MM/DD/YY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {} as SortedSMSMessagesByDate);

  const leadUnsubscribedFromSMS =
    leadData?.fetchLead?.sms_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    leadData?.fetchLead?.sms_sub_status === CommunicationStates.LeadUnsubscribed;

  return (
    <Formik
      validateOnMount
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={{
        message: "",
      }}
      validationSchema={messageSchema}
      onSubmit={async (values, { resetForm }) => {
        const suggestedActionCustomSequenceIntent =
          suggestedActionData?.expectedAction === "manualSms" && SMSLead?.lead_id === suggestedActionData?.leadData?.id;

        const currentLeadCustomSequenceIntent =
          SMSLead?.intent?.type === "SequenceAction" &&
          leadData?.fetchLead?.sequence_step?.actions?.[0]?.task === "manualSms";

        await sendSMS({
          variables: {
            lead_id: SMSLead.lead_id,
            phone_number: SMSLead.phone_number,
            text: values.message,
            template_id: selectedTemplateId ?? undefined,
            // look at lead  intent data if flag on and truthy
            step_action_id: currentLeadCustomSequenceIntent
              ? leadData?.fetchLead?.sequence_step?.actions?.[0]?.id
              : // look at suggested action data if flag on and falsy
              suggestedActionCustomSequenceIntent
              ? suggestedActionData?.leadData?.sequence_step?.actions?.[0]?.id
              : undefined,
          },
        });

        await refetch();
      }}
    >
      {({ submitForm, values, isSubmitting, setFieldValue, errors, validateForm }: FormikProps<MyFormikProps>) => {
        console.log("values: ", values);

        const currentSMSCharCount = !showCompliancePreview
          ? values?.message?.length
          : messagePreview?.includes(placeholderBodyForPreview)
          ? messagePreview?.length - placeholderBodyForPreview.length
          : messagePreview?.length;

        return (
          <Modal open={!!showSMSChat} onClose={closeSMSChat} bigCloseButton>
            <Main>
              <PhoenixStyledTooltip id="email-editor-tooltip" place="right" lineHeight={16} />
              <PhoenixStyledTooltip id="email-editor-failed-tooltip" place="top" lineHeight={16} />
              <TitleDiv>
                <AppText fontSize={18} fontWeight={700}>
                  Compose SMS
                </AppText>
                {leadUnsubscribedFromSMS && (
                  <AppText fontSize={12} fontWeight={400} color={theme.DANGER500}>
                    (Unsubscribed From SMS)
                  </AppText>
                )}
                <FlexDiv gap={8} align="center">
                  <LeadContactLabel
                    onClick={() => {
                      goToLeadDetail && goToLeadDetail(leadData?.fetchLead?.id);
                    }}
                    fontSizeOverride={14}
                    fontWeightOverride={500}
                    contact={leadData?.fetchLead}
                    contactType={leadData?.fetchLead?.associate_parent_id ? "associate" : "primary"}
                  />

                  <PhoenixAppButton
                    variant="brand"
                    buttonType="ghost-small"
                    onClick={() => {
                      goToLeadDetail && goToLeadDetail(leadData?.fetchLead?.id);
                    }}
                    style={{
                      marginBottom: "4px",
                    }}
                  >
                    Lead Detail
                  </PhoenixAppButton>
                </FlexDiv>
              </TitleDiv>
              <BodyDiv>
                <LeftContainer>
                  <MessageDialogContainer ref={messageDialogContainerRef}>
                    {SMSItemsLoading ? (
                      <Loading />
                    ) : SMSItemsError ? (
                      <div>Error loading sms messages</div>
                    ) : Object.entries(sortedSMSMessagesByDate)?.length === 0 ? (
                      <FullMessageWithTime>
                        <FlexDiv
                          justify="center"
                          style={{
                            width: "100%",
                          }}
                        >
                          <AppText
                            fontSize={12}
                            fontWeight={500}
                            color={theme.text.neutral.secondary}
                            style={{
                              // wrap text
                              whiteSpace: "pre-wrap",
                              padding: "0px 24px",
                            }}
                          >
                            {`This is the beginning of your SMS exchange with ${
                              leadData?.fetchLead?.full_name ?? leadData?.fetchLead?.business_name ?? "this lead"
                            }.`}
                          </AppText>
                        </FlexDiv>
                      </FullMessageWithTime>
                    ) : (
                      Object.entries(sortedSMSMessagesByDate)?.map(([date, messages]: [string, any]) => {
                        if (!Array.isArray(messages)) return null;
                        return <IndividualDateMessages date={date} messages={messages} />;
                      })
                    )}
                  </MessageDialogContainer>
                </LeftContainer>
                <RightContainer>
                  <FlexDiv
                    style={{
                      width: "100%",
                    }}
                    align="center"
                    gap={8}
                  >
                    <FlexDiv
                      gap={8}
                      direction="column"
                      style={{
                        width: "100%",
                      }}
                    >
                      <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.primary}>
                        Template
                      </AppText>
                      <div style={{ width: "100%" }}>
                        <PhoenixMultiSelect
                          marginBottom={false}
                          name="template-select"
                          isMulti={false}
                          options={templates?.fetchTemplates
                            ?.map((template: any) => ({
                              value: template.id,
                              label: template.name,
                            }))
                            ?.concat({
                              value: "",
                              label: "Select a template",
                            })}
                          onChange={(e: any) => {
                            const selectedTemplate = templates?.fetchTemplates.find(
                              (template: any) => template?.id === e?.value,
                            );

                            const userSelectedTheBlankOption = e?.value === "";

                            if (userSelectedTheBlankOption) {
                              // formik
                              values.message = "";

                              // editor
                              initialContent === "" ? setInitialContent(null) : setInitialContent("");

                              // template id state to send to BE on submit
                              setSelectedTemplateId("");
                            } else if (!!selectedTemplate) {
                              setFieldValue("message", "");
                              // we set the editor content to the preview content
                              fetchTemplatePreview({
                                variables: {
                                  template_id: selectedTemplate?.id,
                                  lead_id: SMSLead.lead_id,
                                },
                              });
                            }
                          }}
                          value={{
                            value: selectedTemplateId,
                            label: templates?.fetchTemplates.find((template: any) => template.id === selectedTemplateId)
                              ?.name,
                          }}
                        />
                      </div>
                    </FlexDiv>
                    <PhoenixIcon
                      svg={info}
                      variant="brand"
                      size={14}
                      data-for="email-editor-tooltip"
                      data-tip={`SMS templates are created by admins. Templates will automatically fill in the body of a message.`}
                      style={{
                        paddingTop: "24px",
                      }}
                    />
                  </FlexDiv>
                  <div>
                    {templatesLoading || templatePreviewLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "378px",
                        }}
                      >
                        <Loading />
                      </div>
                    ) : (
                      <FlexDiv
                        direction="column"
                        style={{
                          height: "100%",
                        }}
                      >
                        <TemplateEditor
                          titleText="Message"
                          requiredStar
                          displayNoContextText
                          noMargin={!showCompliancePreview}
                          initialContent={initialContent}
                          onContentChange={(content: any) => {
                            setFieldValue("message", content);
                            generateMessagePreview(content, setMessagePreview, fetchSMSGlobalTemplateData);
                            if (!content.length) setSelectedTemplateId("");
                          }}
                          userHasAlteredTemplate={userHasAlteredTemplate}
                          setUserHasAlteredTemplate={setUserHasAlteredTemplate}
                          smsEditor
                          emojiPickerTop={-400}
                          emojiPickerRight={50}
                          width={"100%"}
                          editorMinHeight={showCompliancePreview ? 200 : 300}
                          height={"100%"}
                          deliver
                        />
                        {showCompliancePreview ? (
                          <FlexDiv direction="column" gap={8}>
                            <FlexDiv align="center">
                              <PhoenixStyledTooltip id="email-editor-tooltip" place="right" lineHeight={16} />
                              <AppText fontWeight={500} fontSize={12}>
                                Preview with Compliance Text
                              </AppText>
                            </FlexDiv>
                            <PhoenixTextArea
                              value={messagePreview}
                              ableToEdit={false}
                              ableToResize={false}
                              // noBorder
                              hideError
                              height={"100%"}
                              minHeight={80}
                              maxHeight={120}
                              width={"100%"}
                              fontSize={13}
                              lineHeight={1.42}
                            />
                          </FlexDiv>
                        ) : null}
                        <FlexDiv justify="space-between" align="center">
                          <AppText color={theme.DANGER600}>
                            {currentSMSCharCount > MAX_SMS_CHARACTERS && (
                              <span>Message length cannot exceed ({MAX_SMS_CHARACTERS})</span>
                            )}
                          </AppText>
                          <AppText fontSize={10} fontWeight={500}>
                            <span
                              style={{
                                color: `${
                                  currentSMSCharCount <= MAX_SMS_CHARACTERS ? theme.PRIMARY600 : theme.DANGER600
                                }`,
                              }}
                            >
                              {currentSMSCharCount}
                            </span>{" "}
                            / {MAX_SMS_CHARACTERS}
                          </AppText>
                        </FlexDiv>
                        {selectedTemplateId && userHasAlteredTemplate && (
                          <FlexDiv>
                            <AppErrorText>The template you selected has been modified.</AppErrorText>
                          </FlexDiv>
                        )}
                      </FlexDiv>
                    )}
                  </div>
                </RightContainer>
              </BodyDiv>
              <ButtonContainer>
                <PhoenixAppButton
                  variant="danger-outline"
                  disabled={leadUnsubscribedFromSMS}
                  buttonType="secondary"
                  onClick={() => {
                    closeSMSChat();
                  }}
                >
                  Cancel
                </PhoenixAppButton>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <PhoenixAppButton
                    variant="brand"
                    buttonType="secondary"
                    onClick={submitForm}
                    disabled={
                      !values?.message?.length ||
                      values?.message?.length > MAX_SMS_CHARACTERS ||
                      leadUnsubscribedFromSMS ||
                      isSubmitting
                    }
                  >
                    Send
                  </PhoenixAppButton>
                </div>
              </ButtonContainer>
            </Main>
          </Modal>
        );
      }}
    </Formik>
  );
};
const IndividualDateMessages = ({ date, messages }: { date: string; messages: SMSItem[] }) => {
  return (
    <>
      <div key={date}>
        <FlexDiv justify="center" style={{ width: "100%", margin: "24px 0px" }}>
          <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.primary}>
            {moment(date).format("MM/DD/YY")}
          </AppText>
        </FlexDiv>
        <FlexDiv direction="column" gap={16}>
          {messages?.map((message: SMSItem) => {
            return <SMSComponent message={message} />;
          })}
        </FlexDiv>
      </div>
    </>
  );
};

const formatSMSDateString = (sent_at: string) => {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const sms_date = moment(sent_at);

  if (sms_date.isSame(today, "d")) {
    return `Today at ${sms_date.format("h:mm A")}`;
  } else if (sms_date.isSame(yesterday, "d")) {
    return `Yesterday at ${sms_date.format("h:mm A")}`;
  } else {
    return sms_date.format("MM/DD/YY [at] h:mm A");
  }
};

const formatLeadName = (lead_activity: any) => {
  if (!lead_activity) return "No Name";
  const { lead } = lead_activity;

  switch (true) {
    case !!lead?.full_name:
      return lead?.full_name;
    case !!lead?.first_name && !!lead?.last_name:
      return `${lead?.last_name}, ${lead?.first_name}`;
    case !!lead?.first_name:
      return lead?.first_name;
    case !!lead?.last_name:
      return lead?.last_name;
    default:
      return "No Name";
  }
};
const SMSComponent = ({ message }: { message: SMSItem }) => {
  const { isFromUser, id, text, sent_at, lead_number_used, lead_activity, twilio_failed_at } = message;

  const goToLeadDetail = (lead_id: string) => {
    window.open(`/lead-detail/${lead_id}`, "_blank");
  };
  return (
    <>
      {isFromUser ? (
        <MessageDiv key={id}>
          <MessageTextDiv>
            <FlexDiv justify="flex-end">
              <FlexDiv gap={8}>
                <AppText fontSize={12} fontWeight={600} color={theme.text.neutral.tertiary}>
                  {lead_activity?.user?.first_name} {lead_activity?.user?.last_name}
                </AppText>
                <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.secondary}>
                  {formatSMSDateString(sent_at)}
                </AppText>
              </FlexDiv>
            </FlexDiv>
            <MessageDialog isFromUser={isFromUser}>
              <MessageDialogBubble isFromUser={isFromUser}>
                <MessageDialogText isFromUser={isFromUser}>{text}</MessageDialogText>
                <PhoenixIcon
                  svg={point}
                  variant="brand"
                  size={16}
                  style={{
                    position: "absolute",
                    bottom: "-10px",
                    right: "0px",
                  }}
                />
              </MessageDialogBubble>
              {twilio_failed_at && (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PhoenixIcon
                    svg={alert_circle}
                    variant="danger"
                    size={16}
                    data-tip="Failed to send message. Please try again."
                    data-for="email-editor-failed-tooltip"
                  />
                </div>
              )}
            </MessageDialog>
          </MessageTextDiv>
        </MessageDiv>
      ) : (
        <MessageDiv key={id}>
          <MessageTextDiv>
            <FlexDiv justify="flex-start">
              <FlexDiv gap={8} align="center">
                <AppText
                  fontSize={12}
                  fontWeight={600}
                  color={theme.text.neutral.tertiary}
                  style={{
                    textDecoration: "underline",
                  }}
                  cursor="pointer"
                  showBorderOnClick={false}
                  onClick={() => {
                    // open lead detail in new tab
                    if (lead_activity?.lead?.id) goToLeadDetail(lead_activity?.lead?.id);
                  }}
                >
                  {formatLeadName(lead_activity)}
                </AppText>
                <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.secondary}>
                  {formatSMSDateString(sent_at)}
                </AppText>
                <AppText fontSize={10} fontWeight={600} color={theme.text.brand.primary}>
                  {`(${lead_number_used})`}
                </AppText>
              </FlexDiv>
            </FlexDiv>
            <MessageDialog isFromUser={isFromUser}>
              <MessageDialogBubble isFromUser={isFromUser}>
                <MessageDialogText isFromUser={isFromUser}>{text}</MessageDialogText>
                <PhoenixIcon
                  svg={point}
                  variant="white"
                  size={16}
                  fillIcon
                  style={{
                    position: "absolute",
                    bottom: "-10px",
                    left: "0px",
                    color: theme.WHITE_COLOR,
                    // flip the icon
                    transform: "scaleX(-1)",
                  }}
                />
              </MessageDialogBubble>
            </MessageDialog>
          </MessageTextDiv>
        </MessageDiv>
      )}
    </>
  );
};

export { SMSDialogModalV2 };

const FullMessageWithTime = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;

const Main = styled.div`
  width: 1000px;
  max-width: 90vw;
  overflow: auto;
  height: fit-content;
`;

const BodyDiv = styled.div`
  padding-top: 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  border-top: 1px solid ${theme.border.neutral.secondary};
  height: 546px;
  max-height: 65vh;
  width: 100%;
  overflow: auto;
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82px;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 16px;
  gap: 16px;
`;

const LeftContainer = styled.div`
  width: 100%;
  min-width: 400px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  width: 100%;
  min-width: 400px;

  padding-right: 40px;
`;

const MessageDialogContainer = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 0px 8px 0px;
  margin-bottom: 16px;
  background-color: ${theme.LIGHT_BLUE};
`;

const MessageTextDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0px 24px;
`;

const MessageDiv = styled.div`
  display: flex;
  align-items: flex-end;
`;

interface MessageProps {
  isFromUser: boolean;
}

const MessageDialog = styled.div<MessageProps>``;

const MessageDialogBubble = styled.div<MessageProps>`
  float: ${(props) => (props.isFromUser ? "right" : "left")};
  background-color: ${(props) => (props.isFromUser ? theme.PRIMARY500 : theme.WHITE_COLOR)};
  border-radius: 16px;
  padding: 5px 10px;
  max-width: 530px;
  position: relative;
`;

const MessageDialogText = styled(AppText)<MessageProps>`
  float: ${(props) => (props.isFromUser ? "right" : "left")};
  font-size: 12px;
  color: ${(props) => (props.isFromUser ? "white" : "black")};
  word-break: break-word;
  // respect line breaks
  white-space: pre-wrap;
`;
