import { Dispatch, useCallback, useEffect, useRef, useState } from "react";

/**
 * triggers a callback function onChange of the dependencies after the specified delay
 *  */
export const useDebounce = (effect: () => void, dependencies: any[], delay: number) => {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
};

/** This hook takes a useRef and callback as params and runs the callback when clicking outside of the ref DOM element
 * : Useful for popup menu's
 *
 * @param ref useRef hook reference - Checks the element we're clicking outside of
 * @param callback callback function that runs when clicking outside of the 'ref'
 */

export const useClickOutside = (ref: any, callback: () => void) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback]);
};

type UseTabFocusOptions = {
  buffer?: number;
  waitTime?: number;
  cleanup?: () => void;
};

/** Custom React hook that adds a visibility change event listener to the document.
 *
 * Invokes the provided callback when the tab comes into focus (becomes visible).
 *
 * Optionally accepts an args object for more customization:
 * - buffer: Optional buffer between callback executions.
 * - waitTime: Optional wait time. If the tab is still visible by the end of the wait time, the callback will be invoked.
 * - cleanup: Optional cleanup callback to be executed on component unmount.
 *
 * @param {() => void} callback - Callback function to be invoked on tab focus.
 * @param {UseTabFocusOptions} [args] - Optional args object for customization.
 * @returns {void}
 */
export const useTabFocus = (callback: () => void, args?: UseTabFocusOptions): void => {
  useEffect(() => {
    let bufferTimeout: NodeJS.Timeout | null = null;
    let waitTimeout: NodeJS.Timeout | null = null;

    let { buffer, waitTime, cleanup } = args || {};

    const handleBuffer = () => {
      if (!!buffer) {
        if (!bufferTimeout) {
          callback();
          bufferTimeout = setTimeout(() => {
            bufferTimeout = null;
          }, buffer);
        }
      } else {
        callback();
      }
    };

    const handleVisibilityChange = () => {
      if (!!waitTime) {
        if (!waitTimeout) {
          waitTimeout = setTimeout(() => {
            if (document.visibilityState === "visible") {
              handleBuffer();
            }
            waitTimeout = null;
          }, waitTime);
        }
      } else {
        if (document.visibilityState === "visible") {
          handleBuffer();
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (cleanup) {
        cleanup();
      }
      if (bufferTimeout) {
        clearTimeout(bufferTimeout);
      }
      if (waitTimeout) {
        clearTimeout(waitTimeout);
      }
    };
  }, []);
};
type PersitableStateOptions<V extends any> = {
  onGet?: (persistedValue: any) => V;
  onSet?: (persistedValue: V) => any;
};

export const useSessionStoragePersistedState = <V extends any>(
  key: string,
  defaultValue: V,
  options?: PersitableStateOptions<V>,
): [V, Dispatch<V>] => {
  const { onGet, onSet } = options || {};

  const [state, setState] = useState(() => {
    let persistedState = sessionStorage.getItem(key)!;
    let final = persistedState ? (onGet ? onGet(persistedState) : (persistedState as V)) : defaultValue;
    return final;
  });

  useEffect(() => {
    const value = onSet ? onSet(state) : state;
    sessionStorage.setItem(key, value);
  }, [key, state]);

  return [state, setState];
};

export const useLocalStoragePersistedState = <V extends any>(
  key: string,
  defaultValue: V,
  options?: PersitableStateOptions<V>,
): [V, Dispatch<V>] => {
  const { onGet, onSet } = options || {};

  const [state, setState] = useState(() => {
    let persistedState = localStorage.getItem(key)!;
    let final = persistedState ? (onGet ? onGet(persistedState) : (persistedState as V)) : defaultValue;
    return final;
  });

  useEffect(() => {
    const value = onSet ? onSet(state) : state;
    localStorage.setItem(key, value);
  }, [key, state]);

  return [state, setState];
};

/** This hook tracks if the component is rendering for the first time
 *
 * @returns {boolean} - Returns true if the component is rendering for the first time, otherwise false
 */
export const useFirstRender = () => {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
};

/**
 * A custom React hook to provide a debounced version of a state value.
 * @param {*} initialValue - The initial value of the state.
 * @param {number} delay - The delay (in milliseconds) after which the state should be updated with the latest value.
 * @returns {Array} An array containing the debounced state value and a function to update the state.
 */

export const useDebouncedState = <V extends any>(initialValue: V, delay: number): [V, Dispatch<V>] => {
  const [state, setState] = useState(initialValue);
  const [debouncedState, setDebouncedState] = useState(initialValue);

  useDebounce(() => setDebouncedState(state), [state], delay);

  return [debouncedState, setState];
};
