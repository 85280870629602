import styled from "styled-components";
import * as React from "react";
import { useContext, useState } from "react";
import { NewAppButton, AppText, Loading, AppErrorText, FlexDiv } from "../UI";
import { theme } from "../../utils/theme";
import { mobileSize } from "../../utils/breakpoints";
import { useMutation, gql } from "@apollo/client";
import { isLoggedInVar, loggedInUser } from "../../apollo/cache";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { InputField } from "../Field";
import * as Sentry from "@sentry/react";
import { Link } from "react-router-dom";
import { client, restAPI } from "../../apollo";
import { setSentry } from "../../services/sentry";
import { initLogRocket, setLogRocket } from "../../services/logrock";
import { appToast } from "../../utils/toast";
import { CallContext } from "../../context";
import { TOKEN_PERSIST_KEY, USER_PERSIST_KEY } from "../../utils/variables";
import { initMixpanel } from "../../services/mixpanel";

interface MyFormikProps {
  username: string;
  password: string;
}

const loginSchema = Yup.object().shape({
  username: Yup.string().required("Username or email is required"),
  password: Yup.string().required("Please specify a password").max(50, "* Too Long!"),
});

export const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
      first_name
      last_name
      session_token
      role
      status
      organization_id
      team_id
      team_name
      manager_id
      manager {
        id
        first_name
        last_name
      }
      phone_number
      timezone
      profile_image
      organization {
        id
        name
        onboardComplete
        international
        mrr_label
        enable_rep_to_connect_customers
        allow_external_transfer
      }
      joinMe_connected
      created_at
      timekit_id
      timekit_type
      panda_doc_access
      dashboard_2_access
      visible_all_leads_sm
      allow_external_transfer
    }
  }
`;

const Login: React.FC = () => {
  const { refreshToken } = useContext(CallContext);
  const [login, { loading, error }] = useMutation(LOGIN_USER, {
    fetchPolicy: "no-cache",
    onCompleted: async ({ login }) => {
      console.log("login: ", login);
      if (!login) {
        return;
      }
      localStorage.setItem(TOKEN_PERSIST_KEY, login.session_token as string);
      localStorage.setItem("timezone", login.timezone as string);
      localStorage.setItem(USER_PERSIST_KEY, JSON.stringify(login));
      localStorage.setItem("user_id", login.id as string);
      localStorage.setItem("profile_image", login.profile_image as string);
      localStorage.setItem("user_full_name", (login.first_name + " " + login.last_name) as string);
      localStorage.setItem("organization_id", login.organization_id as string);
      localStorage.setItem("international", login.organization.international as string);
      localStorage.setItem("userStatusLocal", JSON.stringify("IDLE"));
      restAPI.defaults.headers = { authorization: login.session_token };

      // loggedInUser(login);
      await refreshToken();
      isLoggedInVar(true);
      loggedInUser(login);
      setSentry();
      initMixpanel();
      // initLogRocket();
    },
    onError({ message }) {
      console.log("Error logging in: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `login GraphQL Error: ${message}`,
      });
    },
  });

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Login page"}>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={({ username, password }) => {
          login({ variables: { email: username, password: password } });
          // testLogin();
        }}
      >
        {({ submitForm, values }: FormikProps<MyFormikProps>) => {
          return (
            <Main>
              <LoginContainer
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <TempLogo>OPSIQ</TempLogo>

                <InputField
                  textAlign="center"
                  name="username"
                  title="Username"
                  style={{ marginBottom: "22px" }}
                  primary
                />
                <InputField textAlign="center" name="password" title="Password" type="password" primary />
                {loading ? (
                  <div style={{ marginTop: "42px", height: "66px" }}>
                    <Loading />
                  </div>
                ) : (
                  <>
                    <ForgotPasswordText to="/forgot-password/">Forgot password?</ForgotPasswordText>
                    <NewAppButton size={"lg"} variant={"confirm"} id="login-button" onClick={submitForm}>
                      Login
                    </NewAppButton>
                    {error && (
                      <FlexDiv
                        style={{ marginTop: 20, backgroundColor: theme.NEUTRAL200, padding: 10, borderRadius: 0 }}
                      >
                        <AppErrorText>{error.message}</AppErrorText>
                      </FlexDiv>
                    )}
                  </>
                )}
              </LoginContainer>
            </Main>
          );
        }}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const ForgotPasswordText = styled(Link)`
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  margin: 16px;
  color: ${theme.WHITE_COLOR};
  :hover {
    cursor: pointer;
  }
`;

const ForgotPasswordLabelText = styled(ForgotPasswordText)`
  margin-bottom: 50px;
  :hover {
    cursor: auto;
  }
`;

const TempLogo = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: white;
  font-size: 60px;
  font-weight: 500;
  margin-bottom: 45px;
  letter-spacing: 10px;
  @media ${mobileSize} {
    margin-bottom: 50px;
  }
`;

const LoginContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 426px;
  max-width: 90%;
  padding: 25px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background: linear-gradient(to bottom, ${theme.PRIMARY500}, #08056e);
`;

export { Login };
