import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { debounce } from "lodash";
import * as React from "react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import styled, { keyframes } from "styled-components";
import { loggedInUser } from "../../../apollo/cache";
import { IS_USER_REP, MRR_LABEL, SHOW_LEAD_SOURCE_TO_USER } from "../../../apollo/query";
import { LeadFilterContext, ModalContext } from "../../../context";
import { filter, refresh, search, share, xIcon } from "../../../images/NewDesign";
import { LEAD_DATA_TO_NEVER_DISPLAY, formatBusinessName, formatDateTime, getTitle } from "../../../utils/format";
import { useTabFocus } from "../../../utils/hooks";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { APP_ENABLEMENT_ROLES, APP_REP_ROLES, APP_ROLES, SEARCH_DEBOUNCE_INTERVAL } from "../../../utils/variables";
import { AppErrorText, AppText, Loading, SkeletonBlock } from "../../UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput, PhoenixMenuSelect } from "../../UI/Phoenix";
import {
  BulkMergeLeadsModal,
  BulkReassignLeadsModalComponent,
  BulkStatusChangeModal,
  MyLeadsManageColumnsModal,
  SystemLeadsFiltersComponent,
} from "../../modal";
import LeadsTable from "./LeadsTable";
import { useFlags } from "launchdarkly-react-client-sdk";

interface DisappearingDivProps {
  isOnboarding?: boolean;
  my_leads?: boolean;
  display_bulk_unassign: boolean;
}

export const FETCH_FILTER_LEADS = gql`
  query filterLeads(
    $search_text: String
    $lead_filter: LeadFilter
    $skip: Int!
    $take: Int!
    $order_by: String
    $ascending: Boolean!
    $my_leads: Boolean
    $lead_system_operators: LeadSystemOperators
  ) {
    filterLeads(
      search_text: $search_text
      lead_filter: $lead_filter
      skip: $skip
      take: $take
      order_by: $order_by
      ascending: $ascending
      my_leads: $my_leads
      lead_system_operators: $lead_system_operators
    )
  }
`;

export const EXPORT_FILTERED_LEAD = gql`
  mutation exportFilteredLead($lead_filter: LeadFilter, $search_text: String, $my_leads: Boolean) {
    exportFilteredLead(lead_filter: $lead_filter, search_text: $search_text, my_leads: $my_leads)
  }
`;

const FETCH_FILTERED_LEAD_COUNT = gql`
  query fetchFilteredLeadCount(
    $lead_filter: LeadFilter
    $my_leads: Boolean
    $search_text: String
    $lead_system_operators: LeadSystemOperators
  ) {
    fetchFilteredLeadCount(
      lead_filter: $lead_filter
      my_leads: $my_leads
      search_text: $search_text
      lead_system_operators: $lead_system_operators
    )
  }
`;

const nonSortableColumns: string[] = [];

const BulkReassignComponent: React.FC<DisappearingDivProps> = ({
  isOnboarding,
  my_leads = false,
  display_bulk_unassign = false,
}) => {
  const { showNewLeadSearch } = useFlags();
  const { data: mrrLabel } = useQuery(MRR_LABEL);

  const INACTIONABLE_STATUSES = [
    ...(!loggedInUser()?.organization?.enable_rep_to_connect_customers ? ["Customer"] : []),
    "Retired",
    "Resting",
  ];

  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);

  if (!showLeadSourceToUser?.showLeadSourceToUser) {
    LEAD_DATA_TO_NEVER_DISPLAY.push("Lead Source");
  }

  const [searchValue, setSearchValue] = useState("");

  const searchInput = useRef<HTMLInputElement>(null);

  const [selectedLeadIds, setSelectedLeadIds] = useState([] as string[]);
  const noLeadsSelected = selectedLeadIds?.length === 0;

  const [isAllSelected, setIsAllSelected] = useState(false);

  const [canExport, setCanExport] = useState<boolean>(true);

  const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);

  // Table query states
  const [take, setTake] = useState(25);
  const [skip, setSkip] = useState(0);

  const [localSearchValue, setLocalSearchValue] = useState(searchValue);

  const tableRef = useRef<HTMLDivElement>(null);

  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState<boolean>(false);

  const {
    showFiltersModal,
    setShowFiltersModal,
    reassignLeadModal,
    setReassignLeadModal,
    setSelectedLeadToReassign,
    setIsUnassignOn,
    myLeadsColumnModal,
    setMyLeadsColumnModal,
    showBulkMergeLeadsModal,
    showBulkStatusChangeModal,
    setShowBulkStatusChangeModal,
    setBulkStatusChangeModalData,
    setShowBulkMergeLeadsModal,
    setBulkMergeLeadsModalData,
  } = useContext(ModalContext);

  const {
    leadSystemFilter,
    currentFilterCount,
    leadSystemOperators,
    resetLeadSystemFilters,
    leadSort,
    setLeadSort,
    leadSortAscending,
    setLeadSortAscending,
    loadingUserSavedHeaders,
    userSavedHeaders,
    refetchUserSavedHeaders,
  } = useContext(LeadFilterContext);

  const LIMIT_ON_BULK_ASSOCIATION = 10;
  const LIMIT_ON_BULK_STATUS_CHANGE = 100;
  const leadsNumberSelected = selectedLeadIds.length;

  const { data: dataCount, loading: loadingCount, error: errorCount } = useQuery(FETCH_FILTERED_LEAD_COUNT, {
    variables: {
      search_text: searchValue,
      lead_filter: {
        reps: leadSystemFilter?.reps,
        status: leadSystemFilter?.status,
        industries: leadSystemFilter?.industries,
        custom_fields: leadSystemFilter?.custom_fields,
        sub_industry: leadSystemFilter?.sub_industry,
        lead_sources: leadSystemFilter?.lead_sources,
        lead_creation_source: leadSystemFilter?.lead_creation_source,
        states: leadSystemFilter?.states,
        timezones: leadSystemFilter?.timezones,
        history_filter: {
          amount:
            leadSystemFilter?.history_filter?.amount === 0 ? 0 : leadSystemFilter?.history_filter?.amount || undefined,
          amount_2:
            leadSystemFilter?.history_filter?.amount_2 === 0
              ? 0
              : leadSystemFilter?.history_filter?.amount_2 || undefined,
          operator: leadSystemFilter?.history_filter?.operator || undefined,
          metric: leadSystemFilter?.history_filter?.metric || undefined,
          lowerbound_date: leadSystemFilter?.history_filter?.lowerbound_date || undefined,
          upperbound_date: leadSystemFilter?.history_filter?.upperbound_date || undefined,
        },
        rep_activity_filter: {
          user_ids: leadSystemFilter?.rep_activity_filter?.user_ids?.map((item: any) => item?.value) || [],
          rep_lowerbound_date: leadSystemFilter?.rep_activity_filter?.rep_lowerbound_date || undefined,
          rep_upperbound_date: leadSystemFilter?.rep_activity_filter?.rep_upperbound_date || undefined,
        },
        call_result_filter: {
          call_result_type: leadSystemFilter?.call_result_filter?.call_result_type || undefined,
          call_result_option: leadSystemFilter?.call_result_filter?.call_result_option || undefined,
        },
        NextScheduledEventDays: Number(leadSystemFilter?.next_scheduled_event?.NextScheduledEventDays) || undefined,
        NextScheduledEventTypes: leadSystemFilter?.next_scheduled_event?.NextScheduledEventTypes || undefined,
        channels: leadSystemFilter?.channels ?? [],
      },
      my_leads,
      lead_system_operators: leadSystemOperators,
    },
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataTable, loading: loadingTable, error: errorTable, refetch: refetchTable } = useQuery(
    FETCH_FILTER_LEADS,
    {
      fetchPolicy: "no-cache",
      partialRefetch: false,
      nextFetchPolicy: "no-cache",
      variables: {
        search_text: searchValue,
        lead_filter: {
          reps: leadSystemFilter?.reps,
          status: leadSystemFilter?.status,
          industries: leadSystemFilter?.industries,
          custom_fields: leadSystemFilter?.custom_fields,
          sub_industry: leadSystemFilter?.sub_industry,
          lead_sources: leadSystemFilter?.lead_sources,
          lead_creation_source: leadSystemFilter?.lead_creation_source,
          states: leadSystemFilter?.states,
          timezones: leadSystemFilter?.timezones,
          history_filter: {
            amount:
              leadSystemFilter?.history_filter?.amount === 0
                ? 0
                : leadSystemFilter?.history_filter?.amount || undefined,
            amount_2:
              leadSystemFilter?.history_filter?.amount_2 === 0
                ? 0
                : leadSystemFilter?.history_filter?.amount_2 || undefined,
            operator: leadSystemFilter?.history_filter?.operator || undefined,
            metric: leadSystemFilter?.history_filter?.metric || undefined,
            lowerbound_date: leadSystemFilter?.history_filter?.lowerbound_date || undefined,
            upperbound_date: leadSystemFilter?.history_filter?.upperbound_date || undefined,
          },
          rep_activity_filter: {
            user_ids: leadSystemFilter?.rep_activity_filter?.user_ids?.map((item: any) => item?.value) || [],
            rep_lowerbound_date: leadSystemFilter?.rep_activity_filter?.rep_lowerbound_date || undefined,
            rep_upperbound_date: leadSystemFilter?.rep_activity_filter?.rep_upperbound_date || undefined,
          },
          call_result_filter: {
            call_result_type: leadSystemFilter?.call_result_filter?.call_result_type || undefined,
            call_result_option: leadSystemFilter?.call_result_filter?.call_result_option || undefined,
          },
          NextScheduledEventDays: Number(leadSystemFilter?.next_scheduled_event?.NextScheduledEventDays) || undefined,
          NextScheduledEventTypes: leadSystemFilter?.next_scheduled_event?.NextScheduledEventTypes || undefined,
          channels: leadSystemFilter?.channels ?? [],
        },
        lead_system_operators: leadSystemOperators,
        skip: skip * take,
        take: take,
        order_by: getTitle(leadSort, mrrLabel) ?? "Status",
        ascending: leadSortAscending,
        my_leads,
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
      onCompleted({ filterLeads }) {
        setIsInitialPageLoad(false);
      },
    },
  );

  const loadingTableAndHeaders = loadingTable || loadingUserSavedHeaders;

  const { data: clientData } = useQuery(IS_USER_REP);

  const [exportFilteredLead, { loading: loadingExport, error: errorExport }] = useMutation(EXPORT_FILTERED_LEAD, {
    variables: {
      lead_filter: {
        // ...leadSystemFilter,
        reps: leadSystemFilter?.reps,
        status: leadSystemFilter?.status,
        industries: leadSystemFilter?.industries,
        custom_fields: leadSystemFilter?.custom_fields,
        sub_industry: leadSystemFilter?.sub_industry,
        lead_sources: leadSystemFilter?.lead_sources,
        lead_creation_source: leadSystemFilter?.lead_creation_source,
        states: leadSystemFilter?.states,
        timezones: leadSystemFilter?.timezones,
        channels: leadSystemFilter?.channels ?? [],
        NextScheduledEventDays: Number(leadSystemFilter?.next_scheduled_event?.NextScheduledEventDays) || undefined,
        NextScheduledEventTypes: leadSystemFilter?.next_scheduled_event?.NextScheduledEventTypes || undefined,
        history_filter: {
          amount:
            leadSystemFilter?.history_filter?.amount === 0 ? 0 : leadSystemFilter?.history_filter?.amount || undefined,
          amount_2:
            leadSystemFilter?.history_filter?.amount_2 === 0
              ? 0
              : leadSystemFilter?.history_filter?.amount_2 || undefined,
          operator: leadSystemFilter?.history_filter?.operator || undefined,
          metric: leadSystemFilter?.history_filter?.metric || undefined,
          lowerbound_date: leadSystemFilter?.history_filter?.lowerbound_date || undefined,
          upperbound_date: leadSystemFilter?.history_filter?.upperbound_date || undefined,
        },
        rep_activity_filter: {
          user_ids: leadSystemFilter?.rep_activity_filter?.user_ids?.map((item: any) => item?.value) || [],
          rep_lowerbound_date: leadSystemFilter?.rep_activity_filter?.rep_lowerbound_date || undefined,
          rep_upperbound_date: leadSystemFilter?.rep_activity_filter?.rep_upperbound_date || undefined,
        },
        call_result_filter: {
          call_result_type: leadSystemFilter?.call_result_filter?.call_result_type || undefined,
          call_result_option: leadSystemFilter?.call_result_filter?.call_result_option || undefined,
        },
      },
      search_text: searchValue,
    },
    async onCompleted({ exportFilteredLead }) {
      console.log("exportFilteredLead: ", exportFilteredLead);
      if (!exportFilteredLead) {
        appToast("Error exporting leads");
        return;
      }
      appToast("Success! Leads been have emailed.");
    },
    onError({ message }) {
      appToast(`Error exporting: ${message}`);
      Sentry.captureEvent({
        message: `exportFilteredLead GraphQL Error: ${message}`,
      });
      console.log("Error in exportFilteredLead: ", message);
    },
  });

  const isRep = !!loggedInUser().role && APP_REP_ROLES.includes(loggedInUser().role as APP_ROLES);

  useTabFocus(
    () => {
      refetchTable();
    },
    { buffer: 10000, waitTime: 1500 },
  );

  useEffect(() => {
    setSelectedLeadIds([]);
  }, [leadSystemFilter, leadSort, leadSortAscending]);

  useEffect(() => {
    if (loadingTableAndHeaders) return;

    if (!!dataTable && !!dataTable.filterLeads && !!dataTable.filterLeads.length) {
      const ids = dataTable?.filterLeads?.map((item: any) => item?.id);
      const result = ids.every((id: string) => selectedLeadIds.includes(id));
      setIsAllSelected(result);
    } else {
      setIsAllSelected(false);
    }
  }, [dataTable, loadingTableAndHeaders]);

  useEffect(() => {
    if (!!dataCount && !!dataCount.fetchFilteredLeadCount && skip * take > Number(dataCount.fetchFilteredLeadCount)) {
      setSkip(Math.floor(Number(dataCount.fetchFilteredLeadCount) / take));
    }
  }, [take]);

  useEffect(() => {
    setSkip(0);
  }, [leadSystemFilter]);

  useEffect(() => {
    let delay: NodeJS.Timeout = setTimeout(() => {}, 0);
    if (!canExport) {
      delay = setTimeout(() => {
        setCanExport(true);
      }, 15000);
    }
    return () => clearTimeout(delay);
  }, [canExport]);

  const handleAllSelected = () => {
    let ids =
      loggedInUser().role === "AE" || loggedInUser().role === "SDR"
        ? dataTable.filterLeads.filter(
            (item: any) =>
              !INACTIONABLE_STATUSES.includes(item?.hidden_status) &&
              !(["Owned"].includes(item?.hidden_status) && item?.hidden_rep_id !== loggedInUser().id),
          )
        : dataTable.filterLeads;
    console.log(ids);
    ids = ids?.map((item: any) => item?.id);

    if (isAllSelected === false) {
      setSelectedLeadIds([...new Set([...selectedLeadIds, ...ids])]);
      setIsAllSelected(true);
    }
    if (isAllSelected === true) {
      setSelectedLeadIds(selectedLeadIds.filter((item: string) => !ids.includes(item)));
      setIsAllSelected(false);
    }
  };

  function cleanPhoneNumberInString(input: string) {
    const phoneNumberRegex = /^\+?[0-9()\s.-]+$/;

    // Check if the string is just a phone number
    if (phoneNumberRegex.test(input.trim())) {
      // If it's a phone number, remove spaces and symbols
      return input.replace(/[^\d]/g, "");
    }

    // If it's not just a phone number, return the original string
    return input;
  }

  //debounced search rather than query as this involves less breaking changes
  const debouncedChange = useMemo(
    () =>
      debounce((e) => {
        const { value } = e.target;
        setSearchValue(cleanPhoneNumberInString(value));
      }, SEARCH_DEBOUNCE_INTERVAL),
    [searchValue],
  );

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  }, []);

  const getHeaderLabel = (header: string) => {
    switch (header) {
      case "MRR":
        return mrrLabel?.getMrrLabel;
      default:
        return header;
    }
  };

  const generateBulkActionsOptions = () => {
    return [
      // Reassign option
      !my_leads && {
        value: "reassign",
        label: clientData?.isUserRep ? `Claim ` : `Reassign `,
        onOptionClick: () => {
          setSelectedLeadToReassign({
            lead_id: "",
            source_user_id: "",
            lead_id_list: selectedLeadIds,
          });
          setIsUnassignOn("Reassign");
          setReassignLeadModal(!reassignLeadModal);
        },
        disabled: noLeadsSelected,
      },
      // Unassign option
      display_bulk_unassign &&
        (my_leads || !isRep) && {
          value: "unassign",
          label: "Unassign ",
          onOptionClick: () => {
            setSelectedLeadToReassign({
              lead_id: "",
              source_user_id: "",
              lead_id_list: selectedLeadIds,
            });
            setIsUnassignOn("Unassign");
            setReassignLeadModal(!reassignLeadModal);
          },
          disabled: noLeadsSelected,
        },

      // Favorite option (only for user reps and when my_leads is true)
      clientData?.isUserRep &&
        my_leads && {
          value: "favorite",
          label: "Favorite ",
          onOptionClick: () => {
            setShowBulkActionsMenu(false);
            setShowFiltersModal(false);
            setSelectedLeadToReassign({
              lead_id: "",
              source_user_id: "",
              lead_id_list: selectedLeadIds,
            });
            setIsUnassignOn("Favorite");
            setReassignLeadModal(!reassignLeadModal);
          },
          disabled: noLeadsSelected,
        },
      // Add to Custom Queue option (only for user reps and when my_leads is true)
      clientData?.isUserRep &&
        my_leads && {
          value: "customQueue",
          label: "Add to Custom Queue",
          onOptionClick: () => {
            setSelectedLeadToReassign({
              lead_id: "",
              source_user_id: "",
              lead_id_list: selectedLeadIds,
            });
            setShowBulkActionsMenu(false);
            setShowFiltersModal(false);
            setIsUnassignOn("CustomQueue");
            setReassignLeadModal(!reassignLeadModal);
          },
          disabled: noLeadsSelected,
        },

      // status tab section (enablement only)
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "status",
        label: `Status (Max ${LIMIT_ON_BULK_STATUS_CHANGE})`,
        tabOption: true,
        onOptionClick: () => {},
      },

      // change all to Resting
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "resting",
        label: "Resting",
        onOptionClick: () => {
          console.log("resting clicked");
          setBulkStatusChangeModalData({
            desired_status: "Resting",
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // change all to Retired
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "retired",
        label: "Retired",
        onOptionClick: () => {
          console.log("retired clicked");
          setBulkStatusChangeModalData({
            desired_status: "Retired",
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // change all to Long Resting

      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "longResting",
        label: "Long Resting",
        onOptionClick: () => {
          console.log("longResting clicked");
          setBulkStatusChangeModalData({
            desired_status: "LongResting",
            selected_lead_ids: selectedLeadIds,
          });

          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // change all to Customer

      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "customer",
        label: "Customer",
        onOptionClick: () => {
          console.log("customer clicked");
          setBulkStatusChangeModalData({
            desired_status: "Customer",
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(false);
          setShowBulkActionsMenu(false);
          setShowBulkStatusChangeModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_STATUS_CHANGE,
      },

      // Associate dummy tab section (enablement only)
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "associate",
        label: `Associate (Max ${LIMIT_ON_BULK_ASSOCIATION})`,
        tabOption: true,
        onOptionClick: () => {},
      },

      // designate a primary rep
      APP_ENABLEMENT_ROLES.includes(loggedInUser().role as APP_ROLES) && {
        value: "primaryContact",
        label: "Designate a Primary Contact",
        onOptionClick: () => {
          console.log("primaryRep clicked");
          setShowBulkActionsMenu(false);
          setShowFiltersModal(false);
          setBulkMergeLeadsModalData({
            selected_lead_ids: selectedLeadIds,
          });
          setShowBulkMergeLeadsModal(true);
        },
        disabled: noLeadsSelected || leadsNumberSelected > LIMIT_ON_BULK_ASSOCIATION,
      },
    ].filter(Boolean);
  };

  const loading = loadingCount || loadingTable || loadingExport || loadingUserSavedHeaders;
  const error = errorCount || errorTable || errorExport;

  const TableLoadingState = () => {
    return (
      <TableContainer
        style={{
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <GridTable
          style={{
            width: "100%",
          }}
        >
          <thead>
            <GridTR style={{ position: "sticky", top: "0px", zIndex: 3, overflowX: "hidden" }}>
              {userSavedHeaders?.map((item: string, index) => (
                <GridTH
                  key={item}
                  style={{
                    padding: "4px",
                  }}
                >
                  <SkeletonBlock width={200} height={"95%"} borderRadius={6} delayNumber={index} />
                </GridTH>
              ))}
            </GridTR>
          </thead>
        </GridTable>
        {(new Array(userSavedHeaders) || [null, null, null])?.map((_, index) => {
          return (new Array(userSavedHeaders?.length - 1) || [null, null, null])?.fill(0)?.map((_, rowIndex) => (
            <div
              style={{
                marginTop: "4px",
                marginBottom: "4px",
              }}
            >
              <SkeletonBlock width={"100vw"} height={"40px"} borderRadius={6} delayNumber={rowIndex} />
            </div>
          ));
        })}
      </TableContainer>
    );
  };

  if (error) {
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <AppErrorText>
          Unable to load table. Please try refreshing or clearning filters and contact support.
        </AppErrorText>
        <ResetFilterError onClick={resetLeadSystemFilters}>
          <AppText color={theme.PRIMARY500} fontWeight={500}>
            Reset System Filters
          </AppText>
          <PhoenixIcon svg={refresh} size={14} variant="brand" pointer />
        </ResetFilterError>
      </div>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occued in System View"}>
      {reassignLeadModal && (
        <BulkReassignLeadsModalComponent
          claim={clientData?.isUserRep && !my_leads}
          resetSelectedLeads={() => setSelectedLeadIds([])}
        />
      )}
      {!showNewLeadSearch && showFiltersModal && (
        <DarkDiv>
          <SlideInDiv>
            <SystemLeadsFiltersComponent />
          </SlideInDiv>
        </DarkDiv>
      )}
      {showBulkMergeLeadsModal && <BulkMergeLeadsModal resetSelectedLeads={() => setSelectedLeadIds([])} />}
      {showBulkStatusChangeModal && <BulkStatusChangeModal />}
      <SpaceBetweenDiv>
        <SpaceTogetherDiv>
          <>
            <ReactTooltip
              multiline
              place="top"
              effect="solid"
              backgroundColor={theme.PRIMARY800}
              getContent={(dataTip) => (
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "10px",
                    lineHeight: "14px",
                  }}
                >
                  {dataTip}
                </span>
              )}
            />
            {loadingCount ? (
              <SkeletonBlock width={75} height={"95%"} borderRadius={6} />
            ) : errorCount ? (
              <AppErrorText>Error loading count.</AppErrorText>
            ) : (
              <AppText>{dataCount?.fetchFilteredLeadCount?.toLocaleString()} Leads</AppText>
            )}
            {loggedInUser().role === "ADMIN" && (
              <div
                style={{ margin: "0px 0px 3px 3px" }}
                data-tip={
                  loadingCount
                    ? "loading count..."
                    : dataCount?.fetchFilteredLeadCount > 75000
                    ? "Cannot export more than 75,000 at a time. Use filters to refine your results."
                    : canExport
                    ? "Export these leads (75,000 lead limit)"
                    : "Please wait 15 seconds before exporting again."
                }
                onClick={async () => {
                  if (dataCount?.fetchFilteredLeadCount <= 75000 && canExport) {
                    await exportFilteredLead();
                    canExport && setCanExport(false);
                  }
                }}
              >
                {loadingExport || loadingCount ? (
                  <SkeletonBlock width={"100%"} height={"100%"} borderRadius={6} />
                ) : (
                  <PhoenixIcon
                    svg={share}
                    size={14}
                    variant="neutral"
                    pointer={dataCount?.fetchFilteredLeadCount <= 75000 && canExport}
                  />
                )}
              </div>
            )}
          </>
          <div />
          <AppText>{leadsNumberSelected.toLocaleString()} Selected</AppText>

          <PhoenixAppButton
            buttonType="ghost-large"
            variant="brand"
            onClick={() => {
              setMyLeadsColumnModal(true);
              // changes to columns should show full loading state to prevent UI issues of seeing old data
              setIsInitialPageLoad(true);
            }}
          >
            Manage Columns
          </PhoenixAppButton>
          {loadingTableAndHeaders && !isInitialPageLoad && (
            <div
              style={{
                transform: "scale(0.5)",
                height: 15,
              }}
            >
              <Loading />
            </div>
          )}
          {myLeadsColumnModal && (
            <MyLeadsManageColumnsModal
              open={myLeadsColumnModal}
              onClose={() => setMyLeadsColumnModal(false)}
              refetchTable={refetchTable}
              setIsInitialPageLoad={setIsInitialPageLoad}
            />
          )}
        </SpaceTogetherDiv>
        <SpaceTogetherDiv>
          <FilterButton onClick={() => setShowFiltersModal(!showFiltersModal)}>
            <PhoenixIcon
              svg={filter}
              size={16}
              variant="neutral"
              pointer={true}
              color={theme.WHITE_COLOR}
              hoverColor={theme.WHITE_COLOR}
            />
            {currentFilterCount > 0 && <RedDot>{currentFilterCount}</RedDot>}
          </FilterButton>
          {!loggedInUser().visible_all_leads_sm && (
            <div
              style={{
                position: "relative",
              }}
            >
              <PhoenixMenuSelect
                title="Bulk Actions"
                width={208}
                containerZIndex={999}
                loading={loadingTableAndHeaders}
                error={!!errorTable}
                containerTop={40}
                onClick={() => setShowBulkActionsMenu(true)}
                setShow={setShowBulkActionsMenu}
                show={showBulkActionsMenu}
                containerMaxHeight={"max-content"}
                containerMinHeight={"max-content"}
                options={generateBulkActionsOptions()}
                containerWidth={208}
              />
            </div>
          )}
          <SearchContainer>
            <PhoenixInput
              value={localSearchValue}
              placeholder="Search Leads"
              displayNoContextText
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.persist();
                debouncedChange(e);
                setLocalSearchValue(e.target.value);
              }}
              insiderightElementOverride={
                <PhoenixIcon
                  svg={searchValue.length ? xIcon : search}
                  size={13}
                  pointer
                  onClick={() => {
                    if (searchValue.length) setSearchValue("");
                    if (localSearchValue.length) setLocalSearchValue("");
                    if (searchInput && searchInput.current) searchInput.current.focus();
                  }}
                />
              }
            />
          </SearchContainer>
        </SpaceTogetherDiv>
      </SpaceBetweenDiv>

      {/* <TableLoadingState /> */}
      {(loadingTable && isInitialPageLoad) || loadingUserSavedHeaders ? (
        <TableLoadingState />
      ) : errorTable ? (
        <AppErrorText>Error loading table</AppErrorText>
      ) : (
        <FlexDiv ref={tableRef} style={{ width: "100%", height: "695px", overflowX: "auto" }}>
          <LeadsTable
            userSavedHeaders={userSavedHeaders}
            items={dataTable?.filterLeads}
            isAllSelected={isAllSelected}
            loadingTableAndHeaders={loadingTableAndHeaders}
            handleAllSelected={handleAllSelected}
            leadSort={leadSort}
            leadSortAscending={leadSortAscending}
            setLeadSort={setLeadSort}
            setLeadSortAscending={setLeadSortAscending}
            nonSortableColumns={nonSortableColumns}
            getHeaderLabel={getHeaderLabel}
            getTitle={getTitle}
            mrrLabel={mrrLabel}
            selectedLeadIds={selectedLeadIds}
            setSelectedLeadIds={setSelectedLeadIds}
            inactionableStatuses={INACTIONABLE_STATUSES}
          />
        </FlexDiv>
      )}
      <FlexDiv>
        <CornerDiv>
          <LeadsPerPageText>Leads Per Page</LeadsPerPageText>
          <LeadsNumberText onClick={() => setTake(25)} selected={take === 25}>
            25
          </LeadsNumberText>

          <LeadsNumberText onClick={() => setTake(50)} selected={take === 50}>
            50
          </LeadsNumberText>

          <LeadsNumberText onClick={() => setTake(75)} selected={take === 75}>
            75
          </LeadsNumberText>

          <LeadsNumberText onClick={() => setTake(100)} selected={take === 100}>
            100
          </LeadsNumberText>
        </CornerDiv>
        <CornerDiv>
          {loadingCount ? (
            <SkeletonBlock width={"95%"} height={"95%"} borderRadius={6} />
          ) : errorCount ? (
            <AppErrorText>Error loading count.</AppErrorText>
          ) : (
            <>
              <LeadsPerPageText style={{ marginLeft: "40px" }}>
                Page <span style={{ fontWeight: 600 }}>{skip + 1}</span> of{" "}
                {Math.ceil(Number(dataCount?.fetchFilteredLeadCount) / take).toLocaleString()}
              </LeadsPerPageText>
              <div style={{ display: "flex" }}>
                <NavIcon
                  disabled={loadingCount || loadingTableAndHeaders || skip <= 0}
                  onClick={() => {
                    tableRef?.current?.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    setSkip(skip - 1);
                  }}
                >
                  <FiArrowLeft
                    size={22}
                    color={loadingCount || loadingTableAndHeaders || skip <= 0 ? theme.NEUTRAL400 : theme.PRIMARY600}
                    style={{ transform: "rotate(0deg)" }}
                  />
                </NavIcon>
                <NavIcon
                  style={{ marginLeft: "10px" }}
                  disabled={
                    loadingTableAndHeaders ||
                    loadingCount ||
                    skip * take + take >= Number(dataCount?.fetchFilteredLeadCount)
                  }
                  onClick={() => {
                    tableRef?.current?.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    setSkip(skip + 1);
                  }}
                >
                  <FiArrowLeft
                    size={22}
                    color={
                      loadingTableAndHeaders ||
                      loadingCount ||
                      skip * take + take >= Number(dataCount?.fetchFilteredLeadCount)
                        ? theme.NEUTRAL400
                        : theme.PRIMARY600
                    }
                    style={{ transform: "rotate(180deg)" }}
                  />
                </NavIcon>
              </div>
            </>
          )}
        </CornerDiv>
      </FlexDiv>
    </Sentry.ErrorBoundary>
  );
};

interface DisabledProps {
  disabled: boolean;
}

const NavIcon = styled.div<DisabledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const CornerDiv = styled.div`
  display: flex;
  align-items: center;
`;

interface SelectedProps {
  selected?: boolean;
}
const LeadsPerPageText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-right: 16px;
`;

const LeadsNumberText = styled(AppText)<SelectedProps>`
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.2px;
  padding: 0px 8px;
  color: ${(props) => (props.selected ? theme.PRIMARY600 : theme.BLACK_COLOR)};
  border-bottom: none;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  cursor: pointer;
  border-right: 1px solid #c4c4c4;
  :last-child {
    border-right: none;
  }
`;

const FlexDiv = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface CheckboxProps {
  hideTopBorder?: boolean;
}

const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 480px; }
`;

const DarkDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const FilterButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: ${theme.PRIMARY600};
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: ${theme.PRIMARY600};
  border-radius: 4px;
`;
const SlideInDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 478px;
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  background-color: ${theme.WHITE_COLOR};
`;

const SpaceBetweenDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const SpaceTogetherDiv = styled.div`
  width: fit-content;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  grid-auto-flow: column;
`;

interface SelectedProp {
  selected?: boolean;
  clickable?: boolean;
}

const GridTR = styled.tr`
  ${loggedInUser().visible_all_leads_sm && `td:first-child {border-left: 1px solid ${theme.NEUTRAL200};}`}
`;

const GridTH = styled.th`
  background: ${theme.NEUTRAL100};

  height: 40px;
  padding: 0px;
  margin: 0px;

  ${loggedInUser().visible_all_leads_sm && `:first-child {border-left: 1px solid ${theme.NEUTRAL200};}`}
`;

const GridTable = styled.table`
  font-size: 10px;
  font-weight: 500;
  border-collapse: collapse;
  /* padding: 5px; */
`;

const TableContainer = styled.div`
  max-width: 100%;
  max-height: 695px;
  /* min-height: 600px; */
  overflow: auto;
  position: relative;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
`;

const RedDot = styled.div`
  position: absolute;
  top: 6px;
  right: 5px;
  background-color: red;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
`;

const ResetFilterError = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 16px;
`;

export { BulkReassignComponent };
