import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../UI";
import { GridFilterContext, ModalContext } from "../../context";
import { Modal } from "./Modal";
import { PhoenixAppButton, PhoenixInput } from "../UI/Phoenix";
import { theme } from "../../utils/theme";
import { gql, useMutation } from "@apollo/client";
import { appToast, errorToast } from "../../utils/toast";
import { MixpanelActions } from "../../services/mixpanel";

const CREATE_DASHBOARD_SAVED_VIEW = gql`
  mutation createDashboardSavedView($data: DashBoardSavedViewArgument!, $name: String!) {
    createDashboardSavedView(data: $data, name: $name) {
      id
      name
    }
  }
`;

const UPDATE_DASHBOARD_SAVED_VIEW = gql`
  mutation updateDashboardSavedView($data: DashBoardSavedViewArgument!, $name: String!, $saved_view_id: String!) {
    updateDashboardSavedView(data: $data, name: $name, saved_view_id: $saved_view_id) {
      id
      name
    }
  }
`;

const DELETE_DASHBOARD_VIEW = gql`
  mutation deleteDashboardSavedView($saved_view_id: String!) {
    deleteDashboardSavedView(saved_view_id: $saved_view_id) {
      id
    }
  }
`;

interface DashboardViewModalProps {
  createViewArgs: {
    lead_filter: any;
    rep_filter: any;
    date_filter: any;
    group_option: any;
    measure_by: any;
    column_options: any;
  };
  isShareable: boolean;
  setIsShareable: React.Dispatch<React.SetStateAction<boolean>>;
  newSaveViewName: string;
  setNewSaveViewName: React.Dispatch<React.SetStateAction<string>>;
  currentSavedView: any;
  handleDefaultSavedView: (fetchDefaultSavedView: any) => void;
  fetchDefaultSavedView: any;
}

export const DashboardViewModal: React.FC<DashboardViewModalProps> = ({
  createViewArgs,
  isShareable,
  setIsShareable,
  newSaveViewName,
  setNewSaveViewName,
  currentSavedView,
  handleDefaultSavedView,
  fetchDefaultSavedView,
}) => {
  const {
    showDashboardViewModal,
    setShowDashboardViewModal,
    updateDashboardView,
    setUpdateDashboardView,
    deleteDashboardView,
    setDeleteDashboardView,
  } = useContext(ModalContext);
  const { setCurrentSavedView } = useContext(GridFilterContext);

  const [name, setName] = useState<string>(updateDashboardView ? newSaveViewName || "" : "");

  const [createDashboardSavedView, { loading: loadingUpdateDashboardView }] = useMutation(CREATE_DASHBOARD_SAVED_VIEW, {
    variables: {
      data: createViewArgs,
      name: name,
    },
    onCompleted({ createDashboardSavedView }) {
      appToast(`Saved View ${createDashboardSavedView?.name} created!`);
      setCurrentSavedView({ ...createDashboardSavedView });
      setShowDashboardViewModal(false);

      MixpanelActions.track("Dashboard", {
        type: "New View Created",
        group_option: `${createViewArgs.group_option}`,
        measure_by: `${createViewArgs.measure_by}`,
      });
    },
    onError({ message }) {
      errorToast(`${message}`);
    },
    refetchQueries: ["fetchAllSavedViews"],
  });

  const [updateDashboardSavedView, { loading: loadingCreateDashboardView }] = useMutation(UPDATE_DASHBOARD_SAVED_VIEW, {
    variables: {
      data: {
        shareable: isShareable,
        ...createViewArgs,
      },
      saved_view_id: currentSavedView?.id,
      name: newSaveViewName || currentSavedView?.name,
    },
    onCompleted({ updateDashboardSavedView }) {
      appToast(`Saved View ${updateDashboardSavedView?.name} Updated!`);
      setNewSaveViewName(updateDashboardSavedView?.name);
      setIsShareable(false);
      setShowDashboardViewModal(false);
      setUpdateDashboardView(false);
    },
    onError({ message }) {
      errorToast(`${message}`);
    },
    refetchQueries: ["fetchAllSavedViews"],
  });

  const [deleteDashboardSavedView, { loading: loadingDeleteDashboardView }] = useMutation(DELETE_DASHBOARD_VIEW, {
    variables: {
      saved_view_id: deleteDashboardView?.value,
    },
    onCompleted() {
      appToast(`View successfully deleted.`);
      setDeleteDashboardView(null);
      setIsShareable(false);
      setShowDashboardViewModal(false);

      if (!!fetchDefaultSavedView && !!handleDefaultSavedView) {
        handleDefaultSavedView(fetchDefaultSavedView);
        setCurrentSavedView({ ...fetchDefaultSavedView });
      }
    },
    onError({ message }) {
      errorToast(`${message}`);
    },
    refetchQueries: ["fetchAllSavedViews"],
  });

  return (
    <Modal
      open={showDashboardViewModal}
      onClose={() => {
        setShowDashboardViewModal(false);
        setUpdateDashboardView(false);
        setDeleteDashboardView(null);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600} lineHeight={22}>
            {updateDashboardView
              ? "Update Current View"
              : deleteDashboardView
              ? `Delete '${deleteDashboardView.label}'?`
              : "Save as New View"}
          </AppText>

          {updateDashboardView ? (
            <AppText fontSize={14} fontWeight={400} lineHeight={20}>
              Are you sure you want to update this view? The current filters will override the previous filters saved to
              this view.
            </AppText>
          ) : deleteDashboardView ? (
            <AppText fontSize={14} fontWeight={400} lineHeight={20}>
              Are you sure you want to delete this view? This action cannot be undone.
            </AppText>
          ) : (
            <FlexDiv direction="column" gap={8}>
              <AppText fontSize={12} fontWeight={500} lineHeight={18}>
                View Name
              </AppText>
              <PhoenixInput
                placeholder="View Name"
                variant="primary"
                type="text"
                fontSize={12}
                fontWeight={400}
                displayNoContextText
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                width={396}
                height={40}
                disabled={loadingUpdateDashboardView}
              />
            </FlexDiv>
          )}
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              setShowDashboardViewModal(false);
              setUpdateDashboardView(false);
              setDeleteDashboardView(null);
            }}
          >
            Cancel
          </PhoenixAppButton>

          <FlexDiv gap={8}>
            {updateDashboardView && (
              <PhoenixAppButton
                buttonType="secondary"
                variant="brand-outline"
                uppercase
                buttonTextFontSize={10}
                style={{ letterSpacing: "1px" }}
                onClick={() => {
                  setShowDashboardViewModal(false);
                  setUpdateDashboardView(false);
                  setDeleteDashboardView(null);
                  createDashboardSavedView();
                }}
              >
                Save as New
              </PhoenixAppButton>
            )}
            <PhoenixAppButton
              buttonType="secondary"
              uppercase
              buttonTextFontSize={10}
              style={{ letterSpacing: "1px" }}
              onClick={() =>
                updateDashboardView
                  ? updateDashboardSavedView()
                  : deleteDashboardView
                  ? deleteDashboardSavedView()
                  : createDashboardSavedView()
              }
              disabled={
                (!updateDashboardView && !name.length && !deleteDashboardView) ||
                loadingUpdateDashboardView ||
                loadingCreateDashboardView ||
                loadingDeleteDashboardView
              }
            >
              {updateDashboardView || deleteDashboardView ? "Continue" : "Save"}
            </PhoenixAppButton>
          </FlexDiv>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 75px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
