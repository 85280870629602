import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Switch from "react-switch";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI/AppText";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { info, trash } from "src/images/NewDesign";
import { PhoenixIcon, PhoenixMultiSelect, PhoenixRadio } from "src/Components/UI/Phoenix";
import { CustomField as CustomFieldType, FormField, systemFieldOptions } from "src/utils/misc";
import { OptionItem } from "src/types";
import { CustomField } from "src/Components/Field/CustomField";
import { InputField } from "src/Components/Field";
import { useFormikContext } from "formik";

interface IField {
  availableCustomFields: CustomFieldType[];
  availableSystemFields: string[];
  customFieldData: CustomFieldType[];
  data: FormField;
  fieldName?: string;
  index?: number;
  isHidden?: boolean;
  onDeleteField?: () => void;
  onFieldChange: (id: string, newOption: { label: string; value: string }, radio: "system" | "custom") => void;
  onFieldRadioChange: (id: string, newType: "system" | "custom") => void;
  onFieldRequiredChange?: (id: string, newRequired: boolean) => void;
  setFieldValue?: (fieldName: any, value: any) => void;
  values?: any;
}

export const Field: React.FC<IField> = ({
  availableCustomFields,
  availableSystemFields,
  customFieldData,
  data,
  fieldName = "",
  index = 0,
  isHidden,
  onDeleteField,
  onFieldChange,
  onFieldRadioChange,
  onFieldRequiredChange,
  setFieldValue,
  values,
}) => {
  const { errors } = useFormikContext<{ form_fields: FormField[]; hidden_fields: FormField[] }>();

  const error = errors?.[fieldName as keyof typeof errors]?.[index];

  const [radio, setRadio] = useState<"system" | "custom">(data.type === "SYSTEM_FIELD" ? "system" : "custom");
  const [required, setRequired] = useState(data.required || false);

  const customFieldItem = useMemo(() => customFieldData?.find((cf: any) => cf.id === data.custom_field_id), [
    data,
    customFieldData,
  ]);

  const customFieldOptions = useMemo(
    () => availableCustomFields?.map((cf: CustomFieldType) => ({ label: cf.key, value: cf.id })),
    [availableCustomFields],
  );

  const availableSystemFieldsOptions = useMemo(
    () => systemFieldOptions.filter((sfo: OptionItem) => availableSystemFields.includes(sfo.value.toString())),
    [availableSystemFields],
  );

  const [value, setValue] = useState<OptionItem | null>(
    data.type === "SYSTEM_FIELD"
      ? systemFieldOptions.find((sfo: OptionItem) => sfo.value === data.system_field) || null
      : !!customFieldItem
      ? { label: customFieldItem?.key, value: customFieldItem?.id }
      : null,
  );

  useEffect(() => {
    onFieldRequiredChange?.(data.id, required);
  }, [required]);

  return (
    <FieldContent>
      <FieldConfigContainer>
        <FlexDiv align="center" gap={24}>
          <FlexDiv align="center" gap={8}>
            <PhoenixRadio
              selected={radio === "system"}
              onClick={() => {
                setRadio("system");
                setValue(null);
                onFieldRadioChange(data.id, "system");
              }}
            />
            <FieldText>System Field</FieldText>
          </FlexDiv>
          <FlexDiv align="center" gap={8}>
            <PhoenixRadio
              selected={radio === "custom"}
              onClick={() => {
                setRadio("custom");
                setValue(null);
                onFieldRadioChange(data.id, "custom");
              }}
            />
            <FieldText>Custom Field</FieldText>
          </FlexDiv>
        </FlexDiv>

        {isHidden && <PhoenixIcon svg={trash} size={16} variant="danger" pointer onClick={onDeleteField} />}

        {!isHidden && value?.value !== "LEAD_ID_LABEL" && (
          <FlexDiv align="center" gap={8}>
            <Switch
              checked={required}
              onChange={() => setRequired(!required)}
              onColor={theme.PRIMARY500}
              height={16}
              width={32}
              checkedIcon={false}
              uncheckedIcon={false}
              handleDiameter={12}
            />
            <FieldText>Required</FieldText>
          </FlexDiv>
        )}
      </FieldConfigContainer>

      <FlexDiv direction="column" gap={8}>
        <FieldText>Select Field</FieldText>
        <FlexDiv align="center" gap={8}>
          <PhoenixMultiSelect
            name={`field-${data.id}`}
            isMulti={false}
            isClearable={false}
            isDisabled={false}
            options={radio === "system" ? availableSystemFieldsOptions : customFieldOptions}
            value={value}
            onChange={(oi: { label: string; value: string }) => {
              setValue(oi);
              onFieldChange(data.id, oi, radio);
            }}
            marginBottom={false}
            width={352}
            menuPortal
            maxMenuHeight={250}
            menuPosition="fixed"
            menuShouldBlockScroll
          />

          <PhoenixIcon
            svg={info}
            size={16}
            variant="brand"
            data-tip="Only visible fields will be displayed."
            data-for="field-tooltip"
          />
        </FlexDiv>

        {isHidden ? (
          <FlexDiv>
            {customFieldItem ? (
              <CustomField
                label="Value"
                setFieldValue={setFieldValue}
                values={values}
                fieldName={fieldName}
                index={index}
                customField={customFieldItem}
                disabled={false}
              />
            ) : (
              <div style={{ width: "100%" }}>
                <TitleLabel>Value</TitleLabel>
                <EditTextInput name={`${fieldName}[${index}].value`} />
              </div>
            )}
          </FlexDiv>
        ) : null}

        {error ? <AppText color={theme.ATTENTION700}>{error}</AppText> : null}
      </FlexDiv>
    </FieldContent>
  );
};

const FieldConfigContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldContent = styled(FlexDiv)`
  flex-direction: column;
  gap: 40px;
`;

const FieldText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const TitleLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const EditTextInput = styled(InputField)`
  font-size: 13px;
  height: 36px;
  margin-top: 0px;
  width: 100%;
  border: 1px solid ${theme.NEUTRAL200};
  :focus {
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;
