import styled from "styled-components";
import React, { useEffect, useRef, useMemo } from "react";
import { AppText, NewAppSelect, AppErrorText, Loading, ReactDatesWrapper } from "../../UI";
import { theme } from "../../../utils/theme";
import * as Sentry from "@sentry/react";
import { useState, useContext } from "react";
import { NewAppButton } from "../../UI/NewAppButton";
import { ModalContext } from "../../../context";
import { useMutation, useQuery, gql } from "@apollo/client";
import moment from "moment";
import { appToast, successToast, errorToast } from "../../../utils/toast";
import { DateRangePicker } from "react-dates";
import { downloadResourceAWS, copyTextToClipboard, redirectToLeadDetailPage } from "../../../utils/misc";
import { FiArrowLeft, FiRefreshCcw } from "react-icons/fi";
import { DATE_RANGE_OPTIONS, isOutsideRange } from "../../../utils/date-options";
import { info } from "../../../images/NewDesign";

// grid imports
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { PhoenixIcon, PhoenixInput } from "../../UI/Phoenix";
import { useDebounce } from "../../../utils/hooks";
// import "ag-grid-enterprise";

// grid defaults and utils

const CustomTooltip = (props: any) => {
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);

  return (
    <div
      style={{
        minWidth: "300px",
        lineHeight: "1.4px",
        textAlign: "center",
        fontFamily: theme.PRIMARY_FONT,
        padding: "10px 20px",
        backgroundColor: theme.PRIMARY500,
        color: theme.WHITE_COLOR,
        borderRadius: "10px",
      }}
    >
      <span
        className="sib-tooltip"
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "15px",
          lineHeight: "14px",
        }}
      >
        {props?.value}
      </span>
    </div>
  );
};
interface clickableCellProps {
  text: string;
  header: string;
}

const clickableCell = (props: clickableCellProps) => {
  return (
    <span
      style={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: 0,
        wordBreak: "break-all",
      }}
      data-tip={props?.text}
      data-for="errorLogTooltip"
      onClick={
        props?.header?.toUpperCase() === "OPSIQ RECORD ID"
          ? () => {
              redirectToLeadDetailPage(props?.text);
            }
          : () => {
              copyTextToClipboard(props?.text);
            }
      }
    >
      {props.text ? props.text : "N/A"}
    </span>
  );
};

const CustomToolTipHeader = (props: any) => {
  const { column } = props;
  const { headerName, tooltip } = column.getColDef();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "8px",
      }}
    >
      <div>{headerName}</div>
      <div>
        <PhoenixIcon
          svg={info}
          data-tip={tooltip}
          data-for="errorLogTooltip"
          data-place="right"
          data-type="dark"
          data-effect="solid"
          data-border={true}
          variant="brand"
          size={14}
        />
      </div>
    </div>
  );
};

const RESULT_OPTIONS = [
  { label: "Success", value: "Success" },
  { label: "Failure", value: "Failure" },
  { label: "In Progress", value: "InProgress" },
  { label: "All", value: null },
];

const SALESFORCE_HEADERS = ["SFDC ID", "OPSIQ Record ID", "Date", "Business Name", "Type", "Result", "Data", "Action"];
// the action to repush events are only for SalesForce, so the other integrations have the "Action" header omitted
const HUBSPOT_HEADERS = ["HS Record ID", "OPSIQ Record ID", "Date", "Type", "Result", "Data"];
const PANDADOC_HEADERS = ["ID", "Date", "Business Name", "Type", "Result", "Data"];
const DEFAULT_HEADERS = ["ID", "Date", "Business Name", "Type", "Result", "Data"];

interface integrationArgs {
  lowerbound_date: string;
  upperbound_date: string;
  take: number;
  skip: number;
  integration_type: "SalesForce" | "HubSpot" | "PandaDoc";
  // undefined will return all types
  operation_type: string | null;
  // undefined will return all results
  result_type: "Failure" | "Success" | "InProgress" | null;
  // search for by entity id
  entity_id?: string | undefined;
}

const FETCH_INTEGRATION_ERROR_V2 = gql`
  query FetchIntegrationErrorV2($intergrationArgs: IntegrationLogArgument!) {
    fetchIntegrationErrorV2(intergration_args: $intergrationArgs) {
      id
      created_at
      integration_type
      error_message
      result_type
      integration_operation_log_id
      IntegrationOperationLog {
        entity_id
        entity_type
        integration_type
        operation_type_computed
        result_type
        Lead {
          id
          business_name
        }
      }
    }
  }
`;

interface repushEventArgs {
  integration_type: "SalesForce" | "HubSpot" | "PandaDoc";
  error_log_id: string;
}

const REPUSH_EVENT = gql`
  mutation RepushEvent($error_log_id: String!, $integration_type: INTEGRATION_TYPE) {
    repushEvent(error_log_id: $error_log_id, integration_type: $integration_type)
  }
`;

const EXPORT_LOGS = gql`
  mutation ExportErrorReport($intergrationArgs: IntegrationLogArgument!) {
    exportErrorReport(intergration_args: $intergrationArgs) {
      url
    }
  }
`;

const GET_ALL_LOGS = gql`
  query fetchIntegrationErrorV2Count($intergrationArgs: IntegrationLogArgument!) {
    fetchIntegrationErrorV2Count(intergration_args: $intergrationArgs)
  }
`;

const GET_TYPE_OPTIONS = gql`
  query Query($integrationType: INTEGRATION_TYPE!) {
    fetchIntegrationOperationType(integration_type: $integrationType)
  }
`;

// this is used to go back to the main integration layout page
interface IntegrationReportingProps {
  backToIntegrations: () => void;
}

const IntegrationReportingComponent: React.FC<IntegrationReportingProps> = ({ backToIntegrations }) => {
  // configure state is the current integration (Salesforce, Quickbooks, etc)
  const { selectedIntegration, selectedIntegrationLabel } = useContext(ModalContext);

  // not used but will be necessary for the future updates if we want more filters to search through
  // const INTEGRATION_SEARCH_OPTIONS = [{ label: `${selectedIntegrationLabel} ID`, value: "ID" }];

  // ----------------------- GRID STATE -----------------------

  const gridRef: any = useRef(); // Optional - for accessing Grid's API

  const IntegrationHeaders: string[] =
    selectedIntegration === "SalesForce"
      ? SALESFORCE_HEADERS
      : selectedIntegration === "HubSpot"
      ? HUBSPOT_HEADERS
      : selectedIntegration === "PandaDoc"
      ? PANDADOC_HEADERS
      : DEFAULT_HEADERS;

  const columnDefs = IntegrationHeaders?.map((header: string) => {
    switch (true) {
      case ["HS Record ID", "ID", "OPSIQ Record ID", "SFDC ID", "OPSIQ ID"].includes(header):
        return {
          headerName: header,
          field: header,
          tooltipField: header,
          tooltipComponent: CustomTooltip,
          // sortable: true,
          // filter: true,
          resizable: true,
          maxWidth: 400,
          minWidth: 150,
          width: 150,
          suppressSizeToFit: true,
          cellRendererFramework: (params: any) => {
            console.log(params);
            return clickableCell({ text: params?.value || "NA", header });
          },
        };
      case header === "Business Name":
        return {
          headerName: header,
          field: header,
          maxWidth: 400,
          minWidth: 150,
          width: 150,
          suppressSizeToFit: true,
          // sortable: true,
          // filter: true,
          resizable: true,
        };
      case header === "Date":
        return {
          headerName: header,
          field: header,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
          suppressSizeToFit: false,
          // sortable: true,
          // filter: true,
          // resizable: true,
        };
      case header === "Type":
        return {
          headerName: header,
          field: header,
          maxWidth: 300,
          minWidth: 180,
          width: 180,
          suppressSizeToFit: true,
          // sortable: true,
          // filter: true,
          resizable: true,
        };
      case header === "Result":
        return {
          headerName: header,
          field: header,
          maxWidth: 200,
          minWidth: 100,
          width: 100,
          suppressSizeToFit: true,
          // sortable: true,
          // filter: true,
          resizable: true,
        };
      case header === "Data":
        return {
          headerName: header,
          field: header,
          tooltipField: "Data",
          tooltipComponent: CustomTooltip,
          flex: 1,
          // sortable: true,
          // filter: true,
          resizable: true,
          cellRendererFramework: (params: any) => {
            return clickableCell({ text: params?.data?.Data || "", header });
          },
        };
      case header === "Action":
        return {
          headerName: header,
          headerTooltip: `Once you believe you’ve corrected the underlying integration error use the Repush Event button to try syncing the data from OPSIQ to ${
            selectedIntegration && selectedIntegrationLabel
          }`,
          headerComponent: CustomToolTipHeader,
          field: header,
          width: 175,
          maxWidth: 175,
          minWidth: 175,
          suppressSizeToFit: false,
          resizable: false,
          cellRendererFramework: (props: any) => {
            const BE_SF_SUPPORTED_EVENT_TYPES = ["Sales Force Sale", "Salesforce Sale"];

            if (props?.value?.integration_type !== "SalesForce") {
              return null;
            }

            return (
              <NewAppButton
                variant="primary"
                height={30}
                disabled={
                  repushLoading ||
                  // we only support repushing certain types of events
                  !BE_SF_SUPPORTED_EVENT_TYPES.includes(props?.data?.Type)
                }
                onClick={async () => {
                  // repush the event
                  const result = await repushEvent({
                    variables: {
                      error_log_id: props.value.errorLogId,
                      integration_type: props.value.integration_type,
                    },
                  }).finally(() => {
                    refetchIntegrationLog();
                  });
                }}
              >
                Repush Event
              </NewAppButton>
            );
          },
        };
      default:
        return {
          headerName: header,
          field: header,
          // sortable: true,
          // filter: true,
          resizable: true,
        };
    }
  });

  const [currentResult, setCurrentResult] = useState(null as any);
  const [currentType, setCurrentType] = useState(null as any);
  const [integration, setIntegration] = useState(selectedIntegration ? selectedIntegration : "");
  const [dateStart, setDateStart] = useState(moment().startOf("day").format());
  const [dateEnd, setDateEnd] = useState(moment().endOf("day").format());
  const [dateRange, setDateRange] = useState("Today");
  const [focusedInput, setFocusedInput] = useState(null as any);
  const [takeNumber, setTakeNumber] = useState(25);
  const [skip, setSkip] = useState(0);
  const tableRef = useRef<HTMLDivElement>(null);
  const [tableRows, setTableRows] = useState([]);
  const [typeOptions, setTypeOptions] = useState([] as any);
  // used in the query
  const [searchText, setSearchText] = useState("");
  // updated on every change for dispalying in the input
  const [inputSearchText, setInputSearchText] = useState("");

  const [leadId, setLeadId] = useState("");
  const [inputLeadId, setInputLeadId] = useState("");
  // not used in community edition

  useDebounce(
    () => {
      setSearchText(inputSearchText);
    },
    [inputSearchText],
    1000,
  );

  useDebounce(
    () => {
      setLeadId(inputLeadId);
    },
    [inputLeadId],
    1000,
  );
  // const onCellValueChanged = useCallback((params) => {
  //   console.log("Callback onCellValueChanged:", params);
  // }, []);

  // const onPasteStart = useCallback((params) => {
  //   console.log("Callback onPasteStart:", params);
  // }, []);

  // const onPasteEnd = useCallback((params) => {
  //   // unselect the cells
  //   console.log("Callback onPasteEnd:", params);
  // }, []);

  // ------------------- GraphQL -------------------  //

  // query to get typeOptions
  const { data: typeOptionsData, loading: loadingFetchTypeOptions, error: typeOptionsError } = useQuery(
    GET_TYPE_OPTIONS,
    {
      fetchPolicy: "network-only",
      variables: {
        integrationType: integration,
      },
      onCompleted: (data) => {
        Object.keys(data["fetchIntegrationOperationType"]).forEach(function (key: any, index: any) {
          typeOptions.push({ label: data["fetchIntegrationOperationType"][key], value: key });
        });

        typeOptions.push({ label: "All", value: null });
      },
    },
  );

  const {
    data: integrationLog,
    loading: integrationLogLoading,
    error: integrationLogErrors,
    refetch: refetchIntegrationLog,
  } = useQuery(FETCH_INTEGRATION_ERROR_V2, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      intergrationArgs: {
        lowerbound_date: dateStart ? dateStart : null,
        upperbound_date: dateEnd ? dateEnd : null,
        take: takeNumber,
        skip: skip * takeNumber,
        integration_type: integration ? integration : null,
        operation_type: currentType ? currentType.value : null,
        result_type: currentResult ? currentResult.value : null,
        entity_id: !!searchText ? searchText : null,
        lead_id: !!leadId ? leadId : null,
      } as integrationArgs,
    },
    onError({ message, name }) {
      Sentry.captureEvent({
        message: `${name} GraphQL Error: ${message}`,
      });
      errorToast(message);
    },
    onCompleted() {
      const rows = integrationLog?.fetchIntegrationErrorV2?.map((log: any) => {
        const id = log?.IntegrationOperationLog?.entity_id ? log.IntegrationOperationLog?.entity_id : "N/A";
        return {
          ID: id,
          "HS Record ID": id,
          "SFDC ID": id,
          "OPSIQ Record ID": log?.IntegrationOperationLog?.Lead?.id ? log.IntegrationOperationLog?.Lead?.id : "N/A",
          Date: moment(log.created_at).format("MM/DD/YYYY h:mm A"),
          "Business Name": log?.IntegrationOperationLog?.Lead?.business_name
            ? log?.IntegrationOperationLog?.Lead?.business_name
            : "N/A",
          Type: log?.IntegrationOperationLog?.operation_type_computed
            ? log.IntegrationOperationLog.operation_type_computed
            : "N/A",

          Result: (() => {
            const resultType =
              log?.integration_type !== "PandaDoc"
                ? log?.result_type || log?.IntegrationOperationLog?.result_type
                : log?.IntegrationOperationLog?.result_type || log?.result_type;
            if (!resultType) return "N/A";
            return resultType === "InProgress" ? "In Progress" : resultType;
          })(),

          Data: log.error_message ? log.error_message : "N/A",
          Action: {
            errorLogId: log.id,
            integration_type: log.integration_type,
            status: log?.result_type,
          },
        };
      });
      console.log("mcjs table rows:", rows);
      setTableRows(rows);
    },
  });

  const [repushEvent, { loading: repushLoading, error: repushError }] = useMutation(REPUSH_EVENT, {
    variables: {} as repushEventArgs,
    onCompleted({ repushEvent }) {
      if (repushEvent) {
        if (repushEvent === "success") {
          successToast("Repushed To " + integration);
        } else {
          appToast(repushEvent);
        }
      }
      refetchIntegrationLog();
    },
    onError({ message, name }) {
      Sentry.captureEvent({
        message: `repushEvent GraphQL Error: ${message}`,
      });
      console.log(`Error in ${name}: `, message);
      errorToast(message);
    },
  });

  const [exportLogs, { loading: exportLoading, error: exportError }] = useMutation(EXPORT_LOGS, {
    variables: {
      intergrationArgs: {
        lowerbound_date: dateStart ? dateStart : null,
        upperbound_date: dateEnd ? dateEnd : null,
        take: takeNumber,
        skip: skip * takeNumber,
        integration_type: integration ? integration : null,
        operation_type: currentType ? currentType.value : null,
        result_type: currentResult ? currentResult.value : null,
      } as integrationArgs,
    },
    onCompleted(data) {
      const url = data.exportErrorReport.url;
      if (!url) {
        appToast("Error exporting log item. Something went wrong.");
        return;
      }
      downloadResourceAWS(url);
      appToast("Error log item exported successfully!");
    },
    onError({ message }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `createIntegrationErrorReportCustomDates GraphQL Error: ${message}`,
      });
      console.log("Error in createIntegrationErrorReportCustomDates: ", message);
    },
  });

  const {
    data: integrationLogCount,
    loading: integrationLogCountLoading,
    error: integrationLogCountErrors,
    refetch: refetchIntegrationLogCount,
  } = useQuery(GET_ALL_LOGS, {
    variables: {
      intergrationArgs: {
        lowerbound_date: dateStart ? dateStart : null,
        upperbound_date: dateEnd ? dateEnd : null,
        take: takeNumber,
        skip: skip * takeNumber,
        integration_type: integration ? integration : null,
        operation_type: currentType ? currentType.value : null,
        result_type: currentResult ? currentResult.value : null,
        entity_id: !!searchText ? searchText : null,
        lead_id: !!leadId ? leadId : null,
      } as integrationArgs,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
      errorToast(message);
    },
    onCompleted() {
      console.log("integrationLogCount", integrationLogCount.fetchIntegrationErrorV2Count);
    },
  });

  useEffect(() => {
    // Updates the graph and total count anytime the filters change
    if (!!integrationLog?.fetchIntegrationErrorV2) {
      refetchIntegrationLog();
    }
    if (!!integrationLogCount?.fetchIntegrationErrorV2Count) {
      refetchIntegrationLogCount();
    }
  }, [skip, takeNumber, currentResult, currentType, dateStart, dateEnd]);

  return (
    <Sentry.ErrorBoundary fallback={"An error has in skip lead modal"}>
      <Main>
        <Top>
          <BackDiv
            onClick={() => {
              backToIntegrations();
            }}
          >
            {"<"} Back to Integrations
          </BackDiv>
          <TitleDiv>
            <Title>{selectedIntegrationLabel} Integration Logs</Title>
            <SyncValuesButton
              variant="primary"
              size="sm"
              width={120}
              height={32}
              onClick={() => {
                //reset skip and take and fetch
                setSkip(0);
                setTakeNumber(25);
                refetchIntegrationLog();
                appToast("Refreshed Data");
              }}
            >
              Refresh
              <FiRefreshCcw
                size={16}
                style={{
                  marginLeft: 5,
                }}
              />
            </SyncValuesButton>
          </TitleDiv>
        </Top>
        <Options>
          <LeftSide>
            <Filter>
              <FilterText>Type</FilterText>
              <SelectWrapper>
                <NewAppSelect
                  options={typeOptions}
                  value={currentType}
                  disabled={loadingFetchTypeOptions}
                  maxHeight={300}
                  minHeight={42}
                  // transparent
                  // borderless
                  // disabled={loadingErrors}
                  placeholder={"Event Type"}
                  onChange={(e: any) => {
                    setCurrentType(e);
                  }}
                  isSearchable
                  noErrorNeeded
                  menuPortal
                  menuShouldBlockScroll
                ></NewAppSelect>
              </SelectWrapper>
            </Filter>
            <Filter>
              <FilterText>Result</FilterText>
              <SelectWrapper>
                <NewAppSelect
                  options={RESULT_OPTIONS}
                  value={currentResult}
                  maxHeight={300}
                  minHeight={42}
                  // transparent
                  // borderless
                  // disabled={loadingErrors}
                  placeholder={"Event Result"}
                  onChange={(e: any) => {
                    console.log("e", e);
                    setCurrentResult({
                      label: e.label,
                      value: e.value,
                    });
                  }}
                  isSearchable
                  noErrorNeeded
                  menuPortal
                  menuShouldBlockScroll
                ></NewAppSelect>
              </SelectWrapper>
            </Filter>
            <Filter>
              <FilterText>Search By {selectedIntegrationLabel} ID</FilterText>
              <SelectWrapper>
                <PhoenixInput
                  value={inputSearchText}
                  onChange={(e: any) => {
                    setInputSearchText(e.target.value);
                  }}
                />
              </SelectWrapper>
            </Filter>
            <Filter>
              <FilterText>Search By OPSIQ ID</FilterText>
              <SelectWrapper>
                <PhoenixInput
                  value={inputLeadId}
                  onChange={(e: any) => {
                    setInputLeadId(e.target.value);
                  }}
                />
              </SelectWrapper>
            </Filter>
          </LeftSide>
          <RightSide>
            <Filter>
              <FilterText>Time Range</FilterText>
              <SelectWrapper>
                <NewAppSelect
                  name="DateRangeInput"
                  options={DATE_RANGE_OPTIONS?.map((item) => ({
                    label: item.label,
                    value: item.value,
                    start: item.start,
                    end: item.end,
                  }))}
                  onChange={(e: any) => {
                    setDateRange(e.value);
                    setDateStart(e.start);
                    setDateEnd(e.end);
                    // this is to make sure that the page and take is reset when the date range is changed
                    // setTakeNumber(25);
                    setSkip(0);
                  }}
                  maxHeight={300}
                  minHeight={42}
                  width={200}
                  value={dateRange}
                  menuPortal
                  menuShouldBlockScroll
                />
              </SelectWrapper>
            </Filter>
            <Filter>
              <FilterText>Date Range</FilterText>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <ReactDatesWrapper>
                  <DateRangePicker
                    minimumNights={0}
                    withPortal
                    isOutsideRange={isOutsideRange}
                    startDate={!!dateStart ? moment(dateStart) : moment()}
                    startDateId="manager_grid_start_date"
                    endDate={!!dateEnd ? moment(dateEnd) : moment()}
                    endDateId="manager_grid_end_date"
                    onDatesChange={({ startDate, endDate }) => {
                      // if the range was previously a fixee range set it to custom
                      if (dateRange !== "CustomRange") {
                        setDateRange("CustomRange");
                      }

                      if (startDate && moment(startDate).isValid()) {
                        // setDateLabel("CustomRange");
                        setDateStart(moment(startDate).startOf("day").format());
                      }
                      if (endDate && moment(endDate).isValid()) {
                        // setDateLabel("CustomRange");
                        setDateEnd(moment(endDate).endOf("day").format());
                      }
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                    openDirection={"up"}
                  />
                </ReactDatesWrapper>
              </div>
            </Filter>
            <ExportButton
              variant="secondary"
              size="sm"
              width={120}
              height={32}
              disabled={exportLoading}
              onClick={async () => {
                await exportLogs();

                // download
              }}
            >
              {exportLoading ? "Exporting please wait..." : "Export"}
            </ExportButton>
          </RightSide>
        </Options>
        {integrationLogLoading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : integrationLogErrors ? (
          <LoadingWrapper>
            <AppErrorText>Something went wrong.</AppErrorText>
          </LoadingWrapper>
        ) : (
          <div className="ag-theme-alpine" style={{ width: "100%", minHeight: "50vh", height: "70vh" }}>
            <AgGridReact
              tooltipMouseTrack={true}
              tooltipShowDelay={0}
              tooltipHideDelay={3000}
              // enableCellTextSelection={true}
              minColWidth={50}
              ref={gridRef} // Ref for accessing Grid's API3
              // auto size columns
              onGridReady={(params) => {
                // params.api.sizeColumnsToFit();
                // ReactTooltip.rebuild();
              }}
              rowData={tableRows} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              // defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection="multiple" // Options - allows click selection of rows
              onGridSizeChanged={(params: any) => {
                // auto size columns
                params.api.sizeColumnsToFit();
              }}
              // enableRangeSelection={true}
              // onCellValueChanged={onCellValueChanged}
              // onPasteStart={onPasteStart}
              // onPasteEnd={onPasteEnd}
              // on rows changed
              // onRowDataChanged={}
            />
          </div>
        )}

        <FlexDiv>
          <CornerDiv>
            <LeadsPerPageText>Leads Per Page</LeadsPerPageText>
            <LeadsNumberText onClick={() => setTakeNumber(25)} selected={takeNumber === 25}>
              25
            </LeadsNumberText>

            <LeadsNumberText
              onClick={() => {
                setTakeNumber(50);
                setSkip(0);
              }}
              selected={takeNumber === 50}
            >
              50
            </LeadsNumberText>

            <LeadsNumberText
              onClick={() => {
                setTakeNumber(75);
                setSkip(0);
              }}
              selected={takeNumber === 75}
            >
              75
            </LeadsNumberText>

            <LeadsNumberText
              onClick={() => {
                setTakeNumber(100);
                setSkip(0);
              }}
              selected={takeNumber === 100}
            >
              100
            </LeadsNumberText>
          </CornerDiv>
          <CornerDiv>
            {integrationLogLoading || integrationLogCountLoading ? (
              <AppText>Loading count...</AppText>
            ) : integrationLogErrors || integrationLogCountErrors ? (
              <AppErrorText>Error loading count.</AppErrorText>
            ) : (
              <>
                <LeadsPerPageText style={{ marginLeft: "40px" }}>
                  Page <span style={{ fontWeight: 600 }}>{skip + 1}</span> of{" "}
                  {Math.ceil(
                    Number(integrationLogCount?.fetchIntegrationErrorV2Count) / takeNumber,
                  ).toLocaleString() === "0"
                    ? "1"
                    : Math.ceil(
                        Number(integrationLogCount?.fetchIntegrationErrorV2Count) / takeNumber,
                      ).toLocaleString()}
                </LeadsPerPageText>
                <div style={{ display: "flex" }}>
                  <NavIcon
                    disabled={integrationLogLoading || integrationLogCountLoading || skip <= 0}
                    onClick={() => {
                      tableRef?.current?.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setSkip(skip - 1);
                    }}
                  >
                    <FiArrowLeft
                      size={22}
                      color={
                        integrationLogLoading || integrationLogCountLoading || skip <= 0
                          ? theme.NEUTRAL400
                          : theme.PRIMARY600
                      }
                      style={{ transform: "rotate(0deg)" }}
                    />
                  </NavIcon>
                  <NavIcon
                    style={{ marginLeft: "10px" }}
                    disabled={
                      integrationLogLoading ||
                      integrationLogCountLoading ||
                      skip * takeNumber + takeNumber >= Number(integrationLogCount?.fetchIntegrationErrorV2Count)
                    }
                    onClick={() => {
                      tableRef?.current?.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setSkip(skip + 1);
                    }}
                  >
                    <FiArrowLeft
                      size={22}
                      color={
                        integrationLogLoading ||
                        integrationLogCountLoading ||
                        skip * takeNumber + takeNumber >= Number(integrationLogCount?.fetchIntegrationErrorV2Count)
                          ? theme.NEUTRAL400
                          : theme.PRIMARY600
                      }
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </NavIcon>
                </div>
              </>
            )}
          </CornerDiv>
        </FlexDiv>
      </Main>
    </Sentry.ErrorBoundary>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 50vh;
  height: 70vh;
`;
const Main = styled.div`
  width: 100%;
`;
const FilterText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;

const ExportButton = styled(NewAppButton)`
  margin-top: 35px;
  margin-left: 20px;
`;

const BackDiv = styled.div`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
  overflow: auto;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TitleDiv = styled.div`
  display: flex;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: 50px;
`;

const SyncValuesButton = styled(NewAppButton)`
  // font
  font-family: "Inter";
  font-style: "Semi Bold";
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  width: fit-content;
`;

interface DisabledProps {
  disabled: boolean;
}

const NavIcon = styled.div<DisabledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background-color: transparent;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const CornerDiv = styled.div`
  display: flex;
  align-items: center;
`;

interface SelectedProps {
  selected?: boolean;
}

const LeadsPerPageText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-right: 16px;
`;

const LeadsNumberText = styled(AppText)<SelectedProps>`
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.2px;
  padding: 0px 8px;
  color: ${(props) => (props.selected ? theme.PRIMARY600 : theme.BLACK_COLOR)};
  border-bottom: none;
  font-weight: ${(props) => (props.selected ? 600 : 400)};
  cursor: pointer;
  border-right: 1px solid #c4c4c4;
  :last-child {
    border-right: none;
  }
`;

const FlexDiv = styled.div`
  margin-top: 20px;
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
`;

const SelectWrapper = styled.div`
  width: 200px;
  margin-top: 10px;
`;

export { IntegrationReportingComponent };
