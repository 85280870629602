import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { theme } from "../../utils/theme";
import { calculateTimeDifferenceNum } from "../../utils/format";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { Loading, AppText } from "../UI";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useQuery, gql, useSubscription } from "@apollo/client";
import {
  iconCallStroke,
  iconDemoStroke,
  iconCallbackStroke,
  iconDecisionCallStroke,
  iconInboundCallStroke,
  iconPausedStroke,
} from "../../images/";
import { cloudinary } from "../../services/cloudinary";
import { AdvancedImage } from "@cloudinary/react";
import { loggedInUser } from "../../apollo/cache";
import { SearchSelectInput } from "../UI/SearchSelectInput";
import { TimeInterval } from "./TimeInterval";
import { CallContext, LiveUserStatusItem, LiveUserStatusContext } from "../../context";
import { DEFAULT_CLOUDINARY_IMAGE } from "../../utils/variables";
import { PhoenixIcon } from "../UI/Phoenix";
import { chevron_down, chevron_up } from "../../images/NewDesign";

const CALL_TYPE_FILTERS = [
  { label: "Cold Call", value: "Cold Call" },
  { label: "Call back", value: "Call back" },
  { label: "Demo", value: "Demo" },
  { label: "Scheduled Call Back", value: "Scheduled Call Back" },
  { label: "Decision Call", value: "Decision Call" },
  { label: "Inbound Call", value: "Inbound Call" },
  { label: "Automatic Call Back", value: "Automatic Call Back" },
  { label: "Demo Held Call Back", value: "Demo Held Call Back" },
  { label: "Demo Not Held Call Back", value: "Demo Not Held Call Back" },
  { label: "Other", value: "Other" },
];
interface FilterObject {
  label: string;
  value: string;
  component?: string;
}

interface CallMonitorItem {
  label: string;
  value: string;
}

const FETCH_TEAMS_REPS_FROM_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      Reps {
        id
        first_name
        last_name
      }
      Teams {
        id
        name
      }
    }
  }
`;

const MODAL_HEIGHT = 224;
const TRANSITION_TIME = 0.25;

const CallMonitorComponent: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const [repList, setRepList] = useState([]);
  const [teamList, setTeamList] = useState([]);

  const { callMonitorFilter, setCallMonitorFilter, liveUserStatus } = useContext(LiveUserStatusContext);

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_TEAMS_REPS_FROM_ORG, {
    variables: {
      org_id: loggedInUser().organization_id,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    /*might need subscription for data org in the edge case someone adds a new team/rep while they are using the monitor 
    (otherwise team/rep wont appear until refresh) */
    if (dataOrg?.fetchOrganization?.Reps) {
      const repData = dataOrg?.fetchOrganization?.Reps?.map((rep: any) => {
        return { label: `${rep.first_name} ${rep.last_name}`, value: rep.id };
      }).sort((a: any, b: any) => a?.label.localeCompare(b?.label));
      setRepList(repData);
    }

    if (dataOrg?.fetchOrganization?.Teams) {
      const teamData = dataOrg?.fetchOrganization?.Teams?.map((team: any) => {
        return { label: team?.name, value: team?.id };
      }).sort((a: any, b: any) => a?.label.localeCompare(b?.label));
      setTeamList(teamData);
    }
  }, [dataOrg]);

  const renderIcon = (item: LiveUserStatusItem) => {
    switch (item.status) {
      case "IDLE":
        return <img src={iconPausedStroke} alt="Idle" />;
      case "CALL":
        switch (item?.activity_type) {
          case "Demo":
            return <img src={iconDemoStroke} alt="Demo" />;
          case "Scheduled Call Back":
            return <img src={iconCallbackStroke} alt="Scheduled Callback" />;
          case "Inbound Call":
            return <img src={iconInboundCallStroke} alt="Inbound Call" />;
          case "Decision Call":
            return <img src={iconDecisionCallStroke} alt="Decision Call" />;
          case "Paused":
            return <img src={iconPausedStroke} alt="Idle" />;
          default:
            //case "Other"
            return <img src={iconCallStroke} alt="Call" />;
        }
      default:
        //case "Offline"
        return <></>;
    }
  };

  const handleChange = (e: any) => {
    const values: CallMonitorItem[] = [];
    if (e.value) {
      for (let i = 0; i < e.value.length; i++) {
        values.push(e.value[i]);
      }
    }
    switch (e.component) {
      case "Call Types":
        setCallMonitorFilter({ ...callMonitorFilter, statuses: values });
        break;
      case "Teams":
        setCallMonitorFilter({ ...callMonitorFilter, team_ids: values });
        break;
      case "Reps":
        setCallMonitorFilter({ ...callMonitorFilter, user_ids: values });
        break;
      default:
        break;
    }
  };

  const toggleModal = () => {
    if (showModal) {
      // showing -> not showing
      window.scrollBy({
        top: -1 * MODAL_HEIGHT,
        left: 0,
        behavior: "smooth",
      });
    } else {
      // not showing -> showing
      window.scrollBy({
        top: MODAL_HEIGHT,
        left: 0,
        behavior: "smooth",
      });
    }
    setShowModal(!showModal);
  };

  const callerCard = (item: LiveUserStatusItem) => {
    const updatedTime = moment(item?.updated_at);
    const firstName = item?.user?.first_name || "";
    const lastName = item?.user?.last_name || "";

    const profileImage = cloudinary.image(
      item?.user?.profile_image ? item.user.profile_image : DEFAULT_CLOUDINARY_IMAGE,
    );
    //conditional instead of isActive prop on NavLink since pointerEvents: none would only disable link through click only
    return (
      <CallerCard key={`caller-card-${item.user_id}`}>
        {item?.status === "CALL" ? (
          <IconNavLink style={{ textDecoration: "none" }} to={`/call-report/${item.conference_id}`}>
            <AccountSettingsBorder style={{ borderColor: `${theme.SUCCESS500}` }}>
              <AdvancedImageStyle style={{ filter: `blur(0)` }} cldImg={profileImage} />
              <CallIconDiv>{renderIcon(item)}</CallIconDiv>
            </AccountSettingsBorder>
          </IconNavLink>
        ) : (
          <div style={{ marginTop: 16.4, opacity: 0.5 }}>
            <IconNavLink style={{ textDecoration: "none" }} to={`/call-report/${item.conference_id}`}>
              <AccountSettingsBorder style={{ borderColor: `${theme.NEUTRAL200}` }}>
                <AdvancedImageStyle style={{ filter: `blur(2px)`, opacity: 0.5 }} cldImg={profileImage} />
                <CallIconDiv style={{ opacity: 0.5 }}>{renderIcon(item)}</CallIconDiv>
              </AccountSettingsBorder>
            </IconNavLink>
          </div>
        )}
        <CallerNameDiv>{`${firstName} ${lastName.length > 5 ? lastName[0] + `.` : lastName}`}</CallerNameDiv>
        <CallDurationDiv>
          <CallDurationDivSub>
            <CallDurationIconDiv>
              <AiOutlineClockCircle />
            </CallDurationIconDiv>
            <AppText style={{ paddingTop: 1.5 }}>
              <TimeInterval time={updatedTime} />
            </AppText>
          </CallDurationDivSub>
          {item?.status != "CALL" && <AppText>(Idle)</AppText>}
        </CallDurationDiv>
      </CallerCard>
    );
  };

  return (
    <>
      <MarginDiv modalShowing={showModal} />
      <ActiveCallToggle modalShowing={showModal} onClick={toggleModal}>
        <AppText
          fontSize={10}
          fontWeight={600}
          variant="white"
          style={{ letterSpacing: "1px", textTransform: "uppercase", marginTop: "1px" }}
        >
          Active Calls
        </AppText>
        {showModal ? (
          <PhoenixIcon svg={chevron_down} variant="white" pointer />
        ) : (
          <PhoenixIcon svg={chevron_up} variant="white" pointer />
        )}
      </ActiveCallToggle>

      <CallMonitorDiv modalShowing={showModal}>
        <DropdownFiltersDiv>
          <CallFilterDiv>
            <SearchSelectInput
              name="Call Types"
              options={CALL_TYPE_FILTERS}
              onCheckboxClick={(e: FilterObject) => handleChange(e)}
              maxHeight={32}
              selectedValues={callMonitorFilter.statuses}
              loading={loadingOrg}
              menuPlacement={"top"}
              closeMenuOnSelect={false}
              allowMultiple
            />
          </CallFilterDiv>

          <CallFilterDiv>
            <SearchSelectInput
              name="Teams"
              options={teamList}
              onCheckboxClick={(e: FilterObject) => handleChange(e)}
              maxHeight={32}
              selectedValues={callMonitorFilter?.team_ids}
              loading={loadingOrg}
              menuPlacement={"top"}
              closeMenuOnSelect={false}
              allowMultiple
            />
          </CallFilterDiv>

          <CallFilterDiv>
            <SearchSelectInput
              name="Reps"
              options={repList}
              onCheckboxClick={(e: FilterObject) => handleChange(e)}
              maxHeight={32}
              selectedValues={callMonitorFilter?.user_ids}
              loading={loadingOrg}
              menuPlacement={"top"}
              closeMenuOnSelect={false}
              allowMultiple
            />
          </CallFilterDiv>
        </DropdownFiltersDiv>

        <CallMonitorScrollDiv id="call-monitor-div">
          <InfiniteScroll
            dataLength={liveUserStatus?.length || 0}
            //infinite scroll should also be possible with subscribeToMore
            // next={handleShowMore}
            next={() => {}}
            hasMore={!liveUserStatus}
            loader={<Loading />}
            scrollableTarget="call-monitor-div"
            style={{ paddingTop: 10, paddingBottom: 20, display: "flex", flexDirection: "row" }}
            height={140}
          >
            {!!liveUserStatus?.length &&
              liveUserStatus?.map((item) => {
                return callerCard(item);
              })}
          </InfiniteScroll>
        </CallMonitorScrollDiv>
      </CallMonitorDiv>
    </>
  );
};

interface ActiveCallToggleProps {
  modalShowing: boolean;
}

const ActiveCallToggle = styled.button<ActiveCallToggleProps>`
  z-index: 8;
  position: fixed;
  bottom: ${({ modalShowing }) => (modalShowing ? MODAL_HEIGHT : 0)}px;
  right: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 160px;
  height: 48px;
  margin-right: 24px;
  padding: 16px;

  color: ${theme.PRIMARY200};
  background-color: ${(props) => (props.modalShowing ? theme.PRIMARY700 : theme.PRIMARY500)};
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 8px 8px 0px 0px;

  transition: bottom ${TRANSITION_TIME}s, background-color ${TRANSITION_TIME}s;
  cursor: pointer;

  :hover {
    background-color: ${theme.PRIMARY600};
  }
  :focus {
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 1px;
  }
`;

interface CallMonitorDivProps {
  modalShowing: boolean;
}

const CallMonitorDiv = styled.div<CallMonitorDivProps>`
  transition: bottom ${TRANSITION_TIME}s;
  position: fixed;
  bottom: ${({ modalShowing }) => (modalShowing ? 0 : -1 * MODAL_HEIGHT)}px;
  right: 0;
  height: ${MODAL_HEIGHT}px;
  background-color: ${theme.WHITE_COLOR};
  width: calc(100vw - 72px);

  display: flex;
  flex-direction: column;

  border-top: ${theme.NEUTRAL200} 1px solid;
  padding: 15px 24px 15px 36px;
  z-index: 120;
`;

const DropdownFiltersDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CallFilterDiv = styled.div`
  margin-right: 12px;
`;
const CallerCard = styled(AppText)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  width: 93px;
  margin: 0px 12px 0px 12px;
`;

const CallerNameDiv = styled.div`
  font-weight: bold;
  margin: 8px 0px 8px 0px;
`;

const CallDurationDiv = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 2px;
  width: 93px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const CallDurationDivSub = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const CallDurationIconDiv = styled.div`
  padding-top: 2.5px;
`;

const IconNavLink = styled(NavLink)`
  margin-top: 16px;
`;

const AccountSettingsBorder = styled.div`
  height: 36px;
  width: 36px;
  max-height: 36px;
  max-width: 36px;
  padding: 2px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid ${theme.PRIMARY500};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CallMonitorScrollDiv = styled.div`
  overflow: hidden;
`;

const CallIconDiv = styled.div`
  position: relative;
  margin-top: 26px;
  min-width: 20px;
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 30px;
  width: 30px;
  height: 30px;
  margin-bottom: 1px;
  /* marginTop: 78,  */
  margin-left: 21px;
`;

interface MarginDivProps {
  modalShowing: boolean;
}

const MarginDiv = styled.div<MarginDivProps>`
  margin: 0;
  margin-bottom: ${({ modalShowing }) => (modalShowing ? MODAL_HEIGHT : 0)}px;
`;

export { CallMonitorComponent };
