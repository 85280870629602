import React, { useRef } from "react";
import Select, { StylesConfig, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
import { AppErrorText } from "..";
import { caret_down, trash, xIcon } from "../../../images/NewDesign";
import { theme } from "../../../utils/theme";
import { FlexDiv } from "../FlexDiv";
import { PhoenixCheckbox } from "./PhoenixCheckbox";
import { PhoenixIcon } from "./PhoenixIcon";
import { useClickOutside } from "../../../utils/hooks";
import { OptionItem } from "../../../types";

export interface NewAppMultiSelectProps {
  name: string;
  isMulti?: boolean;
  options: any[];
  // we need to update this to use the OnChangeType but this would break the existing code across the app
  // added to backlog
  onChange?: any;
  value?: any;
  error?: any;
  hideErrorMessage?: boolean;
  maxMenuHeight?: number;
  maxHeight?: number;
  maxValueWidth?: number;
  isDisabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  minHeight?: number;
  isClearable?: boolean;
  marginBottom?: boolean;
  itemBackgroundColor?: string;
  menuPortal?: boolean;
  menuPosition?: "fixed" | "absolute";
  menuShouldBlockScroll?: boolean;
  ghostSelect?: boolean;
  titleText?: string;
  titleTextSpacing?: number | string;
  titleFontWeight?: number;
  ghostSelectAppThemeOverride?: "Dark" | "Light";
  required?: boolean;
  multiValueUppercase?: boolean;
  lineHeight?: string;
  style?: any;
  dropdownArrowStyle?: any;
  width?: number;
  height?: number;
  components?: any;
  showCheckbox?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  creatableOptions?: boolean;
  showTrash?: boolean;
  onTrashClick?: (data: { label: string; value: unknown }) => void;
  noOptionsText?: string;
  optionsContainerWidth?: number;
  menuInset?: string;
  pointer?: boolean;
  menuBorder?: boolean;
  removeMenuPadding?: boolean;
  isOptionDisabled?: (option: any) => boolean;
}

export const PhoenixMultiSelect: React.FC<NewAppMultiSelectProps> = ({
  value,
  name,
  ghostSelectAppThemeOverride,
  options,
  isMulti,
  onChange,
  error,
  maxHeight,
  minHeight,
  maxValueWidth,
  maxMenuHeight,
  //isClearable added in as default true to prevent breaking changes
  isClearable = true,
  // the old version had this hard coded to 32px in order to not have breaking changes we are adding this as a prop
  marginBottom = true,
  // createableSelect added in as default false to prevent breaking changes
  creatableOptions = false,
  itemBackgroundColor,
  menuPortal,
  menuPosition,
  menuShouldBlockScroll,
  titleText,
  titleTextSpacing,
  titleFontWeight,
  required,
  hideErrorMessage,
  noOptionsText,
  ghostSelect = false,
  pointer = false,
  closeMenuOnSelect = true,
  ...props
}) => {
  const phoenixMultiSelectRef = useRef<any>(null);

  const customControlStyles: React.CSSProperties = {
    maxHeight: !!maxHeight ? `${maxHeight}px` : "300px",
    overflow: "auto",
    borderRadius: "4px",
    fontFamily: theme.PRIMARY_FONT,
    fontSize: "12px",
    letterSpacing: "0.2px",
    backgroundColor: theme.WHITE_COLOR,
    minHeight: !!minHeight ? `${minHeight}px` : "40px",
    borderColor: theme.NEUTRAL300,
    cursor: pointer ? "pointer" : "initial",
    ...props.style,
  };

  const customGhostControlStyles: React.CSSProperties = {
    // same as above
    ...customControlStyles,
    // ghost select styles
    backgroundColor: "transparent",
    minHeight: !!minHeight ? `${minHeight}px` : "40px",
    border: "none",
    color: ghostSelectAppThemeOverride === "Dark" ? theme.WHITE_COLOR : theme.BLACK_COLOR,
    ...props.style,
  };

  const selectStyle: StylesConfig<OptionItem, boolean> = {
    control: (provided, state) => {
      // provided has CSSObject type
      // state has ControlProps type

      // return type is CSSObject which means this line will throw error if uncommented
      // return false;

      return {
        ...provided,
        ...(ghostSelect ? customGhostControlStyles : customControlStyles),
        width: props.width && `${props.width}px`,
        height: props.height && `${props.height}px`,
      };
    },

    menuPortal: menuPortal ? (base) => ({ ...base, zIndex: 9999 }) : (base) => ({ ...base }),

    menu: (base, state) => ({
      ...base,
      marginTop: "4px",
      width: props.optionsContainerWidth ? `${props.optionsContainerWidth}px` : base.width,
      inset: props.menuInset ? props.menuInset : base.inset,
      border: props.menuBorder ? `1px solid ${theme.NEUTRAL300}` : base.border,
      height: "max-content",

      // target child div of menu
      "& > div": {
        padding: props.removeMenuPadding ? "0px" : "initial",
      },
    }),

    // option dropdown
    option: (base, state) => ({
      ...base,
      fontFamily: theme.PRIMARY_FONT,
      fontSize: "12px",
      letterSpacing: "0.2px",
      padding: "8px",
      height: "34px",
      color: state.isSelected
        ? ghostSelect
          ? theme.BLACK_COLOR
          : theme.WHITE_COLOR
        : state.isFocused
        ? theme.PRIMARY500
        : theme.BLACK_COLOR,
      backgroundColor: state.isSelected
        ? ghostSelect
          ? theme.WHITE_COLOR
          : theme.PRIMARY500
        : state.isFocused
        ? theme.PRIMARY100
        : theme.WHITE_COLOR,
      border: ghostSelect && state.isSelected ? `1px solid ${theme.PRIMARY500}` : "initial",
      borderRadius: "2px",
    }),

    // selected options
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: itemBackgroundColor ?? theme.PRIMARY500,
      color: theme.WHITE_COLOR,
      height: "24px",
      width: "fit-content",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0px 2px 0px 4px",
      textTransform: !!props.multiValueUppercase ? `uppercase` : "unset",
    }),

    indicatorsContainer: (base, state) => ({
      ...base,
      color: theme.WHITE_COLOR,
    }),

    indicatorSeparator: (base, state) => ({ display: "none" }),

    multiValueLabel: (base, state) => ({
      ...base,
      color: theme.WHITE_COLOR,
      fontWeight: 600,
      fontSize: !!props.multiValueUppercase ? "8px" : "10px",
    }),
    // holds the selected options
    valueContainer: (base, state) => ({
      ...base,
      padding: ghostSelect ? "0px 4px" : "2px 4px",
      maxWidth: !!maxValueWidth ? `${maxValueWidth}px` : "unset",
      lineHeight: props.lineHeight ?? "unset",
    }),
    // dropdown indicator
    dropdownIndicator: (base, state) => ({
      ...base,
      color: theme.PRIMARY500,
      maxHeight: maxHeight ?? "unset",
      padding: ghostSelect ? "0px" : base.padding,
      ...props.dropdownArrowStyle,
    }),
    container: (base, state) => ({
      ...base,
      // This might have been used to fix something, but it was also causing the select to render above the dropdown options of other multi selects
      // zIndex: 5,
      // hover color
    }),

    // single value
    singleValue: (base, state) => ({
      ...base,
      color: ghostSelectAppThemeOverride === "Dark" ? theme.WHITE_COLOR : theme.BLACK_COLOR,
      overflow: "visible",
    }),
  };

  return (
    <MultiSelectDiv
      marginbottom={marginBottom}
      ghostSelect={ghostSelect}
      ghostSelectAppThemeOverride={ghostSelectAppThemeOverride}
    >
      {!!titleText && (
        <InputTitle titleTextSpacing={titleTextSpacing} fontWeight={titleFontWeight}>
          {titleText}
          {required && <span style={{ color: theme.DANGER600 }}>*</span>}
        </InputTitle>
      )}
      {
        // CreatableSelect from the react-select library
        creatableOptions ? (
          <CreatableSelect
            ref={phoenixMultiSelectRef}
            isMulti={isMulti ?? true}
            style={{
              borderRadius: "2.9px",
            }}
            styles={selectStyle}
            // styles={props.customStyles}
            options={options}
            value={value}
            onChange={onChange}
            components={{ DropdownIndicator, MultiValueRemove, Option }}
            isClearable={isClearable}
            isDisabled={props.isDisabled}
            maxMenuHeight={maxMenuHeight ? maxMenuHeight : undefined}
            menuPortalTarget={menuPortal ? document.body : null}
            selectProps={{ ...props }}
            noOptionsMessage={!!noOptionsText ? () => noOptionsText : undefined}
            {...props}
          />
        ) : (
          // regular select from react-select library
          <Select
            ref={phoenixMultiSelectRef}
            isMulti={isMulti ?? true}
            style={{
              borderRadius: "2.9px",
            }}
            styles={selectStyle}
            // styles={props.customStyles}
            options={options}
            value={value}
            onChange={(e: OptionItem) => {
              if (onChange) {
                onChange(e);
              }

              // unfocus select on change of value
              if (phoenixMultiSelectRef.current && closeMenuOnSelect) {
                phoenixMultiSelectRef.current.blur();
              }
            }}
            components={{ DropdownIndicator, MultiValueRemove, Option }}
            isClearable={isClearable}
            isDisabled={props.isDisabled}
            maxMenuHeight={maxMenuHeight ? maxMenuHeight : undefined}
            menuPortalTarget={menuPortal ? document.body : null}
            menuPosition={menuPosition ? menuPosition : undefined}
            menuShouldBlockScroll={menuShouldBlockScroll}
            selectProps={{ ...props }}
            noOptionsMessage={!!noOptionsText ? () => noOptionsText : undefined}
            closeMenuOnSelect={closeMenuOnSelect}
            {...props}
          />
        )
      }
      {!!error && !hideErrorMessage && <AppErrorText>{error}</AppErrorText>}
    </MultiSelectDiv>
  );
};

const MultiSelectDiv = styled.div<{
  marginbottom: boolean;
  ghostSelect?: boolean;
  ghostSelectAppThemeOverride?: "Dark" | "Light";
}>`
  margin-bottom: ${(props) => (props.marginbottom ? "32px" : "0px")};

  :focus {
    // todo fix this so that the double border works.
    outline: 1px solid ${theme.PRIMARY500};
    outline-offset: 10px;
    border: 1px solid ${theme.NEUTRAL400};
  }
  :hover {
    background-color: ${(props) =>
      props.ghostSelect
        ? props.ghostSelectAppThemeOverride === "Dark"
          ? theme.PRIMARY800
          : theme.PRIMARY200
        : "inherit"};

    /* * {
      color: ${(props) => (props.ghostSelect ? theme.BLACK_COLOR : "inherit")} !important;
    } */
    border-radius: 4px;
  }
`;

const InputTitle = styled.p<{
  titleTextSpacing?: number | string;
  fontWeight?: number;
}>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-bottom: ${(props) => (props.titleTextSpacing ? `${props.titleTextSpacing}px` : "3px")};
  text-align: "left";
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
`;

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <PhoenixIcon svg={caret_down} color={theme.PRIMARY500} hoverColor={theme.PRIMARY600} size={16} fillIcon />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <PhoenixIcon svg={xIcon} color={theme.WHITE_COLOR} hoverColor={theme.DANGER600} size={12} fillIcon />
    </components.MultiValueRemove>
  );
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <OptionWrapper align="center">
        {props.selectProps.showCheckbox && !props?.data?.disabled && <PhoenixCheckbox checked={props.isSelected} />}
        {props.children}

        {props.selectProps.showTrash && !props.data.hide_trash && (
          <PhoenixIcon
            svg={trash}
            size={16}
            pointer
            color={props.isSelected ? theme.PRIMARY200 : theme.PRIMARY500}
            hoverColor={props.isSelected ? theme.PRIMARY200 : theme.PRIMARY500}
            style={{ marginLeft: "auto" }}
            onClick={() => {
              !!props.selectProps.onTrashClick && props.selectProps.onTrashClick(props.data);
            }}
          />
        )}
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled(FlexDiv)`
  transition: color 0.15s ease-in-out;
`;
