import * as React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { AppButton, AppText, NewAppButton } from "../Components/UI";
import { Modal } from "../Components/modal";
import micDeniedIcon from "../images/denied_mic.png";
import { theme } from "../utils/theme";

const DevicePermission: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [deniedVisible, setDeniedVisible] = useState(false);

  useEffect(() => {
    const checkPermissions = async () => {
      //@ts-ignore
      const perms = await navigator.permissions.query({ name: "microphone" });
      // If user has not granted permission to access the mic, prompt the user to do so.
      if (perms.state === "denied") {
        setDeniedVisible(true);
      }
      if (perms.state === "prompt") {
        setVisible(true);
      }
    };
    checkPermissions();
  }, []);

  const promptForPermission = async () => {
    const nativePrompt = async () => {
      // Prompt user for mic access
      const perms = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("perms: ", perms);
    };
    setVisible(false);
    nativePrompt();
  };

  const close = () => setVisible(false);

  const reloadPage = () => window.location.reload();

  return (
    <>
      <Modal open={visible} onClose={() => setVisible(false)}>
        <Container>
          <ThisTitleText>Microphone Access</ThisTitleText>
          <DescriptionText>
            In order to use OPSIQ properly, you need to grant us permission to use the mic.
          </DescriptionText>
          <ButtonDiv>
            <NewAppButton size={"lg"} variant={"confirm"} onClick={promptForPermission}>
              Grant
            </NewAppButton>
            <NewAppButton size={"lg"} variant={"attention"} onClick={close}>
              Deny
            </NewAppButton>
          </ButtonDiv>
        </Container>
      </Modal>
      <Modal open={deniedVisible} onClose={() => setDeniedVisible(false)}>
        <Container>
          <ThisTitleText>Microphone Access Blocked</ThisTitleText>
          <DescriptionText>
            Your browser has blocked access to your microphone. In order to fix this, click the mic icon in the browser
            URL bar, then select "Always allow https://opsiq.ai to access your microphone" and click "Done". Finally, In
            order for these changes to take affect, you will need to reload your browser.
          </DescriptionText>
          <img src={micDeniedIcon} style={{ marginTop: 15 }} />
          <ButtonDiv>
            <NewAppButton size={"lg"} variant={"secondary"} onClick={reloadPage}>
              Reload Page
            </NewAppButton>
          </ButtonDiv>
        </Container>
      </Modal>
    </>
  );
};

interface ModalOnProps {
  visible?: boolean;
}

const PopupContainerDiv = styled.div<ModalOnProps>`
  position: fixed;
  display: ${(props) => (props.visible ? "block" : "none")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 12;
`;

const Container = styled.div`
  padding: 40px 20px;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 104px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
`;

const ThisTitleText = styled(AppText)`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.BLACK_COLOR};
  text-align: center;
  margin-bottom: 15px;
`;

const ThisText = styled(AppText)`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.BLACK_COLOR};
  text-align: center;
`;

const DescriptionText = styled(AppText)`
  font-size: 15px;
  font-weight: 400;
  color: ${theme.BLACK_COLOR};
  text-align: center;
`;

const AcceptButton = styled(AppButton)`
  height: 45px;
  width: 240px;
  background-color: ${theme.PRIMARY500};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const RejectButton = styled(AppButton)`
  height: 45px;
  width: 240px;
  background-color: transparent;
  border: 1px solid ${theme.BLACK_COLOR};
  color: ${theme.ATTENTION700};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReloadButton = styled(AppButton)`
  height: 45px;
  width: 240px;
  background-color: transparent;
  border: 1px solid ${theme.BLACK_COLOR};
  color: ${theme.ATTENTION700};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { DevicePermission };
