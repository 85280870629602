import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, NewAppSelect, SkeletonBlock, StatCard } from "../../../UI";
import { NewAppButton } from "../../../UI/NewAppButton";

import { AiOutlineLeft } from "react-icons/ai";
import { HiPlus } from "react-icons/hi";
import { NewAppIconButton } from "../../../UI";

import InfiniteScroll from "react-infinite-scroll-component";
import { SHOW_LEAD_SOURCE_TO_USER } from "../../../../apollo/query";
import {
  addNewUser,
  arrowsLeftAndRight,
  arrowsUpAndDown,
  associated_white,
  check_thick,
  chevron_down,
  chevron_up,
  contact_info_add,
  info,
  merge_primary,
  rotate,
  sequences,
  user_white,
  xIcon,
} from "../../../../images/NewDesign";
import {
  EMAIL_UNSUBSCRIBED_REP_TOOLTIP,
  SMS_UNSUBSCRIBED_REP_TOOLTIP,
  formatBusinessName,
} from "../../../../utils/format";
import { StyledTooltip } from "../../../Dumb";
import { EmailCommunicationToggle, SMSCommunicationToggle } from "../../../Pages/LeadDetail";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { loggedInUser } from "../../../../apollo/cache";
import { CallContext, LeadCardContext, ModalContext } from "../../../../context";
import { googleLogo } from "../../../../images";
import { CurrentLeadType, NextIntent, SelectedContactType, VisibleCustomFieldsType } from "../../../../types";
import {
  CommunicationStates,
  convertDate,
  formatDate,
  formatPhoneNumber,
  formatUSDRaw,
} from "../../../../utils/format";
import {
  getLocalStorage,
  renderLeadIntentEventTypeValues,
  returnLeadAlternatePhoneNumbers,
  returnLeadScreensharingNumber,
} from "../../../../utils/misc";
import { appToast, errorToast } from "../../../../utils/toast";
import { FetchLeadHistoryExpectedResponse, LeadHistoryFetchItem } from "../../../Pages";
import { AppErrorText, AppTabDiv } from "../../../UI";
import { SaveToFolderModal } from "../../../modal";
import { JoinMeLink } from "../JoinMeLink";

import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FiEdit } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import { CORE_LEAD_CARD_DATA } from "../../../../apollo/fragments";
import { MRR_LABEL } from "../../../../apollo/query";
import { UserStatusContext } from "../../../../context";
import { MixpanelActions } from "../../../../services/mixpanel";
import { OpacityDiv } from "../../../Dumb";
import { PhoenixStyledTooltip } from "../../../Dumb/PhoenixStyledTooltip";
import { FlexDiv } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";
import {
  CommunicationsActivityLeadCard,
  CustomSequenceActionHeader,
  DefaultSequenceActionHeader,
  FavoritedStar,
  LeadCardCTAButton,
  SearchMenu,
  SwapPrimaryNumberDiv,
} from "../LeadCardV2";
import { LeadCardNotes } from "./LeadCardNotes";

import { functions, isEqual, omit } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { CustomObjectSummary } from "../../../../__generated__/graphql";
import { returnLocalTime } from "../../../../static";
import { ParentType } from "../../../../types";
import { useTabFocus } from "../../../../utils/hooks";
import {
  LEAD_OWNERSHIP_STATUS,
  makeNextActionDisabledCheck,
  returnLeadAlternateEmails,
  returnLeadGroupScreensharingNumber,
  returnLeadPrimaryEmail,
} from "../../../../utils/misc";
import { DeleteLeadCustomObjectRelationModal } from "../../../modal/DeleteLeadCustomRelationModal";
import {
  CustomFieldLinkCheck,
  LeadCardContactButton,
  LeadCardLeadHistoryItem,
  SalesforceRelatedRecordsLeadCardTab,
} from "../LeadCardV2";
import { RelatedRecordsLeadCardTab } from "./RelatedRecordsLeadCard";
import { LeadCardTopInfo } from "./LeadCardTopInfo";
import { requiredDemoFields } from "../../CallSegments/ConfirmDemoRequiredFields";

const statusColor = (status: string) => {
  switch (status) {
    case "Accepted":
      return theme.SUCCESS500;
    case "Pending":
      return theme.TERTIARY500;
    case "Declined":
      return theme.ATTENTION700;
    default:
      return theme.BLACK_COLOR;
  }
};

interface AlternateContactsType {
  id: string;
  title: string;
  channel: "Phone" | "Email" | "Conference";
  type: string;
  value: string;
  contact_label: string;
  mapping_order: number;
}

interface ComputedLeadData {
  id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  business_name: string;
  industry: string;
  sub_industry: string;
  lead_source: string;
  city: string;
  state: string;
  country: string;
  timezone_by_state: string;
  lead_value: number;
  primary_phone_number: string;
  primary_email: string;
  conference_number: string;
  favorited: boolean;
  lead_ownership_status: LEAD_OWNERSHIP_STATUS;
  rep_id: string;
  current_close_date: string;
  computed_mrr: number;
  is_in_active_queue: string;
  channel: string;
  next_intent_scheduled_or_unscheduled: {
    id: string;
    event_type_label: string;
    dial_num_in_sales_cycle: number;
    anytime_before: string;
    anytime_after: string;
    anytime_day: string;
    anytime_day_upperbound: string;
    anytime_tz: string;
    general_time_start_date: string;
    general_time_end_date: string;
    schedule_item: {
      id: string;
      start_time: string;
      end_time: string;
    };
    replacement_lead_intent_id: string;
    current_sequence_step: string;
  };
  next_scheduled_event: {
    id: string;
    type: string;
  };
  next_scheduled_event_time: string;
  pipeline_state_label: string;
  sequence_step: {
    actions?: {
      id: string;
      task: string;
      custom_task_note: string;
      scheduled_event_behavior: string;
    }[];
  };
}

const FETCH_LEAD = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      ...CoreLeadCardData
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_phone_number_title
      primary_phone_number_type
      conference_number
      primary_email
      primary_email_title
      primary_email_type
      business_name
      status
      title
      content
      country
      current_close_date
      computed_mrr
      address
      zip
      current_lead_type
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
      alternate_contacts {
        id
        title
        channel
        type
        value
        mapping_order
        contact_label
      }
      favorited
      associate_parent_id
      sms_sub_status
      email_sub_status
      next_scheduled_event {
        id
        type
      }
      next_scheduled_event_time
      pipeline_state_label
      is_in_active_queue
      organization {
        allow_remove_step_action
        allow_snooze_step_action
      }
      sequence_id
      sequence_step {
        actions {
          id
          task
          custom_task_note
          scheduled_event_behavior
        }
      }
    }
  }
`;

const ADD_LEAD_NOTE = gql`
  mutation addLeadNote($lead_id: String!, $note: String!) {
    addLeadNote(lead_id: $lead_id, note: $note) {
      id
      notes
      created_at
    }
  }
`;

const REMOVE_FROM_CUSTOM_QUEUE = gql`
  mutation removeFromCustomQueue($lead_id: String!) {
    removeFromCustomQueue(lead_id: $lead_id) {
      user_id
      lead_id
    }
  }
`;

const FETCH_ASSOCIATED_LEADS = gql`
  ${CORE_LEAD_CARD_DATA}
  query FetchAssociateContact($primaryLeadId: String!) {
    fetchAssociateContact(primary_lead_id: $primaryLeadId) {
      id
      ...CoreLeadCardData
      first_name
      last_name
      full_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_phone_number_title
      primary_phone_number_type
      conference_number
      primary_email
      primary_email_title
      primary_email_type
      business_name
      rep_id

      channel
      status
      title
      content

      country

      current_close_date
      computed_mrr
      address
      zip
      current_lead_type
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
      alternate_contacts {
        id
        title
        channel
        type
        value
        mapping_order
        contact_label
      }

      call_notes {
        id
        notes
        created_at
        html
      }
      favorited
      next_intent_scheduled_or_unscheduled {
        id
      }
    }
  }
`;

export const CUSTOM_TASK_ACK = gql`
  mutation customTaskAck($customTaskAckInput: customTaskAckInput!) {
    customTaskAck(customTaskAckInput: $customTaskAckInput)
  }
`;

interface FetchLeadCardHistoryCountExpectedResponse {
  fetchLeadHistoryCount: number;
}

const FETCH_LEAD_CARD_LEAD_HISTORY_COUNT = gql`
  query fetchLeadCardHistoryCount($lead_id: String!, $event_filter: HistoryEventTypeOption, $show_all_lead: Boolean) {
    fetchLeadHistoryCount(lead_id: $lead_id, event_filter: $event_filter, show_all_lead: $show_all_lead)
  }
`;

const FETCH_LEAD_CARD_LEAD_HISTORY = gql`
  query fetchLeadHistory(
    $lead_id: String!
    $event_filter: HistoryEventTypeOption
    $show_all_lead: Boolean
    $take: Int
    $skip: Int
  ) {
    fetchLeadHistory(
      lead_id: $lead_id
      event_filter: $event_filter
      show_all_lead: $show_all_lead
      take: $take
      skip: $skip
    ) {
      id
      title
      type
      conference_id
      lead_id
      twilio_action
      computed_title
      intent_type
      call_completed_with
      created_at
      channel
      computed_type
      customer_number
      communication_type
      external_transfer_number
      lead {
        full_name
      }
      local_number {
        number
      }
      related_disposition {
        id
        label
        computed_call_history_theme {
          completed_with_theme {
            label
            bg_color
            font_color
          }
          call_result_theme {
            label
            bg_color
            font_color
          }
        }
      }
      user {
        id
        first_name
        last_name
        profile_image
        phone_number
      }
      conference {
        id
        duration
        recording_url_computed
      }
      email_item {
        id
        subject
        body
        html
        from_email
        to_email
        reply_to_email
        sender_label
      }
      SMSItem {
        id
        text
        sender_label
        lead_number_used
      }
      notes
      associate_child {
        id
        first_name
        last_name
        full_name
        associate_parent_id
      }
    }
  }
`;
const FETCH_LEAD_CARD_LEAD_HISTORY_NOTES = gql`
  query fetchLeadHistory($lead_id: String!, $event_filter: HistoryEventTypeOption, $show_all_lead: Boolean) {
    fetchLeadHistory(lead_id: $lead_id, event_filter: $event_filter, show_all_lead: $show_all_lead) {
      id
      created_at
      notes
      html
      user {
        id
        full_name
        phone_number
      }
    }
  }
`;

interface AddOrEditContactModalProps {
  lead_id: string;
  country_code: string;
  country: string;
  card_type: string;
  title?: string;
  conference_number?: string;
  has_conference_number: boolean;
}

interface AppCallbackCardProps {
  intent?: NextIntent; //should technically be LeadIntent object type from BE
  closeOtherModals?: any;
  showLogNonCallActivity?: boolean;
  leadData?: CurrentLeadType;
  scheduledEventIsWithAssociatedLead?: boolean;
  leadIdOverride?: string;
  isInMyScheduleComponent?: boolean;
  scheduledEvent?: any;
  expandOnRender?: boolean;
  parentType?: ParentType | undefined;
  hideActionButtons?: boolean;
  hideExpandButton?: boolean;
  showEventTypeCard?: boolean;
  border?: boolean;
  borderRadius?: string;
  /**
   * only relevant for nextAction parentType
   */
  dialAsNumber?: string;
  isNextDial?: boolean;
  isSelfSourcedLead?: boolean;
  blocksMakeNextAction?: boolean;
  handleSelfSourcedLeadPriorityClick?: () => void;
  handleSelfSourcedLeadNextDialClick?: () => void;
  customObjectSummary?: CustomObjectSummary[];
  maxHeight?: string;
}

interface AppCallbackCardWithIntent {
  intent: any;
  leadData?: never;
  leadIdOverride?: never;
}

interface AppCallbackCardWithLeadData {
  intent?: never;
  leadData: CurrentLeadType;
}

interface RepAndResultIcon {
  background?: string;
  color?: string;
}

interface AppCallbackCardWithLeadID {
  intent?: never;
  leadData: never;
}

interface ParamTypes {
  row_id?: string;
}

type LeadCardProps = AppCallbackCardProps &
  (AppCallbackCardWithIntent | AppCallbackCardWithLeadData | AppCallbackCardWithLeadID);

type contactTabs = "phone-and-email" | "screen-sharing" | "associated-contacts";

const takeNumber = 6;

const LeadCardV2 = ({
  leadData,
  scheduledEventIsWithAssociatedLead = false,
  intent,
  closeOtherModals,
  showLogNonCallActivity = false,
  isInMyScheduleComponent = false,
  scheduledEvent,
  expandOnRender = false,
  parentType,
  hideActionButtons = false,
  hideExpandButton = false,
  showEventTypeCard = false,
  dialAsNumber,
  leadIdOverride,
  isNextDial,
  isSelfSourcedLead = false,
  blocksMakeNextAction,
  handleSelfSourcedLeadPriorityClick = () => {},
  handleSelfSourcedLeadNextDialClick = () => {},
  customObjectSummary,
  border,
  borderRadius,
  maxHeight,
}: LeadCardProps & React.HTMLProps<HTMLDivElement>) => {
  const { updateUserToIdle } = useContext(UserStatusContext);

  const {
    screenSharingPhoneNumber,
    leadIntegrationRelatedRecords,
    customObject,
    nonCallActivitySales,
    allowExternalTransfers,
  } = useFlags();

  // all expanded states

  const { data: showLeadSourceToUser } = useQuery(SHOW_LEAD_SOURCE_TO_USER);

  const [expandedState, setExpandedState] = useState(isInMyScheduleComponent || expandOnRender);

  const [expandedAssociateList, setExpandedAssociateList] = useState([] as string[]);

  const [historyItemList, setHistoryItemList] = useState<LeadHistoryFetchItem[]>([] as LeadHistoryFetchItem[]);

  const [tabRefresh, setTabRefresh] = useState<boolean>(false);
  const isLeadDetailPage = useHistory().location.pathname.includes("lead-detail");
  const { row_id } = useParams<ParamTypes>();

  const ON_DIAL_WARNING_TOOLTIP =
    "To dial, select a call result and click the “Would you like to try an alternate contact” checkbox before confirming it.";

  const expandAssociateList = useCallback(
    (id: string) => {
      // if the id is already in the list, remove it
      if (expandedAssociateList?.includes(id)) {
        setExpandedAssociateList(expandedAssociateList.filter((item) => item !== id));
      } else {
        setExpandedAssociateList([...expandedAssociateList, id]);
      }
    },
    [expandedAssociateList],
  );

  const {
    selectedTab,
    customDials,
    setCustomDials,
    leadCardHistoryReset,
    setLeadCardHistoryReset,
    setAbleToOverideNextDial,
    setLeadInNextDial,
    leadInNextDial,
    ableToOverideNextDial,
    upcomingDials,
    setUpcomingDials,
  } = useContext(LeadCardContext);

  const {
    goToCall,
    callState,
    logNonCallActivity,
    nextActionOverride,
    setNextActionOverride,
    resetNextDial,
    suggestedActionData,
    callLeadId,
    setRepLeadSearch,
  } = useContext(CallContext);

  const {
    showNonCallActivityModal,
    setShowNonCallActivityModal,
    setLogNonCallActivityModalData,
    setSkipLeadModal,
    setReleaseLeadModal,
    closeCustomFieldsModal,
    openEmailEditorModal,
    showEmailEditorModal,

    openSMSChat,
    showSMSChat,
    setSelfSource,
    setCustomFieldsData,
    openCustomFieldsModal,

    showAddAssociateModal,
    setShowAddAssociateModal,
    setShowDisassociateLeadModal,
    primaryLeadToAddAssociateTo,
    setSwapPrimaryLeadModalData,
    setShowSwapPrimaryLeadModal,
    setShowAddOrEditContactModal,
    setSelectedContactV2,
    setSkipOrReleaseLeadModalData,
  } = useContext(ModalContext);

  // helper functions

  const selectContactToEdit = useCallback(
    (selectedContact: SelectedContactType) => {
      setSelectedContactV2(selectedContact);
      setShowAddOrEditContactModal(true);
    },
    [setSelectedContactV2, setShowAddOrEditContactModal],
  );

  const selectContactToAdd = useCallback(
    ({
      lead_id,
      country_code,
      card_type,
      title,
      conference_number,
      has_conference_number,
      country,
    }: AddOrEditContactModalProps) => {
      setSelectedContactV2({
        lead_id,
        country_code,
        card_type,
        title,
        conference_number,
        has_conference_number,
        country,
      });
      setShowAddOrEditContactModal(true);
    },
    [setShowAddOrEditContactModal, setSelectedContactV2],
  );

  const closeAllModals = () => {
    if (!!closeOtherModals) {
      closeOtherModals();
    }
    closeCustomFieldsModal();
  };

  const [currentConferenceID, setCurrentConferenceID] = useState("");

  const [currentSelectedTab, setCurrentSelectedTab] = useState(`${selectedTab}`);

  const [showSaveToFolderModal, setShowSaveToFolderModal] = useState(false);

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [primaryLead, setPrimaryLead] = useState({
    label: "No Name",
    value: "id",
  });

  const [selectedHistoryContactFilter, setSelectedHistoryContactFilter] = useState("All" as string);
  const [leadHistoryFilter, setLeadHistoryFilter] = useState("All" as string);

  const [hasMoreHistory, setHasMoreHistory] = useState(true);

  const [skip, setSkip] = useState(0);

  const isInternational = useMemo(() => loggedInUser().organization?.international, [loggedInUser]);

  const [contactTab, setContactTab] = useState<contactTabs>("phone-and-email");

  const isSequenceAction = useMemo(() => {
    return intent?.type === "SequenceAction";
  }, [intent]);

  const [fetchLeadDataQuery, { data: dataLead, loading: loadingLead, error: errorLead }] = useLazyQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    variables: { id: leadIdOverride || intent?.lead?.id || leadData?.id },
    onCompleted({ fetchLead }) {
      if (!fetchLead) {
        return;
      }
      setPrimaryLead({
        value: fetchLead.id,
        label: `${fetchLead.full_name ?? `${fetchLead.last_name}, ${fetchLead.first_name}`}`,
      });
    },
  });

  const fetchLeadData = () => {
    const leadID = leadIdOverride || intent?.lead?.id || leadData?.id;
    if (leadID) {
      fetchLeadDataQuery({ variables: { id: leadID } });
    }
  };

  // this means that the primary lead id is not a primary. so we want to limit the actions that can be taken and render the card differently
  // this will show up if you call the associated number on the primary lead card and in other situations
  const isAssociatedLead = !!dataLead?.fetchLead?.associate_parent_id;

  const scheduledEventLeadId = intent?.lead?.id;
  const scheduledEventAssociateLeadIntentId = intent?.is_scheduled_item ? intent.id : undefined;

  const [isOnCallLocal, setIsOnCallLocal] = useState(getLocalStorage("userIsOnCallLocal", false));
  const [localShowIncoming, setLocalShowIncoming] = useState(getLocalStorage("showIncoming", false));

  useEffect(() => {
    const handleStorageChange = () => {
      const newIsOnCallLocal = getLocalStorage("userIsOnCallLocal");
      setIsOnCallLocal(newIsOnCallLocal);
      const newShowIncoming = getLocalStorage("showIncoming");
      setLocalShowIncoming(newShowIncoming);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  //this is data for the nonExpand portion of the card. Everything after expanding should use the query for new data
  const computedLeadData: ComputedLeadData = useMemo(() => {
    return {
      id: dataLead?.fetchLead?.id || intent?.lead?.id || leadData?.id,
      full_name: dataLead?.fetchLead?.full_name || intent?.lead?.full_name || leadData?.full_name,
      first_name: dataLead?.fetchLead?.first_name || intent?.lead?.first_name || leadData?.first_name,
      last_name: dataLead?.fetchLead?.last_name || intent?.lead?.last_name || leadData?.last_name,
      business_name: dataLead?.fetchLead?.business_name || intent?.lead?.business_name || leadData?.business_name,
      industry: dataLead?.fetchLead?.industry || intent?.lead?.industry || leadData?.industry,
      sub_industry: dataLead?.fetchLead?.sub_industry || intent?.lead?.sub_industry || leadData?.sub_industry,
      lead_source: dataLead?.fetchLead?.lead_source || intent?.lead?.lead_source || leadData?.lead_source,
      city: dataLead?.fetchLead?.city || intent?.lead?.city || leadData?.city,
      state: dataLead?.fetchLead?.state || intent?.lead?.state || leadData?.state,
      country: dataLead?.fetchLead?.country || intent?.lead?.country || leadData?.country,
      timezone_by_state:
        dataLead?.fetchLead?.timezone_by_state || intent?.lead?.timezone_by_state || leadData?.timezone_by_state,
      lead_value: dataLead?.fetchLead?.lead_value || intent?.lead?.lead_value || leadData?.lead_value,
      primary_phone_number:
        dataLead?.fetchLead?.primary_phone_number ||
        intent?.lead?.primary_phone_number ||
        leadData?.primary_phone_number,
      primary_email: dataLead?.fetchLead?.primary_email || intent?.lead?.primary_email || leadData?.primary_email,
      conference_number: dataLead?.fetchLead?.conference_number || intent?.lead?.conference_number,
      // leadData?.conference_phone_number,
      favorited: dataLead?.fetchLead?.favorited || intent?.lead?.favorited || leadData?.favorited,
      lead_ownership_status:
        dataLead?.fetchLead?.lead_ownership_status ||
        intent?.lead?.lead_ownership_status ||
        leadData?.lead_ownership_status,
      rep_id: dataLead?.fetchLead?.rep_id || intent?.lead?.rep_id || leadData?.rep_id,
      current_close_date:
        dataLead?.fetchLead?.current_close_date || intent?.lead?.current_close_date || leadData?.current_close_date,
      computed_mrr: dataLead?.fetchLead?.computed_mrr || intent?.lead?.computed_mrr || leadData?.computed_mrr,
      is_in_active_queue: dataLead?.fetchLead?.is_in_active_queue || "",
      channel: dataLead?.fetchLead?.channel || intent?.lead?.channel || leadData?.channel,
      next_intent_scheduled_or_unscheduled: {
        id:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.id ||
          intent?.id ||
          leadData?.next_intent_scheduled_or_unscheduled?.id,
        event_type_label:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.event_type_label ||
          intent?.event_type_label ||
          leadData?.next_intent_scheduled_or_unscheduled?.event_type_label,
        current_sequence_step:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.current_sequence_step ||
          intent?.current_sequence_step ||
          leadData?.next_intent_scheduled_or_unscheduled?.current_sequence_step,
        dial_num_in_sales_cycle:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.dial_num_in_sales_cycle ||
          intent?.dial_num_in_sales_cycle ||
          leadData?.next_intent_scheduled_or_unscheduled?.dial_num_in_sales_cycle,
        anytime_before:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.anytime_before ||
          intent?.anytime_before ||
          leadData?.next_intent_scheduled_or_unscheduled?.anytime_before,
        anytime_after:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.anytime_after ||
          intent?.anytime_after ||
          leadData?.next_intent_scheduled_or_unscheduled?.anytime_after,
        anytime_day:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.anytime_day ||
          intent?.anytime_day ||
          leadData?.next_intent_scheduled_or_unscheduled?.anytime_day,
        anytime_day_upperbound:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.anytime_day_upperbound ||
          intent?.anytime_day_upperbound ||
          leadData?.next_intent_scheduled_or_unscheduled?.anytime_day_upperbound,
        anytime_tz:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.anytime_tz ||
          intent?.anytime_tz ||
          leadData?.next_intent_scheduled_or_unscheduled?.anytime_tz,
        general_time_start_date:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.general_time_start_date ||
          intent?.general_time_start_date ||
          leadData?.next_intent_scheduled_or_unscheduled?.general_time_start_date,
        general_time_end_date:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.general_time_end_date ||
          intent?.general_time_end_date ||
          leadData?.next_intent_scheduled_or_unscheduled?.general_time_end_date,
        schedule_item: {
          id:
            dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.schedule_item?.id ||
            intent?.schedule_item?.id ||
            leadData?.next_intent_scheduled_or_unscheduled?.schedule_item?.id,
          start_time:
            dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.schedule_item?.start_time ||
            intent?.schedule_item?.start_time ||
            leadData?.next_intent_scheduled_or_unscheduled?.schedule_item?.start_time,
          end_time:
            dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.schedule_item?.end_time ||
            intent?.schedule_item?.end_time ||
            leadData?.next_intent_scheduled_or_unscheduled?.schedule_item?.end_time,
        },
        replacement_lead_intent_id:
          dataLead?.fetchLead?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id ||
          leadData?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id,
      },
      next_scheduled_event: {
        id: dataLead?.fetchLead?.next_scheduled_event?.id,
        type: dataLead?.fetchLead?.next_scheduled_event?.type,
      },
      next_scheduled_event_time: dataLead?.fetchLead?.next_scheduled_event_time,
      pipeline_state_label: dataLead?.fetchLead?.pipeline_state_label,
      sequence_step: dataLead?.fetchLead?.sequence_step || intent?.sequence_step || leadData?.sequence_step,
    };
  }, [dataLead, intent, leadData, leadIdOverride]);

  const { data: mrrLabel, loading: mrrLoading, error: mrrError } = useQuery(MRR_LABEL);

  const isOwnedByRep = computedLeadData?.rep_id === loggedInUser()?.id;
  const canDialCustomers = loggedInUser()?.organization?.enable_rep_to_connect_customers;

  const inCallableStatus = [
    LEAD_OWNERSHIP_STATUS.Owned,
    LEAD_OWNERSHIP_STATUS.Assigned,
    ...(canDialCustomers ? [LEAD_OWNERSHIP_STATUS.Customer] : []),
  ].includes(computedLeadData?.lead_ownership_status);

  const activeCall = isOnCallLocal || localShowIncoming;

  const leadUnsubscribedFromEmail =
    dataLead?.fetchLead?.email_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    dataLead?.fetchLead?.email_sub_status === CommunicationStates.LeadUnsubscribed;

  const leadUnsubscribedFromSMS =
    dataLead?.fetchLead?.sms_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    dataLead?.fetchLead?.sms_sub_status === CommunicationStates.LeadUnsubscribed;

  const goToCallObjectForPrimary = {
    // phoneNumber: set in the goToCall()
    lead_id: computedLeadData?.id,
    intentId: computedLeadData?.next_intent_scheduled_or_unscheduled?.id
      ? computedLeadData?.next_intent_scheduled_or_unscheduled?.id
      : undefined,
    intentReplacementId: computedLeadData?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id
      ? computedLeadData?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id
      : undefined,
    dialAsNumber: isNextDial ? dialAsNumber : undefined,
  };

  useEffect(() => {
    console.log("LeadCardV2Data: ", {
      computedLeadData: computedLeadData,
      isAssociatedLead: isAssociatedLead,
      isOwnedByRep: isOwnedByRep,
      isNextDial: isNextDial,
    });
  }, [computedLeadData, isAssociatedLead, isOwnedByRep, isNextDial]);

  const [
    fetchLeadHistoryCount,
    { data: historyCountData, loading: historyCountLoading, error: historyCountError },
  ] = useLazyQuery<FetchLeadCardHistoryCountExpectedResponse>(FETCH_LEAD_CARD_LEAD_HISTORY_COUNT, {
    fetchPolicy: "no-cache",
    variables: {
      lead_id: selectedHistoryContactFilter === "All" ? computedLeadData?.id : selectedHistoryContactFilter,
      event_filter: leadHistoryFilter,
      show_all_lead: selectedHistoryContactFilter === "All" ? true : false,
    },
  });

  const [
    fetchLeadHistoryNotes,
    { data: historyNotesData, loading: historyNotesLoading, error: historyNotesError },
  ] = useLazyQuery<any>(FETCH_LEAD_CARD_LEAD_HISTORY_NOTES, {
    fetchPolicy: "no-cache",
    variables: {
      lead_id: selectedHistoryContactFilter === "All" ? computedLeadData?.id : selectedHistoryContactFilter,
      event_filter: "Note",
      show_all_lead: selectedHistoryContactFilter === "All" ? true : false,
    },
  });

  const [
    fetchLeadHistoryData,
    { data: historyData, loading: historyLoading, error: historyError },
  ] = useLazyQuery<FetchLeadHistoryExpectedResponse>(FETCH_LEAD_CARD_LEAD_HISTORY, {
    fetchPolicy: "no-cache",
    variables: {
      lead_id: selectedHistoryContactFilter === "All" ? computedLeadData?.id : selectedHistoryContactFilter,
      event_filter: leadHistoryFilter,
      show_all_lead: selectedHistoryContactFilter === "All" ? true : false,
      skip: tabRefresh ? 0 : skip,
      take: takeNumber,
    },

    onCompleted: ({ fetchLeadHistory }) => {
      const incomingHistoryItems = [...fetchLeadHistory] as LeadHistoryFetchItem[];

      let newList = [];
      if (tabRefresh) {
        newList = [...incomingHistoryItems, ...historyItemList];
      } else {
        newList = skip === 0 ? [...incomingHistoryItems] : [...historyItemList, ...incomingHistoryItems];
      }

      newList = newList.filter((item, index, array) => array.findIndex((a) => a?.id === item?.id) === index);

      // sort by time in order to fix edge cases where other component call the refetch and the order is not correct for new items

      const sortedList = newList.sort((a, b) => {
        return moment(a?.created_at).isBefore(moment(b?.created_at)) ? 1 : -1;
      });

      setHistoryItemList(sortedList);

      if (incomingHistoryItems.length < takeNumber) setHasMoreHistory(false);
      else setHasMoreHistory(true);

      ReactTooltip.rebuild();

      tabRefresh && setTabRefresh(false);
    },
  });

  // Refetch lead history when switching between tabs. This is to ensure that the call history UI is always up to date
  // 10 second delay on refetch to prevent spamming the backend
  useTabFocus(
    () => {
      if (isLeadDetailPage) {
        fetchLeadHistoryData();
        fetchLeadHistoryCount();
        setTabRefresh(true);
      }
    },
    { buffer: 10000 },
  );

  // main calls

  useEffect(() => {
    if (!expandedState) {
      return;
    }
    // we need to hide any add contact modal that might be open from other leads
    setShowAddOrEditContactModal(false);
    setShowAddAssociateModal(false);

    fetchLeadData();
    fetchAssociatedLeadsData();
    setSelectedHistoryContactFilter("All");
  }, [leadIdOverride, intent?.id, expandedState]);

  // for the history we want to only refresh the history section when the filter changes not the whole lead

  useEffect(() => {
    if (!expandedState) {
      return;
    }
    fetchLeadHistoryNotes();
    fetchLeadHistoryCount();
  }, [leadHistoryFilter, selectedHistoryContactFilter, intent?.id, expandedState]);

  // infinite scroll handling 👇
  useEffect(() => {
    if (!expandedState) {
      return;
    }
    fetchLeadHistoryData();
  }, [skip, expandedState]);
  useEffect(() => {
    if (isNextDial && leadCardHistoryReset) {
      setSkip(0);
      setLeadCardHistoryReset(false);
    }
  }, [leadCardHistoryReset]);

  // we need to let the other lead cards know if they can make a lead the next dial
  useEffect(() => {
    if (isNextDial) {
      setAbleToOverideNextDial(!blocksMakeNextAction);
      setLeadInNextDial(computedLeadData?.id);
    }
  }, [isNextDial, blocksMakeNextAction, computedLeadData?.id]);

  // needed for conditional tooltip rendering bugs
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectContactToAdd, selectContactToEdit, setCurrentSelectedTab]);

  const resetInfiniteScroll = useCallback(() => {
    setLeadCardHistoryReset(true);
    setHasMoreHistory(true);
  }, []);

  const [customTaskAck] = useMutation(CUSTOM_TASK_ACK, {
    onCompleted({ customTaskAck }) {
      // remove from active queue

      const filteredActive = upcomingDials.slice().filter((item: any) => item?.id !== intent?.id);

      setUpcomingDials(filteredActive);
    },
    onError({ message }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `Error marking custom task. GraphQL Error: ${message}`,
      });
      console.log("Error marking custom task: ", message);
    },
    refetchQueries: ["fetchFilteredLeadsInQueueV2"],
  });
  //only for custom queue leads
  const [removeFromCustomQueue, { loading: removeCQLoading }] = useMutation(REMOVE_FROM_CUSTOM_QUEUE, {
    variables: {
      lead_id: computedLeadData?.id,
    },
    onCompleted: ({ removeFromCustomQueue }) => {
      if (!removeFromCustomQueue) {
        return;
      }
      const filteredCustom = customDials.slice().filter((item: any) => item?.lead?.id !== computedLeadData?.id);
      setCustomDials(filteredCustom);
      resetNextDial();

      appToast("Lead removed from custom queue!");
      console.log("removeFromCustomQueue: ", removeFromCustomQueue);
    },
    onError: ({ message }) => {
      appToast("Error removing lead from custom queue");
      console.log("removeFromCustomQueue error: ", message);
    },
    refetchQueries: ["fetchCustomQueueLeads"],
  });

  const [
    fetchAssociatedLeadsData,
    { data: dataAssociatedLeads, loading: loadingAssociatedLeads, error: errorAssociatedLeads },
  ] = useLazyQuery(FETCH_ASSOCIATED_LEADS, {
    fetchPolicy: "no-cache",
    variables: {
      primaryLeadId: leadIdOverride || computedLeadData?.id,
    },

    onCompleted: (data) => {
      console.log("dataAssociatedLeads: ", data);
    },
  });

  // needed for conditional tooltip rendering bugs
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectContactToAdd, selectContactToEdit, setCurrentSelectedTab]);

  // lead notes logic

  // needs to be in lead card to enable persistance while changing sub tabs and automatic saving of unsaved notes on unmount.

  const [showNewNoteUI, setShowNewNoteUI] = useState<boolean>(false);

  const [unsavedNote, setUnsavedNote] = useState<string>("");

  // this is to select contact that will get the note (primary lead or an associated contact)
  const [selectedLeadIdForUnsavedNote, setSelectedLeadIdForUnsavedNote] = useState<string>(computedLeadData?.id ?? "");
  useEffect(() => {
    computedLeadData?.id !== selectedLeadIdForUnsavedNote &&
      setSelectedLeadIdForUnsavedNote(computedLeadData?.id ?? "");
  }, [computedLeadData]);

  const [currentTime, setCurrentTime] = useState<string>(returnLocalTime(computedLeadData?.timezone_by_state));

  const resetUnsavedNoteState = useCallback(() => {
    setShowNewNoteUI(false);
    setUnsavedNote("");
    setSelectedLeadIdForUnsavedNote(computedLeadData?.id ?? "");
  }, []);

  const showCustomSuccessToast = useRef(false);

  const [addLeadNote, { loading: loadingAddLeadNote, error: errorAddLeadNote }] = useMutation(ADD_LEAD_NOTE, {
    onCompleted({ addLeadNote }) {
      if (!addLeadNote) {
        appToast("Error: Something went wrong while adding note");
        return;
      }

      if (showCustomSuccessToast.current) {
        appToast(`Autosaved note for ${selectedLeadNameForNoteRef.current ?? "lead"}`);
      } else {
        appToast("Note added successfully");
      }

      showCustomSuccessToast.current = false;

      fetchLeadHistoryNotes();

      resetUnsavedNoteState();
    },

    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addLeadNote GraphQL Error: ${message}`,
      });
      console.log("Error in addLeadNote: ", message);
    },
  });

  const autoSaveUnsavedNoteCheck = useCallback(
    ({
      currentNote,
      currentSelectedLeadIdForNote,
    }: {
      currentNote?: string;
      currentSelectedLeadIdForNote?: string;
    }) => {
      const hasUnsavedNote = !!currentNote?.trim().length && currentSelectedLeadIdForNote !== "";

      if (hasUnsavedNote) {
        showCustomSuccessToast.current = true;

        addLeadNote({
          variables: {
            lead_id: selectedLeadIdForNoteRef.current,
            note: unsavedNoteRef.current,
          },
        });
      }
    },
    [],
  );

  // needed for conditional tooltip rendering bugs
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [selectContactToAdd, selectContactToEdit, setCurrentSelectedTab]);

  // OPS-6653 - auto save unsaved notes on unmount of lead card

  // NEXT DIAL CHANGE CHECK

  useEffect(() => {
    if (isNextDial) {
      autoSaveUnsavedNoteCheck({
        currentNote: unsavedNoteRef.current,
        currentSelectedLeadIdForNote: selectedLeadIdForNoteRef.current,
      });
    }

    resetUnsavedNoteState();
  }, [computedLeadData?.id]);

  // UNMOUNT UNSAVED NOTE CHECK

  useEffect(() => {
    return () => {
      autoSaveUnsavedNoteCheck({
        currentNote: unsavedNoteRef.current,
        currentSelectedLeadIdForNote: selectedLeadIdForNoteRef.current,
      });

      resetUnsavedNoteState();
    };
  }, []);

  useEffect(() => {
    if (!currentTime) {
      const time = returnLocalTime(computedLeadData?.timezone_by_state);
      if (time) {
        setCurrentTime(time);
      }
      return;
    }
    const intervalId = setInterval(() => {
      setCurrentTime(returnLocalTime(computedLeadData?.timezone_by_state));
    }, 10000);
    return () => clearInterval(intervalId);
  }, [currentTime, computedLeadData]);

  // set variables for use in unmount check above (not used anywhere else)

  // NOTE: state variables will not work and must be set as refs

  // this is not desirable

  //but it is the only way to get this to work without setting up an entire context system to keep track of all lead cards and their notes

  const unsavedNoteRef = useRef("");
  const selectedLeadIdForNoteRef = useRef("");
  const selectedLeadNameForNoteRef = useRef("");

  useEffect(() => {
    unsavedNoteRef.current = unsavedNote;
    selectedLeadIdForNoteRef.current = selectedLeadIdForUnsavedNote;
    selectedLeadNameForNoteRef.current = findLeadName(selectedLeadIdForUnsavedNote);
  }, [unsavedNote, selectedLeadIdForUnsavedNote, primaryLead, dataAssociatedLeads, dataLead]);

  const findLeadName = useCallback(
    (leadId: string) => {
      return leadId === computedLeadData?.id
        ? computedLeadData?.full_name?.trim().length
          ? computedLeadData?.full_name
          : "lead"
        : dataAssociatedLeads?.fetchAssociateContact?.find((item: any) => item?.id === leadId)?.full_name ?? "lead";
    },
    [dataAssociatedLeads, computedLeadData],
  );

  const leadGroup: CurrentLeadType[] = useMemo(() => {
    return [
      // primary
      dataLead?.fetchLead,
      // associated if on lead
      ...(dataAssociatedLeads?.fetchAssociateContact?.filter((item: any) => item?.id !== dataLead?.fetchLead?.id) ??
        ([] as CurrentLeadType[])),
    ];
  }, [dataLead, dataAssociatedLeads]);

  const leadGroupHasNoScreenSharingNumbers: boolean = useMemo(() => {
    return !returnLeadGroupScreensharingNumber(leadGroup)?.length;
  }, [leadGroup]);

  // passes into the headers
  // nextAction passes this logic in but off variation defines it in the main return

  // whether to show the skip button
  const showDefaultSkipButton =
    parentType &&
    ["activeQueueDials", "recommendedDial", "nextAction", "suggestedAction"].includes(parentType) &&
    !intent?.is_scheduled_item;

  const showDefaultReleaseLeadButton = computedLeadData?.rep_id === loggedInUser()?.id;

  const showDefaultCustomQueueRemoveButton = parentType === "customQueueDials";

  const leadIsInOverriddenNextDial = nextActionOverride.intent_object.lead.id === computedLeadData?.id;

  const showDefaultRemoveFromNextDialButton =
    parentType && !!isNextDial && !intent?.is_scheduled_item && leadIsInOverriddenNextDial;
  // &&
  // edge cases can happen if we don't show this override indicator even if it came originaly from suggested action
  // parentType !== "suggestedAction";

  const renderRelatedRecord = () => {
    if (currentSelectedTab === "related-records") {
      if (customObject) {
        return <RelatedRecordsLeadCardTab leadId={computedLeadData?.id} customObjectSummary={customObjectSummary} />;
      } else {
        return <SalesforceRelatedRecordsLeadCardTab leadId={computedLeadData?.id} />;
      }
    }
    return <></>;
  };

  const leadCardScrollableViewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // everytime the lead card tab changes scroll to bottom of leadCardScrollableView if it is not contacts
    if (currentSelectedTab !== "contact-info") {
      leadCardScrollableViewRef.current?.scrollTo({ top: 100000, behavior: "smooth" });
    }
  }, [currentSelectedTab]);

  return (
    <Container border={border} borderRadius={borderRadius}>
      {!computedLeadData || errorLead || historyError || errorAssociatedLeads ? (
        <AppErrorText>Error loading lead</AppErrorText>
      ) : loadingLead || loadingAssociatedLeads ? (
        <SkeletonBlock height={500} width={"100%"} borderRadius={8} />
      ) : (
        <>
          <LeadCardScrollableView ref={leadCardScrollableViewRef} maxHeight={maxHeight}>
            {(isNextDial || parentType === "activeQueueDials" || parentType === "suggestedAction") && (
              <>
                {isSequenceAction ? (
                  <CustomSequenceActionHeader
                    inQueue={!isNextDial}
                    showDefaultRemoveFromCustomQueueButton={false}
                    showDefaultReleaseButton={!!isNextDial}
                    showDefaultRemoveFromNextDialButton={false}
                    showCustomRemoveStepActionButton={
                      !!isNextDial && dataLead && !!dataLead?.fetchLead?.organization?.allow_remove_step_action
                    }
                    showCustomSnoozeStepActionButton={
                      !!isNextDial && dataLead && !!dataLead?.fetchLead?.organization?.allow_snooze_step_action
                    }
                    handleReleaseLead={() => {
                      setSkip(0);
                      MixpanelActions.track("Release Lead");
                      setSkipOrReleaseLeadModalData({
                        lead_id: computedLeadData?.id ?? "",
                        isNextDial: !!isNextDial,
                        intent: intent,
                      });
                      setReleaseLeadModal(true);
                    }}
                    handleRemoveFromCustomQueue={() => {}} // no-op
                    customTaskAck={customTaskAck}
                    leadData={computedLeadData}
                    intentData={intent}
                    scheduledEventBehavior={computedLeadData?.sequence_step?.actions?.[0]?.scheduled_event_behavior}
                  />
                ) : (
                  <DefaultSequenceActionHeader
                    handleReleaseLead={() => {
                      setSkip(0);
                      MixpanelActions.track("Release Lead");
                      setSkipOrReleaseLeadModalData({
                        lead_id: computedLeadData?.id ?? "",
                        isNextDial: !!isNextDial,
                        intent: intent,
                      });
                      setReleaseLeadModal(true);
                    }}
                    showDefaultSnoozeButton={!!isNextDial && !!showDefaultSkipButton}
                    showDefaultReleaseButton={!!isNextDial && !!showDefaultReleaseLeadButton}
                    showDefaultRemoveFromCustomQueueButton={!!isNextDial && !!showDefaultCustomQueueRemoveButton}
                    showDefaultRemoveFromNextDialButton={!!isNextDial && !!showDefaultRemoveFromNextDialButton}
                    handleSnoozeLead={() => {
                      setSkip(0);
                      MixpanelActions.track("Skip Lead");
                      setSkipOrReleaseLeadModalData({
                        lead_id: computedLeadData?.id ?? "",
                        isNextDial: !!isNextDial,
                        intent: intent,
                      });
                      setSkipLeadModal(true);
                    }}
                    handleRemoveFromCustomQueue={removeFromCustomQueue}
                    handleRemoveFromNextDial={() => {
                      resetNextDial();
                      setSkip(0);
                    }}
                  />
                )}
              </>
            )}
            <LeadCardNonExpand removeRightPadding={isSequenceAction && !!isNextDial}>
              {showAddAssociateModal &&
                // the curently selected lead is the primary lead
                primaryLeadToAddAssociateTo === computedLeadData?.id && (
                  <NewAppButton
                    style={{ width: "100%", height: "100%", borderRadius: 0, display: "flex", alignItems: "center" }}
                    onClick={() => {
                      setShowAddAssociateModal(false);
                    }}
                  >
                    <AiOutlineLeft size={12} color={theme.PRIMARY500} />
                    <AppText fontSize={12} fontWeight={600} style={{ color: theme.PRIMARY500 }}>
                      Back
                    </AppText>
                  </NewAppButton>
                )}
              {/*header is split into 3 sections */}
              {!loadingLead && (
                <>
                  <CardContainerHeader>
                    <CardHeaderLeft>
                      {computedLeadData?.rep_id === loggedInUser()?.id && (
                        <FavoritedStar
                          leadId={computedLeadData?.id}
                          parentType={parentType ?? ""}
                          dataFavoritedStatus={computedLeadData?.favorited}
                        />
                      )}
                    </CardHeaderLeft>

                    <CardHeaderMiddle>
                      <CallbackPersonCompany
                        style={{ cursor: "pointer" }}
                        onClick={() => window.open(`/lead-detail/${computedLeadData?.id}`, "_blank", "noreferrer")}
                      >
                        {formatBusinessName(computedLeadData?.business_name)}
                      </CallbackPersonCompany>
                    </CardHeaderMiddle>

                    <CardHeaderRight>
                      <ButtonRow>
                        <SearchButton isSearchClicked={isSearchClicked} setIsSearchClicked={setIsSearchClicked} />
                        <EditButton leadId={computedLeadData?.id} parentType={parentType} />
                        {isSequenceAction && (
                          <SequenceTag borderRadius={!hideExpandButton && !isNextDial ? "10px" : undefined}>
                            <PhoenixIcon svg={sequences} size={16} variant="alert" hoverColor={theme.WARNING500} />
                          </SequenceTag>
                        )}
                        <DisassociateLeadCustomObject
                          leadId={computedLeadData?.id}
                          row_id={row_id}
                          business_name={computedLeadData?.business_name}
                        />

                        {parentType === "customQueueDials" && !isNextDial ? (
                          <PhoenixIcon
                            pointer
                            svg={xIcon}
                            size={16}
                            variant="brand"
                            onClick={async () => {
                              await removeFromCustomQueue();
                            }}
                          />
                        ) : null}
                        {!hideExpandButton && !isNextDial && (
                          <ExpandButton
                            handleClick={() => {
                              setExpandedState(!expandedState);
                            }}
                            isExpanded={expandedState}
                          />
                        )}
                      </ButtonRow>
                      {isSearchClicked && (
                        <SearchMenu setIsSearchClicked={setIsSearchClicked} lead={computedLeadData} />
                      )}
                    </CardHeaderRight>
                  </CardContainerHeader>
                </>
              )}
              {/*main is split into 2 sections */}
              <CardContainerMain>
                <PhoenixStyledTooltip
                  id="lead-card-tooltip"
                  place="right"
                  maxWidth={300}
                  lineHeight={14}
                  textAlign="left"
                />
                <FlexDiv direction="column" align="flex-start" gap={8}>
                  {computedLeadData?.lead_ownership_status === "Customer" && expandedState && (
                    <StatCard
                      icon={check_thick}
                      variant="primary"
                      backgroundColor={theme.SUCCESS500}
                      text="CURRENT CUSTOMER"
                    />
                  )}
                  {!loadingLead && expandedState && isAssociatedLead && (
                    <StatCard icon={associated_white} variant="secondary" text="ASSOCIATED" />
                  )}
                  {!isAssociatedLead && !loadingLead && expandedState && (
                    <StatCard icon={user_white} variant="primary" text="PRIMARY" />
                  )}
                </FlexDiv>
                <CardContainerMainRight>
                  <LeadCardTopInfo
                    computedLeadData={computedLeadData}
                    isInMyScheduleComponent={isInMyScheduleComponent}
                    showLeadSourceToUser={showLeadSourceToUser?.showLeadSourceToUser || false}
                    currentTime={currentTime}
                    mrrLabel={mrrLabel}
                    scheduledEvent={scheduledEvent}
                  />
                  {/* Show extra fields for FieldPulse */}
                  {expandedState &&
                    (loggedInUser().organization_id === "91f1b9c2-009b-48f9-93e5-b044788c1aa5" ||
                      loggedInUser().organization_id === "35372a5d-8395-4f2e-974f-4ac1d0216a3e" ||
                      loggedInUser().organization_id === "ece14be5-953f-4213-b102-9c409ccbba14") && (
                      <>
                        <AppText fontSize={12}>
                          Pipeline:{" "}
                          {dataLead?.fetchLead?.visible_custom_fields.find((field: any) => field.key === "Pipeline")
                            ?.computed_value || "N/A"}
                        </AppText>

                        <AppText fontSize={12}>
                          Lead Source Detail:{" "}
                          {dataLead?.fetchLead?.visible_custom_fields.find(
                            (field: any) => field.key === "Lead Source Detail",
                          )?.computed_value || "N/A"}
                        </AppText>

                        <AppText fontSize={12}>
                          Current Competitor:{" "}
                          {dataLead?.fetchLead?.visible_custom_fields.find(
                            (field: any) => field.key === "Current Competitor",
                          )?.computed_value || "N/A"}
                        </AppText>

                        <AppText fontSize={12}>
                          Amount:{" "}
                          {dataLead?.fetchLead?.visible_custom_fields.find((field: any) => field.key === "Amount")
                            ?.computed_value || "N/A"}
                        </AppText>

                        <AppText fontSize={12}>
                          Current Close Date:{" "}
                          {computedLeadData?.current_close_date
                            ? formatDate(computedLeadData?.current_close_date)
                            : "N/A"}
                        </AppText>

                        <AppText fontSize={12}>
                          Number of Employees:{" "}
                          {dataLead?.fetchLead?.visible_custom_fields.find(
                            (field: any) => field.key === "Number of Employees",
                          )?.computed_value || "N/A"}
                        </AppText>

                        <AppText fontSize={12}>
                          Industry - Other:{" "}
                          {dataLead?.fetchLead?.visible_custom_fields.find(
                            (field: any) => field.key === "Industry - Other",
                          )?.computed_value || "N/A"}
                        </AppText>
                      </>
                    )}
                </CardContainerMainRight>
              </CardContainerMain>
              {/* conference number */}
              {!expandedState && screenSharingPhoneNumber && dataLead?.fetchLead?.conference_number && (
                <PersonNumberDiv>
                  <LeftSizeNumberDiv>
                    {!isInMyScheduleComponent && !!dataLead?.fetchLead && (
                      <IconAndTextDiv>
                        <div
                          style={{ marginRight: "4px" }}
                          onClick={() => {
                            updateUserToIdle({ do_not_update_if_on_call: true });
                            MixpanelActions.track("Lead Card", {
                              type: "edit contact clicked",
                              id: dataLead?.fetchLead?.id,
                              source: parentType,
                            });

                            const regex = `+${dataLead?.fetchLead?.primary_phone_number_country_code ?? "1"}`;
                            selectContactToEdit({
                              lead_id: computedLeadData?.id ?? "",
                              id: computedLeadData?.id ?? "",
                              category: "Primary",
                              conference_number: dataLead?.fetchLead?.conference_number?.replace(regex, "") ?? "",
                              country_code: dataLead?.fetchLead?.primary_phone_number_country_code,
                              channel: "Conference",
                              email: "",
                              title: dataLead?.fetchLead?.primary_phone_number_title,
                              type: dataLead?.fetchLead?.primary_phone_number_type,
                              country: computedLeadData?.country,
                              card_type: "Edit",
                            });
                          }}
                        >
                          <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                        </div>
                        <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                          Screen Sharing Number
                        </AppText>
                      </IconAndTextDiv>
                    )}
                    <NumberTitleDiv>
                      <ContactText>{formatPhoneNumber(dataLead?.fetchLead?.conference_number)}</ContactText>
                    </NumberTitleDiv>
                  </LeftSizeNumberDiv>
                  {loggedInUser().role !== "ADMIN" && (
                    <IconDiv>
                      <LeadCardContactButton
                        disabled={!isOwnedByRep || !inCallableStatus || !!callState || !!activeCall}
                        onClick={() => {
                          closeAllModals();
                          MixpanelActions.track("Lead Card", {
                            type: "primary call clicked",
                            id: dataLead?.fetchLead?.id,
                            source: parentType,
                          });
                          goToCall({
                            phoneNumber: dataLead?.fetchLead?.conference_number,
                            ...goToCallObjectForPrimary,
                          });
                          resetNextDial();
                        }}
                        callState={callState}
                        type="phone"
                        isNextDial={isNextDial}
                      />

                      {!isInternational && (
                        <TooltipWrapperDiv
                          data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined}
                          data-for={leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined}
                        >
                          <LeadCardContactButton
                            disabled={!isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS}
                            onClick={() => {
                              if (showSMSChat) {
                                appToast(
                                  "There is currently a chat open. Please close the existing chat before opening.",
                                );
                                return;
                              }
                              MixpanelActions.track("Lead Card", {
                                type: "sms clicked",
                                id: dataLead?.fetchLead?.id,
                                source: parentType,
                              });

                              openSMSChat({
                                lead_id: dataLead?.fetchLead?.id ?? "",

                                phone_number: dataLead?.fetchLead?.conference_number,
                                intent: intent,
                              });
                            }}
                            type="sms"
                            isNextDial={isNextDial}
                          />
                        </TooltipWrapperDiv>
                      )}
                    </IconDiv>
                  )}
                </PersonNumberDiv>
              )}
              {!expandedState && loggedInUser().role !== "ADMIN" && (
                <>
                  {!!computedLeadData?.id &&
                    nextActionOverride?.intent_object?.lead?.id !== computedLeadData?.id &&
                    !isNextDial && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginTop: "16px",
                        }}
                      >
                        <TooltipWrapperDiv
                          data-tip={
                            makeNextActionDisabledCheck({
                              inCallableStatus: inCallableStatus,
                              currentLeadStatus: computedLeadData?.lead_ownership_status,
                              isOwnedByRep: isOwnedByRep,
                              isAssociatedLead: isAssociatedLead,
                              nextDialOverrideBlocked: !ableToOverideNextDial,
                              expandedState: expandedState,
                              leadIsInNextDial: computedLeadData?.id === leadInNextDial,
                            })?.tooltip
                          }
                          data-for={
                            makeNextActionDisabledCheck({
                              inCallableStatus: inCallableStatus,
                              currentLeadStatus: computedLeadData?.lead_ownership_status,
                              isOwnedByRep: isOwnedByRep,
                              isAssociatedLead: isAssociatedLead,
                              nextDialOverrideBlocked: !ableToOverideNextDial,
                              expandedState: expandedState,
                              leadIsInNextDial: computedLeadData?.id === leadInNextDial,
                            })?.disabled
                              ? "lead-card-tooltip"
                              : undefined
                          }
                        >
                          <MakeNextActionButton
                            variant={"primary"}
                            disabled={
                              makeNextActionDisabledCheck({
                                inCallableStatus: inCallableStatus,
                                currentLeadStatus: computedLeadData?.lead_ownership_status,
                                isOwnedByRep: isOwnedByRep,
                                isAssociatedLead: isAssociatedLead,
                                nextDialOverrideBlocked: !ableToOverideNextDial,
                                expandedState: expandedState,
                                leadIsInNextDial: computedLeadData?.id === leadInNextDial,
                              })?.disabled
                            }
                            onClick={() => {
                              updateUserToIdle({ do_not_update_if_on_call: true });
                              MixpanelActions.track("Lead Card", {
                                type: "make next action",
                                id: computedLeadData?.id,
                                source: parentType,
                              });

                              console.log("intent of lead going into nextAction", intent);
                              setNextActionOverride({
                                intent_object: {
                                  id: computedLeadData?.next_intent_scheduled_or_unscheduled?.id ?? null,
                                  lead: {
                                    ...computedLeadData,
                                    id: computedLeadData?.id ?? "",
                                    primary_phone_number: computedLeadData?.primary_phone_number,
                                  },
                                  type: intent?.type,
                                  ...intent,
                                },
                                parent: parentType,
                              });
                              resetInfiniteScroll();

                              setRepLeadSearch("");
                            }}
                          >
                            MAKE NEXT ACTION
                          </MakeNextActionButton>
                        </TooltipWrapperDiv>
                      </div>
                    )}
                </>
              )}
            </LeadCardNonExpand>

            {expandedState && !!loadingLead && <SkeletonBlock height={300} width={"100%"} borderRadius={8} />}

            {isAssociatedLead && (
              <FlexDiv justify="left" style={{ marginBottom: "32px", marginLeft: "16px", marginRight: "16px" }}>
                <FlexDiv justify="center">
                  <AssociateWarning>
                    <AppText fontSize={14}>
                      Note: This is an associated contact.{" "}
                      <PrimaryText
                        onClick={() =>
                          window.open(
                            `/lead-detail/${dataLead?.fetchLead?.associate_parent_id}`,
                            "_blank",
                            "noreferrer",
                          )
                        }
                      >
                        Go to primary lead detail page
                      </PrimaryText>
                    </AppText>
                  </AssociateWarning>
                </FlexDiv>
              </FlexDiv>
            )}

            {expandedState && (
              //Everything below this should use dataLead?.fetchLead as that has additional details not in computedLeadData. This query should be run on expand
              <>
                {callState && callLeadId !== computedLeadData?.id && (
                  <FlexDiv
                    justify="left"
                    style={{ width: "100%", marginBottom: "32px", marginLeft: "16px", marginRight: "16px" }}
                  >
                    <AppText fontSize={14}>
                      <PrimaryTextDisabled>
                        Via{" "}
                        {dataAssociatedLeads?.fetchAssociateContact?.find((lead: any) => callLeadId === lead.id)
                          ?.full_name ??
                          dataAssociatedLeads?.fetchAssociateContact?.find((lead: any) => callLeadId === lead.id)
                            ?.business_name ??
                          ""}
                        [ASSOCIATED]
                      </PrimaryTextDisabled>
                    </AppText>
                  </FlexDiv>
                )}
                {!callState && !hideActionButtons && (
                  <>
                    {scheduledEventIsWithAssociatedLead && (
                      <FlexDiv
                        justify="left"
                        style={{ width: "100%", marginBottom: "32px", marginLeft: "16px", marginRight: "16px" }}
                      >
                        <AppText fontSize={14}>
                          <PrimaryTextDisabled>
                            Via{" "}
                            {
                              dataAssociatedLeads?.fetchAssociateContact?.find(
                                (lead: any) => lead.id === scheduledEventLeadId,
                              )?.full_name
                            }{" "}
                            [ASSOCIATED]
                          </PrimaryTextDisabled>
                        </AppText>
                      </FlexDiv>
                    )}
                  </>
                )}

                <FlexDiv direction="column" gap={8} style={{ padding: "0px 16px" }}>
                  {!!computedLeadData &&
                    renderEventCards(
                      intent?.lead?.next_scheduled_event ? intent.lead : computedLeadData,
                      showEventTypeCard,
                      computedLeadData,
                    )}
                </FlexDiv>

                {!isAssociatedLead && !!showLogNonCallActivity ? (
                  <LogNonCallDiv>
                    <NewAppButton
                      style={{ margin: "auto" }}
                      variant={"secondary"}
                      height={48}
                      disabled={!isOwnedByRep || !inCallableStatus || !!callState || !!activeCall}
                      onClick={() => {
                        if (nonCallActivitySales) {
                          // open right log call result modal
                          logNonCallActivity(computedLeadData?.id || "");
                        } else {
                          setShowNonCallActivityModal(!showNonCallActivityModal);

                          setLogNonCallActivityModalData({
                            lead_id: computedLeadData?.id ?? "",
                            lead_full_name:
                              computedLeadData?.full_name ??
                              `${computedLeadData?.last_name}, ${computedLeadData?.first_name}`,
                            lead_primary_email: computedLeadData?.primary_email ?? "",
                          });
                        }
                      }}
                    >
                      Log Non-Call Activity
                    </NewAppButton>
                  </LogNonCallDiv>
                ) : (
                  loggedInUser().joinMe_connected &&
                  !isInMyScheduleComponent && <JoinMeLink leadID={dataLead?.fetchLead?.id ?? ""} />
                )}
                <TabsDiv>
                  <AppTabDiv
                    width="fit-content"
                    height="fit-content"
                    padding="8px 16px"
                    selected={currentSelectedTab === "contact-info"}
                    onClick={() => {
                      MixpanelActions.track("Lead Card", {
                        type: "tab clicked",
                        tab: "contact-info",
                        id: dataLead?.fetchLead?.id,
                        source: parentType,
                      });
                      setCurrentSelectedTab("contact-info");
                    }}
                  >
                    Contact
                  </AppTabDiv>
                  <AppTabDiv
                    width="fit-content"
                    height="fit-content"
                    padding="8px 16px"
                    selected={currentSelectedTab === "more-info"}
                    onClick={() => {
                      MixpanelActions.track("Lead Card", {
                        type: "tab clicked",
                        tab: "more-info",
                        id: dataLead?.fetchLead?.id,
                        source: parentType,
                      });
                      setCurrentSelectedTab("more-info");
                    }}
                  >
                    More Info
                  </AppTabDiv>
                  <AppTabDiv
                    width="fit-content"
                    height="fit-content"
                    padding="8px 16px"
                    selected={currentSelectedTab === "lead-history"}
                    onClick={() => {
                      MixpanelActions.track("Lead Card", {
                        type: "tab clicked",
                        tab: "lead-history",
                        id: dataLead?.fetchLead?.id,
                        source: parentType,
                      });
                      setCurrentSelectedTab("lead-history");
                    }}
                  >
                    History
                  </AppTabDiv>
                  <AppTabDiv
                    width="fit-content"
                    height="fit-content"
                    padding="8px 16px"
                    selected={currentSelectedTab === "notes"}
                    onClick={() => {
                      MixpanelActions.track("Lead Card", {
                        type: "tab clicked",
                        tab: "notes",
                        id: dataLead?.fetchLead?.id,
                        source: parentType,
                      });
                      setCurrentSelectedTab("notes");
                    }}
                  >
                    Notes
                  </AppTabDiv>
                  {leadIntegrationRelatedRecords ? (
                    <AppTabDiv
                      width="fit-content"
                      height="fit-content"
                      padding="8px 16px"
                      selected={currentSelectedTab === "related-records"}
                      onClick={() => {
                        MixpanelActions.track("Lead Card", {
                          type: "tab clicked",
                          tab: "notes",
                          id: dataLead?.fetchLead?.id,
                          source: parentType,
                        });
                        setCurrentSelectedTab("related-records");
                      }}
                    >
                      RELATED RECORDS
                    </AppTabDiv>
                  ) : (
                    <AppTabDiv height={34} padding="0px 16px" selected={false} onClick={() => {}} />
                  )}
                </TabsDiv>

                {currentSelectedTab === "contact-info" &&
                  (loadingLead || !dataLead?.fetchLead ? (
                    <SkeletonBlock height={300} width={"100%"} borderRadius={8} />
                  ) : errorLead ? (
                    <AppErrorText>Error loading Lead</AppErrorText>
                  ) : (
                    !!dataLead &&
                    !!dataLead?.fetchLead && (
                      <>
                        <ContactInfoHeaderDiv>
                          <ContactInfoTabContainer>
                            <ContactInfoTab
                              active={contactTab === "phone-and-email"}
                              onClick={() => setContactTab("phone-and-email")}
                            >
                              Phone & Email
                            </ContactInfoTab>
                            <DividerLine
                              visible={contactTab !== "phone-and-email" && contactTab !== "screen-sharing"}
                            />
                            <ContactInfoTab
                              active={contactTab === "screen-sharing"}
                              onClick={() => setContactTab("screen-sharing")}
                            >
                              Screen Sharing
                            </ContactInfoTab>
                            <DividerLine
                              visible={contactTab !== "screen-sharing" && contactTab !== "associated-contacts"}
                            />
                            <ContactInfoTab
                              active={contactTab === "associated-contacts"}
                              onClick={() => setContactTab("associated-contacts")}
                            >
                              Associated Contacts
                            </ContactInfoTab>
                          </ContactInfoTabContainer>
                        </ContactInfoHeaderDiv>
                        <ContactInfoDiv>
                          {contactTab === "phone-and-email" && (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <SMSCommunicationToggle
                                lead={dataLead?.fetchLead}
                                loadingLead={loadingLead}
                                refetchFunction={() => {
                                  fetchLeadData();
                                  fetchLeadHistoryCount();
                                  fetchLeadHistoryData();
                                }}
                              />
                              <EmailCommunicationToggle
                                lead={dataLead?.fetchLead}
                                loadingLead={loadingLead}
                                refetchFunction={() => {
                                  fetchLeadData();
                                  fetchLeadHistoryCount();
                                  fetchLeadHistoryData();
                                }}
                              />
                              {/* Primary Phone Number */}
                              <PersonNumberDiv>
                                <div>
                                  <LeftSizeNumberDiv>
                                    <div>
                                      {!isInMyScheduleComponent && !!dataLead?.fetchLead && (
                                        <IconAndTextDiv>
                                          <div
                                            style={{ marginRight: "4px" }}
                                            onClick={() => {
                                              updateUserToIdle({ do_not_update_if_on_call: true });
                                              MixpanelActions.track("Lead Card", {
                                                type: "edit contact clicked",
                                                id: dataLead?.fetchLead?.id,
                                                source: parentType,
                                              });
                                              selectContactToEdit({
                                                lead_id: computedLeadData?.id ?? "",
                                                id: computedLeadData?.id ?? "",
                                                category: "Primary",
                                                phone_number: dataLead?.fetchLead?.primary_phone_number,
                                                local_phone_number: dataLead?.fetchLead?.local_primary_phone_number,
                                                conference_number: dataLead?.fetchLead?.conference_number,
                                                country_code: dataLead?.fetchLead?.primary_phone_number_country_code,
                                                country: computedLeadData?.country,
                                                channel: "Phone",
                                                email: "",
                                                title: dataLead?.fetchLead?.primary_phone_number_title,
                                                type: dataLead?.fetchLead?.primary_phone_number_type,
                                                card_type: "Edit",
                                              });
                                            }}
                                          >
                                            <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                          </div>
                                          <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                                            Primary Phone
                                          </AppText>
                                        </IconAndTextDiv>
                                      )}
                                    </div>
                                    <ContactText>
                                      {formatPhoneNumber(dataLead?.fetchLead?.primary_phone_number)}
                                    </ContactText>
                                  </LeftSizeNumberDiv>
                                  <NumberTitleDiv>
                                    {dataLead?.fetchLead?.primary_phone_number_title ? (
                                      <PersonNumberTitle>
                                        {dataLead?.fetchLead?.primary_phone_number_title}{" "}
                                        {dataLead?.fetchLead?.primary_phone_number_type
                                          ? `(${dataLead?.fetchLead?.primary_phone_number_type})`
                                          : ``}
                                      </PersonNumberTitle>
                                    ) : (
                                      ""
                                    )}
                                  </NumberTitleDiv>
                                </div>
                                {loggedInUser().role !== "ADMIN" && (
                                  <IconDiv>
                                    <TooltipWrapperDiv
                                      data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                      data-for={!!callState ? "lead-card-tooltip" : ""}
                                    >
                                      <LeadCardContactButton
                                        disabled={!isOwnedByRep || !inCallableStatus || !!callState || !!activeCall}
                                        onClick={() => {
                                          updateUserToIdle({ do_not_update_if_on_call: true });
                                          closeAllModals();
                                          MixpanelActions.track("Lead Card", {
                                            type: "primary call clicked",
                                            id: dataLead?.fetchLead?.id,
                                            source: parentType,
                                          });
                                          goToCall({
                                            phoneNumber: dataLead?.fetchLead?.primary_phone_number,
                                            ...goToCallObjectForPrimary,
                                          });

                                          resetNextDial();
                                        }}
                                        callState={callState}
                                        type="phone"
                                        isNextDial={isNextDial}
                                      />
                                    </TooltipWrapperDiv>

                                    {/* Hide UI from international organizations. */}
                                    {!isInternational && (
                                      <TooltipWrapperDiv
                                        data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined}
                                        data-for={leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined}
                                      >
                                        <LeadCardContactButton
                                          disabled={!isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS}
                                          onClick={() => {
                                            if (showSMSChat) {
                                              appToast(
                                                "There is currently a chat open. Please close the existing chat before opening.",
                                              );
                                              return;
                                            }
                                            MixpanelActions.track("Lead Card", {
                                              type: "sms clicked",
                                              id: dataLead?.fetchLead?.id,
                                              source: parentType,
                                            });
                                            openSMSChat({
                                              lead_id: dataLead?.fetchLead?.id ?? "",
                                              phone_number: dataLead?.fetchLead?.primary_phone_number,
                                              intent: intent,
                                            });
                                          }}
                                          type="sms"
                                          isNextDial={isNextDial}
                                        />
                                      </TooltipWrapperDiv>
                                    )}
                                  </IconDiv>
                                )}
                              </PersonNumberDiv>
                              {/* Primary Alternate Contacts Phone */}
                              {returnLeadAlternatePhoneNumbers(dataLead?.fetchLead)?.map(
                                (item: AlternateContactsType) =>
                                  !!item.value && (
                                    <PersonNumberDiv key={item.id}>
                                      <div>
                                        <LeftSizeNumberDiv>
                                          {!isInMyScheduleComponent && (
                                            <IconAndTextDiv>
                                              <div
                                                style={{ marginRight: "4px" }}
                                                onClick={() => {
                                                  updateUserToIdle({ do_not_update_if_on_call: true });
                                                  const regex = `+${
                                                    dataLead?.fetchLead?.primary_phone_number_country_code ?? "1"
                                                  }`;
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "edit contact clicked",
                                                    id: intent?.lead_id,
                                                    source: parentType,
                                                  });
                                                  selectContactToEdit({
                                                    lead_id: dataLead?.fetchLead?.id ?? "",
                                                    id: item.id,
                                                    category: "Alternate",
                                                    alternate_phone_mapping_option: item?.mapping_order,
                                                    alternate_phone_mapping_option_label: item.contact_label,
                                                    phone_number: item.channel === "Phone" ? item.value : "",
                                                    local_phone_number:
                                                      item.channel === "Phone" ? item.value.replace(regex, "") : "",
                                                    country_code:
                                                      dataLead?.fetchLead?.primary_phone_number_country_code,
                                                    country: computedLeadData?.country,
                                                    email: item.channel === "Email" ? item.value : "",
                                                    channel: item.channel,
                                                    title: item.title,
                                                    type: item.type,
                                                    card_type: "Edit",
                                                  });
                                                }}
                                              >
                                                <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                              </div>
                                              <AppText
                                                fontSize={12}
                                                fontWeight={400}
                                                style={{ color: theme.PRIMARY500 }}
                                              >
                                                {item?.contact_label ??
                                                  `Alternate ${item.channel === "Phone" ? "Phone" : "Email"}`}
                                              </AppText>
                                            </IconAndTextDiv>
                                          )}
                                          <ContactText>
                                            {item.channel === "Phone" ? formatPhoneNumber(item.value) : item.value}
                                          </ContactText>
                                        </LeftSizeNumberDiv>
                                        <FlexDiv
                                          align="center"
                                          gap={8}
                                          style={{
                                            marginLeft: "19px",
                                          }}
                                        >
                                          {item.title ? (
                                            <PersonNumberTitle>
                                              {item.title} {item.type ? ` (${item.type})` : ``}
                                            </PersonNumberTitle>
                                          ) : (
                                            ""
                                          )}
                                          {item.channel === "Phone" && <PersonNumberTitle>|</PersonNumberTitle>}
                                          {item.channel === "Phone" && <SwapPrimaryNumberDiv contact_id={item.id} />}
                                        </FlexDiv>
                                      </div>
                                      {loggedInUser().role !== "ADMIN" && (
                                        <IconDiv>
                                          {item.channel !== "Phone" ? (
                                            <TooltipWrapperDiv
                                              data-tip={
                                                leadUnsubscribedFromEmail ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                              }
                                              data-for={leadUnsubscribedFromEmail ? "lead-card-tooltip" : undefined}
                                            >
                                              <LeadCardContactButton
                                                disabled={
                                                  !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromEmail
                                                }
                                                onClick={() => {
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "email clicked (not primary)",
                                                    id: intent?.lead_id,
                                                    source: parentType,
                                                  });
                                                  !showEmailEditorModal &&
                                                    openEmailEditorModal({
                                                      lead_id: dataLead?.fetchLead?.id ?? "",
                                                      full_name: dataLead?.fetchLead?.full_name,
                                                      email: item.value,
                                                      intent: intent,
                                                      isManualEmailSequenceStep:
                                                        computedLeadData?.sequence_step?.actions?.[0]?.task ===
                                                        "manualEmail",
                                                      sequenceStepId: computedLeadData?.sequence_step?.actions?.[0]?.id,
                                                    });
                                                }}
                                                type="email"
                                                isNextDial={isNextDial}
                                              />
                                            </TooltipWrapperDiv>
                                          ) : (
                                            <>
                                              <TooltipWrapperDiv
                                                data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                                data-for={!!callState ? "lead-card-tooltip" : ""}
                                              >
                                                <LeadCardContactButton
                                                  disabled={
                                                    !isOwnedByRep || !inCallableStatus || !!callState || !!activeCall
                                                  }
                                                  callState={callState}
                                                  onClick={() => {
                                                    MixpanelActions.track("Lead Card", {
                                                      type: "call clicked (not primary)",
                                                      id: intent?.lead_id,
                                                      source: parentType,
                                                    });
                                                    closeAllModals();
                                                    goToCall({
                                                      phoneNumber: item.value,
                                                      ...goToCallObjectForPrimary,
                                                    });
                                                    resetNextDial();
                                                  }}
                                                  type="phone"
                                                  isNextDial={isNextDial}
                                                />
                                              </TooltipWrapperDiv>
                                              {!isInternational && (
                                                <TooltipWrapperDiv
                                                  data-tip={
                                                    leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                                  }
                                                  data-for={leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined}
                                                >
                                                  <LeadCardContactButton
                                                    disabled={
                                                      !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS
                                                    }
                                                    onClick={() => {
                                                      if (showSMSChat) {
                                                        appToast(
                                                          "There is currently a chat open. Please close the existing chat before opening.",
                                                        );
                                                        return;
                                                      }
                                                      MixpanelActions.track("Lead Card", {
                                                        type: "sms clicked",
                                                        id: dataLead?.fetchLead?.id,
                                                        source: parentType,
                                                      });

                                                      openSMSChat({
                                                        lead_id: dataLead?.fetchLead?.id ?? "",
                                                        phone_number: item.value,
                                                        intent: intent,
                                                      });
                                                    }}
                                                    type="sms"
                                                    isNextDial={isNextDial}
                                                  />
                                                </TooltipWrapperDiv>
                                              )}
                                            </>
                                          )}
                                        </IconDiv>
                                      )}
                                    </PersonNumberDiv>
                                  ),
                              )}

                              {/* Associated Contacts Primary and Alternate Phone Numbers */}

                              {dataAssociatedLeads?.fetchAssociateContact?.map((contact: any, index: number) => (
                                <div>
                                  {contact && contact?.primary_phone_number && (
                                    <PersonNumberDiv>
                                      <div>
                                        <AssociateLeadContactLabel contact={contact} />
                                        <LeftSizeNumberDiv>
                                          <div>
                                            {!isInMyScheduleComponent && !!dataLead?.fetchLead && (
                                              <IconAndTextDiv>
                                                <div
                                                  style={{ marginRight: "4px" }}
                                                  onClick={() => {
                                                    updateUserToIdle({ do_not_update_if_on_call: true });
                                                    MixpanelActions.track("Lead Card", {
                                                      type: "edit contact clicked",
                                                      id: dataLead?.fetchLead?.id,
                                                      source: parentType,
                                                    });
                                                    selectContactToEdit({
                                                      lead_id: contact?.id ?? "",
                                                      id: contact?.id ?? "",
                                                      category: "Primary",
                                                      phone_number: contact?.primary_phone_number,
                                                      local_phone_number: contact?.local_primary_phone_number,
                                                      conference_number: contact?.conference_number,
                                                      country_code: contact?.primary_phone_number_country_code,
                                                      country: computedLeadData?.country,
                                                      channel: "Phone",
                                                      email: "",
                                                      title: contact?.primary_phone_number_title,
                                                      type: contact?.primary_phone_number_type,
                                                      card_type: "Edit",
                                                    });
                                                  }}
                                                >
                                                  <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                                </div>
                                                <AppText
                                                  fontSize={12}
                                                  fontWeight={400}
                                                  style={{ color: theme.PRIMARY500 }}
                                                >
                                                  Primary Phone
                                                </AppText>
                                              </IconAndTextDiv>
                                            )}
                                          </div>

                                          <ContactText>{formatPhoneNumber(contact?.primary_phone_number)}</ContactText>
                                        </LeftSizeNumberDiv>
                                        <NumberTitleDiv>
                                          {contact?.primary_phone_number_title ? (
                                            <PersonNumberTitle>
                                              {contact?.primary_phone_number_title}{" "}
                                              {contact?.primary_phone_number_type
                                                ? ` (${contact?.primary_phone_number_type})`
                                                : ``}
                                            </PersonNumberTitle>
                                          ) : (
                                            ""
                                          )}
                                        </NumberTitleDiv>
                                      </div>
                                      {loggedInUser().role !== "ADMIN" && (
                                        <IconDiv>
                                          <TooltipWrapperDiv
                                            data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                            data-for={!!callState ? "lead-card-tooltip" : ""}
                                          >
                                            <LeadCardContactButton
                                              disabled={
                                                !isOwnedByRep || !inCallableStatus || !!callState || !!activeCall
                                              }
                                              onClick={() => {
                                                closeAllModals();
                                                MixpanelActions.track("Lead Card", {
                                                  type: "primary call clicked",
                                                  id: contact?.id,
                                                  source: parentType,
                                                });
                                                goToCall({
                                                  phoneNumber: contact?.primary_phone_number,
                                                  // we need to pass in the associated lead id info here instead of the normal goToCallObject
                                                  // if the normal goToCallObject is passed in it will call correctly but the call will not be associated with the associated lead
                                                  lead_id: contact?.id ?? "",
                                                  intentId: computedLeadData?.next_intent_scheduled_or_unscheduled?.id
                                                    ? computedLeadData?.next_intent_scheduled_or_unscheduled?.id
                                                    : undefined,
                                                  dialAsNumber: isNextDial ? dialAsNumber : undefined,
                                                });
                                                resetNextDial();
                                              }}
                                              callState={callState}
                                              type="phone"
                                              isNextDial={isNextDial}
                                            />
                                          </TooltipWrapperDiv>
                                          {/*international orgs cannot text */}
                                          {!isInternational && (
                                            <TooltipWrapperDiv
                                              data-tip={
                                                leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                              }
                                              data-for={leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined}
                                            >
                                              <LeadCardContactButton
                                                disabled={!isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS}
                                                onClick={() => {
                                                  if (showSMSChat) {
                                                    appToast(
                                                      "There is currently a chat open. Please close the existing chat before opening.",
                                                    );
                                                    return;
                                                  }
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "sms clicked",
                                                    id: contact?.id,
                                                    source: parentType,
                                                  });

                                                  openSMSChat({
                                                    lead_id: contact?.id ?? "",
                                                    phone_number: contact?.primary_phone_number,
                                                    intent: intent,
                                                  });
                                                }}
                                                type="sms"
                                                isNextDial={isNextDial}
                                              />
                                            </TooltipWrapperDiv>
                                          )}
                                        </IconDiv>
                                      )}
                                    </PersonNumberDiv>
                                  )}
                                  {/* assoicated contact alternate phone numbers */}
                                  {returnLeadAlternatePhoneNumbers(contact)?.map(
                                    (item: AlternateContactsType, index: number) =>
                                      !!item.value && (
                                        <PersonNumberDiv key={item.id}>
                                          <div>
                                            <AssociateLeadContactLabel contact={contact} />
                                            <LeftSizeNumberDiv>
                                              {!isInMyScheduleComponent && (
                                                <IconAndTextDiv>
                                                  <div
                                                    style={{ marginRight: "4px" }}
                                                    onClick={() => {
                                                      updateUserToIdle({ do_not_update_if_on_call: true });
                                                      const regex = `+${
                                                        contact?.primary_phone_number_country_code ?? "1"
                                                      }`;
                                                      MixpanelActions.track("Lead Card", {
                                                        type: "edit contact clicked",
                                                        id: intent?.lead_id,
                                                        source: parentType,
                                                      });
                                                      selectContactToEdit({
                                                        lead_id: contact?.id ?? "",
                                                        id: item.id,
                                                        category: "Alternate",
                                                        alternate_phone_mapping_option: item?.mapping_order,
                                                        alternate_phone_mapping_option_label: item.contact_label,
                                                        phone_number: item.channel === "Phone" ? item.value : "",
                                                        local_phone_number:
                                                          item.channel === "Phone" ? item.value.replace(regex, "") : "",
                                                        country_code: contact?.primary_phone_number_country_code,
                                                        country: computedLeadData?.country,
                                                        email: item.channel === "Email" ? item.value : "",
                                                        channel: item.channel,
                                                        title: item.title,
                                                        type: item.type,
                                                        card_type: "Edit",
                                                      });
                                                    }}
                                                  >
                                                    <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                                  </div>
                                                  <AppText
                                                    fontSize={12}
                                                    fontWeight={400}
                                                    style={{ color: theme.PRIMARY500 }}
                                                  >
                                                    {item?.contact_label ??
                                                      `Alternate ${item.channel === "Phone" ? "Phone" : "Email"}`}
                                                  </AppText>
                                                </IconAndTextDiv>
                                              )}
                                              <ContactText>
                                                {item.channel === "Phone" ? formatPhoneNumber(item.value) : item.value}
                                              </ContactText>
                                            </LeftSizeNumberDiv>
                                            <FlexDiv
                                              gap={8}
                                              style={{
                                                marginLeft: "19px",
                                              }}
                                            >
                                              {item.title ? (
                                                <PersonNumberTitle>
                                                  {item.title} {item.type ? ` (${item.type})` : ``}
                                                </PersonNumberTitle>
                                              ) : (
                                                ""
                                              )}
                                              {item.channel === "Phone" && <PersonNumberTitle>|</PersonNumberTitle>}
                                              {item.channel === "Phone" && (
                                                <SwapPrimaryNumberDiv contact_id={item.id} />
                                              )}
                                            </FlexDiv>
                                          </div>
                                          {loggedInUser().role !== "ADMIN" && (
                                            <IconDiv>
                                              {item.channel !== "Phone" ? (
                                                <TooltipWrapperDiv
                                                  data-tip={
                                                    leadUnsubscribedFromEmail
                                                      ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP
                                                      : undefined
                                                  }
                                                  data-for={leadUnsubscribedFromEmail ? "lead-card-tooltip" : undefined}
                                                >
                                                  <LeadCardContactButton
                                                    disabled={
                                                      !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromEmail
                                                    }
                                                    onClick={() => {
                                                      //set email editor
                                                      MixpanelActions.track("Lead Card", {
                                                        type: "email clicked (not primary)",
                                                        id: intent?.lead_id,
                                                        source: parentType,
                                                      });
                                                      !showEmailEditorModal &&
                                                        openEmailEditorModal({
                                                          lead_id: contact?.id ?? "",
                                                          full_name: contact?.full_name,
                                                          email: item.value,
                                                          intent: intent,
                                                          isManualEmailSequenceStep:
                                                            computedLeadData?.sequence_step?.actions?.[0]?.task ===
                                                            "manualEmail",
                                                          sequenceStepId:
                                                            computedLeadData?.sequence_step?.actions?.[0]?.id ?? "",
                                                        });
                                                    }}
                                                    type="email"
                                                    isNextDial={isNextDial}
                                                  />
                                                </TooltipWrapperDiv>
                                              ) : (
                                                <>
                                                  <TooltipWrapperDiv
                                                    data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                                    data-for={!!callState ? "lead-card-tooltip" : ""}
                                                  >
                                                    <LeadCardContactButton
                                                      disabled={
                                                        !isOwnedByRep ||
                                                        !inCallableStatus ||
                                                        !!callState ||
                                                        !!activeCall
                                                      }
                                                      callState={callState}
                                                      onClick={() => {
                                                        MixpanelActions.track("Lead Card", {
                                                          type: "call clicked (not primary)",
                                                          id: intent?.lead_id,
                                                          source: parentType,
                                                        });
                                                        closeAllModals();
                                                        goToCall({
                                                          phoneNumber: item.value,
                                                          lead_id: contact?.id ?? "",
                                                          intentId: dataAssociatedLeads?.fetchAssociateContact?.find(
                                                            (item: any) => item.id === contact?.id,
                                                          )?.next_intent_scheduled_or_unscheduled?.id,
                                                          dialAsNumber: isNextDial ? dialAsNumber : undefined,
                                                        });
                                                        resetNextDial();
                                                      }}
                                                      type="phone"
                                                      isNextDial={isNextDial}
                                                    />
                                                  </TooltipWrapperDiv>
                                                  {!isInternational && (
                                                    <TooltipWrapperDiv
                                                      data-tip={
                                                        leadUnsubscribedFromSMS
                                                          ? SMS_UNSUBSCRIBED_REP_TOOLTIP
                                                          : undefined
                                                      }
                                                      data-for={
                                                        leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined
                                                      }
                                                    >
                                                      <LeadCardContactButton
                                                        type="sms"
                                                        disabled={
                                                          !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS
                                                        }
                                                        onClick={() => {
                                                          if (showSMSChat) {
                                                            appToast(
                                                              "There is currently a chat open. Please close the existing chat before opening.",
                                                            );
                                                            return;
                                                          }
                                                          MixpanelActions.track("Lead Card", {
                                                            type: "sms clicked",
                                                            id: contact?.id,
                                                            source: parentType,
                                                          });

                                                          openSMSChat({
                                                            lead_id: contact?.id ?? "",

                                                            phone_number: item.value,
                                                            intent: intent,
                                                          });
                                                        }}
                                                        isNextDial={isNextDial}
                                                      />
                                                    </TooltipWrapperDiv>
                                                  )}
                                                </>
                                              )}
                                            </IconDiv>
                                          )}
                                        </PersonNumberDiv>
                                      ),
                                  )}
                                </div>
                              ))}
                              {/* Primary Email */}
                              {returnLeadPrimaryEmail(dataLead?.fetchLead) && (
                                <PersonNumberDiv>
                                  <div>
                                    <LeftSizeNumberDiv>
                                      {!isInMyScheduleComponent && (
                                        <IconAndTextDiv>
                                          <div
                                            style={{ marginRight: "4px" }}
                                            onClick={() => {
                                              updateUserToIdle({ do_not_update_if_on_call: true });
                                              MixpanelActions.track("Lead Card", {
                                                type: "edit contact clicked",
                                                id: intent?.lead_id,
                                                source: parentType,
                                              });
                                              selectContactToEdit({
                                                lead_id: dataLead?.fetchLead?.id ?? "",
                                                category: "Primary",
                                                phone_number: "",
                                                local_phone_number: dataLead?.fetchLead?.local_primary_phone_number,
                                                conference_number: dataLead?.fetchLead?.conference_number,
                                                country_code: dataLead?.fetchLead?.primary_phone_number_country_code,
                                                country: computedLeadData?.country,
                                                channel: "Email",
                                                email: dataLead?.fetchLead?.primary_email,
                                                title: dataLead?.fetchLead?.primary_email_title,
                                                type: dataLead?.fetchLead?.primary_email_type,
                                                card_type: "Edit",
                                              });
                                            }}
                                          >
                                            <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                          </div>
                                          <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                                            Primary Email
                                          </AppText>
                                        </IconAndTextDiv>
                                      )}
                                      <ContactText>{dataLead?.fetchLead?.primary_email}</ContactText>
                                    </LeftSizeNumberDiv>
                                    <FlexDiv
                                      gap={8}
                                      style={{
                                        marginLeft: "19px",
                                      }}
                                    >
                                      {dataLead?.fetchLead?.primary_email_title ? (
                                        <PersonNumberTitle>
                                          {dataLead?.fetchLead?.primary_email_title}
                                          {dataLead?.fetchLead?.primary_email_type
                                            ? ` (${dataLead?.fetchLead?.primary_email_type})`
                                            : ``}
                                        </PersonNumberTitle>
                                      ) : (
                                        ""
                                      )}
                                    </FlexDiv>
                                  </div>
                                  {loggedInUser().role !== "ADMIN" && (
                                    <IconDiv>
                                      <TooltipWrapperDiv
                                        data-tip={
                                          leadUnsubscribedFromEmail ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                        }
                                        data-for={leadUnsubscribedFromEmail ? "lead-card-tooltip" : undefined}
                                      >
                                        <LeadCardContactButton
                                          disabled={!isOwnedByRep || !inCallableStatus || leadUnsubscribedFromEmail}
                                          // callState={callState}
                                          onClick={() => {
                                            //set email editor
                                            !showEmailEditorModal &&
                                              openEmailEditorModal({
                                                lead_id: dataLead?.fetchLead?.id ?? "",
                                                full_name: dataLead?.fetchLead?.full_name,
                                                email: dataLead?.fetchLead?.primary_email,
                                                intent: intent,
                                                isManualEmailSequenceStep:
                                                  computedLeadData?.sequence_step?.actions?.[0]?.task === "manualEmail",
                                                sequenceStepId: computedLeadData?.sequence_step?.actions?.[0]?.id,
                                              });
                                          }}
                                          type="email"
                                          isNextDial={isNextDial}
                                        />
                                      </TooltipWrapperDiv>
                                    </IconDiv>
                                  )}
                                </PersonNumberDiv>
                              )}
                              {/* Primary Alternate Contacts EMAILS */}
                              {returnLeadAlternateEmails(dataLead?.fetchLead)?.map(
                                (item: AlternateContactsType) =>
                                  !!item.value && (
                                    <PersonNumberDiv key={item.id}>
                                      <div>
                                        <LeftSizeNumberDiv>
                                          {!isInMyScheduleComponent && (
                                            <IconAndTextDiv>
                                              <div
                                                style={{ marginRight: "4px" }}
                                                onClick={() => {
                                                  updateUserToIdle({ do_not_update_if_on_call: true });
                                                  const regex = `+${
                                                    dataLead?.fetchLead?.primary_phone_number_country_code ?? "1"
                                                  }`;
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "edit contact clicked",
                                                    id: intent?.lead_id,
                                                    source: parentType,
                                                  });
                                                  selectContactToEdit({
                                                    lead_id: dataLead?.fetchLead?.id ?? "",
                                                    id: item.id,
                                                    category: "Alternate",
                                                    alternate_phone_mapping_option: item?.mapping_order,
                                                    alternate_phone_mapping_option_label: item.contact_label,
                                                    phone_number: item.channel === "Phone" ? item.value : "",
                                                    local_phone_number:
                                                      item.channel === "Phone" ? item.value.replace(regex, "") : "",
                                                    country_code:
                                                      dataLead?.fetchLead?.primary_phone_number_country_code,
                                                    country: computedLeadData?.country,
                                                    email: item.channel === "Email" ? item.value : "",
                                                    channel: item.channel,
                                                    title: item.title,
                                                    type: item.type,
                                                    card_type: "Edit",
                                                  });
                                                }}
                                              >
                                                <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                              </div>
                                              <AppText
                                                fontSize={12}
                                                fontWeight={400}
                                                style={{ color: theme.PRIMARY500 }}
                                              >
                                                {item?.contact_label ??
                                                  `Alternate ${item.channel === "Phone" ? "Phone" : "Email"}`}
                                              </AppText>
                                            </IconAndTextDiv>
                                          )}
                                          <ContactText>
                                            {item.channel === "Phone" ? formatPhoneNumber(item.value) : item.value}
                                          </ContactText>
                                        </LeftSizeNumberDiv>
                                        <FlexDiv align="center" gap={8} style={{ marginLeft: "19px" }}>
                                          {item.title ? (
                                            <PersonNumberTitle>
                                              {item.title} {item.type ? ` (${item.type})` : ``}
                                            </PersonNumberTitle>
                                          ) : (
                                            ""
                                          )}
                                          {item.channel === "Phone" && <PersonNumberTitle>|</PersonNumberTitle>}
                                          {item.channel === "Phone" && <SwapPrimaryNumberDiv contact_id={item.id} />}
                                        </FlexDiv>
                                      </div>
                                      {loggedInUser().role !== "ADMIN" && (
                                        <IconDiv>
                                          {item.channel !== "Phone" ? (
                                            <TooltipWrapperDiv
                                              data-tip={
                                                leadUnsubscribedFromEmail ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                              }
                                              data-for={leadUnsubscribedFromEmail ? "lead-card-tooltip" : undefined}
                                            >
                                              <LeadCardContactButton
                                                disabled={
                                                  !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromEmail
                                                }
                                                onClick={() => {
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "email clicked (not primary)",
                                                    id: intent?.lead_id,
                                                    source: parentType,
                                                  });
                                                  !showEmailEditorModal &&
                                                    openEmailEditorModal({
                                                      lead_id: dataLead?.fetchLead?.id ?? "",
                                                      full_name: dataLead?.fetchLead?.full_name,
                                                      email: item.value,
                                                      intent: intent,
                                                      isManualEmailSequenceStep:
                                                        computedLeadData?.sequence_step?.actions?.[0]?.task ===
                                                        "manualEmail",
                                                      sequenceStepId: computedLeadData?.sequence_step?.actions?.[0]?.id,
                                                    });
                                                }}
                                                type="email"
                                                isNextDial={isNextDial}
                                              />
                                            </TooltipWrapperDiv>
                                          ) : (
                                            <>
                                              <TooltipWrapperDiv
                                                data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                                data-for={!!callState ? "lead-card-tooltip" : ""}
                                              >
                                                <LeadCardContactButton
                                                  disabled={
                                                    !isOwnedByRep || !inCallableStatus || !!callState || !!activeCall
                                                  }
                                                  callState={callState}
                                                  onClick={() => {
                                                    MixpanelActions.track("Lead Card", {
                                                      type: "call clicked (not primary)",
                                                      id: intent?.lead_id,
                                                      source: parentType,
                                                    });
                                                    closeAllModals();
                                                    goToCall({
                                                      phoneNumber: item.value,
                                                      ...goToCallObjectForPrimary,
                                                    });
                                                    resetNextDial();
                                                  }}
                                                  type="phone"
                                                  isNextDial={isNextDial}
                                                />
                                              </TooltipWrapperDiv>
                                              {!isInternational && (
                                                <TooltipWrapperDiv
                                                  data-tip={
                                                    leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                                  }
                                                  data-for={leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined}
                                                >
                                                  <LeadCardContactButton
                                                    disabled={
                                                      !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS
                                                    }
                                                    onClick={() => {
                                                      if (showSMSChat) {
                                                        appToast(
                                                          "There is currently a chat open. Please close the existing chat before opening.",
                                                        );
                                                        return;
                                                      }
                                                      MixpanelActions.track("Lead Card", {
                                                        type: "sms clicked",
                                                        id: dataLead?.fetchLead?.id,
                                                        source: parentType,
                                                      });

                                                      openSMSChat({
                                                        lead_id: dataLead?.fetchLead?.id ?? "",
                                                        phone_number: item.value,
                                                        intent: intent,
                                                      });
                                                    }}
                                                    type="sms"
                                                    isNextDial={isNextDial}
                                                  />
                                                </TooltipWrapperDiv>
                                              )}
                                            </>
                                          )}
                                        </IconDiv>
                                      )}
                                    </PersonNumberDiv>
                                  ),
                              )}

                              {/* associated contacts primary and alternate emails */}

                              {dataAssociatedLeads?.fetchAssociateContact?.map((contact: any, index: number) => (
                                <div>
                                  {/* Associated Primary Email */}
                                  {contact?.primary_email && (
                                    <PersonNumberDiv>
                                      <div>
                                        <AssociateLeadContactLabel contact={contact} />
                                        <LeftSizeNumberDiv>
                                          {!isInMyScheduleComponent && (
                                            <IconAndTextDiv>
                                              <div
                                                style={{ marginRight: "4px" }}
                                                onClick={() => {
                                                  updateUserToIdle({ do_not_update_if_on_call: true });
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "edit contact clicked",
                                                    id: intent?.lead_id,
                                                    source: parentType,
                                                  });
                                                  selectContactToEdit({
                                                    lead_id: contact?.id ?? "",
                                                    category: "Primary",
                                                    phone_number: "",
                                                    local_phone_number: contact?.local_primary_phone_number,
                                                    conference_number: contact?.conference_number,
                                                    country_code: contact?.primary_phone_number_country_code,
                                                    country: computedLeadData?.country,
                                                    channel: "Email",
                                                    email: contact?.primary_email,
                                                    title: contact?.primary_email_title,
                                                    type: contact?.primary_email_type,
                                                    card_type: "Edit",
                                                  });
                                                }}
                                              >
                                                <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                              </div>
                                              <AppText
                                                fontSize={12}
                                                fontWeight={400}
                                                style={{ color: theme.PRIMARY500 }}
                                              >
                                                Primary Email
                                              </AppText>
                                            </IconAndTextDiv>
                                          )}
                                          <ContactText>{contact?.primary_email}</ContactText>
                                        </LeftSizeNumberDiv>
                                        <NumberTitleDiv>
                                          {contact?.primary_email_title ? (
                                            <PersonNumberTitle>
                                              {contact?.primary_email_title}{" "}
                                              {contact?.primary_email_type ? ` (${contact?.primary_email_type})` : ``}
                                            </PersonNumberTitle>
                                          ) : (
                                            ""
                                          )}
                                        </NumberTitleDiv>
                                      </div>
                                      {loggedInUser().role !== "ADMIN" && (
                                        <IconDiv>
                                          <TooltipWrapperDiv
                                            data-tip={
                                              leadUnsubscribedFromEmail ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                            }
                                            data-for={leadUnsubscribedFromEmail ? "lead-card-tooltip" : undefined}
                                          >
                                            <LeadCardContactButton
                                              disabled={!isOwnedByRep || !inCallableStatus || leadUnsubscribedFromEmail}
                                              onClick={() => {
                                                //set email editor
                                                !showEmailEditorModal &&
                                                  openEmailEditorModal({
                                                    lead_id: contact?.id ?? "",
                                                    full_name: contact?.full_name,
                                                    email: contact?.primary_email,
                                                    intent: intent,
                                                    isManualEmailSequenceStep:
                                                      computedLeadData?.sequence_step?.actions?.[0]?.task ===
                                                      "manualEmail",
                                                    sequenceStepId: computedLeadData?.sequence_step?.actions?.[0]?.id,
                                                  });
                                              }}
                                              type="email"
                                              isNextDial={isNextDial}
                                            />
                                          </TooltipWrapperDiv>
                                        </IconDiv>
                                      )}
                                    </PersonNumberDiv>
                                  )}

                                  {returnLeadAlternateEmails(contact)?.map(
                                    (item: AlternateContactsType, index: number) =>
                                      !!item.value && (
                                        <PersonNumberDiv key={item.id}>
                                          <div>
                                            <AssociateLeadContactLabel contact={contact} />
                                            <LeftSizeNumberDiv>
                                              {!isInMyScheduleComponent && (
                                                <IconAndTextDiv>
                                                  <div
                                                    style={{ marginRight: "4px" }}
                                                    onClick={() => {
                                                      updateUserToIdle({ do_not_update_if_on_call: true });
                                                      const regex = `+${
                                                        contact?.primary_phone_number_country_code ?? "1"
                                                      }`;
                                                      MixpanelActions.track("Lead Card", {
                                                        type: "edit contact clicked",
                                                        id: intent?.lead_id,
                                                        source: parentType,
                                                      });
                                                      selectContactToEdit({
                                                        lead_id: contact?.id ?? "",
                                                        id: item.id,
                                                        category: "Alternate",
                                                        alternate_phone_mapping_option: item?.mapping_order,
                                                        alternate_phone_mapping_option_label: item.contact_label,
                                                        phone_number: item.channel === "Phone" ? item.value : "",
                                                        local_phone_number:
                                                          item.channel === "Phone" ? item.value.replace(regex, "") : "",
                                                        country_code: contact?.primary_phone_number_country_code,
                                                        country: computedLeadData?.country,
                                                        email: item.channel === "Email" ? item.value : "",
                                                        channel: item.channel,
                                                        title: item.title,
                                                        type: item.type,
                                                        card_type: "Edit",
                                                      });
                                                    }}
                                                  >
                                                    <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                                  </div>
                                                  <AppText
                                                    fontSize={12}
                                                    fontWeight={400}
                                                    style={{ color: theme.PRIMARY500 }}
                                                  >
                                                    {item?.contact_label ??
                                                      `Alternate ${item.channel === "Phone" ? "Phone" : "Email"}`}
                                                  </AppText>
                                                </IconAndTextDiv>
                                              )}
                                              <ContactText>
                                                {item.channel === "Phone" ? formatPhoneNumber(item.value) : item.value}
                                              </ContactText>
                                            </LeftSizeNumberDiv>
                                            <FlexDiv gap={8}>
                                              {item.title ? (
                                                <AlternateSubTextLabel>
                                                  {item.title} {item.type ? ` (${item.type})` : ``}
                                                </AlternateSubTextLabel>
                                              ) : (
                                                ""
                                              )}
                                            </FlexDiv>
                                          </div>
                                          {loggedInUser().role !== "ADMIN" && (
                                            <IconDiv>
                                              {item.channel !== "Phone" ? (
                                                <TooltipWrapperDiv
                                                  data-tip={
                                                    leadUnsubscribedFromEmail
                                                      ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP
                                                      : undefined
                                                  }
                                                  data-for={leadUnsubscribedFromEmail ? "lead-card-tooltip" : undefined}
                                                >
                                                  <LeadCardContactButton
                                                    disabled={
                                                      !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromEmail
                                                    }
                                                    // callState={callState}
                                                    onClick={() => {
                                                      //set email editor
                                                      MixpanelActions.track("Lead Card", {
                                                        type: "email clicked (not primary)",
                                                        id: intent?.lead_id,
                                                        source: parentType,
                                                      });
                                                      !showEmailEditorModal &&
                                                        openEmailEditorModal({
                                                          lead_id: contact?.id ?? "",
                                                          full_name: contact?.full_name,
                                                          email: item.value,
                                                          intent: intent,
                                                          isManualEmailSequenceStep:
                                                            computedLeadData?.sequence_step?.actions?.[0]?.task ===
                                                            "manualEmail",
                                                          sequenceStepId:
                                                            computedLeadData?.sequence_step?.actions?.[0]?.id,
                                                        });
                                                    }}
                                                    type="email"
                                                    isNextDial={isNextDial}
                                                  />
                                                </TooltipWrapperDiv>
                                              ) : (
                                                <>
                                                  <TooltipWrapperDiv
                                                    data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                                    data-for={!!callState ? "lead-card-tooltip" : ""}
                                                  >
                                                    <LeadCardContactButton
                                                      disabled={
                                                        !isOwnedByRep ||
                                                        !inCallableStatus ||
                                                        !!callState ||
                                                        !!activeCall
                                                      }
                                                      callState={callState}
                                                      onClick={() => {
                                                        MixpanelActions.track("Lead Card", {
                                                          type: "call clicked (not primary)",
                                                          id: intent?.lead_id,
                                                          source: parentType,
                                                        });
                                                        closeAllModals();
                                                        goToCall({
                                                          phoneNumber: item.value,
                                                          lead_id: contact?.id ?? "",
                                                          intentId: dataAssociatedLeads?.fetchAssociateContact?.find(
                                                            (item: any) => item.id === contact?.id,
                                                          )?.next_intent_scheduled_or_unscheduled?.id,
                                                          dialAsNumber: isNextDial ? dialAsNumber : undefined,
                                                        });
                                                        resetNextDial();
                                                      }}
                                                      type="phone"
                                                      isNextDial={isNextDial}
                                                    />
                                                  </TooltipWrapperDiv>
                                                  {!isInternational && (
                                                    <TooltipWrapperDiv
                                                      data-tip={
                                                        leadUnsubscribedFromSMS
                                                          ? SMS_UNSUBSCRIBED_REP_TOOLTIP
                                                          : undefined
                                                      }
                                                      data-for={
                                                        leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined
                                                      }
                                                    >
                                                      <LeadCardContactButton
                                                        disabled={
                                                          !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS
                                                        }
                                                        // callState={callState}
                                                        onClick={() => {
                                                          if (showSMSChat) {
                                                            appToast(
                                                              "There is currently a chat open. Please close the existing chat before opening.",
                                                            );
                                                            return;
                                                          }
                                                          MixpanelActions.track("Lead Card", {
                                                            type: "sms clicked",
                                                            id: contact?.id,
                                                            source: parentType,
                                                          });

                                                          openSMSChat({
                                                            lead_id: contact?.id ?? "",

                                                            phone_number: item.value,
                                                            intent: intent,
                                                          });
                                                        }}
                                                        type="sms"
                                                        isNextDial={isNextDial}
                                                      />
                                                    </TooltipWrapperDiv>
                                                  )}
                                                </>
                                              )}
                                            </IconDiv>
                                          )}
                                        </PersonNumberDiv>
                                      ),
                                  )}
                                </div>
                              ))}
                            </div>
                          )}

                          {/* conference number tab */}
                          {contactTab === "screen-sharing" && screenSharingPhoneNumber && (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              {/* Primary conference number */}
                              {returnLeadScreensharingNumber(dataLead?.fetchLead) && (
                                <PersonNumberDiv>
                                  <LeftSizeNumberDiv>
                                    {!isInMyScheduleComponent && !!dataLead?.fetchLead && (
                                      <IconAndTextDiv>
                                        <div
                                          style={{ marginRight: "4px" }}
                                          onClick={() => {
                                            updateUserToIdle({ do_not_update_if_on_call: true });
                                            MixpanelActions.track("Lead Card", {
                                              type: "edit contact clicked",
                                              id: dataLead?.fetchLead?.id,
                                              source: parentType,
                                            });

                                            const regex = `+${
                                              dataLead?.fetchLead?.primary_phone_number_country_code ?? "1"
                                            }`;
                                            selectContactToEdit({
                                              lead_id: computedLeadData?.id ?? "",
                                              id: computedLeadData?.id ?? "",
                                              category: "Primary",
                                              conference_number:
                                                dataLead?.fetchLead?.conference_number?.replace(regex, "") ?? "",
                                              country_code: dataLead?.fetchLead?.primary_phone_number_country_code,
                                              country: computedLeadData?.country,
                                              channel: "Conference",
                                              email: "",
                                              title: dataLead?.fetchLead?.primary_phone_number_title,
                                              type: dataLead?.fetchLead?.primary_phone_number_type,

                                              card_type: "Edit",
                                            });
                                          }}
                                        >
                                          <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                        </div>
                                        <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                                          Screen Sharing Number
                                        </AppText>
                                      </IconAndTextDiv>
                                    )}
                                    <ContactText>
                                      {formatPhoneNumber(dataLead?.fetchLead?.conference_number)}
                                    </ContactText>
                                  </LeftSizeNumberDiv>
                                  {loggedInUser().role !== "ADMIN" && (
                                    <IconDiv>
                                      <TooltipWrapperDiv
                                        data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                        data-for={!!callState ? "lead-card-tooltip" : ""}
                                      >
                                        <LeadCardContactButton
                                          disabled={!isOwnedByRep || !inCallableStatus || !!callState || !!activeCall}
                                          onClick={() => {
                                            closeAllModals();
                                            MixpanelActions.track("Lead Card", {
                                              type: "primary call clicked",
                                              id: dataLead?.fetchLead?.id,
                                              source: parentType,
                                            });
                                            goToCall({
                                              phoneNumber: dataLead?.fetchLead?.conference_number,
                                              ...goToCallObjectForPrimary,
                                            });
                                            resetNextDial();
                                          }}
                                          callState={callState}
                                          type="phone"
                                          isNextDial={isNextDial}
                                        />
                                      </TooltipWrapperDiv>

                                      {!isInternational && (
                                        <TooltipWrapperDiv
                                          data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined}
                                          data-for={leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined}
                                        >
                                          <LeadCardContactButton
                                            disabled={!isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS}
                                            // callState={callState}
                                            onClick={() => {
                                              if (showSMSChat) {
                                                appToast(
                                                  "There is currently a chat open. Please close the existing chat before opening.",
                                                );
                                                return;
                                              }
                                              MixpanelActions.track("Lead Card", {
                                                type: "sms clicked",
                                                id: dataLead?.fetchLead?.id,
                                                source: parentType,
                                              });

                                              openSMSChat({
                                                lead_id: dataLead?.fetchLead?.id ?? "",

                                                phone_number: dataLead?.fetchLead?.conference_number,
                                                intent: intent,
                                              });
                                            }}
                                            type="sms"
                                            isNextDial={isNextDial}
                                          />
                                        </TooltipWrapperDiv>
                                      )}
                                    </IconDiv>
                                  )}
                                </PersonNumberDiv>
                              )}
                              {/* Associated contacts conference number */}
                              {dataAssociatedLeads?.fetchAssociateContact?.map(
                                (contact: CurrentLeadType, index: number) => (
                                  <>
                                    {/* conference number */}
                                    {screenSharingPhoneNumber && contact?.conference_number && (
                                      <PersonNumberDiv>
                                        <div>
                                          <AssociateLeadContactLabel contact={contact} />
                                          <LeftSizeNumberDiv>
                                            {!isInMyScheduleComponent && !!dataLead?.fetchLead && (
                                              <IconAndTextDiv>
                                                <div
                                                  style={{ marginRight: "4px" }}
                                                  onClick={() => {
                                                    updateUserToIdle({ do_not_update_if_on_call: true });
                                                    MixpanelActions.track("Lead Card", {
                                                      type: "edit contact clicked",
                                                      id: contact?.id,
                                                      source: parentType,
                                                    });

                                                    const regex = `+${
                                                      contact?.primary_phone_number_country_code ?? "1"
                                                    }`;
                                                    selectContactToEdit({
                                                      lead_id: computedLeadData?.id ?? "",
                                                      id: computedLeadData?.id ?? "",
                                                      category: "Primary",
                                                      conference_number:
                                                        contact?.conference_number?.replace(regex, "") ?? "",
                                                      country_code: contact?.primary_phone_number_country_code,
                                                      country: computedLeadData?.country,
                                                      channel: "Conference",
                                                      email: "",
                                                      title: contact?.primary_phone_number_title,
                                                      type: contact?.primary_phone_number_type,

                                                      card_type: "Edit",
                                                    });
                                                  }}
                                                >
                                                  <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                                </div>
                                                <AppText
                                                  fontSize={12}
                                                  fontWeight={400}
                                                  style={{ color: theme.PRIMARY500 }}
                                                >
                                                  Screen Sharing Number
                                                </AppText>
                                              </IconAndTextDiv>
                                            )}
                                            <ContactText>{formatPhoneNumber(contact?.conference_number)}</ContactText>
                                          </LeftSizeNumberDiv>
                                        </div>
                                        {loggedInUser().role !== "ADMIN" && (
                                          <IconDiv>
                                            <TooltipWrapperDiv
                                              data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                              data-for={!!callState ? "lead-card-tooltip" : ""}
                                            >
                                              <LeadCardContactButton
                                                disabled={
                                                  !isOwnedByRep || !inCallableStatus || !!callState || !!activeCall
                                                }
                                                onClick={() => {
                                                  closeAllModals();
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "primary call clicked",
                                                    id: contact?.id,
                                                    source: parentType,
                                                  });
                                                  goToCall({
                                                    phoneNumber: contact?.conference_number ?? "",
                                                    lead_id: contact?.id ?? "",
                                                    intentId: dataAssociatedLeads?.fetchAssociateContact?.find(
                                                      (item: any) => item.id === contact?.id,
                                                    )?.next_intent_scheduled_or_unscheduled?.id,
                                                    dialAsNumber: isNextDial ? dialAsNumber : undefined,
                                                  });
                                                  resetNextDial();
                                                }}
                                                callState={callState}
                                                type="phone"
                                                isNextDial={isNextDial}
                                              />
                                            </TooltipWrapperDiv>

                                            {!isInternational && (
                                              <TooltipWrapperDiv
                                                data-tip={
                                                  leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined
                                                }
                                                data-for={leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined}
                                              >
                                                <LeadCardContactButton
                                                  disabled={
                                                    !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS
                                                  }
                                                  onClick={() => {
                                                    if (showSMSChat) {
                                                      appToast(
                                                        "There is currently a chat open. Please close the existing chat before opening.",
                                                      );
                                                      return;
                                                    }
                                                    MixpanelActions.track("Lead Card", {
                                                      type: "sms clicked",
                                                      id: contact?.id,
                                                      source: parentType,
                                                    });

                                                    openSMSChat({
                                                      lead_id: contact?.id ?? "",

                                                      phone_number: contact?.conference_number ?? "",
                                                      intent: intent,
                                                    });
                                                  }}
                                                  type="sms"
                                                  isNextDial={isNextDial}
                                                />
                                              </TooltipWrapperDiv>
                                            )}
                                          </IconDiv>
                                        )}
                                      </PersonNumberDiv>
                                    )}
                                  </>
                                ),
                              )}
                            </div>
                          )}

                          {contactTab === "screen-sharing" && leadGroupHasNoScreenSharingNumbers && (
                            <AppText fontSize={12} style={{ marginTop: "12px", marginBottom: "4px" }} fontWeight={400}>
                              This lead has no screen sharing numbers. Add one now.
                            </AppText>
                          )}

                          {contactTab !== "associated-contacts" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                                marginTop: "8px",
                                paddingRight: "3.5px",
                              }}
                            >
                              <div>
                                <NewAppButton
                                  variant="primary"
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    height: "40px",
                                    width: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0px",
                                    borderRadius: "4px",
                                  }}
                                  onClick={() => {
                                    updateUserToIdle({ do_not_update_if_on_call: true });
                                    MixpanelActions.track("Lead Card", {
                                      type: "add alternate contact",
                                      id: dataLead?.fetchLead?.id,
                                      source: parentType,
                                    });
                                    selectContactToAdd({
                                      lead_id: dataLead?.fetchLead?.id ?? "",
                                      country_code: dataLead?.fetchLead?.primary_phone_number_country_code,
                                      card_type: "Add",
                                      title: "Alternate",
                                      country: computedLeadData?.country,
                                      // if the lead already has a conference number they can't add another
                                      has_conference_number: !!computedLeadData?.conference_number,
                                      // for alternate numbers only
                                    });

                                    setShowAddOrEditContactModal(true);
                                    setShowAddAssociateModal(false);
                                  }}
                                  data-tip="Add alternate contact:|Phone, Email, Screensharing number"
                                  data-for="alt-contact-info-tooltip"
                                >
                                  <>
                                    <StyledTooltip
                                      multiline
                                      place="right"
                                      effect="solid"
                                      id="alt-contact-info-tooltip"
                                      backgroundColor={theme.PRIMARY800}
                                      getContent={(dataTip) => (
                                        <div
                                          style={{
                                            fontFamily: "Inter",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "10px",
                                            lineHeight: "14px",
                                            width: "180px",
                                          }}
                                        >
                                          <div>{dataTip?.split("|")[0]}</div>
                                          <div>{dataTip?.split("|")[1]}</div>
                                        </div>
                                      )}
                                    />

                                    <img src={contact_info_add} alt="add alternative contact information" />
                                  </>
                                </NewAppButton>
                              </div>
                            </div>
                          )}
                          {/* associated contacts tab */}
                          {!isAssociatedLead && contactTab === "associated-contacts" && (
                            <AssociatedContactsDiv>
                              {!dataAssociatedLeads?.fetchAssociateContact?.length ? (
                                <>
                                  <AppText fontSize={12} style={{ marginTop: "12px", marginBottom: "4px" }}>
                                    This lead has no associated contacts. Add one now.
                                  </AppText>
                                </>
                              ) : (
                                <FlexDiv
                                  direction="column"
                                  style={{
                                    maxHeight: "400px",
                                    overflowY: "auto",
                                  }}
                                >
                                  {dataAssociatedLeads?.fetchAssociateContact?.map((contact: any, index: number) => (
                                    <AssociatedContact>
                                      <FlexDiv align="center" justify="space-between">
                                        <IconAndTextDiv>
                                          <StatCard icon={associated_white} variant="secondary" text="ASSOCIATED" />

                                          <AppText
                                            style={{
                                              marginLeft: "8px",
                                            }}
                                          >
                                            {contact?.full_name
                                              ? contact?.full_name
                                              : contact?.first_name && contact?.last_name
                                              ? `${contact?.last_name}, ${contact?.first_name}`
                                              : "No Name"}
                                          </AppText>
                                        </IconAndTextDiv>
                                        <IconAndTextDiv>
                                          <EditButton leadId={contact?.id} parentType={parentType} />
                                          <div style={{ marginLeft: "4px", marginTop: "6px" }}>
                                            <ExpandButton
                                              handleClick={() => expandAssociateList(contact?.id)}
                                              isExpanded={expandedAssociateList?.includes(contact?.id)}
                                            />
                                          </div>
                                        </IconAndTextDiv>
                                      </FlexDiv>
                                      {expandedAssociateList?.includes(contact?.id) && (
                                        <div>
                                          {contact && contact?.primary_phone_number && (
                                            <PersonNumberDiv>
                                              <div>
                                                <LeftSizeNumberDiv>
                                                  <div>
                                                    {!isInMyScheduleComponent && !!dataLead?.fetchLead && (
                                                      <IconAndTextDiv>
                                                        <div
                                                          style={{ marginRight: "4px" }}
                                                          onClick={() => {
                                                            updateUserToIdle({ do_not_update_if_on_call: true });
                                                            MixpanelActions.track("Lead Card", {
                                                              type: "edit contact clicked",
                                                              id: dataLead?.fetchLead?.id,
                                                              source: parentType,
                                                            });
                                                            selectContactToEdit({
                                                              lead_id: contact?.id ?? "",
                                                              id: contact?.id ?? "",
                                                              category: "Primary",
                                                              phone_number: contact?.primary_phone_number,
                                                              local_phone_number: contact?.local_primary_phone_number,
                                                              conference_number: contact?.conference_number,
                                                              country_code: contact?.primary_phone_number_country_code,
                                                              country: computedLeadData?.country,
                                                              channel: "Phone",
                                                              email: "",
                                                              title: contact?.primary_phone_number_title,
                                                              type: contact?.primary_phone_number_type,
                                                              card_type: "Edit",
                                                            });
                                                          }}
                                                        >
                                                          <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                                        </div>
                                                        <AppText
                                                          fontSize={12}
                                                          fontWeight={400}
                                                          style={{ color: theme.PRIMARY500 }}
                                                        >
                                                          Primary Phone
                                                        </AppText>
                                                      </IconAndTextDiv>
                                                    )}
                                                  </div>

                                                  <ContactText>
                                                    {formatPhoneNumber(contact?.primary_phone_number)}
                                                  </ContactText>
                                                </LeftSizeNumberDiv>
                                                <NumberTitleDiv>
                                                  {contact?.primary_phone_number_title ? (
                                                    <PersonNumberTitle>
                                                      {contact?.primary_phone_number_title}{" "}
                                                      {contact?.primary_phone_number_type
                                                        ? ` (${contact?.primary_phone_number_type})`
                                                        : ``}
                                                    </PersonNumberTitle>
                                                  ) : (
                                                    ""
                                                  )}
                                                </NumberTitleDiv>
                                              </div>
                                              {loggedInUser().role !== "ADMIN" && (
                                                <IconDiv>
                                                  <TooltipWrapperDiv
                                                    data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                                    data-for={!!callState ? "lead-card-tooltip" : ""}
                                                  >
                                                    <LeadCardContactButton
                                                      disabled={
                                                        !isOwnedByRep ||
                                                        !inCallableStatus ||
                                                        !!callState ||
                                                        !!activeCall
                                                      }
                                                      onClick={() => {
                                                        closeAllModals();
                                                        MixpanelActions.track("Lead Card", {
                                                          type: "primary call clicked",
                                                          id: contact?.id,
                                                          source: parentType,
                                                        });
                                                        goToCall({
                                                          phoneNumber: contact?.primary_phone_number,
                                                          // we need to pass in the associated lead id info here instead of the normal goToCallObject
                                                          // if the normal goToCallObject is passed in it will call correctly but the call will not be associated with the associated lead
                                                          lead_id: contact?.id ?? "",
                                                          intentId: computedLeadData
                                                            ?.next_intent_scheduled_or_unscheduled?.id
                                                            ? computedLeadData?.next_intent_scheduled_or_unscheduled?.id
                                                            : undefined,
                                                          dialAsNumber: isNextDial ? dialAsNumber : undefined,
                                                        });
                                                        resetNextDial();
                                                      }}
                                                      callState={callState}
                                                      type="phone"
                                                      isNextDial={isNextDial}
                                                    />
                                                  </TooltipWrapperDiv>
                                                  {/*international orgs cannot text */}
                                                  {!isInternational && (
                                                    <TooltipWrapperDiv
                                                      data-tip={
                                                        leadUnsubscribedFromSMS
                                                          ? SMS_UNSUBSCRIBED_REP_TOOLTIP
                                                          : undefined
                                                      }
                                                      data-for={
                                                        leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined
                                                      }
                                                    >
                                                      <LeadCardContactButton
                                                        disabled={
                                                          !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS
                                                        }
                                                        onClick={() => {
                                                          if (showSMSChat) {
                                                            appToast(
                                                              "There is currently a chat open. Please close the existing chat before opening.",
                                                            );
                                                            return;
                                                          }
                                                          MixpanelActions.track("Lead Card", {
                                                            type: "sms clicked",
                                                            id: contact?.id,
                                                            source: parentType,
                                                          });

                                                          openSMSChat({
                                                            lead_id: contact?.id ?? "",
                                                            phone_number: contact?.primary_phone_number,
                                                            intent: intent,
                                                          });
                                                        }}
                                                        type="sms"
                                                        isNextDial={isNextDial}
                                                      />
                                                    </TooltipWrapperDiv>
                                                  )}
                                                </IconDiv>
                                              )}
                                            </PersonNumberDiv>
                                          )}

                                          {/* //---------------------------------------- */}
                                          {contact?.primary_email && (
                                            <PersonNumberDiv>
                                              <div>
                                                <LeftSizeNumberDiv>
                                                  {!isInMyScheduleComponent && (
                                                    <IconAndTextDiv>
                                                      <div
                                                        style={{ marginRight: "4px" }}
                                                        onClick={() => {
                                                          updateUserToIdle({ do_not_update_if_on_call: true });
                                                          MixpanelActions.track("Lead Card", {
                                                            type: "edit contact clicked",
                                                            id: intent?.lead_id,
                                                            source: parentType,
                                                          });
                                                          selectContactToEdit({
                                                            lead_id: contact?.id ?? "",

                                                            category: "Primary",
                                                            phone_number: "",
                                                            local_phone_number: contact?.local_primary_phone_number,
                                                            conference_number: contact?.conference_number,
                                                            country_code: contact?.primary_phone_number_country_code,
                                                            country: computedLeadData?.country,
                                                            channel: "Email",
                                                            email: contact?.primary_email,
                                                            title: contact?.primary_email_title,
                                                            type: contact?.primary_email_type,
                                                            card_type: "Edit",
                                                          });
                                                        }}
                                                      >
                                                        <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                                      </div>
                                                      <AppText
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        style={{ color: theme.PRIMARY500 }}
                                                      >
                                                        Primary Email
                                                      </AppText>
                                                    </IconAndTextDiv>
                                                  )}
                                                  <ContactText>{contact?.primary_email}</ContactText>
                                                </LeftSizeNumberDiv>
                                                <FlexDiv
                                                  gap={8}
                                                  style={{
                                                    marginLeft: "19px",
                                                  }}
                                                >
                                                  {contact?.primary_email_title ? (
                                                    <PersonNumberTitle>
                                                      {contact?.primary_email_title}
                                                      {contact?.primary_email_type
                                                        ? ` (${contact?.primary_email_type})`
                                                        : ``}
                                                    </PersonNumberTitle>
                                                  ) : (
                                                    ""
                                                  )}
                                                </FlexDiv>
                                              </div>
                                              {loggedInUser().role !== "ADMIN" && (
                                                <IconDiv>
                                                  <TooltipWrapperDiv
                                                    data-tip={
                                                      leadUnsubscribedFromEmail
                                                        ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP
                                                        : undefined
                                                    }
                                                    data-for={
                                                      leadUnsubscribedFromEmail ? "lead-card-tooltip" : undefined
                                                    }
                                                  >
                                                    <LeadCardContactButton
                                                      disabled={
                                                        !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromEmail
                                                      }
                                                      onClick={() => {
                                                        //set email editor
                                                        !showEmailEditorModal &&
                                                          openEmailEditorModal({
                                                            lead_id: contact?.id ?? "",
                                                            full_name: contact?.full_name,
                                                            email: contact?.primary_email,
                                                            intent: intent,
                                                            isManualEmailSequenceStep:
                                                              computedLeadData?.sequence_step?.actions?.[0]?.task ===
                                                              "manualEmail",
                                                            sequenceStepId:
                                                              computedLeadData?.sequence_step?.actions?.[0]?.id,
                                                          });
                                                      }}
                                                      type="email"
                                                      isNextDial={isNextDial}
                                                    />
                                                  </TooltipWrapperDiv>
                                                </IconDiv>
                                              )}
                                            </PersonNumberDiv>
                                          )}

                                          {/* conference number */}
                                          {screenSharingPhoneNumber && contact?.conference_number && (
                                            <PersonNumberDiv>
                                              <LeftSizeNumberDiv>
                                                {!isInMyScheduleComponent && !!dataLead?.fetchLead && (
                                                  <IconAndTextDiv>
                                                    <div
                                                      style={{ marginRight: "4px" }}
                                                      onClick={() => {
                                                        updateUserToIdle({ do_not_update_if_on_call: true });
                                                        MixpanelActions.track("Lead Card", {
                                                          type: "edit contact clicked",
                                                          id: contact?.id,
                                                          source: parentType,
                                                        });

                                                        const regex = `+${
                                                          contact?.primary_phone_number_country_code ?? "1"
                                                        }`;
                                                        selectContactToEdit({
                                                          lead_id: computedLeadData?.id ?? "",
                                                          id: computedLeadData?.id ?? "",
                                                          category: "Primary",
                                                          conference_number:
                                                            contact?.conference_number?.replace(regex, "") ?? "",
                                                          country_code: contact?.primary_phone_number_country_code,
                                                          country: computedLeadData?.country,
                                                          channel: "Conference",
                                                          email: "",
                                                          title: contact?.primary_phone_number_title,
                                                          type: contact?.primary_phone_number_type,

                                                          card_type: "Edit",
                                                        });
                                                      }}
                                                    >
                                                      <FiEdit size={14} color={theme.PRIMARY500} cursor="pointer" />
                                                    </div>
                                                    <AppText
                                                      fontSize={12}
                                                      fontWeight={400}
                                                      style={{ color: theme.PRIMARY500 }}
                                                    >
                                                      Screen Sharing Number
                                                    </AppText>
                                                  </IconAndTextDiv>
                                                )}

                                                <ContactText>
                                                  {formatPhoneNumber(contact?.conference_number)}
                                                </ContactText>
                                              </LeftSizeNumberDiv>
                                              {loggedInUser().role !== "ADMIN" && (
                                                <IconDiv>
                                                  <TooltipWrapperDiv
                                                    data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                                    data-for={!!callState ? "lead-card-tooltip" : ""}
                                                  >
                                                    <LeadCardContactButton
                                                      disabled={
                                                        !isOwnedByRep ||
                                                        !inCallableStatus ||
                                                        !!callState ||
                                                        !!activeCall
                                                      }
                                                      onClick={() => {
                                                        closeAllModals();
                                                        MixpanelActions.track("Lead Card", {
                                                          type: "primary call clicked",
                                                          id: contact?.id,
                                                          source: parentType,
                                                        });
                                                        goToCall({
                                                          phoneNumber: contact?.conference_number,
                                                          lead_id: contact?.id ?? "",
                                                          intentId: dataAssociatedLeads?.fetchAssociateContact?.find(
                                                            (item: any) => item.id === contact?.id,
                                                          )?.next_intent_scheduled_or_unscheduled?.id,
                                                          dialAsNumber: isNextDial ? dialAsNumber : undefined,
                                                        });
                                                        resetNextDial();
                                                      }}
                                                      callState={callState}
                                                      type="phone"
                                                      isNextDial={isNextDial}
                                                    />
                                                  </TooltipWrapperDiv>

                                                  {!isInternational && (
                                                    <TooltipWrapperDiv
                                                      data-tip={
                                                        leadUnsubscribedFromSMS
                                                          ? SMS_UNSUBSCRIBED_REP_TOOLTIP
                                                          : undefined
                                                      }
                                                      data-for={
                                                        leadUnsubscribedFromSMS ? "lead-card-tooltip" : undefined
                                                      }
                                                    >
                                                      <LeadCardContactButton
                                                        disabled={
                                                          !isOwnedByRep || !inCallableStatus || leadUnsubscribedFromSMS
                                                        }
                                                        onClick={() => {
                                                          if (showSMSChat) {
                                                            appToast(
                                                              "There is currently a chat open. Please close the existing chat before opening.",
                                                            );
                                                            return;
                                                          }
                                                          MixpanelActions.track("Lead Card", {
                                                            type: "sms clicked",
                                                            id: contact?.id,
                                                            source: parentType,
                                                          });

                                                          openSMSChat({
                                                            lead_id: contact?.id ?? "",

                                                            phone_number: contact?.conference_number,
                                                            intent: intent,
                                                          });
                                                        }}
                                                        type="sms"
                                                        isNextDial={isNextDial}
                                                      />
                                                    </TooltipWrapperDiv>
                                                  )}
                                                </IconDiv>
                                              )}
                                            </PersonNumberDiv>
                                          )}

                                          {!!contact?.alternate_contacts &&
                                            contact?.alternate_contacts
                                              .slice()
                                              ?.sort((a: AlternateContactsType, b: AlternateContactsType) => {
                                                if (a?.mapping_order && b?.mapping_order) {
                                                  return a?.mapping_order - b?.mapping_order;
                                                }
                                                return 0;
                                              })
                                              ?.map(
                                                (item: AlternateContactsType, index: number) =>
                                                  !!item.value && (
                                                    <PersonNumberDiv key={item.id}>
                                                      <div>
                                                        <LeftSizeNumberDiv>
                                                          {!isInMyScheduleComponent && (
                                                            <IconAndTextDiv>
                                                              <div
                                                                style={{ marginRight: "4px" }}
                                                                onClick={() => {
                                                                  updateUserToIdle({ do_not_update_if_on_call: true });
                                                                  const regex = `+${
                                                                    contact?.primary_phone_number_country_code ?? "1"
                                                                  }`;
                                                                  MixpanelActions.track("Lead Card", {
                                                                    type: "edit contact clicked",
                                                                    id: intent?.lead_id,
                                                                    source: parentType,
                                                                  });
                                                                  selectContactToEdit({
                                                                    lead_id: contact?.id ?? "",
                                                                    id: item.id,
                                                                    category: "Alternate",
                                                                    alternate_phone_mapping_option: item?.mapping_order,
                                                                    alternate_phone_mapping_option_label:
                                                                      item.contact_label,
                                                                    phone_number:
                                                                      item.channel === "Phone" ? item.value : "",
                                                                    local_phone_number:
                                                                      item.channel === "Phone"
                                                                        ? item.value.replace(regex, "")
                                                                        : "",
                                                                    country_code:
                                                                      contact?.primary_phone_number_country_code,
                                                                    country: computedLeadData?.country,
                                                                    email: item.channel === "Email" ? item.value : "",
                                                                    channel: item.channel,
                                                                    title: item.title,
                                                                    type: item.type,
                                                                    card_type: "Edit",
                                                                  });
                                                                }}
                                                              >
                                                                <FiEdit
                                                                  size={14}
                                                                  color={theme.PRIMARY500}
                                                                  cursor="pointer"
                                                                />
                                                              </div>
                                                              <AppText
                                                                fontSize={12}
                                                                fontWeight={400}
                                                                style={{ color: theme.PRIMARY500 }}
                                                              >
                                                                {item?.contact_label ??
                                                                  `Alternate ${
                                                                    item.channel === "Phone" ? "Phone" : "Email"
                                                                  }`}
                                                              </AppText>
                                                            </IconAndTextDiv>
                                                          )}
                                                          <ContactText>
                                                            {item.channel === "Phone"
                                                              ? formatPhoneNumber(item.value)
                                                              : item.value}
                                                          </ContactText>
                                                        </LeftSizeNumberDiv>
                                                        <FlexDiv
                                                          gap={8}
                                                          style={{
                                                            marginLeft: "19px",
                                                          }}
                                                        >
                                                          {item.title ? (
                                                            <PersonNumberTitle>
                                                              {item.title} {item.type ? ` (${item.type})` : ``}
                                                            </PersonNumberTitle>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {item.channel === "Phone" && (
                                                            <PersonNumberTitle>|</PersonNumberTitle>
                                                          )}
                                                          {item.channel === "Phone" && (
                                                            <SwapPrimaryNumberDiv contact_id={item.id} />
                                                          )}
                                                        </FlexDiv>
                                                      </div>
                                                      {loggedInUser().role !== "ADMIN" && (
                                                        <IconDiv>
                                                          {item.channel !== "Phone" ? (
                                                            <TooltipWrapperDiv
                                                              data-tip={
                                                                leadUnsubscribedFromEmail
                                                                  ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP
                                                                  : undefined
                                                              }
                                                              data-for={
                                                                leadUnsubscribedFromEmail
                                                                  ? "lead-card-tooltip"
                                                                  : undefined
                                                              }
                                                            >
                                                              <LeadCardContactButton
                                                                disabled={
                                                                  !isOwnedByRep ||
                                                                  !inCallableStatus ||
                                                                  leadUnsubscribedFromEmail
                                                                }
                                                                // callState={callState}
                                                                onClick={() => {
                                                                  //set email editor
                                                                  MixpanelActions.track("Lead Card", {
                                                                    type: "email clicked (not primary)",
                                                                    id: intent?.lead_id,
                                                                    source: parentType,
                                                                  });
                                                                  !showEmailEditorModal &&
                                                                    openEmailEditorModal({
                                                                      lead_id: contact?.id ?? "",
                                                                      full_name: contact?.full_name,
                                                                      email: item.value,
                                                                      intent: intent,
                                                                      isManualEmailSequenceStep:
                                                                        computedLeadData?.sequence_step?.actions?.[0]
                                                                          ?.task === "manualEmail",
                                                                      sequenceStepId:
                                                                        computedLeadData?.sequence_step?.actions?.[0]
                                                                          ?.id,
                                                                    });
                                                                }}
                                                                type="email"
                                                                isNextDial={isNextDial}
                                                              />
                                                            </TooltipWrapperDiv>
                                                          ) : (
                                                            <>
                                                              <TooltipWrapperDiv
                                                                data-tip={!!callState ? ON_DIAL_WARNING_TOOLTIP : ""}
                                                                data-for={!!callState ? "lead-card-tooltip" : ""}
                                                              >
                                                                <LeadCardContactButton
                                                                  disabled={
                                                                    !isOwnedByRep ||
                                                                    !inCallableStatus ||
                                                                    !!callState ||
                                                                    !!activeCall
                                                                  }
                                                                  callState={callState}
                                                                  onClick={() => {
                                                                    MixpanelActions.track("Lead Card", {
                                                                      type: "call clicked (not primary)",
                                                                      id: intent?.lead_id,
                                                                      source: parentType,
                                                                    });
                                                                    closeAllModals();
                                                                    goToCall({
                                                                      phoneNumber: item.value,
                                                                      lead_id: contact?.id ?? "",
                                                                      intentId: dataAssociatedLeads?.fetchAssociateContact?.find(
                                                                        (item: any) => item.id === contact?.id,
                                                                      )?.next_intent_scheduled_or_unscheduled?.id,
                                                                      dialAsNumber: isNextDial
                                                                        ? dialAsNumber
                                                                        : undefined,
                                                                    });
                                                                    resetNextDial();
                                                                  }}
                                                                  type="phone"
                                                                  isNextDial={isNextDial}
                                                                />
                                                              </TooltipWrapperDiv>
                                                              {!isInternational && (
                                                                <TooltipWrapperDiv
                                                                  data-tip={
                                                                    leadUnsubscribedFromSMS
                                                                      ? SMS_UNSUBSCRIBED_REP_TOOLTIP
                                                                      : undefined
                                                                  }
                                                                  data-for={
                                                                    leadUnsubscribedFromSMS
                                                                      ? "lead-card-tooltip"
                                                                      : undefined
                                                                  }
                                                                >
                                                                  <LeadCardContactButton
                                                                    disabled={
                                                                      !isOwnedByRep ||
                                                                      !inCallableStatus ||
                                                                      leadUnsubscribedFromSMS
                                                                    }
                                                                    onClick={() => {
                                                                      if (showSMSChat) {
                                                                        appToast(
                                                                          "There is currently a chat open. Please close the existing chat before opening.",
                                                                        );
                                                                        return;
                                                                      }
                                                                      MixpanelActions.track("Lead Card", {
                                                                        type: "sms clicked",
                                                                        id: contact?.id,
                                                                        source: parentType,
                                                                      });

                                                                      openSMSChat({
                                                                        lead_id: contact?.id ?? "",

                                                                        phone_number: item.value,
                                                                        intent: intent,
                                                                      });
                                                                    }}
                                                                    type="sms"
                                                                    isNextDial={isNextDial}
                                                                  />
                                                                </TooltipWrapperDiv>
                                                              )}
                                                            </>
                                                          )}
                                                        </IconDiv>
                                                      )}
                                                    </PersonNumberDiv>
                                                  ),
                                              )}

                                          <AssociatedContactFooter>
                                            <>
                                              <OpacityDiv>
                                                <FooterActionDiv
                                                  onClick={() => {
                                                    setSwapPrimaryLeadModalData({
                                                      primary_lead_id: computedLeadData?.id,
                                                      associate_lead_id: contact?.id,
                                                      rep_id: contact?.rep_id,
                                                      associate_primary_phone_number: contact?.primary_phone_number,
                                                      parentType: parentType,
                                                    });

                                                    setShowDisassociateLeadModal(true);
                                                  }}
                                                >
                                                  <img
                                                    src={arrowsLeftAndRight}
                                                    alt="arrows"
                                                    style={{ width: "16px", height: "16px" }}
                                                  />

                                                  <AppText
                                                    style={{
                                                      color: theme.DANGER600,
                                                    }}
                                                  >
                                                    Disassociate Lead
                                                  </AppText>
                                                </FooterActionDiv>
                                              </OpacityDiv>
                                              <OpacityDiv>
                                                <FooterActionDiv
                                                  onClick={() => {
                                                    setSwapPrimaryLeadModalData({
                                                      primary_lead_id: computedLeadData?.id,
                                                      associate_lead_id: contact?.id,
                                                      associate_primary_phone_number: contact?.primary_phone_number,
                                                      parentType: parentType,
                                                    });

                                                    setShowSwapPrimaryLeadModal(true);
                                                  }}
                                                >
                                                  <img
                                                    src={arrowsUpAndDown}
                                                    alt="arrows"
                                                    style={{
                                                      width: "16px",
                                                      height: "16px",
                                                    }}
                                                  />
                                                  <AppText
                                                    style={{
                                                      color: theme.PRIMARY500,
                                                    }}
                                                  >
                                                    Make Primary
                                                  </AppText>
                                                </FooterActionDiv>
                                              </OpacityDiv>
                                            </>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                width: "100%",

                                                paddingRight: "3.5px",
                                              }}
                                            >
                                              <NewAppButton
                                                variant="primary"
                                                style={{
                                                  fontSize: "10px",
                                                  fontWeight: 600,
                                                  height: "40px",
                                                  width: "40px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  padding: "0px",
                                                  borderRadius: "4px",
                                                }}
                                                onClick={() => {
                                                  updateUserToIdle({ do_not_update_if_on_call: true });
                                                  MixpanelActions.track("Lead Card", {
                                                    type: "add alternate contact",
                                                    id: contact?.id,
                                                    source: parentType,
                                                  });
                                                  selectContactToAdd({
                                                    lead_id: contact?.id ?? "",
                                                    country_code: contact?.primary_phone_number_country_code,
                                                    country: computedLeadData?.country,
                                                    card_type: "Add",
                                                    title: "Alternate",

                                                    // if the lead already has a conference number they can't add another
                                                    has_conference_number: !!contact?.conference_number,
                                                  });

                                                  setShowAddOrEditContactModal(true);
                                                  setShowAddAssociateModal(false);
                                                }}
                                                data-tip="Add alternate contact:|Phone, Email, Screensharing number"
                                                data-for={`associated-alt-contact-info-tooltip-${index}`}
                                              >
                                                <>
                                                  <StyledTooltip
                                                    multiline
                                                    place="right"
                                                    effect="solid"
                                                    id={`associated-alt-contact-info-tooltip-${index}`}
                                                    backgroundColor={theme.PRIMARY800}
                                                    getContent={(dataTip) => (
                                                      <div
                                                        style={{
                                                          fontFamily: "Inter",
                                                          fontStyle: "normal",
                                                          fontWeight: 600,
                                                          fontSize: "10px",
                                                          lineHeight: "14px",
                                                          width: "180px",
                                                        }}
                                                      >
                                                        <div>{dataTip?.split("|")[0]}</div>
                                                        <div>{dataTip?.split("|")[1]}</div>
                                                      </div>
                                                    )}
                                                  />

                                                  <img
                                                    src={contact_info_add}
                                                    alt="add alternative contact information"
                                                  />
                                                </>
                                              </NewAppButton>
                                            </div>
                                          </AssociatedContactFooter>
                                        </div>
                                      )}
                                    </AssociatedContact>
                                  ))}
                                </FlexDiv>
                              )}
                              <AssociatedContactsFooterDiv
                                computedLeadData={computedLeadData}
                                parentType={parentType}
                              />
                            </AssociatedContactsDiv>
                          )}
                        </ContactInfoDiv>
                      </>
                    )
                  ))}

                {currentSelectedTab === "more-info" &&
                  (!!dataLead?.fetchLead?.visible_custom_fields &&
                  dataLead?.fetchLead?.visible_custom_fields.slice().filter((item: any) => !!item.computed_value)
                    .length ? (
                    <div
                      style={{
                        backgroundColor: theme.LIGHT_BLUE,
                      }}
                    >
                      {!!dataLead?.fetchLead?.visible_custom_fields &&
                        dataLead?.fetchLead?.visible_custom_fields
                          .slice()
                          // .sort((a: any, b: any) => (a?.key < b?.key ? -1 : 1))
                          .sort((a: any, b: any) => {
                            const hasExtraRequiredDemoFields =
                              loggedInUser().organization_id === "91f1b9c2-009b-48f9-93e5-b044788c1aa5" || // Drew Test
                              loggedInUser().organization_id === "35372a5d-8395-4f2e-974f-4ac1d0216a3e" || // FieldPulse
                              loggedInUser().organization_id === "ece14be5-953f-4213-b102-9c409ccbba14"; // FieldPulse Sandbox
                            if (!hasExtraRequiredDemoFields) {
                              return a?.key?.toLowerCase()?.localeCompare(b?.key?.toLowerCase());
                            }
                            const requiredDemoFieldsOrder = requiredDemoFields.map((field) => field.key);

                            const getOrderDemoFields = (key: string) => {
                              const index = requiredDemoFieldsOrder.indexOf(key);
                              return index === -1 ? requiredDemoFieldsOrder.length : index;
                            };
                            // Sorting for orgs that have demo fields

                            const aOrder = getOrderDemoFields(a.key);
                            const bOrder = getOrderDemoFields(b.key);

                            if (aOrder !== bOrder) {
                              return aOrder - bOrder;
                            } else {
                              return a?.key?.toLowerCase()?.localeCompare(b?.key?.toLowerCase());
                            }
                          })
                          ?.map(
                            (item: VisibleCustomFieldsType) =>
                              item.computed_value && (
                                <CustomFieldDiv key={item.id}>
                                  <ReactTooltip
                                    id={item.id}
                                    multiline
                                    place="top"
                                    effect="solid"
                                    className="sib-tooltip"
                                    css={{
                                      maxWidth: 600,
                                      lineHeight: 1.4,
                                      textAlign: "center",
                                      fontFamily: theme.PRIMARY_FONT,
                                    }}
                                    backgroundColor={theme.PRIMARY800}
                                    getContent={(dataTip) => (
                                      <span
                                        style={{
                                          fontFamily: "Inter",
                                          fontStyle: "normal",
                                          fontWeight: 600,
                                          fontSize: "10px",
                                          lineHeight: "14px",
                                        }}
                                      >
                                        {dataTip}
                                      </span>
                                    )}
                                  />
                                  <AppText style={{ fontWeight: 600 }}>{item.key}</AppText>
                                  <AppText>
                                    {item.type === "Date"
                                      ? moment(convertDate(item.computed_value)).format("M/D/YYYY h:mm A")
                                      : CustomFieldLinkCheck(item.computed_value, item.key)}
                                  </AppText>
                                </CustomFieldDiv>
                              ),
                          )}
                      <PersonNumberDiv>
                        <div
                          style={{
                            width: "169px",
                            marginLeft: "8px",
                          }}
                        >
                          <AddContentButton
                            variant="primary"
                            onClick={() => {
                              MixpanelActions.track("Lead Card", {
                                type: "edit custom clicked",
                                id: dataLead?.fetchLead?.id,
                                source: parentType,
                              });
                              setCustomFieldsData({
                                lead: {
                                  id: dataLead?.fetchLead?.id,
                                  visible_custom_fields: dataLead?.fetchLead?.visible_custom_fields,
                                },
                              });
                              openCustomFieldsModal();
                            }}
                          >
                            <HiPlus size={10} color={theme.WHITE_COLOR} style={{ marginRight: "6px" }} />
                            Edit Custom Data
                          </AddContentButton>
                        </div>
                      </PersonNumberDiv>
                    </div>
                  ) : (
                    <EmptyDiv style={{ height: "102px", backgroundColor: theme.LIGHT_BLUE }}>
                      <div style={{ width: "169px", marginBottom: "16px" }}>
                        <NewAppIconButton
                          variant="primary"
                          onClick={() => {
                            MixpanelActions.track("Lead Card", {
                              type: "edit custom clicked",
                              id: dataLead?.fetchLead?.id,
                              source: parentType,
                            });
                            setCustomFieldsData({
                              lead: {
                                id: dataLead?.fetchLead?.id,
                                visible_custom_fields: dataLead?.fetchLead?.visible_custom_fields,
                              },
                            });
                            openCustomFieldsModal();
                          }}
                          icon={<HiPlus size={16} color={theme.WHITE_COLOR} />}
                          text="ADD CUSTOM DATA"
                          alt="ADD CUSTOM DATA Button"
                          fontSize={10}
                          fontWeight={600}
                          textVariant="white"
                        />
                      </div>
                      <EmptyText>There’s no other info on this lead. Want to add some?</EmptyText>
                    </EmptyDiv>
                  ))}

                {showSaveToFolderModal && currentConferenceID && (
                  <SaveToFolderModal
                    blinds={showSaveToFolderModal}
                    setBlinds={setShowSaveToFolderModal}
                    conferenceID={currentConferenceID}
                    moveCall={false}
                  />
                )}

                {currentSelectedTab === "lead-history" && (
                  <>
                    <CallNotesHeaderDiv>
                      <FilterDiv>
                        <FlexDiv align="center" gap={8} style={{ width: "100%" }}>
                          <AppText fontSize={12} fontWeight={500} style={{ whiteSpace: "nowrap" }}>
                            Filter by Type
                          </AppText>
                          <div style={{ width: "100%" }}>
                            <NewAppSelect
                              options={[
                                { label: "Show All", value: "All" },
                                { label: "Call", value: "Call" },
                                { label: "NonCall", value: "NonCall" },
                                { label: "SMS", value: "SMS" },
                                { label: "Email", value: "Email" },
                                { label: "Other", value: "Other" },
                                ...(allowExternalTransfers
                                  ? [{ label: "ExternalTransfer", value: "ExternalTransfer" }]
                                  : []),
                              ]}
                              value={leadHistoryFilter}
                              onChange={(e: any) => {
                                if (e?.value !== leadHistoryFilter) {
                                  setSkip(0);
                                  e?.value && setLeadHistoryFilter(e?.value);
                                }
                              }}
                              noErrorNeeded
                            />
                          </div>
                        </FlexDiv>

                        <FlexDiv align="center" gap={8} style={{ width: "100%" }}>
                          <AppText fontSize={12} fontWeight={500} style={{ whiteSpace: "nowrap" }}>
                            Filter by Contact
                          </AppText>
                          <div style={{ width: "100%" }}>
                            <NewAppSelect
                              options={[
                                { label: "Show All", value: "All" },
                                !!primaryLead.label ? primaryLead : { label: "No Name", value: primaryLead?.value },
                                ...(!!dataAssociatedLeads?.fetchAssociateContact
                                  ? dataAssociatedLeads?.fetchAssociateContact?.map((item: any) => {
                                      return {
                                        label: item?.full_name
                                          ? item.full_name
                                          : item?.first_name && item?.last_name
                                          ? `${item.last_name}, ${item.first_name}`
                                          : "No Name",
                                        value: item?.id,
                                      };
                                    })
                                  : []),
                              ]}
                              value={selectedHistoryContactFilter}
                              onChange={(e: any) => {
                                if (e?.value !== selectedHistoryContactFilter) {
                                  setSkip(0);
                                  e?.value && setSelectedHistoryContactFilter(e?.value);
                                }
                              }}
                              noErrorNeeded
                            />
                          </div>
                        </FlexDiv>
                      </FilterDiv>
                    </CallNotesHeaderDiv>

                    {historyError ? (
                      <AppErrorText>{historyError}</AppErrorText>
                    ) : (
                      <>
                        <HistoryResultsHeaderDiv>
                          <AppText fontWeight={600} fontSize={12}>
                            {historyCountLoading ? (
                              <SkeletonBlock height={20} width={40} borderRadius={8} />
                            ) : (
                              `${historyCountData?.fetchLeadHistoryCount ?? 0} Result${
                                (historyCountData?.fetchLeadHistoryCount ?? 0) !== 1 ? "s" : ""
                              }`
                            )}
                          </AppText>
                        </HistoryResultsHeaderDiv>
                        {historyCountData?.fetchLeadHistoryCount !== 0 ? (
                          <>
                            <InfiniteScroll
                              dataLength={historyItemList.length}
                              next={() => {
                                setSkip((curSkip) => curSkip + takeNumber);
                              }}
                              hasMore={hasMoreHistory}
                              loader={
                                <div>
                                  <SkeletonBlock height={60} width={"100%"} borderRadius={8} />
                                </div>
                              }
                              scrollableTarget="history-list"
                              height={375}
                              style={{ backgroundColor: theme.LIGHT_BLUE }}
                            >
                              <div id="history-list">
                                {/* <CommunicationsActivityLeadCard subscribed={true} height={56} type="email" /> */}
                                {!!historyItemList &&
                                  historyItemList.length > 0 &&
                                  historyItemList?.map((item: any) => {
                                    if (Object.values(CommunicationStates).includes(item?.type)) {
                                      return (
                                        <CommunicationsActivityLeadCard
                                          subscribed={item.type === CommunicationStates.Subscribed}
                                          height={56}
                                          type="email"
                                          text={item?.computed_title}
                                          date={item?.created_at}
                                        />
                                      );
                                    } else {
                                      return (
                                        <LeadCardLeadHistoryItem
                                          isAssociatedLead={isAssociatedLead}
                                          showExtendedInfoSections
                                          key={item.id}
                                          item={item}
                                          primary_lead_id={computedLeadData?.id}
                                          associate_lead_ids={
                                            dataAssociatedLeads?.fetchAssociateContact?.map((lead: CurrentLeadType) => {
                                              return lead?.id || "";
                                            }) ?? []
                                          }
                                          contact_id={item?.lead_id}
                                          setCurrentConferenceID={setCurrentConferenceID}
                                          setShowSaveToFolderModal={setShowSaveToFolderModal}
                                          backgroundColor={theme.LIGHT_BLUE}
                                          showRepIcon={true}
                                        />
                                      );
                                    }
                                  })}
                              </div>
                            </InfiniteScroll>
                          </>
                        ) : (
                          <EmptyDiv style={{ height: "102px", backgroundColor: theme.LIGHT_BLUE }}>
                            <EmptyText>No History Items Found</EmptyText>
                          </EmptyDiv>
                        )}
                      </>
                    )}
                  </>
                )}

                {currentSelectedTab === "notes" && (
                  <LeadCardNotes
                    primaryLead={primaryLead}
                    associatedLeads={dataAssociatedLeads?.fetchAssociateContact}
                    selectedHistoryContactFilter={selectedHistoryContactFilter}
                    setSelectedHistoryContactFilter={setSelectedHistoryContactFilter}
                    historyData={historyNotesData}
                    dataLead={dataLead}
                    loadingAddLeadNote={loadingAddLeadNote}
                    errorAddLeadNote={!!errorAddLeadNote}
                    loadingFetchNotes={historyNotesLoading}
                    errorFetchNotes={!!historyNotesError}
                    computedLeadData={computedLeadData}
                    fetchLeadHistoryData={fetchLeadHistoryData}
                    showNewNoteUI={showNewNoteUI}
                    setShowNewNoteUI={setShowNewNoteUI}
                    saveNote={() => {
                      addLeadNote({
                        variables: {
                          lead_id: selectedLeadIdForUnsavedNote,
                          note: unsavedNote,
                        },
                      });
                    }}
                    resetUnsavedNoteState={resetUnsavedNoteState}
                    setSelectedLeadIdForNote={setSelectedLeadIdForUnsavedNote}
                    selectedLeadIdForNote={selectedLeadIdForUnsavedNote}
                    unsavedNote={unsavedNote}
                    setUnsavedNote={setUnsavedNote}
                  />
                )}
              </>
            )}

            {renderRelatedRecord()}
          </LeadCardScrollableView>

          {!loadingLead && !loadingAssociatedLeads && expandedState && (
            <CTAButtonDiv>
              {isSequenceAction && !!computedLeadData?.sequence_step?.actions?.[0]?.custom_task_note && (
                <CustomNoteContainer>
                  <AppText fontSize={10} fontWeight={500}>
                    Task Note:
                  </AppText>
                  <AppText fontSize={10} fontWeight={400}>
                    {computedLeadData?.sequence_step?.actions[0]?.custom_task_note}
                  </AppText>
                </CustomNoteContainer>
              )}

              <CTAButtonRow>
                {isSelfSourcedLead && (
                  <PrioritizeButton
                    variant={"newDesignSecondary"}
                    height={40}
                    onClick={() => {
                      handleSelfSourcedLeadPriorityClick && handleSelfSourcedLeadPriorityClick();
                    }}
                  >
                    PRIORITIZE
                  </PrioritizeButton>
                )}
                {!!computedLeadData?.id &&
                  nextActionOverride?.intent_object?.lead?.id !== computedLeadData?.id &&
                  !isNextDial && (
                    <TooltipWrapperDiv
                      data-tip={
                        makeNextActionDisabledCheck({
                          inCallableStatus: inCallableStatus,
                          currentLeadStatus: computedLeadData?.lead_ownership_status,
                          isOwnedByRep: isOwnedByRep,
                          isAssociatedLead: isAssociatedLead,
                          nextDialOverrideBlocked: !ableToOverideNextDial,
                          expandedState: expandedState,
                          leadIsInNextDial: computedLeadData?.id === leadInNextDial,
                        })?.tooltip
                      }
                      data-for={
                        makeNextActionDisabledCheck({
                          inCallableStatus: inCallableStatus,
                          currentLeadStatus: computedLeadData?.lead_ownership_status,
                          isOwnedByRep: isOwnedByRep,
                          isAssociatedLead: isAssociatedLead,
                          nextDialOverrideBlocked: !ableToOverideNextDial,
                          expandedState: expandedState,
                          leadIsInNextDial: computedLeadData?.id === leadInNextDial,
                        })?.disabled
                          ? "lead-card-tooltip"
                          : undefined
                      }
                    >
                      <PhoenixAppButton
                        variant={isSelfSourcedLead ? "brand" : "brand-outline"}
                        buttonType={isSelfSourcedLead ? "secondary" : "primary"}
                        height={40}
                        buttonTextFontSize={10}
                        uppercase
                        disabled={
                          makeNextActionDisabledCheck({
                            inCallableStatus: inCallableStatus,
                            currentLeadStatus: computedLeadData?.lead_ownership_status,
                            isOwnedByRep: isOwnedByRep,
                            isAssociatedLead: isAssociatedLead,
                            nextDialOverrideBlocked: !ableToOverideNextDial,
                            expandedState: expandedState,
                            leadIsInNextDial: computedLeadData?.id === leadInNextDial,
                          })?.disabled
                        }
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          updateUserToIdle({ do_not_update_if_on_call: true });
                          if (isSelfSourcedLead) {
                            handleSelfSourcedLeadNextDialClick && handleSelfSourcedLeadNextDialClick();
                            return;
                          }

                          // Previous request by leadership to always have search panel clsoe on make next action
                          // clear search
                          setRepLeadSearch("");

                          MixpanelActions.track("Lead Card", {
                            type: "make next action",
                            id: computedLeadData?.id,
                            source: parentType,
                          });
                          console.log("intent of lead going into nextAction", intent);
                          setNextActionOverride({
                            intent_object: {
                              id: computedLeadData?.next_intent_scheduled_or_unscheduled?.id ?? null,
                              lead: {
                                ...computedLeadData,
                                id: computedLeadData?.id ?? "",
                                primary_phone_number: computedLeadData?.primary_phone_number,
                              },
                              type: intent?.type,
                              ...intent,
                            },
                            parent: parentType,
                          });

                          resetInfiniteScroll();
                        }}
                      >
                        MAKE NEXT ACTION
                      </PhoenixAppButton>
                    </TooltipWrapperDiv>
                  )}
                {!isSelfSourcedLead && (
                  <LeadCardCTAButton
                    scheduledEventIsWithAssociatedLead={scheduledEventIsWithAssociatedLead}
                    dataLead={dataLead}
                    isOwnedByRep={isOwnedByRep}
                    inCallableStatus={inCallableStatus}
                    dataAssociatedLeads={dataAssociatedLeads}
                    computedLeadData={computedLeadData}
                    goToCallObjectForPrimary={goToCallObjectForPrimary}
                    activeCall={activeCall}
                    closeAllModals={closeAllModals}
                    scheduledEventLeadId={scheduledEventLeadId}
                    scheduledEventAssociateLeadIntentId={scheduledEventAssociateLeadIntentId}
                    isNextDial={isNextDial}
                    dialAsNumber={dialAsNumber}
                    setSelfSource={setSelfSource}
                    expectedAction={computedLeadData?.sequence_step?.actions?.[0]?.task ?? ""}
                    leadUnsubscribedFromEmail={leadUnsubscribedFromEmail}
                    showEmailEditorModal={showEmailEditorModal}
                    openEmailEditorModal={openEmailEditorModal}
                    showSMSChat={showSMSChat}
                    openSMSChat={openSMSChat}
                    leadUnsubscribedFromSMS={leadUnsubscribedFromSMS}
                    intentData={intent}
                    inCustomSequence={!loadingLead && !loadingAssociatedLeads && isSequenceAction}
                    sequenceStep={computedLeadData?.sequence_step}
                  />
                )}
              </CTAButtonRow>
              {!loadingLead &&
              !loadingAssociatedLeads &&
              expandedState &&
              isNextDial &&
              showDefaultRemoveFromNextDialButton ? (
                <ManualOverrideFooter>
                  <AppText color={theme.text.dataviz4.secondary} fontSize={12} fontWeight={500}>
                    Task Added Manually
                  </AppText>
                  <FlexDiv
                    gap={4}
                    onClick={() => {
                      resetNextDial();
                      setSkip(0);
                    }}
                    style={{ cursor: "pointer" }}
                    align="center"
                  >
                    <PhoenixIcon svg={rotate} size={12} variant="brand" pointer />
                    <AppText color={theme.buttontext.brand_outline.default} fontSize={10} fontWeight={600}>
                      Undo
                    </AppText>
                  </FlexDiv>
                </ManualOverrideFooter>
              ) : (
                <div style={{ height: 8 }}></div>
              )}
            </CTAButtonDiv>
          )}
        </>
      )}
    </Container>
  );
};

const SearchButton = ({
  isSearchClicked,
  setIsSearchClicked,
}: {
  isSearchClicked: boolean;
  setIsSearchClicked: Dispatch<SetStateAction<boolean>>;
}) => (
  <VerticalyCenteredDiv
    onClick={() => {
      setIsSearchClicked(!isSearchClicked);
    }}
  >
    <img style={{ height: "14px", width: "auto", cursor: "pointer" }} src={googleLogo} alt="search on google" />
  </VerticalyCenteredDiv>
);

interface EditButtonProps {
  leadId: string;
  parentType?: ParentType;
}

const EditButton = ({ leadId, parentType }: EditButtonProps) => {
  const { updateUserToIdle } = useContext(UserStatusContext);

  const { editModal, setEditModal, setEditModalLeadId, setLogNonCallActivityModalData } = useContext(ModalContext);

  return (
    <VerticalyCenteredDiv
      onClick={() => {
        updateUserToIdle({ do_not_update_if_on_call: true });
        MixpanelActions.track("Lead Card", {
          type: "edit form clicked",
          id: leadId,
          source: parentType,
        });
        setEditModalLeadId(leadId);
        setEditModal(true);
      }}
    >
      <FiEdit size={16} color={theme.PRIMARY500} cursor="pointer" />
    </VerticalyCenteredDiv>
  );
};
interface DisassociateLeadCustomObjectProps {
  leadId: string;
  row_id?: string;
  business_name?: string;
}

const DisassociateLeadCustomObject = ({ leadId, row_id, business_name }: DisassociateLeadCustomObjectProps) => {
  const [deleteCOModal, setDeleteCOModal] = useState(false);
  if (row_id) {
    return (
      <div key={row_id}>
        {deleteCOModal && (
          <DeleteLeadCustomObjectRelationModal
            lead_id={leadId}
            row_id={row_id}
            business_name={business_name}
            close={() => {
              setDeleteCOModal(false);
            }}
            blinds={deleteCOModal}
          />
        )}
        <VerticalyCenteredDiv
          onClick={() => {
            setDeleteCOModal(true);
          }}
        >
          <PhoenixIcon svg={arrowsLeftAndRight} color={theme.DANGER600} size={16} pointer variant="danger" />
        </VerticalyCenteredDiv>
      </div>
    );
  }
  return <></>;
};

const ExpandButton = ({ handleClick, isExpanded }: { handleClick: () => void; isExpanded: boolean }) => (
  <button
    onClick={() => {
      handleClick();
    }}
    style={{
      height: "fit-content",
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
    }}
  >
    {isExpanded ? (
      <PhoenixIcon svg={chevron_up} color={theme.PRIMARY500} size={24} pointer />
    ) : (
      <PhoenixIcon svg={chevron_down} color={theme.PRIMARY500} size={24} pointer />
    )}
  </button>
);

const AssociateLeadContactLabel = ({ contact }: { contact: CurrentLeadType }) => {
  return (
    <FlexDiv
      align="center"
      justify="space-between"
      style={{
        marginBottom: "4px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StatCard icon={associated_white} variant="secondary" iconSize={12} containerPadding={"10px"} />
        <AppText
          style={{
            marginLeft: "8px",
          }}
        >
          {contact?.full_name
            ? contact?.full_name
            : contact?.first_name && contact?.last_name
            ? `${contact?.last_name}, ${contact?.first_name}`
            : "No Name"}
        </AppText>
      </div>
    </FlexDiv>
  );
};

export const LeadContactLabel = ({
  contact,
  contactType,
  fontSizeOverride,
  fontWeightOverride,
  onClick,
}: {
  contact: CurrentLeadType;
  contactType: "primary" | "associate";
  fontSizeOverride?: number;
  fontWeightOverride?: number;
  onClick?: () => void;
}) => {
  return (
    <FlexDiv
      align="center"
      justify="space-between"
      style={{
        cursor: onClick ? "pointer" : "default",
        marginBottom: "4px",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {contactType === "primary" ? (
          <StatCard icon={user_white} variant="primary" iconSize={12} containerPadding={"10px"} />
        ) : contactType === "associate" ? (
          <StatCard icon={associated_white} variant="secondary" iconSize={12} containerPadding={"10px"} />
        ) : null}

        <AppText
          style={{
            marginLeft: "8px",
          }}
          fontSize={fontSizeOverride}
          fontWeight={fontWeightOverride}
        >
          {contact?.full_name
            ? contact?.full_name
            : contact?.first_name && contact?.last_name
            ? `${contact?.last_name}, ${contact?.first_name}`
            : "No Name"}
        </AppText>
      </div>
    </FlexDiv>
  );
};

interface AssociatedContactsDivProps {
  computedLeadData: ComputedLeadData;
  parentType?: ParentType;
}

const AssociatedContactsFooterDiv: React.FC<AssociatedContactsDivProps> = ({ computedLeadData, parentType }) => {
  const {
    setShowAddAssociateModal,
    setShowMergeContactsModal,
    setMergeLeadsData,
    setPrimaryLeadToAddAssociateTo,
  } = useContext(ModalContext);
  const { callState } = useContext(CallContext);

  return (
    <AssociatedContactFooter>
      <AssociatedContactFooterDiv>
        <NewAppIconButton
          onClick={() => {
            setShowAddAssociateModal(true);
            setPrimaryLeadToAddAssociateTo(computedLeadData?.id);
            // scroll to top on page load
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
          variant="newDesignSecondary"
          img={addNewUser}
          alt="add new user"
          text="ADD NEW"
          backgroundColor={theme.LIGHT_BLUE}
          imgHeight="16px"
          imgWidth="16px"
        />
      </AssociatedContactFooterDiv>
      <AssociatedContactFooterDiv>
        {!callState && parentType !== "eventDetails" ? (
          <NewAppIconButton
            variant="newDesignSecondary"
            onClick={() => {
              setShowMergeContactsModal(true);
              // apply source lead data to modal context var
              setMergeLeadsData({
                source: {
                  id: computedLeadData?.id,
                  full_name: computedLeadData?.full_name,
                  business_name: computedLeadData?.business_name,
                },
              });
            }}
            img={merge_primary}
            alt="merge"
            text="ASSOCIATE EXISTING"
            backgroundColor={theme.LIGHT_BLUE}
            imgHeight="16px"
            imgWidth="16px"
          />
        ) : null}
      </AssociatedContactFooterDiv>
    </AssociatedContactFooter>
  );
};

interface EventTypeObject {
  [key: string]: any;
}

const renderEventCards = (lead: EventTypeObject, showEventTypeCard: boolean, computedLeadData: any) => {
  const intentType = renderLeadIntentEventTypeValues(lead);

  const returnActiveQueueText = (is_in_active_queue: string, anytime_day?: string) => {
    if (is_in_active_queue === "CyclingBack" && anytime_day) {
      return `Lead will cycle back to Active Queue on ${moment(anytime_day).format("dddd, MMMM Do YYYY [at] h:mm A")}`;
    }
    if (is_in_active_queue === "InActiveQueue") {
      return "Lead in Active Queue.";
    }
    return "";
  };

  const getEventTypeLabel = (eventType: string) => {
    switch (eventType) {
      case "DecisionCall":
        return "Decision Call";
      case "ScheduleCallback":
        return "Callback Set";
      case "ScheduledEvent":
        return "Event Set";
      case "FollowUpDemo":
        return "Followup Demo Set";
      case "RescheduleDemo":
        return "Rescheduled Demo";
      case "Demo":
      default:
        return "Demo Set";
    }
  };

  return (
    <>
      <NextEventInfoContainer>
        <PhoenixStyledTooltip id="event-info-tooltip" place="left" multiline />

        <FlexDiv gap={8} align="center">
          {!!lead?.next_scheduled_event?.type && (
            <PipelineInfoPill>{lead?.pipeline_state_label || "No Contact Made"}</PipelineInfoPill>
          )}

          {!lead?.next_scheduled_event?.id && (
            <AppText style={{ padding: "0px 8px 0px 8px", borderRight: `1px solid ${theme.NEUTRAL250}` }}>
              {lead?.pipeline_state_label || "No contact made"}
            </AppText>
          )}

          <AppText>
            {!!lead?.next_scheduled_event?.id
              ? `Scheduled for ${moment(lead?.next_scheduled_event_time).format("MM/DD/YYYY")}`
              : `No event. ${returnActiveQueueText(
                  computedLeadData?.is_in_active_queue,
                  computedLeadData?.next_intent_scheduled_or_unscheduled?.anytime_day,
                )}`}
            <NextEventInfoTimestamp>
              {!!lead?.next_scheduled_event?.id && `${moment(lead?.next_scheduled_event_time).format("h:mm A")}`}
            </NextEventInfoTimestamp>
          </AppText>
        </FlexDiv>

        <PhoenixIcon
          svg={info}
          variant="neutral"
          data-tip={
            !lead?.next_scheduled_event?.type
              ? `Pipeline Stage: ${lead?.pipeline_state_label || "No Contact Made"}`
              : getEventTypeLabel(lead.next_scheduled_event.type)
          }
          data-for="event-info-tooltip"
          size={16}
        />
      </NextEventInfoContainer>

      {showEventTypeCard && !(intentType.label === "No next action" && !!lead?.next_scheduled_event?.id) && (
        <EventTypeInfoContainer>
          <PhoenixStyledTooltip id="next-intent-info-tooltip" place="left" multiline />

          <FlexDiv gap={16}>
            <AppText
              fontSize={12}
              fontWeight={500}
              color={theme.PRIMARY500}
              style={{
                lineHeight: "18px",
                padding: "0px 16px 0px 8px",
                borderRight: `1px solid ${theme.NEUTRAL250}`,
              }}
            >
              {intentType.label}
            </AppText>

            <AppText color={theme.NEUTRAL350}>{!!intentType.message ? intentType.message : "---"}</AppText>
          </FlexDiv>

          <PhoenixIcon
            svg={info}
            variant="neutral"
            data-tip={intentType.tooltip}
            data-for="next-intent-info-tooltip"
            size={16}
          />
        </EventTypeInfoContainer>
      )}
    </>
  );
};

const VerticalyCenteredDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const FinalButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 72px;
`;

const ContactInfoHeaderDiv = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${theme.PRIMARY200};
`;

const ContactInfoTabContainer = styled.div`
  display: flex;

  width: 100%;
  padding: 2px;
  border-radius: 6px;
  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 4px 0px ${theme.PRIMARY200};
`;

interface ContactInfoTabProps {
  active: boolean;
}

const CTAButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const CTAButtonRow = styled.div`
  margin: 16px 16px 8px 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

const ContactInfoTab = styled.div<ContactInfoTabProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 32px;

  color: ${(props) => (props.active ? theme.WHITE_COLOR : theme.PRIMARY500)};
  background-color: ${(props) => (props.active ? theme.PRIMARY500 : theme.WHITE_COLOR)};

  text-align: center;
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;

  border: ${(props) => props.active && `1px solid ${theme.PRIMARY600}`};
  border-radius: ${(props) => (props.active ? "4px" : "0px")};

  cursor: pointer;
`;

interface DividerLineProps {
  visible: boolean;
}

const DividerLine = styled.div<DividerLineProps>`
  width: 1px;
  height: 28px;
  background-color: ${(props) => (props.visible ? theme.NEUTRAL100 : theme.WHITE_COLOR)};
`;

const HistoryResultsHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: ${theme.LIGHT_BLUE};
  padding-right: 16px;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const EmptyText = styled(AppText)`
  font-weight: 400;
  font-size: 12px;
`;

const EmptyDiv = styled.div`
  height: 84px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.LIGHT_BLUE};
`;
const ManualOverrideFooter = styled.div`
  padding: 0px 8px;
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  margin: 0px 16px 16px 16px;
  background-color: ${theme.fill.dataviz4.secondary};
  border-radius: 4px;
`;

const CustomNoteContainer = styled.div`
  padding: 8px;
  background-color: ${theme.fill.neutral.secondary};
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 12px 16px -4px 16px;
`;

const AssociatedContact = styled.div`
  border-bottom: 1px solid ${theme.PRIMARY300};
  padding: 4px 0px;
`;

const AssociatedContactFooter = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 8px;
  padding-bottom: 4px;
  justify-content: space-between;
  align-items: center;
`;

const AssociatedContactFooterDiv = styled.div`
  display: flex;
  gap: 8px;
`;

const FooterActionDiv = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  width: max-content;
  height: fit-content;
`;

const IconAndTextDiv = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipWrapperDiv = styled.div``;

const FilterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

interface LeftProp {
  left?: boolean;
}

const AddContentButton = styled(NewAppButton)<LeftProp>`
  text-align: ${(props) => (props.left ? "left" : "center")};
`;

const TabsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  margin-top: 16px;
  background-color: ${theme.WHITE_COLOR};
  overflow-x: auto;
`;

const LogNonCallDiv = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  padding-top: 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
  height: 150px;
`;

const AlternateSubTextLabel = styled(AppText)`
  font-weight: 400;
  font-size: 10px;
  color: ${theme.LIGHT_GRAY};
  margin-left: 22px;
`;

const AssociatedContactsDiv = styled.div`
  background-color: ${theme.LIGHT_BLUE};
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 0px;
`;

const ContactText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.PRIMARY800};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
`;

const NumberTitleDiv = styled.div`
  margin-left: 19px;
`;

const LeftSizeNumberDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: fit-content;
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const PersonNumberTitle = styled(AppText)`
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: ${theme.LIGHT_GRAY};
`;

interface PersonNumberProps {
  dontShowBottomBorder?: boolean;
}
const PersonNumberDiv = styled.div<PersonNumberProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;

  border-bottom: ${(props) => (props.dontShowBottomBorder ? "none" : `1px solid ${theme.PRIMARY300}`)};
`;

const LeadCardScrollableView = styled.div<{ maxHeight?: string }>`
  max-height: ${(props) => props.maxHeight || "600px"};

  overflow-y: auto;
  overflow-x: hidden;
`;

const ContactInfoDiv = styled.div`
  background-color: ${theme.LIGHT_BLUE};
  padding: 0px 16px 16px 16px;
`;

const CustomFieldDiv = styled(PersonNumberDiv)`
  padding: 0px 24px;
  height: 50px;
`;

const CallNotesHeaderDiv = styled(PersonNumberDiv)`
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 100%;
  border-bottom: none;
`;

const Container = styled.div<{ border?: boolean; borderRadius?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.border && `border: 1px solid ${theme.border.neutral.primary};`}
  border-radius: ${(props) => props.borderRadius ?? "0px"};
`;

const PrioritizeButton = styled(NewAppButton)`
  width: 100px;
  font-size: 10px;
  font-weight: 600;
`;
const MakeNextActionButton = styled(NewAppButton)`
  width: 146px;
  font-size: 10px;
  font-weight: 600;
`;

const CallbackCityStateDiv = styled.div`
  /* padding: 0px 0px 0px 0px; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
`;

const AssociateWarning = styled.div`
  padding: 8px 12px 8px 12px;
  background-color: ${theme.PRIMARY300};
  border: 1px solid ${theme.PRIMARY300};
  border-radius: 4px;
`;

const CallbackCityStateText = styled(AppText)`
  align-items: center;
  max-width: 240px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const CallbackIndustryDiv = styled.div`
  display: flex;
`;

const CallbackPersonName = styled(AppText)`
  /* font-size: 12px; */
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const CallbackPersonCompany = styled(AppText)`
  border-bottom: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.2px;
  padding: 0px 4px 0px 0px;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  :hover {
    border-bottom: 1px solid ${theme.PRIMARY500};
    margin-bottom: -1px;
  }
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EventTypeInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;

  border-radius: 8px;
  background-color: ${theme.PRIMARY100};
`;

const NextEventInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;

  border-radius: 8px;
  background-color: ${theme.NEUTRAL100};
`;

const NextEventInfoTimestamp = styled.span`
  font-size: 12px;
  color: ${theme.NEUTRAL300};
  padding-left: 4px;
`;

const PipelineInfoPill = styled.div`
  display: flex;
  align-items: center;

  height: 24px;
  padding: 4px 8px;

  color: ${theme.SUCCESS500};
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;

  border-radius: 4px;
  background-color: ${theme.SUCCESS200};
`;

const ActionButtonsDiv = styled.div`
  /* background-color: ${theme.NEUTRAL100}; */
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const CardContainerHeader = styled.div`
  display: grid;

  grid-template-columns: 26px 1fr 1fr;
  min-height: 24px;
  height: fit-content;
  justify-content: center;
  align-items: center;
`;

const CardGridMiddle = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  flex-grow: 2;
  gap: 4px;
  width: 300px;
`;

const CardHeaderRight = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 100%;
`;

interface LeadCardNonExpandProps {
  removeRightPadding: boolean;
}

const LeadCardNonExpand = styled.div<LeadCardNonExpandProps>`
  margin: 16px ${(props) => (props?.removeRightPadding ? "0px" : "16px")} 32px 16px;
`;

const CardHeaderMiddle = styled.div`
  display: flex;
`;

const CardHeaderLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const PrimaryText = styled.span`
  color: ${theme.PRIMARY500};
  font-weight: 600;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const PrimaryTextDisabled = styled.span`
  color: ${theme.PRIMARY500};
  font-weight: 600;
`;
const CardContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
`;

const CardContainerMainRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const IntentButton = styled(NewAppButton)`
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: 600;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;

  width: 100%;
  height: 100%;
`;

const MemoizedLeadCard = React.memo(LeadCardV2, (oldProps, newProps) => {
  const oldP = omit(oldProps, functions(oldProps));
  const newP = omit(newProps, functions(newProps));
  return isEqual(oldP, newP);
});

export { MemoizedLeadCard as LeadCardV2 };

const SequenceTag = styled.div<{ borderRadius?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: ${(props) => props.borderRadius || " 4px 0px 0px 4px;"};
  background-color: ${theme.WARNING200};
`;
