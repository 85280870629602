import React, { Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { useMutation, gql } from "@apollo/client";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton } from "../../UI/Phoenix";
import { theme } from "../../../utils/theme";
import { errorToast, successToast } from "../../../utils/toast";
import { MixpanelActions } from "../../../services/mixpanel";

const DELETE_SEQUENCE = gql`
  mutation deleteSequence($sequenceId: String!) {
    delete_sequence(sequence_id: $sequenceId)
  }
`;

interface DeleteSequenceModalProps {
  sequenceId: string;
  setManualLoad: Dispatch<SetStateAction<boolean>>;
}

export const DeleteSequenceModal: React.FC<DeleteSequenceModalProps> = ({ sequenceId, setManualLoad }) => {
  const { showDeleteSequenceModal, setShowDeleteSequenceModal } = useContext(ModalContext);

  const [deleteSequence, { loading: loadingDeleteSequence }] = useMutation(DELETE_SEQUENCE, {
    async onCompleted({ delete_sequence }) {
      console.log("delete_sequence:", delete_sequence);
      successToast("Sequence successfully deleted!");
      setShowDeleteSequenceModal(false);

      MixpanelActions.track("Sequence Deleted");
    },
    onError({ message }) {
      errorToast(`${message}`);
      Sentry.captureEvent({
        message: `delete_sequence GraphQL Error: ${message}`,
      });
      console.log(`delete_sequence GraphQL Error: ${message}`);
      setShowDeleteSequenceModal(false);
    },
    refetchQueries: ["fetchSequences"],
  });

  return (
    <Modal
      open={showDeleteSequenceModal}
      onClose={() => {
        setShowDeleteSequenceModal(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Delete Sequence
          </AppText>
          <AppText fontSize={14}>Are you sure you want to delete this sequence? This action cannot be undone.</AppText>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowDeleteSequenceModal(false)}
          >
            No, Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              deleteSequence({
                variables: {
                  sequenceId: sequenceId,
                },
              });
              setManualLoad(true);
            }}
            disabled={!!loadingDeleteSequence}
          >
            Yes, Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  padding: 40px 40px 90px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
