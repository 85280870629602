import React, { Dispatch, SetStateAction, useCallback, useContext, useMemo, useRef, useState } from "react";
import { AppText, FlexDiv } from "../../UI";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import moment from "moment";
import { ProfileImage } from "../SettingSegments/ProfileImage";
import { PhoenixIcon } from "../../UI/Phoenix";
import { ai, caret_down, clipboard, more_vertical } from "../../../images/NewDesign";
import { useClickOutside } from "../../../utils/hooks";
import { ModalContext } from "../../../context";
import { convertSecondsToTimestamp } from "../../../utils/misc";
import { SortOption } from "../../../types";

interface AllCallsTableProps {
  data: any;
  setCurrentConferenceID: Dispatch<SetStateAction<string>>;
  handleSort: (sortBy: string) => void;
  tableSort: SortOption;
  sortOrder: "asc" | "desc";
  setTableSort: Dispatch<SetStateAction<SortOption>>;
  setSortOrder: Dispatch<SetStateAction<"asc" | "desc">>;
}

export const AllCallsTable: React.FC<AllCallsTableProps> = ({
  data,
  setCurrentConferenceID,
  handleSort,
  tableSort,
  setTableSort,
  sortOrder,
  setSortOrder,
}) => {
  const [miniMenuPos, setMiniMenuPos] = useState<undefined | number>(undefined);

  const handleColumnClick = useCallback(
    (sortOption: SortOption) => {
      setTableSort(sortOption);
      if (tableSort === sortOption) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortOrder("asc");
      }

      const newSortBy = sortOption.toUpperCase() + "_" + sortOrder.toUpperCase();

      handleSort(newSortBy);
    },
    [tableSort, sortOrder],
  );

  return (
    <>
      {miniMenuPos !== undefined && (
        <MiniMenu
          rowData={data[miniMenuPos]}
          pos={miniMenuPos}
          setMiniMenuPos={setMiniMenuPos}
          listLen={data.length}
          setCurrentConferenceID={setCurrentConferenceID}
        />
      )}
      <FlexDiv direction="column" style={{ padding: "24px 24px 0px 24px" }}>
        <Row fadeSpeed={0}>
          <Cell width={180} onClick={() => handleColumnClick("created_at")}>
            <ColumnText>Date/Time</ColumnText>
            {tableSort === "created_at" && (
              <PhoenixIcon
                svg={caret_down}
                variant="brand"
                hoverColor={theme.PRIMARY500}
                size={16}
                fillIcon
                pointer
                style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
              />
            )}
          </Cell>
          <Cell width={260} onClick={() => handleColumnClick("rep_name")}>
            <ColumnText>Rep</ColumnText>
            {tableSort === "rep_name" && (
              <PhoenixIcon
                svg={caret_down}
                variant="brand"
                hoverColor={theme.PRIMARY500}
                size={16}
                fillIcon
                pointer
                style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
              />
            )}
          </Cell>
          <Cell width={250} onClick={() => handleColumnClick("business_name")}>
            <ColumnText>Company</ColumnText>
            {tableSort === "business_name" && (
              <PhoenixIcon
                svg={caret_down}
                variant="brand"
                hoverColor={theme.PRIMARY500}
                size={16}
                fillIcon
                pointer
                style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
              />
            )}
          </Cell>
          <Cell width={180} onClick={() => handleColumnClick("call_result")}>
            <ColumnText>Who Spoke With</ColumnText>
          </Cell>
          <Cell width={180} onClick={() => handleColumnClick("call_result")}>
            <ColumnText>Call Result</ColumnText>
            {tableSort === "call_result" && (
              <PhoenixIcon
                svg={caret_down}
                variant="brand"
                hoverColor={theme.PRIMARY500}
                size={16}
                fillIcon
                pointer
                style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
              />
            )}
          </Cell>
          <Cell width={106} center onClick={() => handleColumnClick("duration")}>
            <ColumnText>Duration</ColumnText>
            {tableSort === "duration" && (
              <PhoenixIcon
                svg={caret_down}
                variant="brand"
                hoverColor={theme.PRIMARY500}
                size={16}
                fillIcon
                pointer
                style={{ transform: `rotate(${sortOrder === "asc" ? "0deg" : "180deg"})` }}
              />
            )}
          </Cell>
          {/* <Cell width={140}>
            <PhoenixIcon svg={ai} size={16} color={theme.icon.brand.default} hoverColor={theme.icon.brand.default} />
            <ColumnText>OPSIQ Score</ColumnText>
          </Cell> */}
          {/* <Cell width={140}>
            <PhoenixIcon
              svg={clipboard}
              size={16}
              color={theme.icon.brand.default}
              hoverColor={theme.icon.brand.default}
            />
            <ColumnText>Manager Score</ColumnText>
          </Cell> */}
          <Cell width={136} />
          <Cell width={40} />
        </Row>

        {data.slice()?.map((call: any, i: number) => (
          <Row key={i} fadeSpeed={(1.5 / data.length) * (i + 1)}>
            <Cell width={180}>
              <AppText fontSize={12} fontWeight={400} lineHeight={18} noWrap>
                {moment(call?.created_at).format("MM/DD/YYYY hh:mm A")}
              </AppText>
            </Cell>
            <Cell width={260}>
              <ProfileImage profileImage={call?.profile_image} diameter={24} borderDiameter={24} borderWidth={0} />
              <AppText
                fontSize={12}
                fontWeight={400}
                lineHeight={18}
                noWrap
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                {call?.salesperson}
              </AppText>
            </Cell>
            <Cell width={250}>
              <AppText
                fontSize={12}
                fontWeight={400}
                lineHeight={18}
                noWrap
                style={{ textOverflow: "ellipsis", overflow: "hidden", borderBottom: "none", cursor: "pointer" }}
                onClick={() => window.open(`/lead-detail/${call?.lead_id}`, "_blank", "noreferrer")}
              >
                {call?.business_name || "N/A"}
              </AppText>
            </Cell>
            <Cell width={180}>
              <CallResultPill result={call?.person_spoke_to} />
            </Cell>
            <Cell width={180}>
              <CallResultPill result={call?.label} />
            </Cell>
            <Cell width={106} center>
              <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                {convertSecondsToTimestamp(call?.duration || 0)}
              </AppText>
            </Cell>
            {/* <Cell width={140}></Cell>
            <Cell width={140}></Cell> */}
            <Cell width={136}>
              <AppText
                fontSize={12}
                lineHeight={18}
                fontWeight={600}
                color={theme.text.brand.primary}
                noWrap
                style={{ cursor: "pointer", borderBottom: "none" }}
                onClick={() => window.open(`/call-report/${call?.conference_id}`, "_blank", "noreferrer")}
              >
                Call Report
              </AppText>
            </Cell>
            <Cell width={40}>
              <PhoenixIcon
                svg={more_vertical}
                alt="more"
                size={16}
                variant="brand"
                pointer
                onClick={() => setMiniMenuPos(i)}
              />
            </Cell>
          </Row>
        ))}
      </FlexDiv>
    </>
  );
};

const rowHeight = 40;
const Row = styled.div<{ fadeSpeed: number }>`
  display: flex;

  width: 1312px;
  max-width: 1312px;
  min-height: ${rowHeight}px;
  max-height: ${rowHeight}px;

  &:nth-child(odd):not(:first-child) {
    background-color: ${theme.fill.brand.tertiary};
  }
  &:nth-child(even) {
    background-color: ${theme.fill.neutral.primary};
  }

  animation: ${theme.fadeIn} ${(props) => props.fadeSpeed}s ease;
`;

const Cell = styled.div<{ width: number; center?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  width: ${(props) => props.width}px;
`;

const ColumnText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.text.neutral.primary};
  white-space: nowrap;
`;

interface CallResultPillProps {
  result: string;
}

const CallResultPill: React.FC<CallResultPillProps> = ({ result }) => {
  const isSale = result?.toLowerCase() === "sale";
  const isSpokeWith = ["dm", "ndm", "dmviandm", "nocontact"].includes(result?.toLowerCase());
  const color = isSale || isSpokeWith ? theme.text.neutral.inverse : theme.text.brand.primary;
  const backgroundColor = isSale || isSpokeWith ? theme.fill.brand.primary : theme.fill.brand.secondary;

  const formattedResult = useMemo(() => {
    switch (result?.toLowerCase()) {
      case "dmviandm":
        return "DM via NDM";
      case "nocontact":
        return "No Contact";
      default:
        return result;
    }
  }, [result]);

  return (
    <PillContainer backgroundColor={backgroundColor}>
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={color}>
        {formattedResult || "No Result"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;

  padding: 4px 8px;

  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
`;

interface MiniMenuProps {
  rowData: any;
  listLen: number;
  pos: number;
  setMiniMenuPos: Dispatch<SetStateAction<number | undefined>>;
  setCurrentConferenceID: Dispatch<SetStateAction<string>>;
}

const MiniMenu: React.FC<MiniMenuProps> = ({ rowData, pos, setMiniMenuPos, listLen, setCurrentConferenceID }) => {
  const containerRef = useRef(null);

  const { setMoveToAnotherFolderModal } = useContext(ModalContext);

  useClickOutside(containerRef, () => {
    setMiniMenuPos(undefined);
  });

  return (
    <PopupContainer ref={containerRef} topPos={pos} listLen={listLen}>
      <MenuItem
        onClick={() => {
          setMoveToAnotherFolderModal(true);
          setCurrentConferenceID(rowData?.conference_id);
        }}
      >
        Save to Folder
      </MenuItem>
      <MenuItem onClick={() => console.log("TODO: save to rep profile")}>Save to Rep Profile</MenuItem>
    </PopupContainer>
  );
};

const PopupContainer = styled.div<{ topPos: number; listLen: number }>`
  position: absolute;
  top: ${({ topPos, listLen }) => {
    const maxTop = listLen * rowHeight - rowHeight;
    return Math.min(80 + topPos * rowHeight, maxTop);
  }}px;
  right: 48px;
  z-index: 10;

  background-color: ${theme.WHITE_COLOR};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 1px solid ${theme.NEUTRAL300};
  border-radius: 4px;
  overflow: hidden;

  animation: ${theme.popup} 0.2s ease;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;

  width: 152px;
  height: 40px;
  padding: 8px;

  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
  color: ${theme.BLACK_COLOR};

  transition: background-color 0.15s ease, color 0.15s ease;
  z-index: 1;

  :hover {
    background-color: ${theme.PRIMARY100};
    color: ${theme.PRIMARY500};
  }
`;
