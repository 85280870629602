import dagre from "dagre";
import moment from "moment";
import { cloneDeep } from "lodash";
import { chat, info, mail, phone_call, settings, alert_circle } from "../images/NewDesign";
import { listBrazil, listMexico, provincesCanada, statesUSA } from "../static/countries";
import { AllSequenceFetchShape, Segment } from "../types/SequenceTypes";
import { PhoenixIcon } from "../Components/UI/Phoenix";
import { SequenceLeaderboardItem, SequenceLeaderboardSort } from "../types/SequenceTypes";

/** Generates sequence workflow view nodes, edges, and layout
 *
 * @param stepList list of step objects given to us by BE
 * @param active bool representing if the sequence is active
 */
export const handleDiagramGeneration = (stepList: any[], active: boolean) => {
  let { convertedNodeList, edgeList } = createGraphLists(stepList);

  // get each nodes depth in the tree. This is used to display the step number in the UI
  const filteredEdgeList = cloneDeep(edgeList)
    .filter((ele: any) => !ele.target.includes("end"))
    ?.map((edge: any) => {
      // modify edges to filter out dispositionNode's and conditionNode's
      const sourceEdge = edgeList.find((ele) => ele.target === edge.source);
      const sourceEdgeSource = edgeList.find((ele) => ele.target === sourceEdge?.source);
      if (sourceEdge?.source.includes("condition")) {
        edge.source = sourceEdge.source;
      }
      if (!!sourceEdgeSource && sourceEdgeSource?.source.includes("condition")) {
        edge.source = sourceEdgeSource.source;
      }
      return edge;
    });
  const rankList = getNodeDepth(
    convertedNodeList.filter(
      (ele) =>
        !ele.id.includes("end") && !ele?.data?.origin_id?.includes("condition") && !ele?.data?.conditions?.length,
    ),
    filteredEdgeList,
  );
  // update node data to include depth number
  convertedNodeList = convertedNodeList?.map((node: any) => ({
    ...node,
    data: { ...node.data, rank: rankList.find((ele) => ele.id === node.id)?.depth + 1, active },
  }));

  return { nodes: convertedNodeList, edges: edgeList };
};

/** Uses dagreJS to generate a graph layout and update the nodes positions based on the generated layout */
export const updateNodePositions = (nodeList: any, edgeList: any) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 296;
  const fallbackNodeHeight = 98;

  // plug nodes and edges into dagre graph
  dagreGraph.setGraph({ rankdir: "TB", nodesep: 40, ranksep: 56 });
  nodeList.forEach((node: any) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height:
        node.data?.is_host_step && !node?.id?.includes("condition")
          ? 0
          : node.domEleHeight && node.domEleHeight > 130
          ? node.domEleHeight + 32
          : node.domEleHeight || fallbackNodeHeight,
    });
  });
  edgeList.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // generate layout
  dagre.layout(dagreGraph);
  // update node positions to dagre's generated node positions
  nodeList.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = { x: nodeWithPosition.x, y: nodeWithPosition.y };
  });

  return { nodes: nodeList, edges: edgeList };
};

/** Generates a node list in a format that ReactFlow can read. Also generates an edgelist based on the initial stepList */
const createGraphLists = (stepList: any[]) => {
  let convertedNodeList: any[] = stepList?.map((step: any) =>
    // create a node with position 0/0. Positions are updated dynamically by dagre
    ({
      id: step.id,
      position: { x: 0, y: 0 },
      data: step,
      type: step?.conditions?.length ? "dispositionNode" : "sequenceStep",
    }),
  );
  stepList.forEach((step: any) => {
    const curNode = convertedNodeList.find((ele) => ele.id === step.id);
    // push a ConditionNode if step is a conditional
    if (step.is_host_step) {
      curNode.id = `condition-${curNode.id}`;
      convertedNodeList.push({
        id: step.id,
        position: { x: 0, y: 0 },
        data: { ...step, origin_id: curNode.id },
        type: "conditionNode",
      });
    }
    // push an 'End Sequence' node for each 'is_last_step' node
    step.is_last_step &&
      convertedNodeList.push({
        id: `end-${step.id}`,
        position: { x: 0, y: 0 },
        data: { ...step, origin_id: step.id, id: `end-${step.id}` },
        type: "endSequence",
      });
  });
  console.log("sequences: initialStepList & convertedStepList", stepList, convertedNodeList);

  // generate edgeList - if the node has an origin, it requires an edge
  let edgeList: any[] = [];
  convertedNodeList.forEach((node: any, i: number) => {
    node.data?.origin_id &&
      edgeList.push({
        id: `edge-${i}=${node.data.origin_id}`,
        source: node.data.origin_id,
        target: node.id,
        type:
          convertedNodeList.find(
            (ele) =>
              (ele.data?.id === node.data?.origin_id || `condition-${ele.data?.id}` === node.data?.origin_id) &&
              ele.data?.is_host_step,
          ) ||
          (!!convertedNodeList.find((ele) => ele.id === node.data?.origin_id)?.data?.conditions?.length &&
            !node.id.includes("end"))
            ? "smoothstep"
            : "buttonEdge", // add a '+' button for all paths not connected to a condition
        animated: true,
      });
  });
  console.log("sequences: edgeList", edgeList);
  return { convertedNodeList, edgeList };
};

/** Parses through step node data and returns cleaned up data */
export const parseStepNodeData = (data: any) => {
  let icon;
  let label;
  let metrics;
  switch (data?.actions?.[0]?.task) {
    case "automatedEmail":
      icon = mail;
      label = "Automated Email";
      metrics = { ctr: data?.actions?.[0]?.clicked_perc, opened: data?.actions?.[0]?.opened_perc };
      break;
    case "manualEmail":
      icon = mail;
      label = "Manual Email";
      break;
    case "automatedSms":
      icon = chat;
      label = "Automated SMS";
      break;
    case "manualSms":
      icon = chat;
      label = "Manual SMS";
      break;
    case "manualCall":
      icon = phone_call;
      label = "Phone Call";
      break;
    case "requestManagerFeedback":
      icon = info;
      label = "Request Manager Feedback";
      break;
    case "customTask":
      icon = settings;
      label = "Custom Task";
      break;
    default:
      icon = alert_circle;
      label = "";
  }

  let delay;
  if (!!data?.actions?.[0]?.delay_amount) {
    const delayDuration = moment.duration(data?.actions?.[0]?.delay_amount, "minutes");
    const calculatedDurationHours = delayDuration.asDays() - Math.floor(delayDuration.asDays()) * 24;

    delay = {
      days: Math.floor(delayDuration.asDays()),
      hours: delayDuration.days() && delayDuration.hours() ? calculatedDurationHours : delayDuration.hours(),
      minutes: delayDuration.minutes(),
    };
  }

  return { icon, label, metrics, delay };
};

/** Returns a nodeList with their respective depths in the tree using DFS
 *
 * @param nodeList List of nodes
 * @param edgeList List of edges that connect each node
 */
export const getNodeDepth = (nodeList: any[], edgeList: any[]): any[] => {
  const adjacencyList: any = {};
  const depths: any = {};

  // Build adjacency list
  for (const edge of edgeList) {
    const { source, target } = edge;
    if (!adjacencyList[source]) {
      adjacencyList[source] = [];
    }
    adjacencyList[source].push(target);
  }

  function dfs(nodeId: any, depth: any) {
    depths[nodeId] = depth;

    const neighbors = adjacencyList[nodeId] || [];
    for (const neighbor of neighbors) {
      dfs(neighbor, depth + 1);
    }
  }

  for (const node of nodeList) {
    if (!depths[node.id]) {
      dfs(node.id, 0);
    }
  }

  // Collect nodes with their depths
  const nodesWithDepths = nodeList?.map((node) => ({
    id: node.id,
    depth: depths[node.id] || 0,
  }));

  return nodesWithDepths;
};

/** Options for entry criteria dropdown */
export const entryCriteriaOptions = [
  { label: "Cold Call", value: ["ColdCallCold", "ColdCallWorkingNum", "ColdCallNDM", "ColdCallDM"] },
  { label: "Demo set for future date", value: "HeldPhase" },
  { label: "Demo not held at set time", value: "PostInitialHelPhaseDemoNotHeld" },
  { label: "Demo held", value: "PostHoldPhase" },
];

/** Options for person_spoken_to dropdown */
export const personSpokenToOptions = [
  { value: "NDM", label: "Non-Decision Maker" },
  { value: "DM", label: "Decision Maker" },
  { value: "DMviaNDM", label: "Decision Maker via Non-Decision Maker" },
  { value: "NoContact", label: "No Contact" },
];

/** Options for region dropdown */
export const allRegions = [
  { label: "United States:", value: "", disabled: true },
  ...statesUSA?.map((state: any) => ({ value: state.state_code, label: state.name })),
  { label: "Canada:", value: "", disabled: true },
  ...provincesCanada?.map((province: any) => ({ value: province.state_code, label: province.name })),
  { label: "Mexico:", value: "", disabled: true },
  ...listMexico?.map((ele: string) => ({ value: ele, label: ele })),
  { label: "Brazil:", value: "", disabled: true },
  ...listBrazil?.map((ele: string) => ({ value: ele, label: ele })),
];

/** Options for channel dropdown */
export const channelOptions = [
  { value: "Inbound", label: "Inbound" },
  { value: "Outbound", label: "Outbound" },
];

/** Options for end sequence node */
export const endActionOptions = [
  { label: "Default (End Sequence)", value: "None" },
  { label: "Repeat Sequence", value: "RepeatSequence" },
  { label: "Release Lead", value: "ReleaseLead" },
  { label: "Transfer Sequence (Same Phase)", value: "TransferAnotherSequence" },
  { label: "Move Lead to Resting", value: "MoveToResting" },
  { label: "Move Lead to Long Resting", value: "MoveToLongResting" },
  { label: "Move Lead to Retired", value: "MoveToRetired" },
];

/** Dispositions not allowed as a branch */
export const blockedDispositions = ["Sale", "Retired", "PropsectDropped"];

export const leaderboardMetricOptions = [
  { label: "Sequence Completion Count", value: "sequence_completion_rate" },
  { label: "Most Tasks Deleted", value: "most_tasks_deleted" },
  { label: "Fewest Tasks Deleted", value: "fewest_tasks_deleted" },
  { label: "Fastest Task Completion", value: "fastest_tasks_completed" },
  { label: "Tasks Completed as Expected", value: "tasks_completed" },
];

export const sequenceReportingTimeframeOptions = [
  {
    label: "All Time",
    value: {
      lowerbound_date: undefined, // passing undefined to BE will return all time
      upperbound_date: undefined,
    },
  },
  {
    label: "Custom Time Frame",
    value: {
      lowerbound_date: "custom",
      upperbound_date: "custom",
    },
  },
  {
    label: "Last Month",
    value: {
      lowerbound_date: moment().subtract(1, "month").startOf("month").toDate(),
      upperbound_date: moment().subtract(1, "month").endOf("month").toDate(),
    },
  },
  {
    label: "This Month",
    value: {
      lowerbound_date: moment().startOf("month").toDate(),
      upperbound_date: moment().endOf("month").toDate(),
    },
  },
  {
    label: "Last Six Months",
    value: {
      lowerbound_date: moment().subtract(6, "months").startOf("month").toDate(),
      upperbound_date: moment().toDate(),
    },
  },
  {
    label: "Last 12 Months",
    value: {
      lowerbound_date: moment().subtract(1, "years").toDate(),
      upperbound_date: moment().toDate(),
    },
  },
  {
    label: "Last Year",
    value: {
      lowerbound_date: moment().subtract(1, "year").startOf("year").toDate(),
      upperbound_date: moment().subtract(1, "year").endOf("year").toDate(),
    },
  },
];

/** Return's leaderboard rank for SequenceLeaderboardItem
 * @param data SequenceLeaderboardItem[] : The leaderboard data.
 * @param item SequenceLeaderboardItem : The item to get the rank for.
 * @returns number : The rank of the item.
 */
export const getLeaderboardRank = (data: SequenceLeaderboardItem[], item: SequenceLeaderboardItem) => {
  return (
    data?.indexOf(data?.find((ele: SequenceLeaderboardItem) => ele?.id === item?.id) as SequenceLeaderboardItem) + 1
  );
};

/** Sort's leaderboard based on order selected.
 * @param data SequenceLeaderboardItem[] : The leaderboard data.
 * @param order SequenceLeaderboardSort : The order to sort the leaderboard.
 * @param a SequenceLeaderboardItem : The first item to compare.
 * @param b SequenceLeaderboardItem : The second item to compare.
 * @returns number : -1, 0, 1. Used for sort method
 */
export const handleLeaderboardSort = (
  metric: string,
  order: SequenceLeaderboardSort,
  a: SequenceLeaderboardItem,
  b: SequenceLeaderboardItem,
) => {
  switch (order) {
    case "rate-desc":
      return ["fewest_tasks_deleted", "fastest_tasks_completed"].includes(metric) ? a.rate - b.rate : b.rate - a.rate;
    case "rate-asc":
      return ["fewest_tasks_deleted", "fastest_tasks_completed"].includes(metric) ? b.rate - a.rate : a.rate - b.rate;
    case "rep-desc":
      return a.name.localeCompare(b.name);
    case "rep-asc":
      return b.name.localeCompare(a.name);
    default:
      return b.rate - a.rate;
  }
};

/** Filter sequence reporting page by global filters. Used to filter data for all reporting charts without the need to refetch on global filter change.
 *
 * Sales teams and reps filter is not handled here. It is handled in the fetch query.
 *
 * Handled global filters include:
 * - Sequence id filter
 * - Phase filter
 *
 * @param dataFetch Object : The data returned from the fetch query.
 * @param sequenceData AllSequenceFetchShape[] : The data returned from the reporting page fetchSequences query.
 * @param filterBySequences string[] : The sequence_ids to filter by.
 * @param filterByPhases string[] : The phases to filter by.
 */
export const filterSequenceDataGlobal = (
  dataFetch: any,
  sequenceData: AllSequenceFetchShape[],
  filterBySequences: string[],
  filterByPhases: string[],
) => {
  const filteredSequenceData = sequenceData
    ?.filter((sequence: AllSequenceFetchShape) =>
      !!filterBySequences.length ? filterBySequences?.includes(sequence?.id) : true,
    )
    .filter((sequence: AllSequenceFetchShape) =>
      !!filterByPhases?.length ? filterByPhases.includes(sequence?.sequence_entry_criteria?.current_phase?.[0]) : true,
    );
  const newChartData = dataFetch
    ?.map((d: any, i: number) => ({ ...d, index: i }))
    ?.filter((d: any) =>
      !!filterBySequences?.length ? filteredSequenceData.find((ele: any) => ele.id === d.id) : true,
    )
    .filter((d: any) => (!!filterByPhases?.length ? filteredSequenceData.find((ele: any) => ele.id === d.id) : true));
  return newChartData;
};
