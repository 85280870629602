import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ModalContext } from "../../../context";
import { Modal } from "../Modal";
import { PhoenixAppButton, PhoenixInput } from "../../UI/Phoenix";
import { FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { SaleConfigPage } from "../../../utils/misc";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";

interface AddSalePageModalProps {
  pageState: {
    pages: SaleConfigPage[];
    setPages: Dispatch<SetStateAction<SaleConfigPage[]>>;
  };
}

export const AddSalePageModal: React.FC<AddSalePageModalProps> = ({ pageState: { pages, setPages } }) => {
  const { showAddSalePageModal, setShowAddSalePageModal } = useContext(ModalContext);

  const [pageName, setPageName] = useState<string>("");
  const duplicateName = useMemo(() => pages.some((p) => p.title === pageName), [pageName]);

  return (
    <Modal
      open={showAddSalePageModal}
      onClose={() => {
        setShowAddSalePageModal(false);
      }}
      bigCloseButton
      popupAnimation
    >
      <Main>
        <ModalBody>
          <AppText fontSize={16} fontWeight={600}>
            Create a New Page
          </AppText>

          <FlexDiv direction="column" gap={8}>
            <AppText fontSize={12} fontWeight={500} lineHeight={18}>
              Name
            </AppText>
            <PhoenixInput
              value={pageName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPageName(e.target.value)}
              placeholder="A New Page"
              width={396}
              displayNoContextText
            />
          </FlexDiv>
        </ModalBody>

        <ModalButtonContainer>
          <PhoenixAppButton
            buttonType="secondary"
            variant="danger-outline"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => setShowAddSalePageModal(false)}
          >
            Cancel
          </PhoenixAppButton>
          <PhoenixAppButton
            buttonType="secondary"
            uppercase
            buttonTextFontSize={10}
            style={{ letterSpacing: "1px" }}
            onClick={() => {
              const newPages = cloneDeep(pages);
              newPages.push({
                id: uuidv4(),
                order: pages.length,
                title: pageName,
                sections: [],
              });

              // remove required page if there are more than 1 pages
              if (newPages.length > 1) {
                const requiredPage = newPages.findIndex((p) => p.required === true);
                if (requiredPage !== -1) {
                  newPages[requiredPage].required = false;
                }
              }

              setPages(newPages);
              setShowAddSalePageModal(false);
            }}
            disabled={!pageName.length || duplicateName}
          >
            Continue
          </PhoenixAppButton>
        </ModalButtonContainer>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 476px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  padding: 40px 40px 74px 40px;

  text-align: center;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px 40px;

  border-top: 1px solid ${theme.NEUTRAL200};
`;
