import styled from "styled-components";
import * as React from "react";
import * as Sentry from "@sentry/react";
import { AppText, Loading } from "../UI";
import { theme } from "../../utils/theme";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { CustomFieldsType } from "../../types";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { appToast } from "../../utils/toast";
import { NewAppButton } from "../UI/NewAppButton";
import { convertDate } from "../../utils/format";
import { CustomField } from "../Field/CustomField";

const EDIT_CUSTOM_FIELDS_FOR_LEAD = gql`
  mutation editCustomFieldsForLead($custom_fields: [CustomFieldInput!], $lead_id: String) {
    editCustomFieldsForLead(custom_fields: $custom_fields, lead_id: $lead_id) {
      id
      key
      type
      value
      boo_value
      num_value
      date_value
      computed_value
      lead_id
      options
      list_value
    }
  }
`;

interface EditCustomFieldsProps {
  showEditCustomFields: boolean;
  closeCustomFieldsModal: () => void;
  fields: CustomFieldsType[];
  lead_id: string;
  customHeight?: boolean;
}

interface CustomField {
  id: string;
  value: string | number | boolean;
  num_value?: number;
  boo_value?: boolean;
  date_value?: string;
  list_value?: string[];
  type: string;
  key: string;
  options: string[];
  allow_reps_to_edit?: boolean;
}

interface MyFormikProps {
  customFields: CustomField[];
}

const EditCustomFieldsComponent: React.FC<EditCustomFieldsProps> = ({
  showEditCustomFields,
  closeCustomFieldsModal,
  customHeight = false,
  ...props
}) => {
  console.log("props.fields: ", props.fields);

  const [editCustomFieldsForLead, { loading, error }] = useMutation(EDIT_CUSTOM_FIELDS_FOR_LEAD, {
    async onCompleted({ editCustomFieldsForLead }) {
      console.log("editCustomFieldsForLead: ", editCustomFieldsForLead);
      if (!editCustomFieldsForLead) {
        appToast("Error requesting edit. Something went wrong.");
        return;
      }
      appToast("Edit requested!");
      closeCustomFieldsModal();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `editCustomFeildsForLead GraphQL Error: ${message}`,
      });
      console.log("Error in editCustomFieldsForLead: ", message);
    },
    refetchQueries: ["fetchLead", "FetchAssociateContact"],
  });

  const numberYupValidation = () => ({
    is: "Rate" || "Percentage" || "Number",
    then: Yup.number().typeError("Must be a number").nullable(),
  });

  const textYupValidation = () => ({
    is: "Text",
    then: Yup.string(),
  });

  const booleanYupValidation = () => ({
    is: "Boolean",
    then: Yup.boolean().notRequired(),
  });

  const EditCustomFieldsSchema = Yup.object().shape({
    customFields: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        type: Yup.string().notRequired(),
        value: Yup.string().when("type", textYupValidation()).nullable(),
        // list_value: Yup.array().string().when("type", textYupValidation()).nullable(),
        boo_value: Yup.boolean().when("type", booleanYupValidation()).nullable(),
        num_value: Yup.number().typeError("Must be a number").when("type", numberYupValidation()).nullable(),
        // date_value: Yup.string().typeError("Must be a string").when("type", textYupValidation()).nullable(),
      }),
    ),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        customFields: props.fields
          ?.map((item) => item)
          .sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()))
          .map((customField) => {
            let valueToUse = {
              id: customField.id,
              value: "",
              list_value: [],
              type: customField.type,
              key: customField.key,
              options: [],
              allow_reps_to_edit: customField?.allow_reps_to_edit ?? true,
            } as any;
            let optionsToUse = [] as string[];
            switch (customField.type) {
              case "Dropdown":
                valueToUse.value = customField.value;
                valueToUse.options = customField.options;
                break;
              case "MultiDropdown":
                valueToUse.list_value = (customField.list_value || [])?.map((k) => ({ label: k, value: k }));
                valueToUse.options = customField.options;
                break;
              case "MultiText":
                valueToUse.list_value = (customField.list_value || [])?.map((k) => ({ label: k, value: k }));
                break;
              case "Date":
                console.log(`date on convert for ${customField.key}: `, customField.date_value);
                valueToUse.date_value = customField.date_value ? convertDate(customField.date_value) : undefined;
                console.log(`date on after convert for ${customField.key}: `, valueToUse.date_value);
                break;
              case "Number":
                valueToUse.num_value = customField.num_value;
                break;
              case "Text":
                valueToUse.value = customField.value;
                break;
              case "Boolean":
                valueToUse.boo_value = customField.boo_value;
                break;
              case "Rate":
                valueToUse.num_value = customField.num_value;
                break;
              case "Percentage":
                valueToUse.num_value = customField.num_value;
                break;
              default:
                valueToUse.value = "";
            }

            return valueToUse;
          }),
      }}
      validationSchema={EditCustomFieldsSchema}
      onSubmit={async (values) => {
        const finalSubmissionValue = values?.customFields?.map((customField) => {
          switch (customField.type) {
            case "Dropdown":
              return {
                key: customField.key,
                id: customField.id,
                value: customField.value,
              };
            case "MultiDropdown":
              return {
                key: customField.key,
                id: customField.id,
                // @ts-ignore
                list_value: customField?.list_value?.map((k) => k.value ?? k),
              };
            case "MultiText":
              return {
                key: customField.key,
                id: customField.id,
                // @ts-ignore
                list_value: customField?.list_value?.map((k) => k.value ?? k),
              };
            case "Date":
              return {
                key: customField.key,
                id: customField.id,
                date_value: customField.date_value,
              };
            case "Number":
              return {
                key: customField.key,
                id: customField.id,
                num_value: parseFloat(customField.num_value as any),
              };
            case "Text":
              return {
                key: customField.key,
                id: customField.id,
                value: customField.value,
              };
            case "Boolean":
              return {
                key: customField.key,
                id: customField.id,
                boo_value: customField.boo_value,
              };
            case "Rate":
              return {
                key: customField.key,
                id: customField.id,
                num_value: parseFloat(customField.num_value as any),
              };
            case "Percentage":
              return {
                key: customField.key,
                id: customField.id,
                num_value: parseFloat(customField.num_value as any),
              };
            default:
              return {
                key: customField.key,
                id: customField.id,
                value: customField.value,
              };
          }
        });

        await editCustomFieldsForLead({
          variables: {
            custom_fields: finalSubmissionValue,
            lead_id: props.lead_id,
          },
        });
      }}
    >
      {({ submitForm, isSubmitting, values, isValid, errors, dirty, setFieldValue }: FormikProps<MyFormikProps>) => {
        return (
          <>
            <TitleDiv>
              <TitleText>Edit Custom Fields</TitleText>
              <CloseButton></CloseButton>
            </TitleDiv>
            <ScrollingDiv customHeight={customHeight}>
              <FieldArray name="customFields">
                {() => (
                  <>
                    {values.customFields?.map((customField, index) => {
                      return (
                        <CustomField
                          customField={customField}
                          setFieldValue={setFieldValue}
                          values={values}
                          fieldName={`customFields`}
                          index={index}
                        />
                      );
                    })}
                  </>
                )}
              </FieldArray>
            </ScrollingDiv>
            <SubmitDiv customHeight={customHeight}>
              <NewAppButton
                onClick={() => {
                  closeCustomFieldsModal();
                }}
              >
                Cancel
              </NewAppButton>
              <NewAppButton variant={"primary"} onClick={submitForm} disabled={loading}>
                {loading || isSubmitting ? <Loading color={theme.WHITE_COLOR} /> : "Save"}
              </NewAppButton>
            </SubmitDiv>
          </>
        );
      }}
    </Formik>
  );
};

interface CustomHeightProps {
  customHeight: boolean;
}

const TitleText = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
`;

const TitleDiv = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.NEUTRAL100};
  border-bottom: solid 1px ${theme.NEUTRAL200};
`;

const ScrollingDiv = styled.div<CustomHeightProps>`
  min-height: ${(props) => (props.customHeight ? "calc(100vh - 320px)" : "calc(100vh - 80px - 56px - 47px)")};
  max-height: ${(props) => (props.customHeight ? "calc(100vh - 320px)" : "calc(100vh - 80px - 56px - 47px)")};
  overflow: auto;
  width: 100%;
  padding: 32px 24px;
  margin-bottom: ${(props) => (props.customHeight ? "60px" : "0px")};
`;

const SubmitDiv = styled.div<CustomHeightProps>`
  position: absolute;
  height: 80px;
  /* bottom: ${(props) => (props.customHeight ? "0px" : "20px")}; */
  bottom: 0px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  margin-top: auto;
  margin-bottom: ${(props) => (props.customHeight ? "0px" : "5px")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 6px;
  cursor: pointer;
  z-index: 5;
`;

export { EditCustomFieldsComponent };
