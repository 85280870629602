import { gql, useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import React, { createContext, FunctionComponent, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react";
import { IS_LOGGED_IN, LOGGED_IN_USER } from "../apollo/query";
import { calculateTimeDifferenceNum } from "../utils/format";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { useMutation } from "@apollo/client";

interface LiveUserStatusUser {
  id: string;
  first_name: string;
  last_name: string;
  profile_image: string;
  team_id: string;
}

export interface LiveUserStatusItem {
  user_id: string;
  status: string;
  conference_id: string;
  updated_at: Date;
  activity_type: string;
  user: LiveUserStatusUser;
}

interface CallMonitorItem {
  label: string;
  value: string;
}
interface CallMonitorFilter {
  statuses: CallMonitorItem[];
  team_ids: CallMonitorItem[];
  user_ids: CallMonitorItem[];
}

interface LiveUserStatusContextState {
  liveUserStatus: LiveUserStatusItem[];
  setLiveUserStatus: Dispatch<SetStateAction<LiveUserStatusItem[]>>;
  callMonitorFilter: CallMonitorFilter;
  setCallMonitorFilter: Dispatch<SetStateAction<CallMonitorFilter>>;
  loadingUserStatus: boolean;
}

const LIVE_USER_STATUS = gql`
  query fetchLiveUserStatus {
    fetchLiveUserStatus {
      user_id
      status
      conference_id
      updated_at
      activity_type
      user {
        id
        first_name
        last_name
        profile_image
        team_id
      }
    }
  }
`;

const getLoggedInUserChannel = (user: any) => {
  return !user?.loggedInUser?.organization_id
    ? ``
    : user?.loggedInUser?.role === "AE" || user?.loggedInUser?.role === "SDR"
    ? ``
    : `${user?.loggedInUser?.organization_id}:liveUserStatus`;
};

export const LiveUserStatusContext = createContext<LiveUserStatusContextState>({} as LiveUserStatusContextState);

export const LiveUserStatusProvider: FunctionComponent = ({ children }) => {
  // Calendar date picker for my schedule
  const [liveUserStatusData, setLiveUserStatus] = useState<LiveUserStatusItem[]>([]);
  const [callMonitorFilter, setCallMonitorFilter] = useState<CallMonitorFilter>(
    JSON.parse(
      localStorage?.getItem("call_monitor_filter") ??
        `{
          "user_ids": [],
          "team_ids": [],
          "statuses":[]
    }`,
    ) || {
      user_ids: [],
      team_ids: [],
      statuses: [],
    },
  );

  const { data: loggedInUser } = useQuery(LOGGED_IN_USER);

  const [fetchLiveUserStatus, { data: userStatusData, loading: loadingUserStatus }] = useLazyQuery(LIVE_USER_STATUS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!!loggedInUser?.loggedInUser?.id) {
      fetchLiveUserStatus();
    } else {
    }
  }, [loggedInUser?.loggedInUser?.id]);

  useEffect(() => {
    if (!userStatusData?.fetchLiveUserStatus?.length) {
      setLiveUserStatus([]);
      return;
    }
    setLiveUserStatus(userStatusData.fetchLiveUserStatus.slice());
  }, [userStatusData]);

  useEffect(() => {
    localStorage.setItem("call_monitor_filter", JSON.stringify(callMonitorFilter) || "");
  }, [callMonitorFilter]);

  const ably_user_status_channel = getLoggedInUserChannel(loggedInUser);

  const [channel, ably] = useChannel(ably_user_status_channel, (payload) => {
    console.log("live status inbound from Ably: ", payload);
    console.log("loggedInUser: ", loggedInUser);
    console.log("ably_user_status_channel: ", ably_user_status_channel);
    if (!loggedInUser?.loggedInUser?.organization_id) {
      console.log("no org id for logged in user");
      return;
    }
    if (!payload?.data) {
      console.log("no payload data");
      return;
    }
    console.log(`liveUserStatus before update: `, liveUserStatus);

    const statusUpdate = payload.data;
    setLiveUserStatus((prev) =>
      prev?.map((lu) => {
        if (lu.user_id === statusUpdate?.user_id) {
          return statusUpdate;
        }
        return lu;
      }),
    );
  });

  useEffect(() => {
    console.log("live user status change: ", liveUserStatusData);
  }, [liveUserStatusData]);

  const liveUserStatus = liveUserStatusData
    .sort((a, b) => {
      if (a?.status === "CALL" && b?.status !== "CALL") {
        return -1;
      } else if (a?.status === "CALL" && b?.status === "CALL") {
        return moment(a?.updated_at).isBefore(moment(b?.updated_at)) ? -1 : 1;
      } else if (a?.status !== "CALL" && b?.status === "CALL") {
        return 1;
      } else {
        return 0;
      }
    })
    .filter((item) => {
      let isInStatusFilters = true;
      let isInRepFilters = true;
      let isInTeamFilters = true;
      if (callMonitorFilter.statuses?.length) {
        let filtered = callMonitorFilter.statuses?.map((status) => status?.value);
        isInStatusFilters = filtered.includes(item.activity_type) || filtered.includes(item.status);
      }
      if (callMonitorFilter?.team_ids?.length) {
        let filtered = callMonitorFilter.team_ids?.map((team_id) => team_id?.value);
        isInTeamFilters = filtered.includes(item?.user?.team_id);
      }
      if (callMonitorFilter?.user_ids?.length) {
        let filtered = callMonitorFilter.user_ids?.map((user_id) => user_id?.value);
        isInRepFilters = filtered.includes(item?.user_id);
      }
      return isInStatusFilters && isInRepFilters && isInTeamFilters;
    });

  const memoizedValue = useMemo(
    () => ({
      liveUserStatus,
      setLiveUserStatus,
      callMonitorFilter,
      setCallMonitorFilter,
      loadingUserStatus,
    }),
    [liveUserStatus, setLiveUserStatus, callMonitorFilter, setCallMonitorFilter, loadingUserStatus],
  );

  return <LiveUserStatusContext.Provider value={memoizedValue}>{children}</LiveUserStatusContext.Provider>;
};
