import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { CustomFieldType, CustomObjectRow } from "../../../__generated__/graphql";
import { theme } from "../../../utils/theme";
import { CustomObjecetAssociatedLead } from "../../Segments/DashboardSideBarSegments/CustomObjectAssociatedLead";
import { AppBackButton, AppText } from "../../UI";
import "./CustomObjectListView.css";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { isValidURL } from "../../../utils/misc";
import { handleCustomObjectRecordDataType } from "../../../utils/other-functions";

const FETCH_ONE_CUSTOM_OBJECT_ROW = gql`
  query fetchOneCustomObjectRow($custom_object_row_id: String!) {
    fetchOneCustomObjectRow(custom_object_row_id: $custom_object_row_id) {
      id
      record {
        id
        num_value
        string_value
        list_value
        date_value
        boo_value
        object_field_id
        object_field {
          id
          name
          type
        }
      }
    }
  }
`;

interface LocationState {
  row?: CustomObjectRow;
  object_name?: string;
}

interface ParamTypes {
  row_id: string;
}

const CustomObjectDetailView: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("Details");
  const { row_id } = useParams<ParamTypes>();

  const location = useLocation<LocationState>();
  const [row, setRow] = useState<CustomObjectRow | null>(location.state?.row || null);
  const object_name = location.state?.object_name;

  const { data, loading } = useQuery(FETCH_ONE_CUSTOM_OBJECT_ROW, {
    variables: { custom_object_row_id: row_id },
    skip: !!row?.id,
    onCompleted: (data) => {
      setRow(data.fetchOneCustomObjectRow);
    },
  });

  const record = row?.record;

  const DataList = () => {
    return (
      <DataListDiv>
        {record?.map((record, index) => {
          const displayText = handleCustomObjectRecordDataType(record, record.object_field?.type);
          return (
            <FieldGridDiv key={index} index={index}>
              <div>{record.object_field?.name}</div>
              <div>
                {isValidURL(displayText) ? (
                  <a
                    href={`${displayText?.startsWith("http") ? "" : "//"}${displayText}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {displayText}
                  </a>
                ) : (
                  displayText
                )}
              </div>
            </FieldGridDiv>
          );
        })}
      </DataListDiv>
    );
  };

  return (
    <Container>
      <TopOptions>
        <FlexCol>
          <AppBackButton />
          <AppText fontSize={22} fontWeight={500}>
            {object_name}
          </AppText>
        </FlexCol>
      </TopOptions>
      <BodyContainer>
        <GridDiv>
          <LeftDiv>
            <Tabs>
              <Tab selected={selectedTab === "Details"} onClick={() => { }}>
                Details
              </Tab>
            </Tabs>
            <DataList />
          </LeftDiv>
          <RightDiv>
            <CustomObjecetAssociatedLead row_id={row_id} />
          </RightDiv>
        </GridDiv>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 1200px;
  box-sizing: border-box;
  background: #fff;
  height: 100%;
`;

const DataListDiv = styled.div`
  overflow-y: auto;
  // create space for the support hover
  // these fixed css values should be higher up but this is a hotfix
  max-height: calc(100vh - 300px);
`;

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  padding: 24px 40px;
  border-bottom: 1px solid ${theme.border.neutral.secondary};
`;

const BodyContainer = styled.div`
  // remove the 104px from the height to compensate for header
  height: calc(100% - 104px);
  width: 100%;
  background: #f7f9ff;
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 432px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  padding: 40px;
  gap: 40px;
`;

const LeftDiv = styled.div`
  overflow-x: hidden;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--border-neutral-primary, #a6aabc);
  background: var(--fill-neutral-primary, #fff);
  height: fit-content;
`;

const RightDiv = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 80vh;
  width: 100%;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
`;

interface SelectedProps {
  selected?: boolean;
}

const Tab = styled(AppText) <SelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  border-bottom: solid 2px ${(props) => (props.selected ? "#3E65FF" : "transparent")};
  padding-bottom: 6px;
  margin-top: 2px;
  color: ${(props) => (props.selected ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  text-transform: uppercase;
`;

const Tabs = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: 16px 24px 0px 24px;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--border-neutral-primary, #a6aabc);
`;

interface FieldGridProps {
  index: number;
}

const FieldGridDiv = styled.div<FieldGridProps>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 230px 1fr;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  padding: 11px 16px;
  align-items: flex-start;
  align-self: stretch;
  background: ${(props) => (props.index % 2 === 0 ? theme.PRIMARY50 : theme.WHITE_COLOR)};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export default CustomObjectDetailView;
