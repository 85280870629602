import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useContext, useState } from "react";
import Switch from "react-switch";
import { restAPI } from "../../apollo";
import { getIntegrationLabel } from "../../utils/format";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { BACKEND_URL } from "../../utils/variables";
import { AppText, FlexDiv } from "../UI";
import styled from "styled-components";
import { IIntegrations, IntegrationItem } from "../../types/StateTypes";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { ModalContext } from "src/context";

interface IntegrationCardProps {
  integration: IntegrationItem;
  isConnected: boolean;
  optionsButton?: any;
}

// const DISCONNECT_INTEGRATION = gql`
//   mutation disconnectIntegration($integration_type: INTEGRATION_TYPE!) {
//     disconnectIntegration(integration_type: $integration_type)
//   }
// `;

const getGmailLink = async () => {
  try {
    const api_call = await restAPI
      .get(`${BACKEND_URL}/gmail/getGmailUrl`)
      .then((response) => {
        if (!!response?.data) {
          const loc = response.data.split("OK. Redirecting to ").join("");
          window.open(loc, "_blank");
        }
      })
      .catch((error) => console.log("error here: ", JSON.stringify(error)));
    return api_call;
  } catch (error: any) {
    appToast("Error connecting to Gmail. Please try again later.");
  }
};

const sendHubSpotCode = async () => {
  try {
    const api_call = await restAPI
      .get(`${BACKEND_URL}/hubspot/redirect/`)
      .then((response) => {
        if (!!response?.data) {
          const loc = response.data.split("OK. Redirecting to ").join("");
          window.open(loc, "_blank");
        }
      })
      .catch((error) => console.log("error here: ", JSON.stringify(error)));

    return api_call;
  } catch (error: any) {
    appToast("Error connecting to HubSpot. Please try again later.");
  }
};

const IntegrationCardV2: React.FC<IntegrationCardProps> = ({ integration, isConnected, optionsButton }) => {

  const { setShowDisconnectIntegrationModal, setSelectedIntegrationDisconnect } = useContext(ModalContext);
  console.log("integration: ", integration);
  // const [disconnectIntegration, { loading: loadingDisconnect, error: errorDisconnect }] = useMutation(
  //   DISCONNECT_INTEGRATION,
  //   {
  //     async onCompleted({ disconnectIntegration }) {
  //       if (!disconnectIntegration) {
  //         appToast("Error disconnecting integration. Something went wrong.");
  //         return;
  //       }
  //       appToast("Integration removed successfully!");
  //     },
  //     onError({ message }) {
  //       appToast(message);
  //       Sentry.captureEvent({
  //         message: `disconnectIntegration GraphQL Error: ${message}`,
  //       });
  //     },
  //     refetchQueries: ["checkIntegrationStatus"],
  //   },
  // );

  const ConnectButtonDiv = ({ integration, isConnected }: any) => {
    return (
      <ConnectDiv>
        <ConnectSwitch item={integration} isConnected={isConnected} />
        <AppText fontSize={12} fontWeight={500}>
          {isConnected ? "Connected" : "Connect"}
        </AppText>
      </ConnectDiv>
    );
  };

  const ConnectSwitch = ({ item, isConnected }: { item: IntegrationItem; isConnected: boolean }) => {
    const organization_id = localStorage.getItem("organization_id");

    // we use a local state value since the connecting process is off site.

    // we are optomisticly updating the UI

    const [switchStateIsConnected, setSwitchStateIsConnected] = useState(isConnected);

    switch (item.name) {
      case "Gmail":
        return (
          <Switch
            onChange={async () => {
              setSwitchStateIsConnected(!switchStateIsConnected);
              await getGmailLink();
            }}
            checked={!!switchStateIsConnected}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            // disabled={loadingDisconnect}
          />
        );
      case "HubSpot":
        return (
          <Switch
            onChange={async () => {
              if (!!switchStateIsConnected) {
                setSelectedIntegrationDisconnect(item.name);
                setShowDisconnectIntegrationModal(true);
              } else {
                await sendHubSpotCode();
              }

              setSwitchStateIsConnected(!switchStateIsConnected);
            }}
            checked={!!switchStateIsConnected}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            // disabled={loadingDisconnect}
          />
        );
      default:
        return (
          <Switch
            onChange={async () => {
              if (!!switchStateIsConnected) {
                setSelectedIntegrationDisconnect(item.name);
                setShowDisconnectIntegrationModal(true);
              } else {
                window.open(`${item.url}?organization_id=${organization_id}`);
              }

              setSwitchStateIsConnected(!switchStateIsConnected);
            }}
            checked={!!switchStateIsConnected}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
            // disabled={loadingDisconnect}
          />
        );
    }
  };

  return (
    <Main>
      <Header>
        <IntegrationLogo src={integration.logo} width="32px" alt={`${getIntegrationLabel(integration.name)} logo`} />
        <ConnectButtonDiv integration={integration} isConnected={isConnected} />
      </Header>
      <AppText fontSize={16} style={{ fontWeight: 600 }}>
        {getIntegrationLabel(integration.name)}
      </AppText>
      <AppText fontSize={14} style={{ color: theme.NEUTRAL300 }}>
        {integration.description}
      </AppText>
      {optionsButton ? optionsButton : <FlexDiv height={40} />}
    </Main>
  );
};

const Main = styled.div`
  width: 257px;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${theme.NEUTRAL200};
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IntegrationLogo = styled.img`
  display: flex;
  flex-direction: row;
`;

const ConnectDiv = styled.div`
  padding: 12px;
  background-color: ${theme.PRIMARY200};
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-radius: 8px;
`;
export { IntegrationCardV2 };
