import { gql, useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useState, useContext } from "react";
import styled from "styled-components";
import { arrow_left } from "../../../../images/NewDesign";
import { MixpanelActions } from "../../../../services/mixpanel";
import { theme } from "../../../../utils/theme";
import { appToast } from "../../../../utils/toast";
import { AppErrorText, AppText } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";
import { Modal } from "../../../modal";
import { CreateScheduledEventComponentV3 } from "../../CallSegments";
import { ModalContext } from "../../../../context";

import { SelectDispo } from "../../../Smart/SelectDispo";

interface LogNonCallActivityProps {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
}

// Fetch available event types
interface IAction {
  id?: string;
  label?: string;
  event_type?: string;
}
interface IFetchAvailableActions {
  fetchAvailableActions?: IAction[];
}
export const FETCH_AVAILABLE_ACTIONS = gql`
  query FetchAvailableActions($lead_id: String!) {
    fetchAvailableActions(lead_id: $lead_id) {
      id
      label
      event_type
    }
  }
`;

// log disposition (create event flow logs disposition by passing in selected id)

interface ILogDisposition {
  logDisposition?: {
    id?: string;
    channel?: string;
    related_disposition?: {
      id?: string;
      phase?: string;
      person_spoke_to?: string;
      type?: string;
      next_phase?: string;
      completed_text?: string;
      completed_image?: string;
      completed_tip?: string;
    };
  };
}
const LOG_DISPOSITION = gql`
  mutation logDisposition(
    $disposition_id: String!
    $lead_id: String!
    $intent_id: String
    $call: Boolean
    $from_custom_call_queue: Boolean
    $call_sid: String
  ) {
    logDisposition(
      disposition_id: $disposition_id
      lead_id: $lead_id
      intent_id: $intent_id
      call: $call
      from_custom_call_queue: $from_custom_call_queue
      call_sid: $call_sid
    ) {
      id
      channel
      related_disposition {
        id
        phase
        person_spoke_to
        type
        next_phase
        completed_text
        completed_image
        completed_tip
      }
    }
  }
`;

/**
 * Component for logging non-call activities such as scheduling events or logging call results.
 *
 * @deprecated This component is deprecated and will be removed when the nonCallActivitySale flag is fully implemented.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.modalOpen - Whether the modal is open
 * @param {function} props.setModalOpen - Function to set the modal open state
 * @returns {React.ReactElement} The rendered component
 */
const LogNonCallActivityComponentV3: React.FC<LogNonCallActivityProps> = ({ modalOpen, setModalOpen }) => {
  const { logNonCallActivityModalData } = useContext(ModalContext);

  // DM, NDM, etc
  const [personSpokeTo, setPersonSpokeTo] = useState<string | undefined>(undefined);

  // id to log disposition used in both schedule and disposition flows
  const [dispositionId, setDispositionId] = useState("");

  // event type used in schedule flow
  const [eventType, setEventType] = useState("");

  const [selectedAction, setSelectedAction] = useState<"schedule" | "disposition" | undefined>(undefined);

  const [step, setStep] = useState(0);

  // step 0 = choose action (schedule or disposition)

  // step 1 = schedule event or disposition based on action chosen

  // substeps for schedule event and disposition flows
  const [subStep, setSubStep] = useState(0);

  // reset modal data helper function
  const resetModalData = () => {
    setSubStep(0);
    setDispositionId("");
    setPersonSpokeTo("");
  };

  // reset data and go back to beginning
  const resetModal = () => {
    resetModalData();
    setStep(0);
  };
  // queries and mutations
  const { data: availableActionsData, loading: loadingActions, error: errorActions } = useQuery<IFetchAvailableActions>(
    FETCH_AVAILABLE_ACTIONS,
    {
      variables: {
        lead_id: logNonCallActivityModalData?.lead_id,
      },
      fetchPolicy: "cache-and-network",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [logDisposition, { loading: loadingDisposition, error: errorDisposition }] = useMutation<ILogDisposition>(
    LOG_DISPOSITION,
    {
      async onCompleted({ logDisposition }) {
        if (!logDisposition) {
          appToast("Error logging disposition. Please try again.");
          return;
        }
        MixpanelActions.track("Disposition Event", {
          phase: logDisposition?.related_disposition?.phase,
          next_phase: logDisposition?.related_disposition?.next_phase,
          type: logDisposition?.related_disposition?.type,
          person_spoke_to: logDisposition?.related_disposition?.person_spoke_to,
        });

        appToast("Call result logged.");

        setModalOpen(false);
      },
      onError({ message }) {
        appToast(message);
        console.log("Error in logDisposition: ", message);
      },
      refetchQueries: ["fetchNextDial", "fetchFilteredLeadsInQueueV2", "fetchCustomQueueLeads"],
    },
  );

  const loading = !!loadingActions || !!loadingDisposition;

  const ScheduleNonCallEvent = () => {
    return (
      <>
        <BackArrow
          svg={arrow_left}
          size={20}
          color={theme.PRIMARY500}
          onClick={handleGoBackScheduleEventFlow}
          pointer
        />

        {/* select event type (schedule demo, etc)  */}
        {subStep === 0 ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
            <AppText fontSize={16} color={theme.NEUTRAL400}>
              What would you like to schedule?
            </AppText>
            <ScheduleButtonsDiv>
              {availableActionsData?.fetchAvailableActions?.map(
                (action: any) =>
                  action.label !== "Make Sale" && (
                    <PhoenixAppButton
                      variant="brand-outline"
                      buttonType="secondary"
                      key={action?.id}
                      onClick={() => {
                        setEventType(action.event_type);
                        setSubStep(1);
                      }}
                    >
                      {action.label}
                    </PhoenixAppButton>
                  ),
              )}
            </ScheduleButtonsDiv>
          </div>
        ) : /* select person spoke to and disposition */
        subStep === 1 ? (
          <SelectDispo
            // getters
            selectedPersonSpokeTo={personSpokeTo}
            selectedDispositionId={dispositionId}
            selectedEventType={eventType}
            // setters
            setSelectedPersonSpokeTo={setPersonSpokeTo}
            setSelectedDispositionId={setDispositionId}
            // required
            lead_id={logNonCallActivityModalData?.lead_id}
            setStepForward={() => {
              setSubStep(subStep + 1);
            }}
          />
        ) : /* create scheduled event */
        subStep === 2 ? (
          <CreateScheduledEventComponentV3
            backToActionButtons={() => {
              setSubStep(0);
            }}
            fromNonCallActivity
            eventType={eventType}
            lead_id={logNonCallActivityModalData?.lead_id}
            lead_full_name={logNonCallActivityModalData?.lead_full_name}
            closeModal={() => {
              setModalOpen(false);
            }}
            selectedDisposition={dispositionId}
            lead_primary_email={logNonCallActivityModalData?.lead_primary_email}
          />
        ) : (
          // error
          <AppErrorText>Something went wrong</AppErrorText>
        )}
      </>
    );
  };
  const LogNonCallDispostion = () => {
    return (
      <>
        <BackArrow
          svg={arrow_left}
          size={20}
          color={theme.PRIMARY500}
          onClick={handleGoBackLogDispositionFlow}
          pointer
        />
        {/* select person spoke to and disposition */}
        {subStep === 0 ? (
          <SelectDispo
            selectedPersonSpokeTo={personSpokeTo}
            selectedDispositionId={dispositionId}
            selectedEventType={eventType}
            setSelectedPersonSpokeTo={setPersonSpokeTo}
            setSelectedDispositionId={setDispositionId}
            lead_id={logNonCallActivityModalData?.lead_id}
            setStepForward={() => {
              setSubStep(subStep + 1);
            }}
          />
        ) : // log disposition
        subStep === 1 ? (
          <>
            <AppText fontSize={16} color={theme.NEUTRAL400}>
              Click the button below to log the call result.
            </AppText>
            <PhoenixAppButton
              variant="brand-outline"
              buttonType="secondary"
              disabled={loading}
              onClick={async () => {
                await logDisposition({
                  variables: {
                    disposition_id: dispositionId,
                    lead_id: logNonCallActivityModalData?.lead_id,
                    call: false,
                  },
                });
              }}
            >
              Submit
            </PhoenixAppButton>
          </>
        ) : (
          <AppErrorText>Something went wrong</AppErrorText>
        )}
      </>
    );
  };

  const handleGoBackScheduleEventFlow = () => {
    if (subStep === 2) {
      // unselct disposition
      setDispositionId("");

      // go back to select disposition step
      setSubStep(1);
      return;
    }

    if (subStep === 1) {
      // unselct event person spoke to and disposition
      setPersonSpokeTo("");
      setDispositionId("");
      // go back to select event type
      setSubStep(0);
    }

    if (subStep === 0) {
      resetModal();
      return;
    }
  };

  const handleGoBackLogDispositionFlow = () => {
    if (subStep === 1) {
      // unselct disposition
      setDispositionId("");
      // go back to select disposition step
      setSubStep(0);
      return;
    }
    // unselect event person spoke to and disposition
    // go back to beginning
    resetModal();
  };

  return (
    <LogNonCallActivityModal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      bigCloseButton
    >
      <Main>
        <AppText fontSize={20} fontWeight={600} color={theme.PRIMARY700}>
          Log Non-Call Activity
        </AppText>
        {step === 0 ? (
          <SelectActionDiv>
            <PhoenixAppButton
              onClick={() => {
                setSelectedAction("schedule");
                resetModalData();
                setStep(1);
              }}
              variant="brand-outline"
              buttonType="secondary"
            >
              Schedule An Event
            </PhoenixAppButton>
            <PhoenixAppButton
              onClick={() => {
                setSelectedAction("disposition");
                resetModalData();
                setStep(1);
              }}
              variant="brand-outline"
              buttonType="secondary"
            >
              Log A Call Result
            </PhoenixAppButton>
          </SelectActionDiv>
        ) : step === 1 && selectedAction === "schedule" ? (
          <ScheduleNonCallEvent />
        ) : step === 1 && selectedAction === "disposition" ? (
          <LogNonCallDispostion />
        ) : (
          <AppErrorText>Something went wrong</AppErrorText>
        )}
      </Main>
    </LogNonCallActivityModal>
  );
};

const LogNonCallActivityModal = styled(Modal)``;

const ScheduleButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  display: flex;
  width: max-content;
  max-width: 100%;
  flex-wrap: wrap;
`;

const Main = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;
  max-height: 80vh;
  overflow: auto;
  max-width: 70vw;
  min-width: 500px;
`;

const SelectActionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const BackArrow = styled(PhoenixIcon)`
  position: absolute;
  left: 20px;
  top: 20px;
`;

export { LogNonCallActivityComponentV3 };
