import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { AgGridReact } from "ag-grid-react";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { useContext, useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ModalContext } from "../../context";
import { check, xIcon } from "../../images/NewDesign";
import { hubSpotDefinition, salesforceDefinition } from "../../static/definitions";
import { IIntegrations } from "../../types";
import { downloadResourceAWS } from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { AppErrorText, AppText, Loading } from "../UI";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  integration_mapping_id: string;
}

interface FieldsData {
  external_field: string;
  match: boolean;
  opsiq_field: string;
}

interface FetchMappingFieldSelectOptionExpectedResponse {
  fetchMappingFieldSelectOption?: FieldsData[];
}

// queries

const FETCH_MAPPING_FIELD_SELECT_OPTION = gql`
  query fetchMappingFieldSelectOption($integration_mapping_id: String!) {
    fetchMappingFieldSelectOption(integration_mapping_id: $integration_mapping_id) {
      external_field
      match
      opsiq_field
    }
  }
`;

const SYNC_ALL_MAPPING_FIELD = gql`
  mutation syncAllMappingFieldSelectOption($integration_mapping_id: String!) {
    syncAllMappingFieldSelectOption(integration_mapping_id: $integration_mapping_id)
  }
`;

const SYNC_ONE_MAPPING_FIELD = gql`
  mutation syncMappingFieldSelectOption($integration_mapping_id: String!, $select_option_value: String!) {
    syncMappingFieldSelectOption(
      integration_mapping_id: $integration_mapping_id
      select_option_value: $select_option_value
    )
  }
`;

const EXPORT_INTEGRATION_PICKLIST_OPTIONS = gql`
  mutation exportIntegrationPickListOptions($integration_mapping_id: String!, $integration_type: INTEGRATION_TYPE!) {
    exportIntegrationPickListOptions(
      integration_mapping_id: $integration_mapping_id
      integration_type: $integration_type
    ) {
      url
    }
  }
`;

const IntegrationMapSyncPicklistV2: React.FC<DisappearingDivProps> = ({ integration_mapping_id }) => {
  const {
    integrationMapPicklistModal,
    setIntegrationMapPicklistModal,
    selectedIntegration,
    selectedIntegrationLabel,
  } = useContext(ModalContext);

  // fetch table data
  const [
    fetchMappingFieldSelectOption,
    { data: dataOptions, loading: loadingOptions, error: errorOptions },
  ] = useLazyQuery<FetchMappingFieldSelectOptionExpectedResponse>(FETCH_MAPPING_FIELD_SELECT_OPTION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  // sync all button
  const [syncAllMappingField, { loading }] = useMutation(SYNC_ALL_MAPPING_FIELD, {
    onCompleted({ syncAllMappingFieldSelectOption }) {
      if (!syncAllMappingFieldSelectOption) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("Updated mappings");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `syncAllMappingFieldSelectOption GraphQL Error: ${message}`,
      });
      console.log("Error in syncAllMappingFieldSelectOption: ", message);
    },
    refetchQueries: [{ query: FETCH_MAPPING_FIELD_SELECT_OPTION, variables: { integration_mapping_id } }],
  });

  // sync individual field button
  const [syncOneMappingField] = useMutation(SYNC_ONE_MAPPING_FIELD, {
    onCompleted({ syncMappingFieldSelectOption }) {
      if (!syncMappingFieldSelectOption) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("Updated mappings");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `syncMappingFieldSelectOption GraphQL Error: ${message}`,
      });
      console.log("Error in syncMappingFieldSelectOption: ", message);
    },
    refetchQueries: [{ query: FETCH_MAPPING_FIELD_SELECT_OPTION, variables: { integration_mapping_id } }],
  });

  const syncOneMapping = (item: FieldsData) => {
    syncOneMappingField({
      variables: {
        integration_mapping_id,
        select_option_value: item?.external_field,
      },
    });
  };

  // export picklist options

  const [exportIntegrationPicklistOptions, { loading: loadingExportIntegrationPicklistOptions }] = useMutation(
    EXPORT_INTEGRATION_PICKLIST_OPTIONS,
    {
      async onCompleted({ exportIntegrationPickListOptions }) {
        if (!exportIntegrationPickListOptions) {
          appToast("Error exporting calls. Something went wrong.");
          return;
        }
        appToast("Success! Download should begin shortly");
        !!exportIntegrationPickListOptions.url && downloadResourceAWS(exportIntegrationPickListOptions.url);
      },
      onError({ message }) {
        appToast(`${message}`);
        Sentry.captureEvent({
          message: `exportIntegrationPickListOptions GraphQL Error: ${message}`,
        });
      },
    },
  );

  // fetch table data if we have a mapping id
  useEffect(() => {
    fetchMappingFieldSelectOption({ variables: { integration_mapping_id } });
  }, [integration_mapping_id]);

  // cell renderers to use in ag-grid
  const matchedItem = (item: FieldsData, value: string) => {
    if (item?.match) {
      // if the field is matched, show a checkmark icon
      return (
        <MatchedItemDiv>
          <PhoenixIcon svg={check} size={16} color={theme.SUCCESS500} />
          <TableText>{value}</TableText>
        </MatchedItemDiv>
      );
    } else if (!item?.match && value) {
      // if the field is not matched, show an x icon
      return (
        <MatchedItemDiv>
          <PhoenixIcon svg={xIcon} size={16} color={theme.DANGER500} />
          <TableText
            style={{
              color: theme.DANGER500,
            }}
          >
            {value}
          </TableText>
        </MatchedItemDiv>
      );
    } else return null;
  };

  const individualSyncBox = (item: FieldsData) => {
    if (!item?.match && !item?.opsiq_field) {
      return (
        <SyncBoxDiv>
          <PhoenixAppButton
            variant="brand-outline"
            buttonType="secondary"
            onClick={() => syncOneMapping(item)}
            height={32}
            buttonTextFontSize={10}
            buttonTextFontWeight={600}
          >
            SYNC TO OPSIQ
          </PhoenixAppButton>
        </SyncBoxDiv>
      );
    }
    return null;
  };

  // column definitions for ag-grid

  const COLUMN_DEFS = [
    {
      headerName: "External Field",
      field: "external_field",
      flex: 1,
      cellRendererFramework: (params: any) => {
        return matchedItem(params?.data?.external_field, params.data?.external_field?.external_field);
      },
      headerClass: "ag-theme-integration-mapping-table-header",
    },
    {
      headerName: "OPSIQ Field",
      field: "opsiq_field",
      flex: 1,
      cellRendererFramework: (params: any) => {
        return matchedItem(params?.data?.opsiq_field, params.data?.opsiq_field?.opsiq_field);
      },
      headerClass: "ag-theme-integration-mapping-table-header",
    },
    {
      headerName: "Manage",
      field: "sync",
      flex: 1,
      maxWidth: 152,
      minWidth: 152,
      cellRendererFramework: (params: any) => {
        return individualSyncBox(params.data?.sync);
      },
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      headerClass: "ag-theme-integration-mapping-table-header ag-theme-integration-mapping-table-header-center",
    },
  ];

  const validationSchema = Yup.object().shape({
    integration_mapping_id: Yup.string(),
  });

  return (
    <Sentry.ErrorBoundary fallback={"An error has occurred in skip lead modal"}>
      <Formik
        initialValues={{
          integration_mapping_id,
        }}
        onSubmit={async (values) => {
          await syncAllMappingField({
            variables: {
              integration_mapping_id,
            },
          });
        }}
        validationSchema={validationSchema}
      >
        {({
          submitForm,
          setFieldValue,
          isValid,
          isSubmitting,
          values,
        }: FormikProps<{ integration_mapping_id: string }>) => (
          <PopupContainerDiv>
            <Modal open={integrationMapPicklistModal} onClose={() => setIntegrationMapPicklistModal(false)}>
              <PaddingDiv>
                <Header>
                  <SectionTitle>{selectedIntegrationLabel} Field Mapping Discrepancies</SectionTitle>
                </Header>
                <Main>
                  <SectionSubTitle>
                    {selectedIntegration === IIntegrations.Salesforce && salesforceDefinition}
                    {selectedIntegration === IIntegrations.HubSpot && hubSpotDefinition}
                  </SectionSubTitle>
                  <Spacer height={24} />
                  <PhoenixAppButton variant="brand" buttonType="secondary" onClick={submitForm}>
                    SYNC ALL TO OPSIQ
                  </PhoenixAppButton>
                  <Spacer height={24} />

                  {loadingOptions || loading ? (
                    <Loading />
                  ) : errorOptions ? (
                    <AppErrorText>Error loading mapping data</AppErrorText>
                  ) : (
                    <PhoenixTableBody
                      className={"ag-theme-integration-mapping-table"}
                      style={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                        width: "100%",
                      }}
                    >
                      <AgGridReact
                        columnDefs={COLUMN_DEFS}
                        rowData={dataOptions?.fetchMappingFieldSelectOption?.map((item: FieldsData) => ({
                          external_field: item,
                          sync: item,
                          opsiq_field: item,
                        }))}
                        domLayout="autoHeight"
                        suppressMovableColumns={true}
                      />
                    </PhoenixTableBody>
                  )}
                </Main>
              </PaddingDiv>
              <ButtonContainer>
                <LeftButtons>
                  <PhoenixAppButton
                    variant="danger-outline"
                    buttonType="secondary"
                    onClick={() => {
                      setIntegrationMapPicklistModal(false);
                    }}
                  >
                    Cancel
                  </PhoenixAppButton>
                </LeftButtons>
                <RightButtons>
                  <PhoenixAppButton
                    variant="brand-outline"
                    buttonType="secondary"
                    disabled={loadingExportIntegrationPicklistOptions}
                    onClick={() => {
                      exportIntegrationPicklistOptions({
                        variables: {
                          integration_mapping_id: values?.integration_mapping_id,
                          integration_type: selectedIntegration,
                        },
                      });
                    }}
                  >
                    Download CSV
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    variant="brand"
                    buttonType="secondary"
                    onClick={() => {
                      setIntegrationMapPicklistModal(false);
                    }}
                  >
                    Done
                  </PhoenixAppButton>
                </RightButtons>
              </ButtonContainer>
            </Modal>
          </PopupContainerDiv>
        )}
      </Formik>
    </Sentry.ErrorBoundary>
  );
};

const SyncBoxDiv = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
`;
const Header = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 40px 40px 40px;
`;
const SectionTitle = styled(AppText)`
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  text-align: center;
`;

const SectionSubTitle = styled(AppText)`
  font-weight: 400;
  font-size: 14px;
  min-width: 656px;
  max-width: 656px;
  text-align: center;
  color: ${theme.NEUTRAL350};
`;

const PaddingDiv = styled.div`
  height: 572px;
  max-height: 60vh;
  overflow: auto;
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const PopupContainerDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  background-attachment: fixed;
  overflow: hidden;
  z-index: 9;
`;

const TableText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${theme.NEUTRAL200};
  padding: 20px;
`;

const LeftButtons = styled.div``;

const RightButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const PhoenixTableBody = styled.div``;

const MatchedItemDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 100%;
`;

export { IntegrationMapSyncPicklistV2 };
