import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FieldArray, Formik, FormikProps } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { iconNewPlus, iconTrashNew } from "../../images";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { FormSelectField, InputField } from "../Field";
import { AppText, Loading } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  /**
   * Only pass in the id if it is an edit operation
   */
  id?: string;
  type?: string;
  keyName?: string;
  options?: string[];
  allow_reps_to_edit?: string;
  visible?: boolean;
  setDeleteModalData?: Dispatch<SetStateAction<{ id: string; fieldType: string; fieldName: string }>>;
}

const ADD_CUSTOM_FIELD = gql`
  mutation AddCustomField(
    $type: CustomFieldType
    $visible: Boolean!
    $key: String!
    $allow_reps_to_edit: Boolean!
    $options: [String!]
  ) {
    addCustomField(
      type: $type
      visible: $visible
      key: $key
      allow_reps_to_edit: $allow_reps_to_edit
      options: $options
    ) {
      id
      type
      key
      value
      options
      allow_reps_to_edit
    }
  }
`;

const UPDATE_CUSTOM_FIELD = gql`
  mutation UpdateOneCustomField(
    $customFieldId: String!
    $key: String
    $type: CustomFieldType
    $allowRepsToEdit: Boolean
    $visible: Boolean
    $options: [String]
  ) {
    updateOneCustomField(
      custom_field_id: $customFieldId
      key: $key
      type: $type
      allow_reps_to_edit: $allowRepsToEdit
      visible: $visible
      options: $options
    ) {
      id
      key
      type
      options
    }
  }
`;

const optionsBoolean = [
  {
    label: "No",
    value: false,
  },
  {
    label: "Yes",
    value: true,
  },
];

interface MyFormikProps {
  id: string;
  type: string;
  key: string;
  options: string[];
  allow_reps_to_edit: string;
  visible: boolean;
}

const UpdateCustomField: React.FC<DisappearingDivProps> = ({
  blinds,
  close,
  id,
  keyName,
  setDeleteModalData,
  ...propsField
}) => {
  const { multiTextField } = useFlags();

  const optionTypeRaw = [
    {
      label: "Select input type",
      value: "",
    },
    {
      label: "Text Field",
      value: "Text",
    },
    {
      label: "Dropdown",
      value: "Dropdown",
    },
    {
      label: "Multi Dropdown",
      value: "MultiDropdown",
    },
    {
      label: "Numeric Value",
      value: "Number",
    },
    {
      label: "Rate",
      value: "Rate",
    },
    {
      label: "Percentage",
      value: "Percentage",
    },
    {
      label: "True/False",
      value: "Boolean",
    },
    {
      label: "Date/Time",
      value: "Date",
    },
  ];

  const optionsType = multiTextField ? [...optionTypeRaw, { label: "Multi Text", value: "MultiText" }] : optionTypeRaw;

  const customFieldsSchema = Yup.object().shape({
    id: Yup.string(),
    key: Yup.string().required("Field required!"),
    type: Yup.string().required("Please select a valid type!"),
    allow_reps_to_edit: Yup.string(),
    visible: Yup.boolean(),
    options: Yup.array(Yup.string()),
  });

  const [updateOneCustomField, { loading: updateLoading, error: errorLoading }] = useMutation(UPDATE_CUSTOM_FIELD, {
    onCompleted({ updateOneCustomField }) {
      console.log("Update field: ", updateOneCustomField);
      // window.location.reload(false);
      if (!updateOneCustomField) {
        return;
      }
      close();
    },
    onError({ message }) {
      console.log("Error in updateOneCustomField: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `updateOneCustomField GraphQL Error: ${message}`,
      });
    },
  });

  const [addCustomField, { loading: addLoading, error: addError }] = useMutation(ADD_CUSTOM_FIELD, {
    onCompleted({ addCustomField }) {
      console.log("Add custom field: ", addCustomField);
      // window.location.reload(false);
      if (!addCustomField) {
        appToast("Error: Something went wrong!");
        return;
      }
      close();
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `addCustomField GraphQL Error: ${message}`,
      });
      console.log("Error in addCustomField: ", message);
    },
  });

  return (
    <Formik
      initialValues={{
        id: id || "",
        key: keyName || "",
        type: propsField.type || "",
        visible: propsField.visible ?? false,
        allow_reps_to_edit: propsField.allow_reps_to_edit ?? "false",
        options: propsField.options || ["new", "option"],
      }}
      validationSchema={customFieldsSchema}
      onSubmit={async ({ id, type, visible, key, allow_reps_to_edit, options }) => {
        if (id) {
          await updateOneCustomField({
            variables: {
              customFieldId: id,
              key: key,
              type: type,
              allowRepsToEdit: allow_reps_to_edit === "true",
              visible: visible,
              options: options,
            },
          });
        } else {
          await addCustomField({
            variables: {
              type: type,
              visible: visible,
              key: key,
              allow_reps_to_edit: allow_reps_to_edit === "true",
              options: options,
            },
          });
        }
      }}
    >
      {({ submitForm, isSubmitting, values, isValid, dirty, setFieldValue }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={blinds} onClose={close}>
            <ModalContent>
              <TitleDiv>
                <PopupTitle>{id ? `Edit ` : `Create New `}Custom Field</PopupTitle>
              </TitleDiv>
              <ScrollingDiv>
                <WidthContainer>
                  <PopupInputLabel>
                    Custom Field Name<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FieldInput name="key" disabled={!!id} />
                  <PopupInputLabel>
                    Input Type<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FieldSelect textAlign="left" name="type" disabled={!!id} options={optionsType} />
                </WidthContainer>
                {(values.type === "Dropdown" || values.type === "MultiDropdown") && (
                  <FieldArray
                    name="options"
                    render={({ push, insert, remove }) => (
                      <div>
                        {values.options && values.options.length > 0 ? (
                          values.options?.map((option, index) => (
                            <>
                              <PopupInputLabel style={{ marginLeft: "24px" }}>
                                Dropdown Value<span style={{ color: "red" }}>*</span>
                              </PopupInputLabel>
                              <DropdownOptionContainer key={index}>
                                <DropdownOption name={`options.${index}`} />
                                <OptionTaskContainer>
                                  <img
                                    src={iconTrashNew}
                                    style={{ cursor: "pointer" }}
                                    // size={26}
                                    // color={theme.NEUTRAL500}
                                    onClick={() => remove(index)}
                                  />
                                </OptionTaskContainer>
                                {index === values.options.length - 1 ? (
                                  <OptionTaskContainer>
                                    <img
                                      src={iconNewPlus}
                                      style={{ cursor: "pointer" }}
                                      // size={26}
                                      // color={theme.SUCCESS500}
                                      onClick={() => push("")}
                                    />
                                  </OptionTaskContainer>
                                ) : (
                                  <div style={{ width: "45px" }} />
                                )}
                              </DropdownOptionContainer>
                            </>
                          ))
                        ) : (
                          <CenterDiv
                            style={{ cursor: "pointer", height: "45px", paddingBottom: "15px", maxWidth: "385px" }}
                            onClick={() => push("")}
                          >
                            <AppText>Add Select Option</AppText> <img src={iconNewPlus} />
                          </CenterDiv>
                        )}
                      </div>
                    )}
                  />
                )}
                <WidthContainer>
                  <PopupInputLabel>
                    Allow Reps to Edit<span style={{ color: "red" }}>*</span>
                  </PopupInputLabel>
                  <FieldSelect textAlign="left" name="allow_reps_to_edit" options={optionsBoolean} />
                </WidthContainer>
              </ScrollingDiv>
              <SubmitDiv>
                {!!id ? (
                  <NewAppButton
                    size="md"
                    variant="attention"
                    onClick={() =>
                      !!setDeleteModalData &&
                      setDeleteModalData({ id: id, fieldType: "Custom Field", fieldName: keyName ?? "" })
                    }
                  >
                    Delete
                  </NewAppButton>
                ) : (
                  <div />
                )}
                {isSubmitting ? (
                  <Loading />
                ) : (
                  <NewAppButton size="md" variant="primary" type="submit" onClick={submitForm}>
                    {id ? `Save` : `Create Custom Field`}
                  </NewAppButton>
                )}
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

const WidthContainer = styled.div`
  width: 100%;
`;

const ModalContent = styled.div`
  width: 720px;
  height: 680px;
`;
const SubmitDiv = styled.div`
  position: absolute;
  bottom: 0px;
  height: 88px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.NEUTRAL100};
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  /* margin-bottom: 32px; */
  background-color: ${theme.NEUTRAL100};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionTaskContainer = styled.div`
  height: 40px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DropdownOptionContainer = styled.div`
  margin-left: 24px;
  z-index: 3;
  width: 384px;
  overflow: visible;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const DropdownOption = styled(InputField)`
  margin: 0;
  text-align: left;
  border-radius: 2px;
  width: 304px;
`;

const FieldSelect = styled(FormSelectField)``;

const PopupInputLabel = styled(AppText)`
  margin-bottom: 8px;
`;

const FieldInput = styled(InputField)`
  margin: 0;
  text-align: left;

  ::placeholder {
    text-align: left;
  }
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 32px 24px;
  overflow: auto;
  width: 100%;
  min-height: 536px;
  height: 536px;

  max-height: 536px;
  margin-bottom: 88px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { UpdateCustomField };
