import React, { Dispatch, SetStateAction } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { theme } from "../../../../utils/theme";
import { AppText } from "../../../UI";
import { PhoenixIcon } from "../../../UI/Phoenix";
import Switch from "react-switch";
import { edit, reorder } from "../../../../images/NewDesign";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Row } from "./Row";
import { Cell } from "./Cell";

interface LeadTableProps {
  dataRules: any[];
  setDataRules: Dispatch<SetStateAction<any[]>>;
  onDragEndRules: (res: any) => void;
  leadTableCellWidths: number[];
  updateRoutingRuleStatus: any;
  updateRoutingRuleApplyToInboundConciergeCallMeNow: any;
  updateRoutingRuleApplyToInboundConciergeSchedule: any;
  togglePageTitle: any;
  URL: string;
}

export const LeadTable: React.FC<LeadTableProps> = ({
  dataRules,
  setDataRules,
  onDragEndRules,
  leadTableCellWidths,
  updateRoutingRuleStatus,
  updateRoutingRuleApplyToInboundConciergeCallMeNow,
  updateRoutingRuleApplyToInboundConciergeSchedule,
  togglePageTitle,
  URL,
}) => {
  const history = useHistory();

  return (
    <DragDropContext onDragEnd={onDragEndRules}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {dataRules
              .slice()
              .sort((a: any, b: any) => a.priority - b.priority)
              ?.map((item: any, index: number) => (
                <Draggable key={`table_${item.id}`} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <Row ref={provided.innerRef} {...provided.draggableProps}>
                      <Cell gap={8} width={leadTableCellWidths[0]}>
                        <PhoenixIcon
                          svg={reorder}
                          size={16}
                          color={theme.icon.brand.default}
                          hoverColor={theme.icon.brand.default}
                          alt="drag me"
                          style={{ cursor: "grab" }}
                          {...provided.dragHandleProps}
                        />

                        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                          {item.priority + 1}
                        </AppText>
                      </Cell>
                      <Cell width={leadTableCellWidths[1]}>
                        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                          {item.name}
                        </AppText>
                      </Cell>
                      <Cell width={leadTableCellWidths[2]}>
                        <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                          {!!item.updated_at && moment(item.updated_at).format("MM/DD/YYYY hh:mmA")}
                        </AppText>
                      </Cell>
                      <Cell width={leadTableCellWidths[3]} center>
                        <Switch
                          onChange={(checked) => {
                            updateRoutingRuleStatus({
                              variables: {
                                rule_id: item.id,
                                active: checked,
                              },
                            });
                            setDataRules(
                              dataRules?.map((item2: any) => {
                                if (item2.id === item.id) {
                                  return {
                                    ...item2,
                                    active: checked,
                                  };
                                }
                                return item2;
                              }),
                            );
                          }}
                          onColor={theme.PRIMARY500}
                          offColor={theme.NEUTRAL200}
                          checked={item.active}
                          height={16}
                          width={32}
                          handleDiameter={12}
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      </Cell>
                      <Cell width={leadTableCellWidths[4]} center>
                        <Switch
                          onChange={(checked) => {
                            updateRoutingRuleApplyToInboundConciergeCallMeNow({
                              variables: {
                                rule_id: item.id,
                                applyToInboundConciergeCallMeNow: checked,
                              },
                            });

                            setDataRules(
                              dataRules?.map((item2: any) => {
                                if (item2.id === item.id) {
                                  return {
                                    ...item2,
                                    applyToInboundConciergeCallMeNow: checked,
                                  };
                                }
                                return item2;
                              }),
                            );
                          }}
                          onColor={theme.PRIMARY500}
                          offColor={theme.NEUTRAL200}
                          checked={item.applyToInboundConciergeCallMeNow}
                          height={16}
                          width={32}
                          handleDiameter={12}
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      </Cell>
                      <Cell width={leadTableCellWidths[5]} center>
                        <Switch
                          onChange={(checked) => {
                            updateRoutingRuleApplyToInboundConciergeSchedule({
                              variables: {
                                rule_id: item.id,
                                applyToInboundConciergeSchedule: checked,
                              },
                            });
                            setDataRules(
                              dataRules?.map((item2: any) => {
                                if (item2.id === item.id) {
                                  return {
                                    ...item2,
                                    applyToInboundConciergeSchedule: checked,
                                  };
                                }
                                return item2;
                              }),
                            );
                          }}
                          onColor={theme.PRIMARY500}
                          offColor={theme.NEUTRAL200}
                          checked={item.applyToInboundConciergeSchedule}
                          height={16}
                          width={32}
                          handleDiameter={12}
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                      </Cell>
                      <Cell width={leadTableCellWidths[6]} center>
                        <AppText
                          fontSize={12}
                          fontWeight={600}
                          lineHeight={18}
                          color={theme.text.brand.primary}
                          style={{ borderBottom: "none", cursor: "pointer" }}
                          onClick={() => {
                            history.replace(`/system-config/${URL}/${item.id}/log`);
                          }}
                        >
                          {item.routing_count}
                        </AppText>
                      </Cell>
                      <Cell width={leadTableCellWidths[7]} center>
                        <PhoenixIcon
                          svg={edit}
                          size={16}
                          color={theme.icon.brand.default}
                          hoverColor={theme.icon.brand.default}
                          alt="edit"
                          pointer
                          onClick={() => {
                            !!togglePageTitle && togglePageTitle();
                            history.replace(`/system-config/${URL}/${item.id}`);
                          }}
                        />
                      </Cell>
                    </Row>
                  )}
                </Draggable>
              ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
