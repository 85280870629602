import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { debounce } from "lodash";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { loggedInUser } from "../../../apollo/cache";
import { ModalContext } from "../../../context";
import { searchIcon } from "../../../images";
import { daysFromCreatedDateShort } from "../../../utils/format";
import { theme } from "../../../utils/theme";
import { appToast } from "../../../utils/toast";
import { SEARCH_DEBOUNCE_INTERVAL } from "../../../utils/variables";
import { AppInput, AppSidebarCard, AppText, Loading } from "../../UI";

interface MessagesInfoHeaderProps {
  data: any;
  error: any;
  loading: any;
  setDropdownOpen: React.Dispatch<React.SetStateAction<string>>;
}

const UPDATE_ONE_SMS_ITEM = gql`
  mutation UpdateOneSMSItem($smsItemId: String!, $read: Boolean!, $readAt: String!) {
    updateOneSMSItem(sms_item_id: $smsItemId, read: $read, read_at: $readAt) {
      id
      sent_at
      text
      isFromUser
      sender_label
      sender_id
      read_at
      read
    }
  }
`;

const MessagesInfoHeader: React.FC<MessagesInfoHeaderProps> = ({ data, error, loading, setDropdownOpen }) => {
  const [searchValue, setSearchValue] = useState("");

  const { openSMSChat } = useContext(ModalContext);

  const [updateOneSMSItem, { loading: loadingSMSRead, error: errorSMSRead }] = useMutation(UPDATE_ONE_SMS_ITEM, {
    onCompleted({ updateOneSMSItem }) {
      console.log("updateOneSMSItem: ", updateOneSMSItem);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateOneSMSItem GraphQL Error: ${message}`,
      });
      console.log("Error in updateOneSMSItem: ", message);
    },
  });

  //debounced search rather than query as this involves less breaking changes
  const debouncedChange = useMemo(
    () =>
      debounce((e) => {
        const { value } = e.target;
        setSearchValue(value);
      }, SEARCH_DEBOUNCE_INTERVAL),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedChange.cancel();
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    event.persist();
    debouncedChange(event);
  };

  const handleSMSClick = (item: any) => {
    const currentTime = moment().toDate();
    if (!item?.read) {
      updateOneSMSItem({
        variables: {
          smsItemId: item?.id,
          readAt: currentTime,
          read: true,
        },
      });
    }
  };

  if (error) {
    return <p>Error loading SMS</p>;
  }
  if (loading) {
    return <Loading />;
  }

  const { fetchSMSItems } = data;
  return (
    <NotificationsContent onClick={(e) => e.stopPropagation()}>
      <AppSidebarCard width={350} title="SMS Messages">
        <AllNotifications>
          {fetchSMSItems?.sms_messages
            ?.slice()
            ?.filter((item: any) => {
              if (!!searchValue) {
                return item.text.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                  item.sender_label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
                  ? true
                  : false;
              }
              return true;
            })
            .filter((item: { sender_id: string | undefined }) => {
              return item.sender_id !== loggedInUser()?.id ? true : false;
            })
            .reduce((unique: any[], item: any) => {
              if (!unique.some((uniqueItem) => uniqueItem?.id === item?.id)) {
                unique.push(item);
              }
              return unique;
            }, [])
            ?.map((item: any) => (
              <OneMessage
                key={item.id}
                onClick={() => {
                  openSMSChat({ lead_id: item.sender_id, phone_number: item.lead_number_used, intent: undefined });
                  handleSMSClick(item);
                  setDropdownOpen("");
                }}
              >
                <div style={{ paddingLeft: "10px" }}>{!item.read && <UnreadDot />}</div>
                <MessageContent>
                  <FlexToSides>
                    <AppText style={{ fontWeight: 600 }}>{item.sender_label}</AppText>
                    <AppText style={{ fontWeight: 600, color: theme.NEUTRAL400 }}>
                      {daysFromCreatedDateShort(item.sent_at)}
                    </AppText>
                  </FlexToSides>
                  <AppText
                    style={{
                      margin: "0px 5px 9px 0px",
                      whiteSpace: "nowrap",
                      maxWidth: "280px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.text}
                  </AppText>
                </MessageContent>
              </OneMessage>
            ))}
        </AllNotifications>
        <SearchDiv onClick={(e) => e.stopPropagation()}>
          <SearchLeadInput placeholder="Search Leads" onChange={handleChange} />
        </SearchDiv>
      </AppSidebarCard>
    </NotificationsContent>
  );
};

const FlexToSides = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  width: 285px;
  margin: 9px 0px 6px 0px;
`;

const SearchLeadInput = styled(AppInput)`
  margin: 0px;
  margin-top: 16px;
  padding-left: 40px;
  z-index: 10;
  background: url(${searchIcon}) no-repeat scroll 15px 10px;
`;

interface HeaderTextProps {
  fontSize?: number;
}

const NotificationsContentText = styled(AppText)<HeaderTextProps>`
  font-size: ${(props) => props.fontSize || 12}px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
  text-align: right;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 9px;
`;

const NotificationsContent = styled.div`
  position: fixed;
  bottom: calc(50% - 220px);
  left: 96px;
  /* border: 1px solid ${theme.BLACK_COLOR}; */
  width: 350px;
  height: 466px;
  background: ${theme.WHITE_COLOR};
  border-radius: 4px;
  /* padding: 0px 14px; */
  cursor: default;
  pointer-events: none;
  box-shadow: 0px 2px 0px ${theme.NEUTRAL200};
  z-index: 10;
  /* :before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    left: -20px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: ${theme.PRIMARY500};
    pointer-events: inherit;
    transform: rotate(270deg);
  } */
  /* :after {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    bottom: calc(100% - 0.5px);
    width: 0;
    height: 0;
    border: 24px solid transparent;
    border-bottom-color: ${theme.WHITE_COLOR};
    pointer-events: inherit;
  } */
`;

const AllNotifications = styled.div`
  height: 346px;
  pointer-events: initial;
  overflow-x: visible;
  overflow-y: scroll;
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.BLACK_COLOR};
`;

const OneMessage = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr 50px;
  align-items: center;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  cursor: pointer;
  background: ${theme.WHITE_COLOR};
  :hover {
    background: ${theme.NEUTRAL100};
  }
`;

const SearchDiv = styled.div`
  width: 100%;
  pointer-events: initial;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin: 0px;
  padding: 0px 16px;
  border-top: 1px solid ${theme.NEUTRAL200};
`;

const UnreadDot = styled.div`
  display: inline-block;
  margin: auto auto;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${theme.TERTIARY500};
`;

export { MessagesInfoHeader };
