import { AgGridReact } from "ag-grid-react";
import React, { useContext, useState, useRef } from "react";
import styled from "styled-components";
import { ModalContext } from "../../../../context";
import { chevron_left, edit } from "../../../../images/NewDesign";
import { IIntegrations } from "../../../../types";
import { theme } from "../../../../utils/theme";
import { AppErrorText, AppMultiSelect, AppText, SkeletonBlock } from "../../../UI";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";
import { PanelModal, SetupRelatedRecordModal } from "../../../modal";
import { ExpectedIntegrationMappingsResponse } from "../StepIntegrationV2";
import { convertSyncRuleToLabel } from "../../../../utils/format";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import Switch from "react-switch";
import { useDebounce } from "../../../../utils/hooks";
import { appToast } from "../../../../utils/toast";
import { useFlags } from "launchdarkly-react-client-sdk";

interface FieldMappingProps {
  tableType: string;
  dataMappings: ExpectedIntegrationMappingsResponse | undefined;
  errorMappings: any;
  loadingMappings: any;
}

const UPDATE_STATUS = gql`
  mutation UpdateROObjectActiveStatus($updateRoObjectActiveStatusId: String!, $active: Boolean!) {
    updateROObjectActiveStatus(id: $updateRoObjectActiveStatusId, active: $active) {
      active
    }
  }
`;

const FieldMappingTable: React.FC<FieldMappingProps> = ({
  tableType,
  dataMappings,
  errorMappings,
  loadingMappings,
}) => {
  const {
    selectedIntegration,
    selectedIntegrationLabel,

    setShowIntegrationMapRulesModal,

    setIntegrationMapPicklistModal,

    setMapStateData,
    resetMapStateData,

    setEditIntegrationType,
    templateId,
  } = useContext(ModalContext);

  const formatTableTypeName = (name: string) => {
    switch (name) {
      case "System":
        return "SystemField";
      case "Custom":
        return "CustomField";
      case "Static":
        return "Static";
      default:
        break;
    }
  };

  let mappingType = formatTableTypeName(tableType);

  // Custom cell renderer functions
  const FieldMappingStatusCellRenderer = ({ value }: { value: boolean }) => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
      <AppText fontSize={12} fontWeight={600} style={{ color: value ? "green" : "red", alignSelf: "center" }}>
        {value ? "OK" : "Issue"}
      </AppText>
    </div>
  );

  const FieldMappingActionCellRenderer = ({ data }: { data: any }) => {
    const item = data?.inspect;

    return (
      <PhoenixAppButton
        variant="danger-outline"
        buttonType="secondary"
        style={{ display: "contents" }}
        onClick={() => {
          setMapStateData({
            id: item?.id,
            external_object: item?.external_object,
            external_field: item?.external_field,
            external_field_label: item?.external_field_label,
            opsiq_object: item?.opsiq_object,
            opsiq_field: item?.opsiq_field,
            opsiq_field_label: item?.opsiq_field_label,
            opsiq_field_type: item?.opsiq_field_type,
            sync_rule: item?.sync_rule,
            trigger_events: item?.trigger_events || [],
            mapping_type: item?.mapping_type,
          });
          setIntegrationMapPicklistModal(true);
        }}
        disabled={!item?.inspect}
      >
        <TableText style={{ color: theme.ATTENTION700, opacity: item?.inspect ? 1 : 0.25 }}>View</TableText>
      </PhoenixAppButton>
    );
  };

  const FieldMappingManageCellRenderer = ({ data }: { data: any }) => {
    const item = data?.manage;
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
        <PhoenixIcon
          svg={edit}
          size={16}
          onClick={() => {
            setMapStateData({
              id: item?.id,
              external_object: item?.external_object,
              external_field: item?.external_field,
              external_field_label: item?.external_field_label,
              opsiq_object: item?.opsiq_object,
              opsiq_field: item?.opsiq_field,
              opsiq_field_label: item?.opsiq_field_label,
              opsiq_field_type: item?.opsiq_field_type,
              sync_rule: item?.sync_rule,
              trigger_events: item?.trigger_events || [],
              mapping_type: item?.mapping_type,
            });
            setEditIntegrationType(tableType);
            setShowIntegrationMapRulesModal(true);
          }}
          pointer
        />
      </div>
    );
  };

  // Define a function to get column definitions based on the configuration state
  const getFieldMappingColumnDefs = () => {
    return [
      {
        headerName: "OPSIQ Object",
        field: "opsiq_object",
        sortable: false,
        filter: false,
        resizable: true,
        flex: 1,
        cellStyle: { textAlign: "left" },
        headerClass: "ag-theme-integration-mapping-table-header",
      },
      {
        headerName: "OPSIQ Field",
        field: "opsiq_field",
        sortable: false,
        filter: false,
        resizable: true,
        flex: 1,
        cellStyle: { textAlign: "left" },
        headerClass: "ag-theme-integration-mapping-table-header",
      },
      ...(selectedIntegration !== "PandaDoc"
        ? [
            {
              headerName: `${selectedIntegrationLabel} Object`,
              field: "external_object",
              sortable: false,
              filter: false,
              resizable: true,
              flex: 1,
              cellStyle: { textAlign: "left" },
              headerClass: "ag-theme-integration-mapping-table-header",
            },
            {
              headerName: `${selectedIntegrationLabel} Field`,
              field: "external_field",
              sortable: false,
              filter: false,
              resizable: true,
              flex: 1,
              cellStyle: { textAlign: "left" },
              headerClass: "ag-theme-integration-mapping-table-header",
            },
            {
              headerName: "Sync Rule",
              field: "sync_rule",
              cellRenderer: "syncRuleCellRenderer",
              sortable: false,
              filter: false,
              resizable: true,
              flex: 1,
              cellStyle: { textAlign: "left" },
              headerClass: "ag-theme-integration-mapping-table-header",
            },
            {
              headerName: "Events",
              field: "trigger_events",
              sortable: false,
              filter: false,
              resizable: true,
              flex: 1,
              cellStyle: { textAlign: "center" },
              headerStyle: { textAlign: "center" },
              headerClass: "ag-theme-integration-mapping-table-header ag-theme-integration-mapping-table-header-center",
            },
            {
              headerName: "Status",
              field: "status",
              cellRenderer: "statusCellRenderer",
              sortable: false,
              filter: false,
              resizable: true,
              flex: 1,
              cellStyle: { textAlign: "center" },
              headerStyle: { textAlign: "center" },
              headerClass: "ag-theme-integration-mapping-table-header ag-theme-integration-mapping-table-header-center",
            },
            {
              headerName: "Inspect",
              field: "inspect",
              cellRenderer: "inspectCellRenderer",
              sortable: false,
              filter: false,
              resizable: true,
              flex: 1,
              cellStyle: { textAlign: "center" },
              headerStyle: { textAlign: "center" },
              headerClass: "ag-theme-integration-mapping-table-header ag-theme-integration-mapping-table-header-center",
            },
          ]
        : []),
      {
        headerName: "Manage",
        field: "manage",
        cellRenderer: "manageCellRenderer",
        sortable: false,
        filter: false,
        resizable: true,
        width: 100,
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
        headerClass:
          "ag-theme-integration-mapping-table-header ag-theme-integration-mapping-table-header-center" /* other properties */,
      },
    ];
  };

  // Define a function to format rowData
  const formatFieldMappingRowData = () => {
    if (loadingMappings) return [];
    if (errorMappings) return [];

    console.log("dataMappings?.fetchIntegrationMappings", dataMappings?.fetchIntegrationMappings);
    return dataMappings?.fetchIntegrationMappings
      ?.filter((item: any) => item?.mapping_type === mappingType)
      ?.map((item: any) => ({
        // Map your data to the rowData format here
        opsiq_object: item?.opsiq_object,
        opsiq_field: item?.is_custom_object ? item?.opsiq_field_label : item?.opsiq_field,
        ...(selectedIntegration !== "PandaDoc"
          ? {
              external_object: item?.external_object,
              external_field: item?.external_field,
              sync_rule: convertSyncRuleToLabel(item?.sync_rule, selectedIntegrationLabel),
              trigger_events: item?.trigger_events,
              status: item?.status,
              inspect: item,
            }
          : {}),
        manage: {
          ...item,
          integration: selectedIntegration,
        },
      }));
  };

  return (
    <>
      {tableType === "System" ? (
        <AddNewBottomDiv>
          <PhoenixAppButton
            variant={"brand"}
            buttonType="secondary"
            disabled={selectedIntegration === "PandaDoc" && !templateId}
            onClick={() => {
              setEditIntegrationType(tableType);
              resetMapStateData();
              setShowIntegrationMapRulesModal(true);
            }}
          >
            {`Map ${tableType} Field`}
          </PhoenixAppButton>
        </AddNewBottomDiv>
      ) : (
        <AddNewBottomDiv>
          <PhoenixAppButton
            variant={"brand"}
            buttonType="secondary"
            disabled={selectedIntegration === "PandaDoc" && !templateId}
            onClick={() => {
              setEditIntegrationType(tableType);
              resetMapStateData();
              setShowIntegrationMapRulesModal(true);
            }}
          >
            {`Map ${tableType} Field`}
          </PhoenixAppButton>
        </AddNewBottomDiv>
      )}

      {selectedIntegration === IIntegrations.PandaDoc && !templateId ? (
        <AppText
          fontSize={12}
          fontWeight={600}
          style={{ color: theme.ATTENTION700, alignSelf: "center", marginRight: 8 }}
        >
          Please select a template to map fields.
        </AppText>
      ) : (
        <PhoenixTableBody
          className={"ag-theme-integration-mapping-table"}
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <AgGridReact
            // auto size height
            domLayout="autoHeight"
            columnDefs={getFieldMappingColumnDefs()}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(params) => {
              //
              params.api.sizeColumnsToFit();
            }}
            rowData={formatFieldMappingRowData()}
            frameworkComponents={{
              statusCellRenderer: FieldMappingStatusCellRenderer,
              inspectCellRenderer: FieldMappingActionCellRenderer,
              manageCellRenderer: FieldMappingManageCellRenderer,
            }}
          />
        </PhoenixTableBody>
      )}
    </>
  );
};

interface SALESFORCE_RECORD_OBJECT {
  id?: string;
  integration_type?: string;
  lead_fk_field?: string;
  num_fields?: number;
  object_name?: string;
  organization_id?: string;
  active?: boolean;
  created_at?: string;
  updated_at?: string;
}
interface expectedSalesforceRelatedRecordsResponse {
  fetchAllIntegrationROObject: SALESFORCE_RECORD_OBJECT[];
}

const SalesforceLeadDetailViewTable = () => {
  const FETCH_SF_RELATED_RECORDS = gql`
    query FetchAllIntegrationROObject($integration: INTEGRATION_TYPE!) {
      fetchAllIntegrationROObject(integration: $integration) {
        id
        integration_type
        lead_fk_field
        num_fields
        object_name
        organization_id
        active
        created_at
        updated_at
      }
    }
  `;

  const {
    data,
    loading: loadingFetchRecords,
    error: errorFetchRecords,
    refetch: refetchSFRelatedRecords,
  } = useQuery<expectedSalesforceRelatedRecordsResponse>(FETCH_SF_RELATED_RECORDS, {
    variables: {
      integration: IIntegrations.Salesforce,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
    onError: (error) => {},
  });

  const SFManageCellRenderer = ({ data }: { data: any }) => {
    const item = data?.manage;

    // to do update this to the correct id
    const id = item?.id;

    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
        <PhoenixIcon
          svg={edit}
          size={16}
          onClick={() => {
            // add id to state
            setRelatedRecordModalData({
              id: id,
              integration: item?.integration_type,
            });
            // open modal

            setShowSetupRelatedRecordModal(true);
          }}
          pointer
        />
      </div>
    );
  };

  const {
    showSetupRelatedRecordModal,
    setShowSetupRelatedRecordModal,

    relatedRecordModalData,
    setRelatedRecordModalData,
  } = useContext(ModalContext);

  const SFRelatedRecordsColumnDefs = [
    {
      headerName: "Salesforce Object",
      field: "salesforce_object_text",
      sortable: false,
      filter: false,
      resizable: true,
      width: 300,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-theme-integration-mapping-table-header",
    },
    {
      headerName: "Number of Fields",
      field: "field_count",
      sortable: false,
      filter: false,
      resizable: true,
      flex: 1,
      minWidth: 100,
      cellStyle: { textAlign: "left" },
      headerClass: "ag-theme-integration-mapping-table-header",
    },
    {
      headerName: "Active",
      field: "active",
      sortable: false,
      filter: false,
      resizable: true,
      width: 75,
      cellRenderer: "activeCellRenderer",
      headerClass: "ag-theme-integration-mapping-table-header ag-theme-integration-mapping-table-header-center",
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      headerName: "Manage",
      field: "manage",
      cellRenderer: "manageCellRenderer",
      sortable: false,
      filter: false,
      resizable: true,
      width: 75,
      cellStyle: { textAlign: "center" },
      headerStyle: { textAlign: "center" },
      headerClass:
        "ag-theme-integration-mapping-table-header ag-theme-integration-mapping-table-header-center" /* other properties */,
    },
  ];

  const SFActiveCellRenderer = ({ data }: { data: any }) => {
    const activeRowData = data?.active;

    // debounce update
    const [localToggle, setLocalToggle] = useState<boolean>(activeRowData?.active);

    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
        <EmailsSwitchDiv>
          <Switch
            onChange={(checked) => {
              setLocalToggle(checked);

              updateROObjectActiveStatus({
                variables: {
                  updateRoObjectActiveStatusId: activeRowData?.id,
                  active: checked,
                },
              });
            }}
            onColor={theme.PRIMARY500}
            offColor={theme.NEUTRAL200}
            checked={localToggle}
            height={16}
            width={32}
            handleDiameter={12}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </EmailsSwitchDiv>
      </div>
    );
  };

  const [updateROObjectActiveStatus] = useMutation(UPDATE_STATUS, {
    onCompleted: (data) => {
      appToast("Updated Record Object Status");
    },
    onError: (error) => {
      appToast("Error updating Record Object Status");
      // refetch data
      refetchSFRelatedRecords();
    },
  });

  const loading = loadingFetchRecords;

  const error = errorFetchRecords;

  const SFAgridRef = useRef<null | any>(null);

  return (
    <>
      {showSetupRelatedRecordModal && (
        <PanelModal
          open={showSetupRelatedRecordModal}
          onClose={() => setShowSetupRelatedRecordModal(false)}
          bigCloseButton
        >
          <SetupRelatedRecordModal />
        </PanelModal>
      )}
      {error ? (
        <AppErrorText>Error loading Salesforce related records.</AppErrorText>
      ) : (
        <>
          <PhoenixTableHeader
            style={{
              marginTop: 16,
              marginBottom: 16,
            }}
          >
            <AppText fontSize={14} fontWeight={500}>
              Related Records to Display on Leads
            </AppText>
            <AddNewDiv>
              <PhoenixAppButton
                variant={"brand"}
                buttonType="secondary"
                onClick={() => {
                  // if id set id

                  // else set id to undefined

                  setRelatedRecordModalData({
                    id: undefined,
                    integration: relatedRecordModalData?.integration ?? IIntegrations.Salesforce,
                  });

                  // open modal
                  setShowSetupRelatedRecordModal(true);
                }}
              >
                Setup Related Record
              </PhoenixAppButton>
            </AddNewDiv>
          </PhoenixTableHeader>
          <PhoenixTableBody
            className={"ag-theme-integration-mapping-table"}
            style={{
              width: "100%",
            }}
          >
            <AgGridReact
              ref={SFAgridRef}
              columnDefs={SFRelatedRecordsColumnDefs}
              rowData={data?.fetchAllIntegrationROObject?.map((item: SALESFORCE_RECORD_OBJECT) => {
                return {
                  salesforce_object_text: item?.object_name,
                  field_count: item?.num_fields,
                  active: item,
                  manage: item,
                };
              })}
              frameworkComponents={{
                manageCellRenderer: SFManageCellRenderer,
                activeCellRenderer: SFActiveCellRenderer,
              }}
              // auto size height
              domLayout="autoHeight"
            />
          </PhoenixTableBody>
        </>
      )}
    </>
  );
};

const FieldMappingPage = ({
  dataMappings,
  errorMappings,
  loadingMappings,
  selectedIntegration,
  setSelectedIntegration,
  setTemplateId,
  showIntegrationSubpage,
  setShowIntegrationSubpage,
  togglePageTitle,

  dataVisibleTemplatesRep,
}: {
  dataMappings: any;
  errorMappings: any;
  loadingMappings: any;
  selectedIntegration: string;
  setSelectedIntegration: any;
  setTemplateId: any;
  showIntegrationSubpage: boolean;
  setShowIntegrationSubpage: any;
  togglePageTitle: any;

  dataVisibleTemplatesRep: any;
}) => {
  const options = dataVisibleTemplatesRep?.fetchAllPandaDocTemplateForRep?.map((item: any) => ({
    label: item?.template_name,
    value: item?.template_id,
  }));

  const { customObject } = useFlags();

  const [mappingTab, setMappingTab] = useState<"System" | "Custom">("System");

  return (
    <Page>
      <Header>
        <TopRowDiv>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: theme.PRIMARY500,
              cursor: "pointer",
              gap: 8,
              height: "fit-content",
            }}
            onClick={() => {
              // setSelectedIntegration("");
              setTemplateId(undefined);
              setShowIntegrationSubpage(false);
              !!togglePageTitle && togglePageTitle();
            }}
          >
            <PhoenixIcon svg={chevron_left} size={16} />
            <AppText fontSize={10} fontWeight={600} uppercase color={theme.PRIMARY500}>
              Back
            </AppText>
          </div>

          {selectedIntegration === "PandaDoc" && (
            <TemplateIdSelect>
              <AppMultiSelect
                isMulti={false}
                name="template_id"
                options={options}
                placeholder="Select a template..."
                onChange={(e: any) => {
                  setTemplateId((!!e?.value && e.value) || "");
                }}
              />
            </TemplateIdSelect>
          )}
        </TopRowDiv>

        <AppText
          fontSize={22}
          fontWeight={500}
          style={{
            marginTop: 8,
          }}
        >
          Field Mapping
        </AppText>
      </Header>

      <TabsDiv>
        <Tab active={mappingTab === "System"} onClick={() => setMappingTab("System")}>
          <AppText
            fontSize={10}
            fontWeight={600}
            uppercase
            style={{
              border: "none",
            }}
          >
            OPSIQ System Fields
          </AppText>
        </Tab>
        <Tab active={mappingTab === "Custom"} onClick={() => setMappingTab("Custom")}>
          <AppText
            fontSize={10}
            fontWeight={600}
            uppercase
            style={{
              border: "none",
            }}
          >
            Custom Fields
          </AppText>
        </Tab>
      </TabsDiv>

      {mappingTab === "System" && (
        <FieldMappingTable
          dataMappings={dataMappings}
          errorMappings={errorMappings}
          loadingMappings={loadingMappings}
          tableType={"System"}
          key={"System"}
        />
      )}
      {mappingTab === "Custom" && (
        <>
          <FieldMappingTable
            dataMappings={dataMappings}
            errorMappings={errorMappings}
            loadingMappings={loadingMappings}
            tableType={"Custom"}
            key={"Custom"}
          />
          {selectedIntegration === IIntegrations.Salesforce && !customObject && <SalesforceLeadDetailViewTable />}
        </>
      )}
    </Page>
  );
};

const Page = styled.div`
  min-height: 400px;

  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 0px 16px;
`;

const TopRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TemplateIdSelect = styled.div`
  width: 200px;
`;

const AddNewDiv = styled.div`
  padding: 4px 12px 4px 12px;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  justify-content: flex-end;
`;

const AddNewBottomDiv = styled.div`
  margin: 16px 0px;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  justify-content: flex-end;
`;

const TableText = styled(AppText)`
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid ${theme.NEUTRAL200};
  padding-top: 11px;
  width: 100%;
  letter-spacing: 0.2px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PhoenixTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const Header = styled.div`
  padding: 40px 24px 24px 24px;
`;

const TabsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${theme.NEUTRAL200};
`;

const Tab = styled.div<{ active?: boolean }>`
  padding: ${(props) => (props.active ? "8px 16px 6px 16px" : "8px 16px 8px 16px")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 162px;
  margin-bottom: -1px;
  border-bottom: ${(props) => (props.active ? `2px solid ${theme.PRIMARY500}` : "none")};
  cursor: pointer;
`;

const EmailsSwitchDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PhoenixTableBody = styled.div``;

export default FieldMappingPage;
