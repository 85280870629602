import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { AppText } from "src/Components/UI/AppText";
import { FlexDiv } from "src/Components/UI/FlexDiv";
import { PhoenixAppButton, PhoenixInput } from "src/Components/UI/Phoenix";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { DraggableFieldType } from "src/utils/variables";
import { cloneDeep } from "lodash";
import { CustomField, SaleConfigPage, SaleConfigSection, formatMakeSaleConfigFields } from "src/utils/misc";
import { SkeletonBlock } from "src/Components/UI";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";
import SaleConfigFieldList from "src/Components/Segments/SaleConfigFieldList";

interface FieldSectionProps {
  pageState: {
    pages: SaleConfigPage[];
    setPages: Dispatch<SetStateAction<SaleConfigPage[]>>;
    selectedPage: SaleConfigPage | undefined;
    setSelectedPage: Dispatch<SetStateAction<SaleConfigPage | undefined>>;
    pageTitle: string;
    setPageTitle: Dispatch<SetStateAction<string>>;
    setSelectedFieldID: Dispatch<SetStateAction<string>>;
  };
  handleFieldDragEnd: (result: DropResult) => void;
  requiredFieldData: string[];
  loading: boolean;
  customFieldData: CustomField[];
}

export const FieldSection: React.FC<FieldSectionProps> = ({
  pageState: { pages, selectedPage, setSelectedPage, pageTitle, setPageTitle, setSelectedFieldID },
  requiredFieldData,
  handleFieldDragEnd,
  loading,
  customFieldData,
}) => {
  const fieldData = useMemo(() => selectedPage?.sections, [selectedPage]);

  const fields = useMemo(() => {
    ReactTooltip.rebuild();
    return formatMakeSaleConfigFields(fieldData, requiredFieldData, customFieldData);
  }, [fieldData]);

  // all field data across all pages
  const allFields = useMemo(() => {
    const combinedFields = pages?.reduce((acc: SaleConfigSection[], page: SaleConfigPage) => {
      return acc.concat(page.sections);
    }, []);
    return formatMakeSaleConfigFields(combinedFields, requiredFieldData, customFieldData);
  }, [pages, selectedPage]);

  const customFieldOptions = useMemo(() => {
    const filteredFields = customFieldData.filter(
      (cf: CustomField) =>
        !allFields?.find((f: SaleConfigSection) => f.custom_field_id === cf.id) && cf.allow_reps_to_edit === true,
    );
    return filteredFields?.map((cf: CustomField) => ({ label: cf.key, value: cf.id }));
  }, [allFields]);

  const isPandaDocPage = useMemo(() => !!selectedPage?.sections?.find((f) => f.type === DraggableFieldType.PANDADOC), [
    selectedPage,
  ]);

  useEffect(() => {
    const newSelectedPage = cloneDeep(selectedPage);
    if (!!newSelectedPage) {
      setSelectedPage(newSelectedPage);
    }
  }, [pageTitle]);

  const onInputChange = (id: string, newContent: string) => {
    const newSelectedPage = cloneDeep(selectedPage);
    const index = newSelectedPage?.sections.findIndex((f) => f.id === id);
    if (index !== undefined && index !== -1 && !!newSelectedPage) {
      newSelectedPage.sections[index].content = newContent;
      setSelectedPage(newSelectedPage);
    }
  };

  const onNoteStyleChange = (id: string, newStyle: "default" | "warning" | "link") => {
    const newSelectedPage = cloneDeep(selectedPage);
    const index = newSelectedPage?.sections.findIndex((f) => f.id === id);
    if (index !== undefined && index !== -1 && !!newSelectedPage) {
      newSelectedPage.sections[index].type =
        newStyle === "default"
          ? DraggableFieldType.NOTE
          : newStyle === "warning"
          ? DraggableFieldType.WARNING
          : DraggableFieldType.LINK;
      setSelectedPage(newSelectedPage);
    }
  };

  const onFieldChange = (id: string, newOption: { label: string; value: string }, radio: "system" | "custom") => {
    setSelectedPage((prevSelectedPage) => {
      if (!prevSelectedPage) return prevSelectedPage;

      const updatedSections = prevSelectedPage.sections?.map((section) =>
        section.id === id
          ? {
              ...section,
              system_field: radio === "system" ? newOption.value : undefined,
              custom_field_id: radio === "custom" ? newOption.value : undefined,
              content: newOption.label,
            }
          : section,
      );

      return { ...prevSelectedPage, sections: updatedSections };
    });
  };

  const onFieldRadioChange = (id: string, newType: "system" | "custom") => {
    setSelectedPage((prevSelectedPage) => {
      if (!prevSelectedPage) return prevSelectedPage;

      const updatedSections: SaleConfigSection[] = prevSelectedPage.sections?.map((section) =>
        section.id === id
          ? {
              // reset dropdown
              ...section,
              system_field: undefined,
              custom_field_id: undefined,
              type: newType === "system" ? "SYSTEM_FIELD" : "CUSTOM_FIELD",
              content: undefined,
            }
          : section,
      );

      return {
        ...prevSelectedPage,
        sections: updatedSections,
      };
    });
  };

  const onFieldRequiredChange = (id: string, newRequired: boolean) => {
    const newSelectedPage = cloneDeep(selectedPage);
    const index = newSelectedPage?.sections.findIndex((f) => f.id === id);
    if (index !== undefined && index !== -1 && !!newSelectedPage) {
      newSelectedPage.sections[index].required = newRequired;
      setSelectedPage(newSelectedPage);
    }
  };

  const createNewSection = (type: "NOTE" | "WARNING" | "HEADER" | "SYSTEM_FIELD" | "CUSTOM_FIELD") => {
    if (selectedPage) {
      const newSelectedPage = cloneDeep(selectedPage);
      newSelectedPage.sections.push({
        id: uuidv4(),
        type,
        required: false,
      });
      setSelectedPage(newSelectedPage);
    }
  };

  return (
    <FieldSectionWrap>
      <PhoenixStyledTooltip id="field-tooltip" />

      <FlexDiv direction="column" gap={8} style={{ width: "424px", marginBottom: "24px" }}>
        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
          Page Title
        </AppText>
        <PhoenixInput
          placeholder="Page Title"
          displayNoContextText
          value={pageTitle}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPageTitle(e.target.value)}
          disabled={loading}
        />
      </FlexDiv>

      {!loading ? (
        <SaleConfigFieldList
          droppableId="make-sale-config-fields"
          handleFieldDragEnd={handleFieldDragEnd}
          fields={fields}
          allFields={allFields}
          customFieldData={customFieldData}
          customFieldOptions={customFieldOptions}
          onChangeHandlers={{
            onInputChange,
            onNoteStyleChange,
            onFieldChange,
            onFieldRequiredChange,
            onFieldRadioChange,
          }}
          setSelectedFieldID={setSelectedFieldID}
        />
      ) : (
        <FlexDiv direction="column" gap={16} style={{ paddingBottom: "24px" }}>
          {Array.from({ length: 3 })?.map((_, i) => (
            <SkeletonBlock
              width={422}
              height={120}
              borderRadius={8}
              key={i}
              delayNumber={i + 1}
              lightPulseStateColor={theme.PRIMARY100}
              opacity={Math.max(1 - i * 0.3, 0.3)}
            />
          ))}
        </FlexDiv>
      )}

      <ButtonContainer>
        <PhoenixAppButton
          variant="brand"
          buttonType="secondary"
          uppercase
          width={240}
          buttonTextFontSize={10}
          onClick={() => createNewSection("SYSTEM_FIELD")}
          disabled={loading || isPandaDocPage}
        >
          Add Field
        </PhoenixAppButton>
        <PhoenixAppButton
          variant="brand-outline"
          buttonType="secondary"
          uppercase
          width={240}
          buttonTextFontSize={10}
          onClick={() => createNewSection("HEADER")}
          disabled={loading || isPandaDocPage}
        >
          Add Section Heading
        </PhoenixAppButton>
        <PhoenixAppButton
          variant="brand-outline"
          buttonType="secondary"
          uppercase
          width={240}
          buttonTextFontSize={10}
          onClick={() => createNewSection("NOTE")}
          disabled={loading || isPandaDocPage}
        >
          Add Note
        </PhoenixAppButton>
      </ButtonContainer>
    </FieldSectionWrap>
  );
};

const FieldSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 445px;
  height: 85vh;
  padding-top: 40px;

  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
