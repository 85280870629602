import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { FieldArray, Formik, FormikProps } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Switch from "react-switch";
import styled from "styled-components";
import * as Yup from "yup";
import { iconNewMoveRow, iconNewPlus } from "../../images";
import { trash } from "../../images/NewDesign";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { FormSelectField, InputField } from "../Field";
import { PhoenixInputField } from "../Field/Phoenix";
import { AppText, Loading, NewAppSelect } from "../UI";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { Modal } from "./Modal";

interface DisappearingDivProps {
  blinds: boolean;
  close: () => void;
  setBlinds?: Dispatch<SetStateAction<boolean>>;
  id?: string;
  keyName?: string;
  name?: string;
}

const CREATE_ONE_CUSTOM_OBJECT = gql`
  mutation createOneCustomObject($name: String!, $field_name: String!, $field_type: CustomFieldType!) {
    createOneCustomObject(name: $name, field_name: $field_name, field_type: $field_type) {
      id
      name
    }
  }
`;

const UPDATE_ONE_CUSTOM_OBJECT = gql`
  mutation updateOneCustomObject($id: String!, $name: String!) {
    updateOneCustomObject(custom_object_id: $id, name: $name) {
      id
      name
    }
  }
`;

interface MyFormikProps {
  id: string;
  name: string;
  key: string;
}

const UpdateCustomObject: React.FC<DisappearingDivProps> = ({
  blinds,
  setBlinds,
  close,
  id,
  name,
  keyName,
  ...propsField
}) => {
  const customObjectSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().required("Field required!"),
    field_name: Yup.string().when("id", {
      is: (id) => !id, // Check if 'id' is not null
      then: Yup.string().required("Field Name is required!"), // Apply this rule if 'id' is not null
      otherwise: Yup.string(), // No additional rules if 'id' is null
    }),
    field_type: Yup.string().when("id", {
      is: (id) => !id, // Check if 'id' is not null
      then: Yup.string().required("Field Name is required!"), // Apply this rule if 'id' is not null
      otherwise: Yup.string(), // No additional rules if 'id' is null
    }),
  });

  const [createOneCustomObject, { loading: createLoading, error: createError }] = useMutation(
    CREATE_ONE_CUSTOM_OBJECT,
    {
      onCompleted({ createOneCustomObject }) {
        appToast("Custom Object Created");
        close();
      },
      onError({ message }) {
        appToast(message);
        console.log("Error in createOneCustomObject: ", message);
      },
      fetchPolicy: "no-cache",
      refetchQueries: ["fetchAllCustomObject"],
    },
  );

  const [updateOneCustomObject, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_ONE_CUSTOM_OBJECT,
    {
      onCompleted({ updateOneCustomObject }) {
        appToast("Custom Object Updated");
        close();
      },
      onError({ message }) {
        appToast(message);
        console.log("Error in updateOneCustomObject: ", message);
      },
      fetchPolicy: "no-cache",
      refetchQueries: ["fetchAllCustomObject", "fetchOneCustomObject"],
    },
  );

  const createOptions = () => {
    if (!id) {
      return (
        <>
          <PopupInputLabel>
            Record Name<span style={{ color: "red" }}>*</span>
          </PopupInputLabel>
          <FieldInput name="field_name" placeholder="Example: Location Name" />
          {/* <PopupInputLabel>
            Field Type<span style={{ color: "red" }}>*</span>
          </PopupInputLabel>
          <NewAppSelect
            value={"Text"}
            textAlign="left"
            name="type"
            isDisabled={true}
            options={[{ value: "Text", label: "Text" }]}
            maxMenuHeight={100}
          /> */}
        </>
      );
    }
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        key: keyName || "",
        name: name || "",
        id: id || "",
        field_name: "",
        field_type: "Text",
      }}
      validationSchema={customObjectSchema}
      onSubmit={async (values) => {
        if (id) {
          await updateOneCustomObject({
            variables: {
              id: values.id,
              name: values.name,
            },
          });
        } else {
          await createOneCustomObject({
            variables: {
              name: values.name,
              field_name: values.field_name,
              field_type: "Text",
            },
          });
        }
      }}
    >
      {({
        submitForm,
        isSubmitting,
        isValid,
        dirty,
        errors,
        values: { id, name, key },
      }: FormikProps<MyFormikProps>) => {
        return (
          <Modal open={blinds} onClose={close} bigCloseButton>
            <ModalContent>
              <TitleDiv>
                <PopupTitle>{id ? "Edit" : "Add"} Custom Object</PopupTitle>
              </TitleDiv>
              <ScrollingDiv>
                <PopupInputLabel>
                  Object Name<span style={{ color: "red" }}>*</span>
                </PopupInputLabel>
                <FieldInput name="name" placeholder="Example: Location" />
                {createOptions()}
              </ScrollingDiv>
              <SubmitDiv>
                <SubmitFlexBetweenDiv>
                  <PhoenixAppButton type="button" onClick={close} buttonType="secondary" variant="danger-outline">
                    Cancel
                  </PhoenixAppButton>
                  <PhoenixAppButton
                    type="submit"
                    onClick={submitForm}
                    buttonType="secondary"
                    variant="brand"
                    disabled={createLoading || updateLoading || !isValid || isSubmitting}
                  >
                    Continue
                  </PhoenixAppButton>
                </SubmitFlexBetweenDiv>
              </SubmitDiv>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};

const ModalContent = styled.div`
  width: 456px;
`;

const SubmitDiv = styled.div`
  position: relative;
  height: 88px;
  width: 100%;
  gap: 12px;
  padding: 0px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: solid 1px ${theme.NEUTRAL200};
`;

const SubmitFlexBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px;
`;

const TitleDiv = styled.div`
  height: 56px;
  border-bottom: solid 1px ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const PopupInputLabel = styled(AppText)`
  margin: 8px 0;
`;

const FieldInput = styled(InputField)`
  margin: 0;
  text-align: left;

  ::placeholder {
    text-align: left;
  }
`;

const PopupTitle = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: 27px; */
`;

const ScrollingDiv = styled.div`
  position: relative;
  padding: 40px;
  overflow: auto;
  width: 100%;
  min-height: 186px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export { UpdateCustomObject };
