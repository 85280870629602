import { IIntegrations } from "../../../../types";
import { AppErrorText, SkeletonBlock } from "../../../UI";
import styled from "styled-components";
import React, { useState } from "react";
import { PhoenixIcon } from "../../../UI/Phoenix";
import { chevron_left } from "../../../../images/NewDesign";
import { theme } from "../../../../utils/theme";
import { AppText } from "../../../UI";
import Switch from "react-switch";
import { useDebounce } from "../../../../utils/hooks";
import { gql, useQuery, useMutation } from "@apollo/client";
import { appToast } from "../../../../utils/toast";

const IntegrationSettingsPage = ({
  integration,
  integrationLabel,
  integrationLogo,
  goBack,
}: {
  integration: IIntegrations;
  integrationLogo: string;
  integrationLabel: string;
  goBack: () => void;
}) => {
  const supportedIntegrations = [IIntegrations.Salesforce, IIntegrations.HubSpot];

  const GetIntegrationLabelForSwitchText = (integration: IIntegrations) => {
    switch (integration) {
      case IIntegrations.Salesforce:
        return "SFDC";
      case IIntegrations.HubSpot:
        return "HubSpot";
      default:
        return "";
    }
  };

  // fetches

  // salesforce

  interface fetchSalesforceSyncRuleExpectedResponse {
    fetchSalesforceSyncRule: {
      organization_id: string;
      sfdc_inbound_creation_sync: boolean;
      sfdc_inbound_update_sync: boolean;
      sfdc_outbound_activity_sync: boolean;
      sfdc_outbound_creation_sync: boolean;
      sfdc_outbound_update_sync: boolean;
    };
  }

  const FETCH_SALESFORCE_SETTINGS = gql`
    query FetchSalesforceSyncRule {
      fetchSalesforceSyncRule {
        organization_id
        sfdc_inbound_creation_sync
        sfdc_inbound_update_sync
        sfdc_outbound_activity_sync
        sfdc_outbound_creation_sync
        sfdc_outbound_update_sync
      }
    }
  `;

  // hubspot

  interface fetchHubSpotSyncRuleExpectedResponse {
    fetchHubSpotSyncRule: {
      hubspot_inbound_creation_sync: boolean;
      hubspot_inbound_update_sync: boolean;
      hubspot_outbound_activity_sync: boolean;
      hubspot_outbound_creation_sync: boolean;
      hubspot_outbound_update_sync: boolean;
      hubspot_deal_create_on_sale: boolean;
    };
  }

  const FETCH_HUBSPOT_SETTINGS = gql`
    query FetchHubSpotSyncRule {
      fetchHubSpotSyncRule {
        hubspot_inbound_creation_sync
        hubspot_inbound_update_sync
        hubspot_outbound_activity_sync
        hubspot_outbound_creation_sync
        hubspot_outbound_update_sync
        hubspot_deal_create_on_sale
      }
    }
  `;

  // we are going to have the settings in a state var and set with the fetch. a debounce listener will update the settings for all of the toggles

  interface ISettings {
    leadCreationOutbound?: boolean;
    leadCreationInbound?: boolean;
    leadUpdateOutbound?: boolean;
    leadUpdateInbound?: boolean;
    leadActivityOutbound?: boolean;
    dealCreationOnSale?: boolean;
  }

  // we are using this setup to support both salesforce and hubspot and whatever else we add in the future
  // the vars from the BE are going to be different for each integration, so we need to map them to the same vars seen above
  const [initialSettings, setInitialSettings] = useState<ISettings | undefined>(undefined);
  const [currentSettings, setCurrentSettings] = useState<ISettings | undefined>(undefined);

  // fetch for SF
  const {
    data: salesforceData,
    loading: salesforceLoading,
    error: salesforceError,
  } = useQuery<fetchSalesforceSyncRuleExpectedResponse>(FETCH_SALESFORCE_SETTINGS, {
    skip: integration !== IIntegrations.Salesforce,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        const {
          fetchSalesforceSyncRule: {
            sfdc_inbound_creation_sync,
            sfdc_inbound_update_sync,
            sfdc_outbound_activity_sync,
            sfdc_outbound_creation_sync,
            sfdc_outbound_update_sync,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });

        setCurrentSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });
      }
    },
  });

  // update for SF
  const UPDATE_SALESFORCE_SYNC_RULE = gql`
    mutation UpdateSFDCSyncRule($syncRuleInput: OrgSalesforceSyncInput!) {
      updateSFDCSyncRule(sync_rule_input: $syncRuleInput) {
        organization_id
        sfdc_inbound_creation_sync
        sfdc_inbound_update_sync
        sfdc_outbound_activity_sync
        sfdc_outbound_creation_sync
        sfdc_outbound_update_sync
      }
    }
  `;

  // mutation
  const [updateSalesforceSyncRule] = useMutation(UPDATE_SALESFORCE_SYNC_RULE, {
    onCompleted: (data) => {
      appToast("Updated Salesforce settings");

      if (data) {
        const {
          updateSFDCSyncRule: {
            sfdc_inbound_creation_sync,
            sfdc_inbound_update_sync,
            sfdc_outbound_activity_sync,
            sfdc_outbound_creation_sync,
            sfdc_outbound_update_sync,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });

        setCurrentSettings({
          leadCreationOutbound: sfdc_outbound_creation_sync,
          leadCreationInbound: sfdc_inbound_creation_sync,
          leadUpdateOutbound: sfdc_outbound_update_sync,
          leadUpdateInbound: sfdc_inbound_update_sync,
          leadActivityOutbound: sfdc_outbound_activity_sync,
        });
      }
    },
  });

  // fetch for HS

  const {
    data: hubspotData,
    loading: hubspotLoading,
    error: hubspotError,
  } = useQuery<fetchHubSpotSyncRuleExpectedResponse>(FETCH_HUBSPOT_SETTINGS, {
    skip: integration !== IIntegrations.HubSpot,
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data) {
        const {
          fetchHubSpotSyncRule: {
            hubspot_inbound_creation_sync,
            hubspot_inbound_update_sync,
            hubspot_outbound_activity_sync,
            hubspot_outbound_creation_sync,
            hubspot_outbound_update_sync,
            hubspot_deal_create_on_sale,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });

        setCurrentSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });
      }
    },
  });

  // update for HS
  const UPDATE_HUBSPOT_SYNC_RULE = gql`
    mutation UpdateHubSpotSyncRule($syncRuleInput: OrgHubSpotSyncInput!) {
      updateHubSpotSyncRule(sync_rule_input: $syncRuleInput) {
        organization_id
        hubspot_inbound_creation_sync
        hubspot_inbound_update_sync
        hubspot_outbound_activity_sync
        hubspot_outbound_creation_sync
        hubspot_outbound_update_sync
        hubspot_deal_create_on_sale
      }
    }
  `;

  // mutation
  const [updateHubSpotSyncRule] = useMutation(UPDATE_HUBSPOT_SYNC_RULE, {
    onCompleted: (data) => {
      // update the intial and current settings
      appToast("Updated HubSpot settings");

      if (data) {
        const {
          updateHubSpotSyncRule: {
            hubspot_inbound_creation_sync,
            hubspot_inbound_update_sync,
            hubspot_outbound_activity_sync,
            hubspot_outbound_creation_sync,
            hubspot_outbound_update_sync,
            hubspot_deal_create_on_sale,
          },
        } = data;

        setInitialSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });

        setCurrentSettings({
          leadCreationOutbound: hubspot_outbound_creation_sync,
          leadCreationInbound: hubspot_inbound_creation_sync,
          leadUpdateOutbound: hubspot_outbound_update_sync,
          leadUpdateInbound: hubspot_inbound_update_sync,
          leadActivityOutbound: hubspot_outbound_activity_sync,
          dealCreationOnSale: hubspot_deal_create_on_sale,
        });
      }
    },
  });

  // listen for changes to the settings and debounce them

  useDebounce(
    () => {
      const notInitalized = !currentSettings || !initialSettings;

      if (notInitalized) {
        return;
      }

      const settingsHaveNotChanged =
        currentSettings.leadCreationOutbound === initialSettings.leadCreationOutbound &&
        currentSettings.leadCreationInbound === initialSettings.leadCreationInbound &&
        currentSettings.leadUpdateOutbound === initialSettings.leadUpdateOutbound &&
        currentSettings.leadUpdateInbound === initialSettings.leadUpdateInbound &&
        currentSettings.leadActivityOutbound === initialSettings.leadActivityOutbound &&
        currentSettings.dealCreationOnSale === initialSettings.dealCreationOnSale;

      if (settingsHaveNotChanged) {
        return;
      }

      if (integration === IIntegrations.Salesforce) {
        updateSalesforceSyncRule({
          variables: {
            syncRuleInput: {
              sfdc_inbound_creation_sync: currentSettings.leadCreationInbound,
              sfdc_inbound_update_sync: currentSettings.leadUpdateInbound,
              sfdc_outbound_activity_sync: currentSettings.leadActivityOutbound,
              sfdc_outbound_creation_sync: currentSettings.leadCreationOutbound,
              sfdc_outbound_update_sync: currentSettings.leadUpdateOutbound,
            },
          },
        });
      } else if (integration === IIntegrations.HubSpot) {
        updateHubSpotSyncRule({
          variables: {
            syncRuleInput: {
              hubspot_inbound_creation_sync: currentSettings.leadCreationInbound,
              hubspot_inbound_update_sync: currentSettings.leadUpdateInbound,
              hubspot_outbound_activity_sync: currentSettings.leadActivityOutbound,
              hubspot_outbound_creation_sync: currentSettings.leadCreationOutbound,
              hubspot_outbound_update_sync: currentSettings.leadUpdateOutbound,
              hubspot_deal_create_on_sale: currentSettings.dealCreationOnSale,
            },
          },
        });
      }
    },
    [currentSettings],
    1000,
  );

  const BackButton = () => {
    return (
      <div
        style={{ display: "flex", gap: "4px", cursor: "pointer", padding: "4px", alignItems: "center" }}
        onClick={() => {
          goBack();
        }}
      >
        <PhoenixIcon svg={chevron_left} size={16} color={theme.PRIMARY500} />
        <AppText
          fontSize={10}
          fontWeight={600}
          color={theme.PRIMARY600}
          style={{
            color: theme.PRIMARY600,
          }}
          uppercase
        >
          Back
        </AppText>
      </div>
    );
  };

  if (!supportedIntegrations.includes(integration)) {
    return <AppErrorText>{`Integration ${integration} is not supported yet.`}</AppErrorText>;
  }

  const error = salesforceError || hubspotError;

  const loading = salesforceLoading || hubspotLoading;

  const canToggle = !loading && !error;

  if (error) {
    return <AppErrorText>{error.message}</AppErrorText>;
  }

  const Loader = () => {
    return (
      <Page>
        <SkeletonBlock height={"90vh"} width={"100%"} borderRadius={6} />
      </Page>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Page>
      <PageHeader>
        <BackButton />
        <AppText fontSize={22} fontWeight={500}>
          Integrations
        </AppText>
      </PageHeader>

      <IntegrationHeader>
        <IntegrationLogoImg src={integrationLogo} alt={integrationLabel} />
        <IntegrationName>{integrationLabel}</IntegrationName>
      </IntegrationHeader>

      <Spacer height={40} />

      {supportedIntegrations.includes(integration) && (
        <>
          <SettingsContainer>
            <SettingsContainerTitle>Lead Creation</SettingsContainerTitle>
            <ToggleRow>
              <Switch
                onChange={(checked: boolean) => {
                  if (canToggle) {
                    setCurrentSettings({
                      ...currentSettings,
                      leadCreationOutbound: checked ? true : false,
                    });
                  }
                }}
                checked={!!currentSettings?.leadCreationOutbound}
                onColor={theme.PRIMARY500}
                offColor={theme.NEUTRAL200}
                height={16}
                width={32}
                handleDiameter={12}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!canToggle || loading}
              />
              <ToggleText>{`Outbound ${GetIntegrationLabelForSwitchText(integration)} Lead Creation Sync`}</ToggleText>
            </ToggleRow>
            <ToggleRow>
              <Switch
                onChange={(checked: boolean) => {
                  if (canToggle) {
                    setCurrentSettings({
                      ...currentSettings,
                      leadCreationInbound: checked,
                    });
                  }
                }}
                checked={!!currentSettings?.leadCreationInbound}
                onColor={theme.PRIMARY500}
                offColor={theme.NEUTRAL200}
                height={16}
                width={32}
                handleDiameter={12}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!canToggle || loading}
              />
              <ToggleText>{`Inbound ${GetIntegrationLabelForSwitchText(integration)} Lead Creation Sync`}</ToggleText>
            </ToggleRow>
          </SettingsContainer>
          <SettingsContainer>
            <SettingsContainerTitle>Lead Update</SettingsContainerTitle>
            <ToggleRow>
              <Switch
                onChange={(checked: boolean) => {
                  if (canToggle) {
                    setCurrentSettings({
                      ...currentSettings,
                      leadUpdateOutbound: checked,
                    });
                  }
                }}
                checked={!!currentSettings?.leadUpdateOutbound}
                onColor={theme.PRIMARY500}
                offColor={theme.NEUTRAL200}
                height={16}
                width={32}
                handleDiameter={12}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!canToggle || loading}
              />
              <ToggleText>{`Outbound ${GetIntegrationLabelForSwitchText(integration)} Lead Update Sync`}</ToggleText>
            </ToggleRow>
            <ToggleRow>
              <Switch
                onChange={(checked: boolean) => {
                  if (canToggle) {
                    setCurrentSettings({
                      ...currentSettings,
                      leadUpdateInbound: checked,
                    });
                  }
                }}
                checked={!!currentSettings?.leadUpdateInbound}
                onColor={theme.PRIMARY500}
                offColor={theme.NEUTRAL200}
                height={16}
                width={32}
                handleDiameter={12}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!canToggle || loading}
              />
              <ToggleText>{`Inbound ${GetIntegrationLabelForSwitchText(integration)} Lead Update Sync`}</ToggleText>
            </ToggleRow>
          </SettingsContainer>
          <SettingsContainer>
            <SettingsContainerTitle>Lead Activity</SettingsContainerTitle>
            <ToggleRow>
              <Switch
                onChange={(checked: boolean) => {
                  if (canToggle) {
                    setCurrentSettings({
                      ...currentSettings,
                      leadActivityOutbound: checked,
                    });
                  }
                }}
                checked={!!currentSettings?.leadActivityOutbound}
                onColor={theme.PRIMARY500}
                offColor={theme.NEUTRAL200}
                height={16}
                width={32}
                handleDiameter={12}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={!canToggle || loading}
              />
              <ToggleText>{`Outbound ${GetIntegrationLabelForSwitchText(integration)} Lead Activity Sync`}</ToggleText>
            </ToggleRow>
          </SettingsContainer>

          <SettingsContainer>
            <SettingsContainerTitle>Deal</SettingsContainerTitle>
            {integration === IIntegrations.HubSpot && (
              <ToggleRow>
                <Switch
                  onChange={(checked: boolean) => {
                    if (canToggle) {
                      setCurrentSettings({
                        ...currentSettings,
                        dealCreationOnSale: checked,
                      });
                    }
                  }}
                  checked={!!currentSettings?.dealCreationOnSale}
                  onColor={theme.PRIMARY500}
                  offColor={theme.NEUTRAL200}
                  height={16}
                  width={32}
                  handleDiameter={12}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  disabled={!canToggle || loading}
                />
                <ToggleText>Create Deal On Sale</ToggleText>
              </ToggleRow>
            )}
          </SettingsContainer>
        </>
      )}
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px;
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const ToggleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const ToggleText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
`;

const PageHeader = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const SettingsContainerTitle = styled(AppText)`
  font-size: 16px;
  font-weight: 600;
`;
const IntegrationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const IntegrationLogoImg = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 8px;
`;

const IntegrationName = styled(AppText)`
  font-size: 20px;
  font-weight: 600;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;
export default IntegrationSettingsPage;
