const countryCodesAll = [
  { iso2: "BD", iso3: "BGD", name: "Bangladesh", code: "880" },
  { iso2: "BE", iso3: "BEL", name: "Belgium", code: "32" },
  { iso2: "BF", iso3: "BFA", name: "Burkina Faso", code: "226" },
  { iso2: "BG", iso3: "BGR", name: "Bulgaria", code: "359" },
  { iso2: "BA", iso3: "BIH", name: "Bosnia and Herzegovina", code: "387" },
  { iso2: "BB", iso3: "BRB", name: "Barbados", code: "1246" },
  { iso2: "WF", iso3: "WLF", name: "Wallis and Futuna", code: "681" },
  { iso2: "BL", iso3: "BLM", name: "Saint Barthelemy", code: "590" },
  { iso2: "BM", iso3: "BMU", name: "Bermuda", code: "1441" },
  { iso2: "BN", iso3: "BRN", name: "Brunei", code: "673" },
  { iso2: "BO", iso3: "BOL", name: "Bolivia", code: "591" },
  { iso2: "BH", iso3: "BHR", name: "Bahrain", code: "973" },
  { iso2: "BI", iso3: "BDI", name: "Burundi", code: "257" },
  { iso2: "BJ", iso3: "BEN", name: "Benin", code: "229" },
  { iso2: "BT", iso3: "BTN", name: "Bhutan", code: "975" },
  { iso2: "JM", iso3: "JAM", name: "Jamaica", code: "1876" },
  { iso2: "BW", iso3: "BWA", name: "Botswana", code: "267" },
  { iso2: "WS", iso3: "WSM", name: "Samoa", code: "685" },
  { iso2: "BQ", iso3: "BES", name: "Bonaire, Saint Eustatius and Saba ", code: "599" },
  { iso2: "BR", iso3: "BRA", name: "Brazil", code: "55" },
  { iso2: "BS", iso3: "BHS", name: "Bahamas", code: "1242" },
  { iso2: "JE", iso3: "JEY", name: "Jersey", code: "441534" },
  { iso2: "BY", iso3: "BLR", name: "Belarus", code: "375" },
  { iso2: "BZ", iso3: "BLZ", name: "Belize", code: "501" },
  { iso2: "RU", iso3: "RUS", name: "Russia", code: "7" },
  { iso2: "RW", iso3: "RWA", name: "Rwanda", code: "250" },
  { iso2: "RS", iso3: "SRB", name: "Serbia", code: "381" },
  { iso2: "TL", iso3: "TLS", name: "East Timor", code: "670" },
  { iso2: "RE", iso3: "REU", name: "Reunion", code: "262" },
  { iso2: "TM", iso3: "TKM", name: "Turkmenistan", code: "993" },
  { iso2: "TJ", iso3: "TJK", name: "Tajikistan", code: "992" },
  { iso2: "RO", iso3: "ROU", name: "Romania", code: "40" },
  { iso2: "TK", iso3: "TKL", name: "Tokelau", code: "690" },
  { iso2: "GW", iso3: "GNB", name: "Guinea-Bissau", code: "245" },
  { iso2: "GU", iso3: "GUM", name: "Guam", code: "1671" },
  { iso2: "GT", iso3: "GTM", name: "Guatemala", code: "502" },
  { iso2: "GR", iso3: "GRC", name: "Greece", code: "30" },
  { iso2: "GQ", iso3: "GNQ", name: "Equatorial Guinea", code: "240" },
  { iso2: "GP", iso3: "GLP", name: "Guadeloupe", code: "590" },
  { iso2: "JP", iso3: "JPN", name: "Japan", code: "81" },
  { iso2: "GY", iso3: "GUY", name: "Guyana", code: "592" },
  { iso2: "GG", iso3: "GGY", name: "Guernsey", code: "441481" },
  { iso2: "GF", iso3: "GUF", name: "French Guiana", code: "594" },
  { iso2: "GE", iso3: "GEO", name: "Georgia", code: "995" },
  { iso2: "GD", iso3: "GRD", name: "Grenada", code: "1473" },
  { iso2: "GB", iso3: "GBR", name: "United Kingdom", code: "44" },
  { iso2: "GA", iso3: "GAB", name: "Gabon", code: "241" },
  { iso2: "SV", iso3: "SLV", name: "El Salvador", code: "503" },
  { iso2: "GN", iso3: "GIN", name: "Guinea", code: "224" },
  { iso2: "GM", iso3: "GMB", name: "Gambia", code: "220" },
  { iso2: "GL", iso3: "GRL", name: "Greenland", code: "299" },
  { iso2: "GI", iso3: "GIB", name: "Gibraltar", code: "350" },
  { iso2: "GH", iso3: "GHA", name: "Ghana", code: "233" },
  { iso2: "OM", iso3: "OMN", name: "Oman", code: "968" },
  { iso2: "TN", iso3: "TUN", name: "Tunisia", code: "216" },
  { iso2: "JO", iso3: "JOR", name: "Jordan", code: "962" },
  { iso2: "HR", iso3: "HRV", name: "Croatia", code: "385" },
  { iso2: "HT", iso3: "HTI", name: "Haiti", code: "509" },
  { iso2: "HU", iso3: "HUN", name: "Hungary", code: "36" },
  { iso2: "HK", iso3: "HKG", name: "Hong Kong", code: "852" },
  { iso2: "HN", iso3: "HND", name: "Honduras", code: "504" },
  { iso2: "VE", iso3: "VEN", name: "Venezuela", code: "58" },
  { iso2: "PR", iso3: "PRI", name: "Puerto Rico", code: "1939" },
  { iso2: "PR", iso3: "PRI", name: "Puerto Rico", code: "1787" },
  { iso2: "PS", iso3: "PSE", name: "Palestinian Territory", code: "970" },
  { iso2: "PW", iso3: "PLW", name: "Palau", code: "680" },
  { iso2: "PT", iso3: "PRT", name: "Portugal", code: "351" },
  { iso2: "SJ", iso3: "SJM", name: "Svalbard and Jan Mayen", code: "47" },
  { iso2: "PY", iso3: "PRY", name: "Paraguay", code: "595" },
  { iso2: "IQ", iso3: "IRQ", name: "Iraq", code: "964" },
  { iso2: "PA", iso3: "PAN", name: "Panama", code: "507" },
  { iso2: "PF", iso3: "PYF", name: "French Polynesia", code: "689" },
  { iso2: "PG", iso3: "PNG", name: "Papua New Guinea", code: "675" },
  { iso2: "PE", iso3: "PER", name: "Peru", code: "51" },
  { iso2: "PK", iso3: "PAK", name: "Pakistan", code: "92" },
  { iso2: "PH", iso3: "PHL", name: "Philippines", code: "63" },
  { iso2: "PN", iso3: "PCN", name: "Pitcairn", code: "870" },
  { iso2: "PL", iso3: "POL", name: "Poland", code: "48" },
  { iso2: "PM", iso3: "SPM", name: "Saint Pierre and Miquelon", code: "508" },
  { iso2: "ZM", iso3: "ZMB", name: "Zambia", code: "260" },
  { iso2: "EH", iso3: "ESH", name: "Western Sahara", code: "212" },
  { iso2: "EE", iso3: "EST", name: "Estonia", code: "372" },
  { iso2: "EG", iso3: "EGY", name: "Egypt", code: "20" },
  { iso2: "ZA", iso3: "ZAF", name: "South Africa", code: "27" },
  { iso2: "EC", iso3: "ECU", name: "Ecuador", code: "593" },
  { iso2: "IT", iso3: "ITA", name: "Italy", code: "39" },
  { iso2: "VN", iso3: "VNM", name: "Vietnam", code: "84" },
  { iso2: "SB", iso3: "SLB", name: "Solomon Islands", code: "677" },
  { iso2: "ET", iso3: "ETH", name: "Ethiopia", code: "251" },
  { iso2: "SO", iso3: "SOM", name: "Somalia", code: "252" },
  { iso2: "ZW", iso3: "ZWE", name: "Zimbabwe", code: "263" },
  { iso2: "SA", iso3: "SAU", name: "Saudi Arabia", code: "966" },
  { iso2: "ES", iso3: "ESP", name: "Spain", code: "34" },
  { iso2: "ER", iso3: "ERI", name: "Eritrea", code: "291" },
  { iso2: "ME", iso3: "MNE", name: "Montenegro", code: "382" },
  { iso2: "MD", iso3: "MDA", name: "Moldova", code: "373" },
  { iso2: "MG", iso3: "MDG", name: "Madagascar", code: "261" },
  { iso2: "MF", iso3: "MAF", name: "Saint Martin", code: "590" },
  { iso2: "MA", iso3: "MAR", name: "Morocco", code: "212" },
  { iso2: "MC", iso3: "MCO", name: "Monaco", code: "377" },
  { iso2: "UZ", iso3: "UZB", name: "Uzbekistan", code: "998" },
  { iso2: "MM", iso3: "MMR", name: "Myanmar", code: "95" },
  { iso2: "ML", iso3: "MLI", name: "Mali", code: "223" },
  { iso2: "MO", iso3: "MAC", name: "Macao", code: "853" },
  { iso2: "MN", iso3: "MNG", name: "Mongolia", code: "976" },
  { iso2: "MH", iso3: "MHL", name: "Marshall Islands", code: "692" },
  { iso2: "MK", iso3: "MKD", name: "Macedonia", code: "389" },
  { iso2: "MU", iso3: "MUS", name: "Mauritius", code: "230" },
  { iso2: "MT", iso3: "MLT", name: "Malta", code: "356" },
  { iso2: "MW", iso3: "MWI", name: "Malawi", code: "265" },
  { iso2: "MV", iso3: "MDV", name: "Maldives", code: "960" },
  { iso2: "MQ", iso3: "MTQ", name: "Martinique", code: "596" },
  { iso2: "MP", iso3: "MNP", name: "Northern Mariana Islands", code: "1670" },
  { iso2: "MS", iso3: "MSR", name: "Montserrat", code: "1664" },
  { iso2: "MR", iso3: "MRT", name: "Mauritania", code: "222" },
  { iso2: "IM", iso3: "IMN", name: "Isle of Man", code: "441624" },
  { iso2: "UG", iso3: "UGA", name: "Uganda", code: "256" },
  { iso2: "TZ", iso3: "TZA", name: "Tanzania", code: "255" },
  { iso2: "MY", iso3: "MYS", name: "Malaysia", code: "60" },
  { iso2: "MX", iso3: "MEX", name: "Mexico", code: "52" },
  { iso2: "IL", iso3: "ISR", name: "Israel", code: "972" },
  { iso2: "FR", iso3: "FRA", name: "France", code: "33" },
  { iso2: "IO", iso3: "IOT", name: "British Indian Ocean Territory", code: "246" },
  { iso2: "SH", iso3: "SHN", name: "Saint Helena", code: "290" },
  { iso2: "FI", iso3: "FIN", name: "Finland", code: "358" },
  { iso2: "FJ", iso3: "FJI", name: "Fiji", code: "679" },
  { iso2: "FK", iso3: "FLK", name: "Falkland Islands", code: "500" },
  { iso2: "FM", iso3: "FSM", name: "Micronesia", code: "691" },
  { iso2: "FO", iso3: "FRO", name: "Faroe Islands", code: "298" },
  { iso2: "NI", iso3: "NIC", name: "Nicaragua", code: "505" },
  { iso2: "NL", iso3: "NLD", name: "Netherlands", code: "31" },
  { iso2: "NO", iso3: "NOR", name: "Norway", code: "47" },
  { iso2: "NA", iso3: "NAM", name: "Namibia", code: "264" },
  { iso2: "VU", iso3: "VUT", name: "Vanuatu", code: "678" },
  { iso2: "NC", iso3: "NCL", name: "New Caledonia", code: "687" },
  { iso2: "NE", iso3: "NER", name: "Niger", code: "227" },
  { iso2: "NF", iso3: "NFK", name: "Norfolk Island", code: "672" },
  { iso2: "NG", iso3: "NGA", name: "Nigeria", code: "234" },
  { iso2: "NZ", iso3: "NZL", name: "New Zealand", code: "64" },
  { iso2: "NP", iso3: "NPL", name: "Nepal", code: "977" },
  { iso2: "NR", iso3: "NRU", name: "Nauru", code: "674" },
  { iso2: "NU", iso3: "NIU", name: "Niue", code: "683" },
  { iso2: "CK", iso3: "COK", name: "Cook Islands", code: "682" },
  { iso2: "CI", iso3: "CIV", name: "Ivory Coast", code: "225" },
  { iso2: "CH", iso3: "CHE", name: "Switzerland", code: "41" },
  { iso2: "CO", iso3: "COL", name: "Colombia", code: "57" },
  { iso2: "CN", iso3: "CHN", name: "China", code: "86" },
  { iso2: "CM", iso3: "CMR", name: "Cameroon", code: "237" },
  { iso2: "CL", iso3: "CHL", name: "Chile", code: "56" },
  { iso2: "CC", iso3: "CCK", name: "Cocos Islands", code: "61" },
  { iso2: "CA", iso3: "CAN", name: "Canada", code: "1" },
  { iso2: "CG", iso3: "COG", name: "Republic of the Congo", code: "242" },
  { iso2: "CF", iso3: "CAF", name: "Central African Republic", code: "236" },
  { iso2: "CD", iso3: "COD", name: "Democratic Republic of the Congo", code: "243" },
  { iso2: "CZ", iso3: "CZE", name: "Czech Republic", code: "420" },
  { iso2: "CY", iso3: "CYP", name: "Cyprus", code: "357" },
  { iso2: "CX", iso3: "CXR", name: "Christmas Island", code: "61" },
  { iso2: "CR", iso3: "CRI", name: "Costa Rica", code: "506" },
  { iso2: "CW", iso3: "CUW", name: "Curacao", code: "599" },
  { iso2: "CV", iso3: "CPV", name: "Cape Verde", code: "238" },
  { iso2: "CU", iso3: "CUB", name: "Cuba", code: "53" },
  { iso2: "SZ", iso3: "SWZ", name: "Swaziland", code: "268" },
  { iso2: "SY", iso3: "SYR", name: "Syria", code: "963" },
  { iso2: "SX", iso3: "SXM", name: "Sint Maarten", code: "599" },
  { iso2: "KG", iso3: "KGZ", name: "Kyrgyzstan", code: "996" },
  { iso2: "KE", iso3: "KEN", name: "Kenya", code: "254" },
  { iso2: "SS", iso3: "SSD", name: "South Sudan", code: "211" },
  { iso2: "SR", iso3: "SUR", name: "Suriname", code: "597" },
  { iso2: "KI", iso3: "KIR", name: "Kiribati", code: "686" },
  { iso2: "KH", iso3: "KHM", name: "Cambodia", code: "855" },
  { iso2: "KN", iso3: "KNA", name: "Saint Kitts and Nevis", code: "1869" },
  { iso2: "KM", iso3: "COM", name: "Comoros", code: "269" },
  { iso2: "ST", iso3: "STP", name: "Sao Tome and Principe", code: "239" },
  { iso2: "SK", iso3: "SVK", name: "Slovakia", code: "421" },
  { iso2: "KR", iso3: "KOR", name: "South Korea", code: "82" },
  { iso2: "SI", iso3: "SVN", name: "Slovenia", code: "386" },
  { iso2: "KP", iso3: "PRK", name: "North Korea", code: "850" },
  { iso2: "KW", iso3: "KWT", name: "Kuwait", code: "965" },
  { iso2: "SN", iso3: "SEN", name: "Senegal", code: "221" },
  { iso2: "SM", iso3: "SMR", name: "San Marino", code: "378" },
  { iso2: "SL", iso3: "SLE", name: "Sierra Leone", code: "232" },
  { iso2: "SC", iso3: "SYC", name: "Seychelles", code: "248" },
  { iso2: "KZ", iso3: "KAZ", name: "Kazakhstan", code: "7" },
  { iso2: "KY", iso3: "CYM", name: "Cayman Islands", code: "1345" },
  { iso2: "SG", iso3: "SGP", name: "Singapore", code: "65" },
  { iso2: "SE", iso3: "SWE", name: "Sweden", code: "46" },
  { iso2: "SD", iso3: "SDN", name: "Sudan", code: "249" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1809" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1829" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1849" },
  { iso2: "DM", iso3: "DMA", name: "Dominica", code: "1767" },
  { iso2: "DJ", iso3: "DJI", name: "Djibouti", code: "253" },
  { iso2: "DK", iso3: "DNK", name: "Denmark", code: "45" },
  { iso2: "VG", iso3: "VGB", name: "British Virgin Islands", code: "1284" },
  { iso2: "DE", iso3: "DEU", name: "Germany", code: "49" },
  { iso2: "YE", iso3: "YEM", name: "Yemen", code: "967" },
  { iso2: "DZ", iso3: "DZA", name: "Algeria", code: "213" },
  { iso2: "US", iso3: "USA", name: "United States", code: "1" },
  { iso2: "UY", iso3: "URY", name: "Uruguay", code: "598" },
  { iso2: "YT", iso3: "MYT", name: "Mayotte", code: "262" },
  { iso2: "UM", iso3: "UMI", name: "United States Minor Outlying Islands", code: "1" },
  { iso2: "LB", iso3: "LBN", name: "Lebanon", code: "961" },
  { iso2: "LC", iso3: "LCA", name: "Saint Lucia", code: "1758" },
  { iso2: "LA", iso3: "LAO", name: "Laos", code: "856" },
  { iso2: "TV", iso3: "TUV", name: "Tuvalu", code: "688" },
  { iso2: "TW", iso3: "TWN", name: "Taiwan", code: "886" },
  { iso2: "TT", iso3: "TTO", name: "Trinidad and Tobago", code: "1868" },
  { iso2: "TR", iso3: "TUR", name: "Turkey", code: "90" },
  { iso2: "LK", iso3: "LKA", name: "Sri Lanka", code: "94" },
  { iso2: "LI", iso3: "LIE", name: "Liechtenstein", code: "423" },
  { iso2: "LV", iso3: "LVA", name: "Latvia", code: "371" },
  { iso2: "TO", iso3: "TON", name: "Tonga", code: "676" },
  { iso2: "LT", iso3: "LTU", name: "Lithuania", code: "370" },
  { iso2: "LU", iso3: "LUX", name: "Luxembourg", code: "352" },
  { iso2: "LR", iso3: "LBR", name: "Liberia", code: "231" },
  { iso2: "LS", iso3: "LSO", name: "Lesotho", code: "266" },
  { iso2: "TH", iso3: "THA", name: "Thailand", code: "66" },
  { iso2: "TG", iso3: "TGO", name: "Togo", code: "228" },
  { iso2: "TD", iso3: "TCD", name: "Chad", code: "235" },
  { iso2: "TC", iso3: "TCA", name: "Turks and Caicos Islands", code: "1649" },
  { iso2: "LY", iso3: "LBY", name: "Libya", code: "218" },
  { iso2: "VA", iso3: "VAT", name: "Vatican", code: "379" },
  { iso2: "VC", iso3: "VCT", name: "Saint Vincent and the Grenadines", code: "1784" },
  { iso2: "AE", iso3: "ARE", name: "United Arab Emirates", code: "971" },
  { iso2: "AD", iso3: "AND", name: "Andorra", code: "376" },
  { iso2: "AG", iso3: "ATG", name: "Antigua and Barbuda", code: "1268" },
  { iso2: "AF", iso3: "AFG", name: "Afghanistan", code: "93" },
  { iso2: "AI", iso3: "AIA", name: "Anguilla", code: "1264" },
  { iso2: "VI", iso3: "VIR", name: "U.S. Virgin Islands", code: "1340" },
  { iso2: "IS", iso3: "ISL", name: "Iceland", code: "354" },
  { iso2: "IR", iso3: "IRN", name: "Iran", code: "98" },
  { iso2: "AM", iso3: "ARM", name: "Armenia", code: "374" },
  { iso2: "AL", iso3: "ALB", name: "Albania", code: "355" },
  { iso2: "AO", iso3: "AGO", name: "Angola", code: "244" },
  { iso2: "AS", iso3: "ASM", name: "American Samoa", code: "1684" },
  { iso2: "AR", iso3: "ARG", name: "Argentina", code: "54" },
  { iso2: "AU", iso3: "AUS", name: "Australia", code: "61" },
  { iso2: "AT", iso3: "AUT", name: "Austria", code: "43" },
  { iso2: "AW", iso3: "ABW", name: "Aruba", code: "297" },
  { iso2: "IN", iso3: "IND", name: "India", code: "91" },
  { iso2: "AX", iso3: "ALA", name: "Aland Islands", code: "35818" },
  { iso2: "AZ", iso3: "AZE", name: "Azerbaijan", code: "994" },
  { iso2: "IE", iso3: "IRL", name: "Ireland", code: "353" },
  { iso2: "ID", iso3: "IDN", name: "Indonesia", code: "62" },
  { iso2: "UA", iso3: "UKR", name: "Ukraine", code: "380" },
  { iso2: "QA", iso3: "QAT", name: "Qatar", code: "974" },
  { iso2: "MZ", iso3: "MOZ", name: "Mozambique", code: "258" },
  { iso2: "BY", iso3: "BLR", name: "Belarus", code: "375" },
];

export const countryCodes = [
  { iso2: "BO", iso3: "BOL", name: "Bolivia", code: "591" },
  { iso2: "BZ", iso3: "BLZ", name: "Belize", code: "501" },
  { iso2: "GT", iso3: "GTM", name: "Guatemala", code: "502" },
  { iso2: "SV", iso3: "SLV", name: "El Salvador", code: "503" },
  { iso2: "HN", iso3: "HND", name: "Honduras", code: "504" },
  { iso2: "VE", iso3: "VEN", name: "Venezuela", code: "58" },
  { iso2: "PY", iso3: "PRY", name: "Paraguay", code: "595" },
  { iso2: "PA", iso3: "PAN", name: "Panama", code: "507" },
  { iso2: "PE", iso3: "PER", name: "Peru", code: "51" },
  { iso2: "EC", iso3: "ECU", name: "Ecuador", code: "593" },
  { iso2: "MX", iso3: "MEX", name: "Mexico", code: "52" },
  { iso2: "NI", iso3: "NIC", name: "Nicaragua", code: "505" },
  { iso2: "CO", iso3: "COL", name: "Colombia", code: "57" },
  { iso2: "CL", iso3: "CHL", name: "Chile", code: "56" },
  { iso2: "CA", iso3: "CAN", name: "Canada", code: "1" },
  { iso2: "CR", iso3: "CRI", name: "Costa Rica", code: "506" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1809" },
  { iso2: "DO", iso3: "DOM", name: "Dominican Republic", code: "1829" },
  { iso2: "US", iso3: "USA", name: "United States", code: "1" },
  { iso2: "UY", iso3: "URY", name: "Uruguay", code: "598" },
  { iso2: "AR", iso3: "ARG", name: "Argentina", code: "54" },
  { iso2: "BY", iso3: "BLR", name: "Belarus", code: "375" },
];

export const sortedCountryCodesData = [
  { label: "United States (1)", value: { iso2: "US", code: "1" } },
  ...countryCodes
    ?.map((item) => ({ label: `${item.name} (${item.code})`, value: { iso2: item.iso2, code: item.code } }))
    ?.filter((item: any) => item.label !== "United States (1)")
    ?.sort((a: any, b: any) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      else return 0;
    }),
];

export const findCountryWithCode = (code: string) => {
  const country = countryCodes?.map((item) => item)?.filter((item: any) => item.iso2 === code);
  if (!country || !country.length) {
    return undefined;
  }
  return !!country[0].name ? country[0].name : undefined;
};

export const findStateCode = (name: string, country: string = "US") => {
  let data = statesUSA;
  if (country === "CA") {
    data = provincesCanada;
  }

  const state = data?.map((item) => item)?.filter((item: any) => item.state_code.toLowerCase() === name.toLowerCase());
  if (!state || !state.length) {
    return "";
  }
  return !!state[0].name ? state[0].name : "";
};

export const provincesMexico = [
  {
    id: 3456,
    name: "Aguascalientes",
    state_code: "AGU",
  },
  {
    id: 3457,
    name: "Baja California",
    state_code: "BCN",
  },
  {
    id: 3460,
    name: "Baja California Sur",
    state_code: "BCS",
  },
  {
    id: 3475,
    name: "Campeche",
    state_code: "CAM",
  },
  {
    id: 3451,
    name: "Chiapas",
    state_code: "CHP",
  },
  {
    id: 3447,
    name: "Chihuahua",
    state_code: "CHH",
  },
  {
    id: 3471,
    name: "Coahuila",
    state_code: "COA",
  },
  {
    id: 3472,
    name: "Colima",
    state_code: "COL",
  },
  {
    id: 3453,
    name: "Durango",
    state_code: "DUR",
  },
  {
    id: 3469,
    name: "Guanajuato",
    state_code: "GUA",
  },
  {
    id: 3459,
    name: "Guerrero",
    state_code: "GRO",
  },
  {
    id: 3470,
    name: "Hidalgo",
    state_code: "HID",
  },
  {
    id: 4857,
    name: "Jalisco",
    state_code: "JAL",
  },
  {
    id: 3450,
    name: "México",
    state_code: "MEX",
  },
  {
    id: 3473,
    name: "Mexico City",
    state_code: "CMX",
  },
  {
    id: 3474,
    name: "Michoacán",
    state_code: "MIC",
  },
  {
    id: 3465,
    name: "Morelos",
    state_code: "MOR",
  },
  {
    id: 3477,
    name: "Nayarit",
    state_code: "NAY",
  },
  {
    id: 3452,
    name: "Nuevo León",
    state_code: "NLE",
  },
  {
    id: 3448,
    name: "Oaxaca",
    state_code: "OAX",
  },
  {
    id: 3476,
    name: "Puebla",
    state_code: "PUE",
  },
  {
    id: 3455,
    name: "Querétaro",
    state_code: "QUE",
  },
  {
    id: 3467,
    name: "Quintana Roo",
    state_code: "ROO",
  },
  {
    id: 3461,
    name: "San Luis Potosí",
    state_code: "SLP",
  },
  {
    id: 3449,
    name: "Sinaloa",
    state_code: "SIN",
  },
  {
    id: 3468,
    name: "Sonora",
    state_code: "SON",
  },
  {
    id: 3454,
    name: "Tabasco",
    state_code: "TAB",
  },
  {
    id: 3463,
    name: "Tamaulipas",
    state_code: "TAM",
  },
  {
    id: 3458,
    name: "Tlaxcala",
    state_code: "TLA",
  },
  {
    id: 3464,
    name: "Veracruz",
    state_code: "VER",
  },
  {
    id: 3466,
    name: "Yucatán",
    state_code: "YUC",
  },
  {
    id: 3462,
    name: "Zacatecas",
    state_code: "ZAC",
  },
];

export const provincesBrazil = [
  {
    id: 2012,
    name: "Acre",
    state_code: "AC",
  },
  {
    id: 2007,
    name: "Alagoas",
    state_code: "AL",
  },
  {
    id: 1999,
    name: "Amapá",
    state_code: "AP",
  },
  {
    id: 2004,
    name: "Amazonas",
    state_code: "AM",
  },
  {
    id: 2002,
    name: "Bahia",
    state_code: "BA",
  },
  {
    id: 2016,
    name: "Ceará",
    state_code: "CE",
  },
  {
    id: 2018,
    name: "Espírito Santo",
    state_code: "ES",
  },
  {
    id: 2017,
    name: "Federal District",
    state_code: "DF",
  },
  {
    id: 2000,
    name: "Goiás",
    state_code: "GO",
  },
  {
    id: 2015,
    name: "Maranhão",
    state_code: "MA",
  },
  {
    id: 2011,
    name: "Mato Grosso",
    state_code: "MT",
  },
  {
    id: 2010,
    name: "Mato Grosso do Sul",
    state_code: "MS",
  },
  {
    id: 1998,
    name: "Minas Gerais",
    state_code: "MG",
  },
  {
    id: 2009,
    name: "Pará",
    state_code: "PA",
  },
  {
    id: 2005,
    name: "Paraíba",
    state_code: "PB",
  },
  {
    id: 2022,
    name: "Paraná",
    state_code: "PR",
  },
  {
    id: 2006,
    name: "Pernambuco",
    state_code: "PE",
  },
  {
    id: 2008,
    name: "Piauí",
    state_code: "PI",
  },
  {
    id: 1997,
    name: "Rio de Janeiro",
    state_code: "RJ",
  },
  {
    id: 2019,
    name: "Rio Grande do Norte",
    state_code: "RN",
  },
  {
    id: 2001,
    name: "Rio Grande do Sul",
    state_code: "RS",
  },
  {
    id: 2013,
    name: "Rondônia",
    state_code: "RO",
  },
  {
    id: 4858,
    name: "Roraima",
    state_code: "RR",
  },
  {
    id: 2014,
    name: "Santa Catarina",
    state_code: "SC",
  },
  {
    id: 2021,
    name: "São Paulo",
    state_code: "SP",
  },
  {
    id: 2003,
    name: "Sergipe",
    state_code: "SE",
  },
  {
    id: 2020,
    name: "Tocantins",
    state_code: "TO",
  },
];

export const provincesCanada = [
  { id: 231, name: "British Columbia", state_code: "BC" },
  { id: 232, name: "Alberta", state_code: "AB" },
  { id: 233, name: "Northwest Territories", state_code: "NT" },
  { id: 234, name: "Yukon", state_code: "YT" },
  { id: 235, name: "Manitoba", state_code: "MB" },
  { id: 236, name: "Saskatchewan", state_code: "SK" },
  { id: 237, name: "Ontario", state_code: "ON" },
  { id: 238, name: "Quebec", state_code: "QC" },
  { id: 239, name: "New Brunswick", state_code: "NB" },
  { id: 240, name: "Nova Scotia", state_code: "NS" },
  { id: 241, name: "Newfoundland", state_code: "NF" },
];

export const statesUSA = [
  {
    id: 1456,
    name: "Alabama",
    state_code: "AL",
  },
  {
    id: 1400,
    name: "Alaska",
    state_code: "AK",
  },
  {
    id: 1424,
    name: "American Samoa",
    state_code: "AS",
  },
  {
    id: 1434,
    name: "Arizona",
    state_code: "AZ",
  },
  {
    id: 1444,
    name: "Arkansas",
    state_code: "AR",
  },
  {
    id: 1402,
    name: "Baker Island",
    state_code: "UM-81",
  },
  {
    id: 1416,
    name: "California",
    state_code: "CA",
  },
  {
    id: 1450,
    name: "Colorado",
    state_code: "CO",
  },
  {
    id: 1435,
    name: "Connecticut",
    state_code: "CT",
  },
  {
    id: 1399,
    name: "Delaware",
    state_code: "DE",
  },
  {
    id: 1437,
    name: "District of Columbia",
    state_code: "DC",
  },
  {
    id: 1436,
    name: "Florida",
    state_code: "FL",
  },
  {
    id: 1455,
    name: "Georgia",
    state_code: "GA",
  },
  {
    id: 1412,
    name: "Guam",
    state_code: "GU",
  },
  {
    id: 1411,
    name: "Hawaii",
    state_code: "HI",
  },
  {
    id: 1398,
    name: "Howland Island",
    state_code: "UM-84",
  },
  {
    id: 1460,
    name: "Idaho",
    state_code: "ID",
  },
  {
    id: 1425,
    name: "Illinois",
    state_code: "IL",
  },
  {
    id: 1440,
    name: "Indiana",
    state_code: "IN",
  },
  {
    id: 1459,
    name: "Iowa",
    state_code: "IA",
  },
  {
    id: 1410,
    name: "Jarvis Island",
    state_code: "UM-86",
  },
  {
    id: 1428,
    name: "Johnston Atoll",
    state_code: "UM-67",
  },
  {
    id: 1406,
    name: "Kansas",
    state_code: "KS",
  },
  {
    id: 1419,
    name: "Kentucky",
    state_code: "KY",
  },
  {
    id: 1403,
    name: "Kingman Reef",
    state_code: "UM-89",
  },
  {
    id: 1457,
    name: "Louisiana",
    state_code: "LA",
  },
  {
    id: 1453,
    name: "Maine",
    state_code: "ME",
  },
  {
    id: 1401,
    name: "Maryland",
    state_code: "MD",
  },
  {
    id: 1433,
    name: "Massachusetts",
    state_code: "MA",
  },
  {
    id: 1426,
    name: "Michigan",
    state_code: "MI",
  },
  {
    id: 1438,
    name: "Midway Atoll",
    state_code: "UM-71",
  },
  {
    id: 1420,
    name: "Minnesota",
    state_code: "MN",
  },
  {
    id: 1430,
    name: "Mississippi",
    state_code: "MS",
  },
  {
    id: 1451,
    name: "Missouri",
    state_code: "MO",
  },
  {
    id: 1446,
    name: "Montana",
    state_code: "MT",
  },
  {
    id: 1439,
    name: "Navassa Island",
    state_code: "UM-76",
  },
  {
    id: 1408,
    name: "Nebraska",
    state_code: "NE",
  },
  {
    id: 1458,
    name: "Nevada",
    state_code: "NV",
  },
  {
    id: 1404,
    name: "New Hampshire",
    state_code: "NH",
  },
  {
    id: 1417,
    name: "New Jersey",
    state_code: "NJ",
  },
  {
    id: 1423,
    name: "New Mexico",
    state_code: "NM",
  },
  {
    id: 1452,
    name: "New York",
    state_code: "NY",
  },
  {
    id: 1447,
    name: "North Carolina",
    state_code: "NC",
  },
  {
    id: 1418,
    name: "North Dakota",
    state_code: "ND",
  },
  {
    id: 1431,
    name: "Northern Mariana Islands",
    state_code: "MP",
  },
  {
    id: 4851,
    name: "Ohio",
    state_code: "OH",
  },
  {
    id: 1421,
    name: "Oklahoma",
    state_code: "OK",
  },
  {
    id: 1415,
    name: "Oregon",
    state_code: "OR",
  },
  {
    id: 1448,
    name: "Palmyra Atoll",
    state_code: "UM-95",
  },
  {
    id: 1422,
    name: "Pennsylvania",
    state_code: "PA",
  },
  {
    id: 1449,
    name: "Puerto Rico",
    state_code: "PR",
  },
  {
    id: 1461,
    name: "Rhode Island",
    state_code: "RI",
  },
  {
    id: 1443,
    name: "South Carolina",
    state_code: "SC",
  },
  {
    id: 1445,
    name: "South Dakota",
    state_code: "SD",
  },
  {
    id: 1454,
    name: "Tennessee",
    state_code: "TN",
  },
  {
    id: 1407,
    name: "Texas",
    state_code: "TX",
  },
  {
    id: 1432,
    name: "United States Minor Outlying Islands",
    state_code: "UM",
  },
  {
    id: 1413,
    name: "United States Virgin Islands",
    state_code: "VI",
  },
  {
    id: 1414,
    name: "Utah",
    state_code: "UT",
  },
  {
    id: 1409,
    name: "Vermont",
    state_code: "VT",
  },
  {
    id: 1427,
    name: "Virginia",
    state_code: "VA",
  },
  {
    id: 1405,
    name: "Wake Island",
    state_code: "UM-79",
  },
  {
    id: 1462,
    name: "Washington",
    state_code: "WA",
  },
  {
    id: 1429,
    name: "West Virginia",
    state_code: "WV",
  },
  {
    id: 1441,
    name: "Wisconsin",
    state_code: "WI",
  },
  {
    id: 1442,
    name: "Wyoming",
    state_code: "WY",
  },
];

export const statesUSAWITHProvincesCanada = [
  {
    id: 1456,
    name: "Alabama",
    state_code: "AL",
  },
  {
    id: 1400,
    name: "Alaska",
    state_code: "AK",
  },
  {
    id: 232,
    name: "Alberta",
    state_code: "AB",
  },
  {
    id: 1424,
    name: "American Samoa",
    state_code: "AS",
  },
  {
    id: 1434,
    name: "Arizona",
    state_code: "AZ",
  },
  {
    id: 1444,
    name: "Arkansas",
    state_code: "AR",
  },
  {
    id: 1402,
    name: "Baker Island",
    state_code: "UM-81",
  },
  {
    id: 231,
    name: "British Columbia",
    state_code: "BC",
  },
  {
    id: 1416,
    name: "California",
    state_code: "CA",
  },
  {
    id: 1450,
    name: "Colorado",
    state_code: "CO",
  },
  {
    id: 1435,
    name: "Connecticut",
    state_code: "CT",
  },
  {
    id: 1399,
    name: "Delaware",
    state_code: "DE",
  },
  {
    id: 1437,
    name: "District of Columbia",
    state_code: "DC",
  },
  {
    id: 1436,
    name: "Florida",
    state_code: "FL",
  },
  {
    id: 1455,
    name: "Georgia",
    state_code: "GA",
  },
  {
    id: 1412,
    name: "Guam",
    state_code: "GU",
  },
  {
    id: 1411,
    name: "Hawaii",
    state_code: "HI",
  },
  {
    id: 1398,
    name: "Howland Island",
    state_code: "UM-84",
  },
  {
    id: 1460,
    name: "Idaho",
    state_code: "ID",
  },
  {
    id: 1425,
    name: "Illinois",
    state_code: "IL",
  },
  {
    id: 1440,
    name: "Indiana",
    state_code: "IN",
  },
  {
    id: 1459,
    name: "Iowa",
    state_code: "IA",
  },
  {
    id: 1410,
    name: "Jarvis Island",
    state_code: "UM-86",
  },
  {
    id: 1428,
    name: "Johnston Atoll",
    state_code: "UM-67",
  },
  {
    id: 1406,
    name: "Kansas",
    state_code: "KS",
  },
  {
    id: 1419,
    name: "Kentucky",
    state_code: "KY",
  },
  {
    id: 1403,
    name: "Kingman Reef",
    state_code: "UM-89",
  },
  {
    id: 1457,
    name: "Louisiana",
    state_code: "LA",
  },
  {
    id: 1453,
    name: "Maine",
    state_code: "ME",
  },
  {
    id: 235,
    name: "Manitoba",
    state_code: "MB",
  },
  {
    id: 1401,
    name: "Maryland",
    state_code: "MD",
  },
  {
    id: 1433,
    name: "Massachusetts",
    state_code: "MA",
  },
  {
    id: 1426,
    name: "Michigan",
    state_code: "MI",
  },
  {
    id: 1438,
    name: "Midway Atoll",
    state_code: "UM-71",
  },
  {
    id: 1420,
    name: "Minnesota",
    state_code: "MN",
  },
  {
    id: 1430,
    name: "Mississippi",
    state_code: "MS",
  },
  {
    id: 1451,
    name: "Missouri",
    state_code: "MO",
  },
  {
    id: 1446,
    name: "Montana",
    state_code: "MT",
  },
  {
    id: 1439,
    name: "Navassa Island",
    state_code: "UM-76",
  },
  {
    id: 1408,
    name: "Nebraska",
    state_code: "NE",
  },
  {
    id: 1458,
    name: "Nevada",
    state_code: "NV",
  },
  {
    id: 241,
    name: "Newfoundland",
    state_code: "NF",
  },
  {
    id: 239,
    name: "New Brunswick",
    state_code: "NB",
  },
  {
    id: 1404,
    name: "New Hampshire",
    state_code: "NH",
  },
  {
    id: 1417,
    name: "New Jersey",
    state_code: "NJ",
  },
  {
    id: 1423,
    name: "New Mexico",
    state_code: "NM",
  },
  {
    id: 1452,
    name: "New York",
    state_code: "NY",
  },
  {
    id: 1447,
    name: "North Carolina",
    state_code: "NC",
  },
  {
    id: 1418,
    name: "North Dakota",
    state_code: "ND",
  },
  {
    id: 1431,
    name: "Northern Mariana Islands",
    state_code: "MP",
  },
  {
    id: 233,
    name: "Northwest Territories",
    state_code: "NT",
  },
  {
    id: 240,
    name: "Nova Scotia",
    state_code: "NS",
  },
  {
    id: 4851,
    name: "Ohio",
    state_code: "OH",
  },
  {
    id: 1421,
    name: "Oklahoma",
    state_code: "OK",
  },
  {
    id: 237,
    name: "Ontario",
    state_code: "ON",
  },
  {
    id: 1415,
    name: "Oregon",
    state_code: "OR",
  },
  {
    id: 1448,
    name: "Palmyra Atoll",
    state_code: "UM-95",
  },
  {
    id: 1422,
    name: "Pennsylvania",
    state_code: "PA",
  },
  {
    id: 1449,
    name: "Puerto Rico",
    state_code: "PR",
  },
  {
    id: 238,
    name: "Quebec",
    state_code: "QC",
  },
  {
    id: 1461,
    name: "Rhode Island",
    state_code: "RI",
  },
  {
    id: 236,
    name: "Saskatchewan",
    state_code: "SK",
  },
  {
    id: 1443,
    name: "South Carolina",
    state_code: "SC",
  },
  {
    id: 1445,
    name: "South Dakota",
    state_code: "SD",
  },
  {
    id: 1454,
    name: "Tennessee",
    state_code: "TN",
  },
  {
    id: 1407,
    name: "Texas",
    state_code: "TX",
  },
  {
    id: 1432,
    name: "United States Minor Outlying Islands",
    state_code: "UM",
  },
  {
    id: 1413,
    name: "United States Virgin Islands",
    state_code: "VI",
  },
  {
    id: 1414,
    name: "Utah",
    state_code: "UT",
  },
  {
    id: 1409,
    name: "Vermont",
    state_code: "VT",
  },
  {
    id: 1427,
    name: "Virginia",
    state_code: "VA",
  },
  {
    id: 1405,
    name: "Wake Island",
    state_code: "UM-79",
  },
  {
    id: 1462,
    name: "Washington",
    state_code: "WA",
  },
  {
    id: 1429,
    name: "West Virginia",
    state_code: "WV",
  },
  {
    id: 1441,
    name: "Wisconsin",
    state_code: "WI",
  },
  {
    id: 1442,
    name: "Wyoming",
    state_code: "WY",
  },
  {
    id: 234,
    name: "Yukon",
    state_code: "YT",
  },
];

export const provincesBrazilWITHProvincesMexico = [
  {
    id: 2012,
    name: "Acre",
    state_code: "AC",
  },
  {
    id: 3456,
    name: "Aguascalientes",
    state_code: "AGU",
  },
  {
    id: 2007,
    name: "Alagoas",
    state_code: "AL",
  },
  {
    id: 1999,
    name: "Amapá",
    state_code: "AP",
  },
  {
    id: 2004,
    name: "Amazonas",
    state_code: "AM",
  },
  {
    id: 2002,
    name: "Bahia",
    state_code: "BA",
  },
  {
    id: 3457,
    name: "Baja California",
    state_code: "BCN",
  },
  {
    id: 3460,
    name: "Baja California Sur",
    state_code: "BCS",
  },
  {
    id: 3475,
    name: "Campeche",
    state_code: "CAM",
  },
  {
    id: 2016,
    name: "Ceará",
    state_code: "CE",
  },
  {
    id: 3451,
    name: "Chiapas",
    state_code: "CHP",
  },
  {
    id: 3447,
    name: "Chihuahua",
    state_code: "CHH",
  },
  {
    id: 3471,
    name: "Coahuila",
    state_code: "COA",
  },
  {
    id: 3472,
    name: "Colima",
    state_code: "COL",
  },
  {
    id: 3453,
    name: "Durango",
    state_code: "DUR",
  },
  {
    id: 2018,
    name: "Espírito Santo",
    state_code: "ES",
  },
  {
    id: 2017,
    name: "Federal District",
    state_code: "DF",
  },
  {
    id: 2000,
    name: "Goiás",
    state_code: "GO",
  },
  {
    id: 3469,
    name: "Guanajuato",
    state_code: "GUA",
  },
  {
    id: 3459,
    name: "Guerrero",
    state_code: "GRO",
  },
  {
    id: 3470,
    name: "Hidalgo",
    state_code: "HID",
  },
  {
    id: 4857,
    name: "Jalisco",
    state_code: "JAL",
  },
  {
    id: 2015,
    name: "Maranhão",
    state_code: "MA",
  },
  {
    id: 2011,
    name: "Mato Grosso",
    state_code: "MT",
  },
  {
    id: 2010,
    name: "Mato Grosso do Sul",
    state_code: "MS",
  },
  {
    id: 3473,
    name: "Mexico City",
    state_code: "CMX",
  },
  {
    id: 3474,
    name: "Michoacán",
    state_code: "MIC",
  },
  {
    id: 1998,
    name: "Minas Gerais",
    state_code: "MG",
  },
  {
    id: 3465,
    name: "Morelos",
    state_code: "MOR",
  },
  {
    id: 3450,
    name: "México",
    state_code: "MEX",
  },
  {
    id: 3477,
    name: "Nayarit",
    state_code: "NAY",
  },
  {
    id: 3452,
    name: "Nuevo León",
    state_code: "NLE",
  },
  {
    id: 3448,
    name: "Oaxaca",
    state_code: "OAX",
  },
  {
    id: 2022,
    name: "Paraná",
    state_code: "PR",
  },
  {
    id: 2005,
    name: "Paraíba",
    state_code: "PB",
  },
  {
    id: 2009,
    name: "Pará",
    state_code: "PA",
  },
  {
    id: 2006,
    name: "Pernambuco",
    state_code: "PE",
  },
  {
    id: 2008,
    name: "Piauí",
    state_code: "PI",
  },
  {
    id: 3476,
    name: "Puebla",
    state_code: "PUE",
  },
  {
    id: 3455,
    name: "Querétaro",
    state_code: "QUE",
  },
  {
    id: 3467,
    name: "Quintana Roo",
    state_code: "ROO",
  },
  {
    id: 1997,
    name: "Rio de Janeiro",
    state_code: "RJ",
  },
  {
    id: 2019,
    name: "Rio Grande do Norte",
    state_code: "RN",
  },
  {
    id: 2001,
    name: "Rio Grande do Sul",
    state_code: "RS",
  },
  {
    id: 2013,
    name: "Rondônia",
    state_code: "RO",
  },
  {
    id: 4858,
    name: "Roraima",
    state_code: "RR",
  },
  {
    id: 3461,
    name: "San Luis Potosí",
    state_code: "SLP",
  },
  {
    id: 2014,
    name: "Santa Catarina",
    state_code: "SC",
  },
  {
    id: 2003,
    name: "Sergipe",
    state_code: "SE",
  },
  {
    id: 3449,
    name: "Sinaloa",
    state_code: "SIN",
  },
  {
    id: 3468,
    name: "Sonora",
    state_code: "SON",
  },
  {
    id: 2021,
    name: "São Paulo",
    state_code: "SP",
  },
  {
    id: 3454,
    name: "Tabasco",
    state_code: "TAB",
  },
  {
    id: 3463,
    name: "Tamaulipas",
    state_code: "TAM",
  },
  {
    id: 3458,
    name: "Tlaxcala",
    state_code: "TLA",
  },
  {
    id: 2020,
    name: "Tocantins",
    state_code: "TO",
  },
  {
    id: 3464,
    name: "Veracruz",
    state_code: "VER",
  },
  {
    id: 3466,
    name: "Yucatán",
    state_code: "YUC",
  },
  {
    id: 3462,
    name: "Zacatecas",
    state_code: "ZAC",
  },
];

export const listUSA = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "Baker Island",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Howland Island",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Jarvis Island",
  "Johnston Atoll",
  "Kansas",
  "Kentucky",
  "Kingman Reef",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Midway Atoll",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Navassa Island",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palmyra Atoll",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "United States Minor Outlying Islands",
  "United States Virgin Islands",
  "Utah",
  "Vermont",
  "Virginia",
  "Wake Island",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const listBrazil = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Espírito Santo",
  "Federal District",
  "Goiás",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Pará",
  "Paraíba",
  "Paraná",
  "Pernambuco",
  "Piauí",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rondônia",
  "Roraima",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins",
];

export const listMexico = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Coahuila",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Mexico City",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

export const allStatesAndProvinces = [...statesUSA, ...provincesCanada, ...provincesMexico, ...provincesBrazil];
export const allStatesAndProvincesSelectOptions = [
  ...statesUSAWITHProvincesCanada,
  ...provincesBrazilWITHProvincesMexico,
]?.map((state) => ({
  label: state.name,
  value: state.state_code,
}));
