import { Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { theme } from "../../../../utils/theme";
import { AppTitle, FlexDiv, NewAppButton, AppBackButton, AppText, AppTitle2, NewAppSelect } from "../../../UI";
import * as Yup from "yup";
import { FormSelectField, InputField, NewSelectField } from "../../../Field";
import styled from "styled-components";
import Widget from "./Widget";
import { useQuery, gql, useMutation } from "@apollo/client";
import { successToast, appToast } from "../../../../utils/toast";
import { FONT_OPTIONS } from "../../../../utils/variables";
interface CustomizeWidgetProps {
  togglePageTitle: () => React.SetStateAction<void>;
}

const GET_INBOUND_CONCIERGE_WIDGET_SETTINGS = gql`
  query getInboundConciergeWidgetSettings {
    getInboundConciergeWidgetSettings {
      id
      created_at
      updated_at
      brand_name
      header_background_color
      header_text_color
      button_color
      button_text_color
      font
    }
  }
`;

// const setInboundConciergeWidgetSettings

const SET_INBOUND_CONCIERGE_WIDGET_SETTINGS = gql`
  mutation setInboundConciergeWidgetSettings(
    $brand_name: String
    $header_background_color: String
    $header_text_color: String
    $button_color: String
    $button_text_color: String
    $font: String
  ) {
    setInboundConciergeWidgetSettings(
      brand_name: $brand_name
      header_background_color: $header_background_color
      header_text_color: $header_text_color
      button_color: $button_color
      button_text_color: $button_text_color
      font: $font
    )
    #return type is boolean
  }
`;

interface FormValues {
  brandName: string;
  headerBackgroundColor: string;
  headerTextColor: { label: string; value: string };
  buttonColor: string;
  buttonTextColor: { label: string; value: string };
  font: { label: string; value: string };
}

const widgetSchema = Yup.object().shape({
  brandName: Yup.string().required("Brand Name is required"),
  headerBackgroundColor: Yup.string().required("Header Background Color is required"),
  headerTextColor: Yup.string().required("Header Text Color is required"),
  buttonColor: Yup.string().required("Button Color is required"),
  buttonTextColor: Yup.string().required("Button Text Color is required"),
  font: Yup.string().required("Font is required"),
});

const COLOR_OPTIONS = [
  { label: "White", value: "#ffffff" },
  { label: "Black", value: "#000000" },
];

// all Fonts should have a fallback font

const CustomizeWidget: React.FC<CustomizeWidgetProps> = ({ togglePageTitle }) => {
  const history = useHistory();

  useEffect(() => {
    togglePageTitle();
  }, []);

  const handleSubmit = (values: FormValues) => {
    console.log("values", values);
    setInboundConciergeWidgetSettings({
      variables: {
        brand_name: values.brandName,
        header_background_color: values.headerBackgroundColor,
        header_text_color: values.headerTextColor,
        button_color: values.buttonColor,
        button_text_color: values.buttonTextColor,
        font: values.font,
      },
    });
  };

  const { data: customizationOptions, loading, error, refetch: refetchCustomizationOptions } = useQuery(
    GET_INBOUND_CONCIERGE_WIDGET_SETTINGS,
    {
      fetchPolicy: "cache-and-network",

      onCompleted: (data) => {},
      onError({ message }) {
        appToast(`${message}`);

        console.log("Error ", message);
      },
    },
  );

  const [setInboundConciergeWidgetSettings, { data, loading: mutationLoading, error: mutationError }] = useMutation(
    SET_INBOUND_CONCIERGE_WIDGET_SETTINGS,
    {
      onCompleted: (data) => {
        if (data) {
          successToast("Customization options saved successfully");
          refetchCustomizationOptions();
        }
      },
      onError({ message }) {
        appToast(`${message}`);

        console.log("Error ", message);
      },
    },
  );

  const WidgetContainerDiv = () => {
    const { values } = useFormikContext<FormValues>();
    return (
      <WidgetContainer className="widget_container">
        <Widget
          isMini={false}
          customizationOptions={
            {
              brand_name: values.brandName,
              header_background_color: values.headerBackgroundColor,
              header_text_color: values.headerTextColor,
              button_color: values.buttonColor,
              button_text_color: values.buttonTextColor,
              font: values.font,
            } as any
          }
        />
      </WidgetContainer>
    );
  };

  return (
    <Formik
      initialValues={{
        brandName: customizationOptions?.getInboundConciergeWidgetSettings?.brand_name,
        headerBackgroundColor: customizationOptions?.getInboundConciergeWidgetSettings?.header_background_color,
        headerTextColor: customizationOptions?.getInboundConciergeWidgetSettings?.header_text_color,
        buttonColor: customizationOptions?.getInboundConciergeWidgetSettings?.button_color,
        buttonTextColor: customizationOptions?.getInboundConciergeWidgetSettings?.button_text_color,
        font: customizationOptions?.getInboundConciergeWidgetSettings?.font,
      }}
      validationSchema={widgetSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <FlexDiv justify="space-between" gap={20}>
            <AppBackButton to="/system-config/inbound-concierge" />
            <NewAppButton
              variant="attention"
              type="button"
              width={188}
              height={48}
              onClick={() => {
                // update the values
                resetForm({
                  values: {
                    brandName: customizationOptions?.getInboundConciergeWidgetSettings?.brand_name,
                    headerBackgroundColor:
                      customizationOptions?.getInboundConciergeWidgetSettings?.header_background_color,
                    headerTextColor: customizationOptions?.getInboundConciergeWidgetSettings?.header_text_color,
                    buttonColor: customizationOptions?.getInboundConciergeWidgetSettings?.button_color,
                    buttonTextColor: customizationOptions?.getInboundConciergeWidgetSettings?.button_text_color,
                    font: customizationOptions?.getInboundConciergeWidgetSettings?.font,
                  },
                });
              }}
            >
              Reset Changes
            </NewAppButton>
          </FlexDiv>
          <FlexDiv direction="column" gap={16}>
            <AppTitle>Custom-Branded OPSIQ Booking Widget</AppTitle>
            <AppText>
              Adjust settings below in order to customize OPSIQ’s booking widget to better reflect your brand. Once you
              save your changes, be sure to copy the updated code over to your site.
            </AppText>
          </FlexDiv>

          <ContentGrid>
            <div>
              <FlexDiv direction="column" gap={16} style={{ marginBottom: 16 }}>
                <AppTitle2>Widget Customization</AppTitle2>
                <AppText>
                  Customize OPSIQ’s booking widget using the fields below and preview them in real time in the widget
                  preview area.
                </AppText>
              </FlexDiv>

              <Label>Brand Name</Label>
              <InputField name="brandName" noTopMargin />

              <Label>Header Background Color</Label>
              <FlexDiv gap={8}>
                <InputField name="headerBackgroundColor" noTopMargin />
                <ColorField name="headerBackgroundColor" type="color" marginBottom={2} />
              </FlexDiv>

              <Label>Header Text Color</Label>

              <NewSelectField name="headerTextColor" options={COLOR_OPTIONS} />

              <Label>Button Background Color</Label>
              <FlexDiv gap={8}>
                <InputField name="buttonColor" noTopMargin />
                <ColorField name="buttonColor" type="color" marginBottom={2} />
              </FlexDiv>

              <Label>Button Text Color</Label>

              <NewSelectField name="buttonTextColor" options={COLOR_OPTIONS} />

              <Label>Font</Label>
              <NewSelectField name="font" options={FONT_OPTIONS} />

              <FlexDiv gap={16} style={{ marginTop: 20 }}>
                <NewAppButton
                  variant="attention"
                  width={188}
                  height={48}
                  onClick={() => history.push("/system-config/inbound-concierge")}
                >
                  Cancel
                </NewAppButton>
                <NewAppButton
                  type="submit"
                  variant="primary"
                  width={188}
                  height={48}
                  style={{ letterSpacing: 1, fontWeight: 500 }}
                >
                  SAVE CHANGES
                </NewAppButton>
              </FlexDiv>
            </div>
            <FlexDiv direction="column" align="center" gap={20}>
              <AppTitle2>Widget Preview</AppTitle2>
              <WidgetContainerDiv />
            </FlexDiv>
          </ContentGrid>
        </Form>
      )}
    </Formik>
  );
};

const WidgetContainer = styled.div`
  width: 400px;
  height: fit-content;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;
const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  /* margin-top: 8px; */
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 500;
  font-size: 12px;
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 170px;
  margin-top: 40px;
`;

export const ColorField = styled(Field)<{ marginBottom?: number }>`
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  border: none;
  background: none;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : "0px")};

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::-webkit-color-swatch {
    border-color: ${theme.NEUTRAL100};
    border-radius: 4px;
  }
`;

const TempPreview = styled.div`
  width: 360px;
  height: 416px;
  background-color: ${theme.PRIMARY500};
`;

export default CustomizeWidget;
