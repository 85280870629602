import React from "react";
import styled from "styled-components";
import { theme } from "../../../utils/theme";
import { PhoenixIcon } from "./PhoenixIcon";
import { check_thick } from "../../../images/NewDesign";

interface PhoenixCheckboxProps {
  checked: boolean | undefined;
  onChange?: () => void;
  disabled?: boolean;
}

export const PhoenixCheckbox: React.FC<PhoenixCheckboxProps> = ({ checked, onChange, disabled = false, ...props }) => {
  const handleClick = () => {
    if (disabled) return;
    onChange !== undefined && onChange();
  };
  return (
    <Checkbox checked={checked} onClick={handleClick} disabled={disabled}>
      {checked && <PhoenixIcon svg={check_thick} variant="white" size={12} pointer />}
    </Checkbox>
  );
};

interface CheckboxProps {
  checked: boolean | undefined;
  disabled?: boolean;
}

const Checkbox = styled.div<CheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
  margin-right: 8px;

  border: 1px solid;
  border-color: ${(props) =>
    props.checked
      ? props.disabled
        ? theme.PRIMARY400
        : theme.PRIMARY500
      : props.disabled
      ? theme.NEUTRAL200
      : theme.NEUTRAL300};
  border-radius: 4px;
  cursor: ${(props) => !props.disabled && "pointer"};

  background-color: ${(props) =>
    props.checked
      ? props.disabled
        ? theme.PRIMARY400
        : theme.PRIMARY500
      : props.disabled
      ? theme.NEUTRAL100
      : theme.WHITE_COLOR};
`;
