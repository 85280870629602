import React from "react";
import { CustomField as CustomFieldT } from "src/utils/misc";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { loggedInUser } from "src/apollo/cache";
import { AppText, FlexDiv } from "src/Components/UI";
import { PhoenixCheckbox } from "src/Components/UI/Phoenix";
import { MultiDropdownField } from "./MultiDropdownField";
import { MultiTextField } from "./MultiTextField";
import { DateTimePickerWrapper, EditTextInput, FieldWrapper, TitleLabel } from "./shared";
import { NewSelectField } from "../NewSelectField";

type CustomFieldProps = {
  customField: CustomFieldT;
  disabled?: boolean;
  fieldName: string;
  index: number;
  label?: string;
  setFieldValue?: (field: string, value: any) => void;
  values: any;
};

const CustomField = ({ customField, setFieldValue, values, fieldName, label, index, disabled }: CustomFieldProps) => {
  if (customField.type === "Rate" || customField.type === "Percentage" || customField.type === "Number") {
    return (
      <FieldWrapper key={customField?.id}>
        <TitleLabel>{label ?? customField.key}</TitleLabel>
        <EditTextInput
          name={`${fieldName}[${index}].num_value`}
          percentage={customField.type === "Percentage"}
          disabled={disabled ?? !customField.allow_reps_to_edit}
        />
      </FieldWrapper>
    );
  }
  if (customField.type === "Boolean") {
    return (
      <FlexDiv key={customField?.id} gap={8} style={{ marginBottom: 16, marginTop: 16 }}>
        <PhoenixCheckbox
          checked={!!values[fieldName][index]?.boo_value}
          disabled={disabled ?? !customField.allow_reps_to_edit}
          onChange={() => {
            setFieldValue?.(`${fieldName}[${index}].boo_value`, !values[fieldName][index]?.boo_value);
          }}
        />
        <AppText fontSize={12} fontWeight={500} lineHeight={18}>
          {label ?? customField.key}
        </AppText>
      </FlexDiv>
    );
  }
  if (customField.type === "Date") {
    return (
      <FieldWrapper key={customField?.id} style={{ marginBottom: 16 }}>
        <TitleLabel>{label ?? customField.key}</TitleLabel>
        <DateTimePickerWrapper>
          <DateTimePicker
            disabled={disabled ?? !customField.allow_reps_to_edit}
            // disableClock={true}
            onChange={(date) => {
              const dateMoment = moment(date);
              const userTimezone = loggedInUser()?.timezone ?? "America/New_York";
              const finalDate = dateMoment.tz(userTimezone).toISOString();
              setFieldValue?.(`${fieldName}[${index}].date_value`, finalDate);
            }}
            value={
              values[fieldName][index]?.date_value ? moment(values[fieldName][index]?.date_value).toDate() : undefined
            }
          />
        </DateTimePickerWrapper>
      </FieldWrapper>
    );
  }
  if (customField.type === "MultiDropdown") {
    return (
      <MultiDropdownField
        cf_key={customField?.key}
        disabled={disabled ?? !customField.allow_reps_to_edit}
        fieldName={fieldName}
        id={customField?.id}
        index={index}
        key={customField?.id}
        label={label}
        options={customField?.options?.map((item) => ({ label: item, value: item })) || []}
        setFieldValue={setFieldValue}
        value={values[fieldName][index]?.list_value}
      />
    );
  }

  if (customField.type === "MultiText") {
    return (
      <MultiTextField
        id={customField?.id}
        cf_key={customField?.key}
        disabled={disabled ?? !customField.allow_reps_to_edit}
        fieldName={fieldName}
        key={customField?.id}
        index={index}
        setFieldValue={setFieldValue}
        value={values[fieldName][index]?.list_value}
        label={label}
      />
    );
  }
  if (customField.type !== "Dropdown") {
    return (
      <FieldWrapper key={customField?.id}>
        <TitleLabel>{label ?? customField.key}</TitleLabel>
        <EditTextInput name={`${fieldName}[${index}].value`} disabled={disabled ?? !customField.allow_reps_to_edit} />
      </FieldWrapper>
    );
  } else {
    return (
      <FieldWrapper key={customField?.id}>
        <TitleLabel>{label ?? customField.key}</TitleLabel>
        <NewSelectField
          cf_key={customField?.key}
          disabled={disabled ?? !customField.allow_reps_to_edit}
          id={customField?.id}
          name={`${fieldName}[${index}].value`}
          options={customField?.options?.map((item) => ({ label: item, value: item })) || []}
          value={values[fieldName][index]?.value}
          onChange={(value) => {
            setFieldValue?.(`${fieldName}[${index}].value`, value);
          }}
        />
      </FieldWrapper>
    );
  }
};

export default CustomField;
